import React, { useContext, useEffect, useState } from 'react'
import { deleteBrokerSV, getAllBrokersSV, insertLead, leadCheckforBroker } from '../../services/private/borker.service';
import moment from 'moment';
import Tabs from '../../components/HOC/Tabs';
import SiteVisitLeads from './SiteVisitLeads';
import SiteVisitCompletedLeads from './SiteVisitCompletedLeads';
import SitiVisitAttempts from './SitiVisitAttempts';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import SiteVisitCancelled from './SiteVisitCancelled';
import CreateLeadModal from './Modal/CreateLeadModal';
import SearchBox from '../../components/SearchBox';
import LeadExistModal from './Modal/LeadExistModal';
import BrokerLeadsFilter from './Modal/BrokerLeadsFilter';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
import { getColumnPreferenceForSvTracker, getUsersForSettings } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import Consumer from '../../helpers/context';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import ConfirmDeletionCustom from '../../components/Modals/ConfirmDeletionCustom';
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/plugins/captions.css";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { SV_ENUMS, enums } from '../../helpers/filtersEnum';
import InternalTabs from '../../components/HOC/InternalTabs';

const tabList = [
    {
        name: 'Scheduled',
        value: 'scheduled'
    },
    {
        name: 'Completed',
        value: 'completed'
    },
    // {
    //     name: 'Attempts',
    //     value: 'attempt'
    // },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
]
const svTabList = [
    {
        name: 'All',
        value: 'completed'
    },
    {
        name: 'Un-Tagged',
        value: 'not_tagged'
    },
    {
        name: 'Tagged',
        value: 'tagged'
    },

]

const BorkerLeads = () => {
    const [events, setEvents] = useState([]);
    const [selectedTab, setSelectedTab] = useState({
        name: 'Scheduled',
        value: 'scheduled'
    })
    const [tabRowCount, setTabRowCount] = useState({});
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [leadsCount, setLeadsCount] = useState();
    const [searchText, setSearchText] = useState('');
    const [showCreateLeadModal, setShowCreateLeadModal] = useState(false)
    const [selectedLead, setSelectedLead] = useState('');
    const [showLeadExistModal, setShowLeadExistModal] = useState(false);
    const [leadExistResponse, setLeadExistResponse] = useState({});
    const [refreshTime, setRefreshTime] = useState('');
    const [filters, setFilters] = useState({});
    const [showFilter, setShowFilter] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([]);
    const [showLightModal, setShowLightModal] = useState(false);
    const [selectedImg, setSelectedImg] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [internalTabRowCount, setInternalTabRowCount] = useState({})
    const [assignDisable, setAssignDisable] = useState(false)
    const [selectedInternalTab, setSelectedInternalTab] = useState({
        name: 'All',
        value: 'completed'
    },);

    useDocumentTitle('Site Visit Tracker');
    let { companyConfig } = useContext(Consumer);

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    const getSelectedTab = () => {
        if (selectedTab?.value === 'scheduled' || selectedTab?.value === 'cancelled') {
            return selectedTab?.value
        } else {
            return selectedInternalTab?.value
        }
    }

    const getEvents = async () => {
        setIsLoading(true)
        const payload = {
            "pageNumber": currentPage,
            "perPage": showingResults,
            "searchText": searchText,
            "tab": getSelectedTab(),
            filters: filters
        }
        try {
            const res = await getAllBrokersSV(payload);
            if (res.data.status === 200) {
                setIsLoading(false)
                setRefreshTime(Date.now())
                setEvents(res.data.data?.entries);
                const totalPages = Math.ceil(
                    Number(res.data.data?.stats[selectedTab.value]) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(res.data.data?.stats[selectedTab.value]);
                let stats = {}
                tabList.forEach(i => {
                    stats = { ...stats, [i.name]: `${res.data.data?.stats[i.value]}` }
                })
                let internalStat = {}
                svTabList.forEach(i => {
                    internalStat = { ...internalStat, [i.name]: `${res.data.data?.stats[i.value]}` }
                })
                setInternalTabRowCount(internalStat)
                setTabRowCount(stats)
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }

    const getColumns = async () => {
        const res = await getColumnPreferenceForSvTracker();
        if (res.data.status === 200) {
            // console.log(res.data.data, 'response column')
        }
    }
    const handleCheckLeadExist = async (lead, checkFrom, assignTo) => {
        try {
            setAssignDisable(true)
            const res = await leadCheckforBroker(lead.uuid)
            if (res.data.status === 200) {
                setSelectedLead(lead)
                setLeadExistResponse(res.data.data);
                setShowLeadExistModal(true);
                setAssignDisable(false)
            }
        } catch (err) {
            if (err.response.data.status === 404) {
                setSelectedLead(lead)
                if (checkFrom === 'table') {
                    handleUpdateAssignedTO(lead.uuid, assignTo)
                } else {
                    setShowCreateLeadModal(true)
                    setAssignDisable(false)
                }
            }
        }
    }

    const handleDeleteSV = async () => {
        const res = await deleteBrokerSV(selectedLead.uuid);
        if (res.data.status) {
            toast.success(res.data.message)
            setShowDeleteModal(false);
            getEvents();
        }
    }

    const handleUpdateAssignedTO = async (uuid, assignTo) => {
        const payload = {
            assigend_to: assignTo,
            followupScheduleDate: moment().add('days', 1),
            intentId: intentStatuses.find(i => i.label === 'Hot Lead').value,
            tagging_validity: 30,
            tagging_validity_unit: 'days'
        }
        try {
            await insertLead(uuid, payload).then(res => {
                if (res.status === 200) {
                    // toast.success("Followup Updated");
                    getEvents()
                    setAssignDisable(false);
                }
            })
        } catch (err) {
            toast.error(err.response.data.message);
            setAssignDisable(false)
        }
    }

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setIntentStatuses(companyConfig.Intent);

        }
    }, [companyConfig]);

    useEffect(() => {
        getAllUsers()
        getColumns();
    }, []);

    useEffect(() => {
        getEvents();
    }, [selectedTab, showingResults, currentPage, searchText, filters, selectedInternalTab])

    const removeFilterValue = (keyValue) => {
        let oldFilters = structuredClone(filters);
        delete oldFilters[keyValue]
        setFilters({ ...oldFilters });
    }

    const renderFilterList = (filterLists) => {
        if (SV_ENUMS[filterLists] === 'Project') {
            return filters[filterLists].map((proj, index) => {
                return <li key={`${proj.name}_${index}`}>{proj.name}</li>
            })
        }

        if (SV_ENUMS[filterLists] === 'CP Firm') {
            return filters[filterLists].map((item, index) => (
                <li key={`${item.value}_${index}`}>{item.value}</li>
            ))
        }

        if (SV_ENUMS[filterLists] === 'Marked Date') {
            return <>
                <li className="mb-1">From: {moment(filters[filterLists].from).format("Do MMM YYYY, hh:mm a")} </li>
                <li>To:  {moment(filters[filterLists].to).format("Do MMM YYYY, hh:mm a")}</li>
            </>
        }
        if (SV_ENUMS[filterLists] === 'Marked By') {
            return filters[filterLists].map((item, index) => (
                <li key={`${item.value}_${index}`}>{item.value}</li>
            ))
        }
        if (SV_ENUMS[filterLists] === 'Lead Created By') {
            return filters[filterLists].map((item, index) => (
                <li key={`${item.value}_${index}`}>{item.value}</li>
            ))
        }
        if (SV_ENUMS[filterLists] === 'Completed By') {
            return filters[filterLists].map((item, index) => (
                <li key={`${item.value}_${index}`}>{item.value}</li>
            ))
        }
        if (SV_ENUMS[filterLists] === 'Lead Exist') {
            return <li>Lead Exist </li>
        }
        if (SV_ENUMS[filterLists] === 'Lead Tagged') {
            return <li>Lead Tagged </li>
        }
        if (SV_ENUMS[filterLists] === 'CP Registered') {
            return <li>CP Registered</li>
        }
        if (SV_ENUMS[filterLists] === 'Lead Created Date') {
            return <>
                <li className="mb-1">From: {moment(filters[filterLists].from).format("Do MMM YYYY, hh:mm a")} </li>
                <li>To:  {moment(filters[filterLists].to).format("Do MMM YYYY, hh:mm a")}</li>
            </>
        }

        // filterLists?.entries(([key, value]) => {
        //   console.log(key, value)
        // })
    }

    return (
        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !border-b !border-grayLight">
                <div className="row d-flex d-flex flex-md-fill flex-wrap !mb-4">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-col items-center md:!flex-row">
                        <h1 className="text-2xl mb-0 black fw-po-medium !mr-4">Site Visit Tracker</h1>
                        <p className="mb-0 font-italic black">
                            Last Updated: {moment(refreshTime).format('h :mm a, Do MMM YY')}
                            <span className="text-underline cursor-pointer pr-hover ml-1" onClick={() => {
                                getEvents();
                            }}>
                                refresh
                            </span>
                        </p>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">
                        {/* <div>
                            <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                                <SearchIcon className='mr-2' />
                                <input
                                    type="text"
                                    placeholder='Search For Users'
                                    className=' w-100 focus:outline-none text-sm'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText &&
                                    <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                        <SearchCloseIcon />
                                    </div>}
                            </div>
                        </div> */}
                        <SearchBox searchText={searchText} setSearchText={setSearchText} placeholder={'Search for Site Visits'} classname='mr-0' />
                        <div className="filter-btn !ml-4">
                            <button className={`d-flex align-items-center ${Object.keys(filters)?.length > 0 ? " border-0 pr-hover  active" : "border-0 btn"}`} type="button" onClick={() => setShowFilter(true)}>
                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                                <span className="text-capitalize">Filter {Object.keys(filters)?.length > 0 ? ` (${Object.keys(filters)?.length})` : " "}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <Tabs
                    tabList={tabList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabName={selectedTab}
                    tabRowCount={tabRowCount}
                />
            </div>
            <div className="flex items-center">

                {selectedTab.name === 'Completed' &&
                    <div className="!pl-7 !pr-4 !pt-3">
                        <InternalTabs
                            tabList={svTabList}
                            selectedTab={selectedInternalTab}
                            setSelectedTab={setSelectedInternalTab}
                            tabName={selectedInternalTab}
                            tabRowCount={internalTabRowCount}
                        />
                    </div>
                }
                {selectedTab.name === 'Completed' && Object.keys(filters).length > 0 && <div className="border-r-2 !h-8"></div>}
                {
                    Object.keys(filters).length > 0 ? <div className="all-leads !h-auto filter-applyed pb-2 d-flex align-items-center md:!pl-5 !pl-4 !pt-2">
                        <span className="">Filtered by: &nbsp;</span>
                        <ul className="tags align-items-center pl-1" id="scroll-tag">
                            <OwlCarousel
                                className='owl-theme'
                                dots={false}
                                res='true'
                                autoWidth='true'
                                loop={false}
                                navText={["<img src='/owlleft.svg' /> <img src='/hoverleft.svg' />", "<img src='/right.svg' /> <img src='/hoverright.svg' />"]}
                                margin={10}
                                responsive={{
                                    0: {
                                        items: 1.3,
                                        nav: true,
                                        margin: 0

                                    },
                                    600: {
                                        items: 3,
                                        nav: true
                                    },
                                    900: {
                                        items: 4,
                                        nav: true
                                    },
                                    1000: {
                                        items: 5,
                                        nav: true
                                    },
                                    1200: {
                                        items: 6,
                                        loop: false,
                                        nav: true,
                                        margin: 0

                                    }
                                }
                                }>
                                {Object.keys(filters).map((data, index) => {
                                    return (
                                        <div className="item" key={String(index)}>
                                            <li >
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="button-tooltip" className='sidebar-tooltip tags-tooltip' >
                                                            <div className='info-msg'>
                                                                <ul className="mb-0">
                                                                    {renderFilterList(data)}
                                                                    {/* <li>1. Signed up by mistake</li> */}
                                                                    {/* <li>2. Signed up by mistake</li> */}
                                                                </ul>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button onClick={() => removeFilterValue(data)} className='bg-tr border-0 p-0' bsstyle="default" style={{ fontSize: 0 }}>
                                                        <svg className="ml-0 mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M7.02344 5.07518C7.02344 5.33376 7.12616 5.58176 7.30901 5.76461C7.49186 5.94746 7.73985 6.05018 7.99844 6.05018C8.25702 6.05018 8.50502 5.94746 8.68787 5.76461C8.87071 5.58176 8.97344 5.33376 8.97344 5.07518C8.97344 4.81659 8.87071 4.5686 8.68787 4.38575C8.50502 4.2029 8.25702 4.10018 7.99844 4.10018C7.73985 4.10018 7.49186 4.2029 7.30901 4.38575C7.12616 4.5686 7.02344 4.81659 7.02344 5.07518ZM7.35884 11.3672C7.38597 11.517 7.46487 11.6526 7.58177 11.7503C7.69867 11.8479 7.84613 11.9014 7.99844 11.9014C8.15074 11.9014 8.29821 11.8479 8.41511 11.7503C8.532 11.6526 8.6109 11.517 8.63804 11.3672L8.64844 11.2502V8.00018L8.63804 7.88318C8.6109 7.73331 8.532 7.59773 8.41511 7.5001C8.29821 7.40247 8.15074 7.34899 7.99844 7.34899C7.84613 7.34899 7.69867 7.40247 7.58177 7.5001C7.46487 7.59773 7.38597 7.73331 7.35884 7.88318L7.34844 8.00018V11.2502L7.35884 11.3672Z" fill="#92929D" />
                                                            <rect x="0.75" y="-0.75" width="11.5" height="11.5" rx="5.75" transform="matrix(1 0 0 -1 1.5 13)" stroke="#92929D" strokeWidth="1.5" />
                                                        </svg>

                                                    </Button>
                                                </OverlayTrigger>
                                                {SV_ENUMS[data]}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.94099 7.99988L12.459 11.5211C12.7193 11.7816 12.7191 12.2037 12.4586 12.4639C12.1981 12.7242 11.776 12.724 11.5158 12.4635L7.99748 8.94199L4.47097 12.4633C4.21043 12.7234 3.78832 12.7231 3.52816 12.4626C3.26801 12.202 3.26832 11.7799 3.52886 11.5197L7.05509 7.99875L3.5304 4.47087C3.27017 4.2104 3.27036 3.78829 3.53083 3.52806C3.79129 3.26783 4.2134 3.26802 4.47364 3.52848L7.9986 7.05664L11.5159 3.54459C11.7764 3.28444 12.1985 3.28475 12.4587 3.5453C12.7188 3.80584 12.7185 4.22795 12.458 4.4881L8.94099 7.99988Z" fill="#44444F"></path></svg>
                                            </li>
                                        </div>
                                    )
                                })}

                            </OwlCarousel>
                        </ul>
                    </div> : " "
                }
            </div>
            <div className='broker-page'>
                {selectedTab?.name === 'Scheduled' &&
                    <SiteVisitLeads
                        leads={events}
                        getEvents={getEvents}
                        searchText={searchText}
                        setShowLightModal={setShowLightModal}
                        setSelectedImg={setSelectedImg}
                        setShowDeleteModal={setShowDeleteModal}
                        setSelectedLead={setSelectedLead}
                        isLoading={isLoading}
                        selectedLead={selectedLead}
                    />}
                {selectedTab?.name === 'Completed' && <SiteVisitCompletedLeads
                    leads={events}
                    setSelectedLead={setSelectedLead}
                    setShowCreateLeadModal={setShowCreateLeadModal}
                    getEvents={getEvents}
                    handleCheckLeadExist={handleCheckLeadExist}
                    searchText={searchText}
                    allUsers={allUsers}
                    setEvents={setEvents}
                    handleUpdateAssignedTO={handleUpdateAssignedTO}
                    setShowLightModal={setShowLightModal}
                    setSelectedImg={setSelectedImg}
                    setShowDeleteModal={setShowDeleteModal}
                    isLoading={isLoading}
                    assignDisable={assignDisable}
                />}
                {/* {selectedTab?.name === 'Attempts' && <SitiVisitAttempts leads={events} />} */}
                {selectedTab?.name === 'Cancelled' && <SiteVisitCancelled
                    leads={events}
                    searchText={searchText}
                    setShowLightModal={setShowLightModal}
                    setSelectedImg={setSelectedImg}
                    setSelectedLead={setSelectedLead}
                    setShowDeleteModal={setShowDeleteModal}
                    isLoading={isLoading}

                />}
                <LeadsFooterBar
                    showingResults={showingResults}
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                    classname='!basis-0 !z-30'
                />
            </div>

            {showCreateLeadModal && <CreateLeadModal
                show={showCreateLeadModal}
                setShow={setShowCreateLeadModal}
                selectedLead={selectedLead}
                getEvents={getEvents}
            />}
            {
                showLeadExistModal && <LeadExistModal
                    show={showLeadExistModal}
                    closeModal={() => setShowLeadExistModal(false)}
                    selectedLead={selectedLead}
                    leadExistResponse={leadExistResponse}
                />
            }
            {
                showFilter && <BrokerLeadsFilter
                    show={showFilter}
                    setShow={setShowFilter}
                    filtersData={filters}
                    setFiltersData={setFilters}
                />
            }
            {showLightModal && <Lightbox
                open={showLightModal}
                close={() => {
                    setShowLightModal(false)
                    setSelectedImg('')
                }}
                slides={[{ src: selectedImg.image, title: `${selectedImg.customer_name} - ${selectedImg.customer_mobile}` }]}
                plugins={[Captions]}
            // video={{
            //     controls: true,
            //     playsInline: true,
            //     autoPlay: false,
            //     loop: false,
            //     muted: true,
            //     disablePictureInPicture: false,
            //     disableRemotePlayback: false,
            //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
            //     crossOrigin: '',
            //     preload: '',
            // }}
            />}
            {
                showDeleteModal && selectedLead.uuid && <ConfirmDeletionCustom
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    deleteHandler={handleDeleteSV}
                    deleteText={selectedLead.customer_name}
                />
            }
        </div>

    )
}

export default BorkerLeads