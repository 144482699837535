import React, { useEffect, useState } from 'react'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
// import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/success.svg';

import { toast } from 'react-toastify';

import { ReactComponent as BackIcon } from '../../../../assets/icons/back.svg';

import { addBrokerCompany, addBrokerUser, editBrokerUser, updateBrokerCompany } from '../../../../services/private/company.service';
import InputText from '../../../../components/InputGroup/InputText';
import MobileInput from '../../../../components/InputGroup/MobileInput';
import RightModal from '../../../../components/Modals/RightModal';
import { Oval } from 'react-loader-spinner';
import { ALL_REGEX } from '../../../../config/static';


const customStyles = {
    option: (styles, state) => ({
        ...styles,
        zIndex: 99,
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: (base, state) => ({
        ...base,
        zIndex: 99,
        // You can also use state.isFocused to conditionally style based on the focus state
    }),

};

const AddBrokerCompanyUser = ({ show, closeModal, uuid, getUsers, selectedUser }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [loading, setLoading] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    useEffect(() => {
        if (Object.keys(selectedUser?.length > 0)) {
            setEmail(selectedUser?.email || '');
            setMobile(selectedUser?.mobile || '');
            setName(selectedUser?.name || '');
        }
    }, [selectedUser])

    const handleSubmit = async () => {
        if (!name || !email || !mobile) {
            toast.error('Please fill all required field')
            return;
        }
        if (mobile && mobile.length < 12) {
            setMobileError(true);
            return;
        } else {
            setMobileError(false)
        }
        if (email && !ALL_REGEX.EMAIL.test(email)) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false)
        }
        const payload = {
            mobile: mobile || null,
            email: email || null,
            name: name || null,
        }
        try {
            if (selectedUser?.uuid) {
                setLoading(true);
                const res = await editBrokerUser(selectedUser?.uuid, { ...payload, company_uuid: uuid })
                if (res.data.status === 200) {
                    setLoading(false);
                    toast.success(res.data.message);
                    getUsers();
                    closeModal();
                } else {
                    console.log(res.data.message);
                }
            } else {
                setLoading(true);
                const res = await addBrokerUser(uuid, payload)
                if (res.data.status === 200) {
                    setLoading(false);
                    toast.success(res.data.message);
                    getUsers();
                    closeModal();
                } else {
                    console.log(res.data.message);
                }
            }
        } catch (err) {
            toast.error(err.response.data.message);
            setLoading(false);
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <div className='flex items-center'>
                    <button onClick={closeModal}>
                        <BackIcon />
                    </button>
                    <h1 className='mb-0 black inter fz20 fw-po-medium ml-2'>
                        {selectedUser?.uuid ? 'Edit' : 'Add'} User
                    </h1>
                </div>

                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>

            <div onSubmit={handleSubmit} className='flex flex-col h-full  overflow-hidden'>
                <div className='h-full overflow-auto !px-5 !space-y-4'>
                    <InputText
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        index={2}
                        type="text"
                        inputclass={'!mt-4'}
                        placeholder="Enter Name"
                        label="Name"
                        required={true}
                    />
                    <MobileInput
                        index={1}
                        countryCode="+91"
                        type='text'
                        value={mobile}
                        onChange={(e) => {
                            setMobile(e.phone)
                        }}
                        placeholder="e.g. 9810139281"
                        label="Mobile No."
                        required={true}
                        error={
                            (mobileError && mobile) ? 'Please enter valid mobile no.' : ''
                        }
                    />
                    <InputText
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        index={2}
                        type="email"
                        placeholder="Enter Email"
                        label="Email"
                        required={true}
                        error={
                            (emailError && email) ? 'Please enter valid email' : ''
                        }
                    />

                </div>


                <div className="d-flex flex-row align-items-center justify-content-between !px-5 gap-x-4 !py-4 righmodalfooter shadow-topshadow">

                    <button onClick={closeModal}
                        className='border rounded-lg py-2.5 w-28 basis-28 grow-0 shrink-0 border-primary !text-primary font-semibold text-16 text-center'>Cancel</button>
                    {!loading && <button onClick={() => {
                        handleSubmit()
                    }}
                        className='border rounded-lg py-2.5 w-full ml-4 !bg-primary !border-primary text-white font-semibold text-16 text-center hover:opacity-90'>
                        {selectedUser?.uuid ? 'Update' : 'Save'}
                    </button>}
                    {
                        loading && <button className='border rounded-lg py-2.5 w-full ml-4 !bg-primary flex items-center justify-center  text-center hover:opacity-90'>
                            <Oval
                                height={24}
                                width={24}
                                color="#ffffff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#ffffff"
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                            />
                        </button>
                    }

                </div >
            </div>

        </RightModal>
    )
}

export default AddBrokerCompanyUser