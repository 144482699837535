import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import rightPageArrow from '../../assets/icons/rightPageArrow.svg';
import leftPageArrow from "../../assets/icons/leftPageArrow.svg";
import Select from 'react-select';

export default function LeadsFooterBar({
    showingResults,
    setShowingResults,
    currentPage,
    setCurrentPage,
    maxPage,
    leadsCount,
    classname
}) {

    const showingPageOption = [
        {
            value: 10,
            label: "10/Page"
        },
        {
            value: 20,
            label: "20/Page"
        },
        {
            value: 30,
            label: "30/Page"
        },
        {
            value: 40,
            label: "40/Page"
        },
        {
            value: 50,
            label: "50/Page"
        },
        {
            value: 100,
            label: "100/Page"
        }
    ];

    const [selectedShowingResults, setSelectedShowingResults] = useState({
        value: 10,
        label: "10/Page"
    });

    useEffect(() => {
        if (showingResults) {
            const selectedPage = showingPageOption.find(val => val.value == showingResults);
            setSelectedShowingResults(selectedPage)
        }
    }, [showingResults]);

    return (

        <div className={`${classname} pagination-custom bg-white py-12 px-20 `}>
            <div className="row align-items-center">
                <div className="col-md-6 col-5 pr-0">
                    <div className="d-flex align-items-center">
                        <h2 className="fz14 black-dark-700 mb-0 mr-8">Showing Results</h2>
                        <Select className='black-dark-600'
                            options={showingPageOption}
                            value={selectedShowingResults}
                            name="showingResults"
                            menuPlacement="top"
                            isSearchable={false}
                            onChange={(e) => {
                                setShowingResults(e.value);
                                localStorage.setItem('showingResults', e.value)
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-7">
                    <nav aria-label="Page navigation ">
                        <ul className="d-flex align-items-center justify-content-end black-dark-700 mb-0">
                            {
                                currentPage > 1 ?
                                    <li className="page-item" onClick={() => {
                                        localStorage.removeItem('rowIndex')
                                        setCurrentPage(currentPage - 1);
                                        localStorage.setItem('currentPage', currentPage - 1)
                                    }}>
                                        <a className="page-link border-0 bg-tr p-0 mr-12">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#F1F1F5" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#171725" />
                                                <path d="M4 0.5H20V-0.5H4V0.5ZM23.5 4V20H24.5V4H23.5ZM20 23.5H4V24.5H20V23.5ZM0.5 20V4H-0.5V20H0.5ZM4 23.5C2.067 23.5 0.5 21.933 0.5 20H-0.5C-0.5 22.4853 1.51472 24.5 4 24.5V23.5ZM23.5 20C23.5 21.933 21.933 23.5 20 23.5V24.5C22.4853 24.5 24.5 22.4853 24.5 20H23.5ZM20 0.5C21.933 0.5 23.5 2.067 23.5 4H24.5C24.5 1.51472 22.4853 -0.5 20 -0.5V0.5ZM4 -0.5C1.51472 -0.5 -0.5 1.51472 -0.5 4H0.5C0.5 2.067 2.067 0.5 4 0.5V-0.5Z" fill="#E2E2EA" />
                                            </svg>
                                        </a>
                                    </li> : " "
                            }

                            {leadsCount > 0 &&
                                <>
                                    <li>Page <span>{currentPage}</span>/ <span>{maxPage}</span></li>
                                    {maxPage > currentPage ?

                                        <li className="page-item" onClick={() => {
                                            localStorage.removeItem('rowIndex');
                                            setCurrentPage(currentPage + 1)
                                            localStorage.setItem('currentPage', currentPage + 1)
                                        }}>
                                            <a className="page-link border-0 bg-tr p-0 ml-3" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M24 4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4Z" fill="#F1F1F5" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.5858 12L9.29289 16.2929C8.90237 16.6834 8.90237 17.3166 9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289C8.90237 6.68342 8.90237 7.31658 9.29289 7.70711L13.5858 12Z" fill="#171725" />
                                                    <path d="M20 0.5H4V-0.5H20V0.5ZM0.5 4V20H-0.5V4H0.5ZM4 23.5H20V24.5H4V23.5ZM23.5 20V4H24.5V20H23.5ZM20 23.5C21.933 23.5 23.5 21.933 23.5 20H24.5C24.5 22.4853 22.4853 24.5 20 24.5V23.5ZM0.5 20C0.5 21.933 2.067 23.5 4 23.5V24.5C1.51472 24.5 -0.5 22.4853 -0.5 20H0.5ZM4 0.5C2.067 0.5 0.5 2.067 0.5 4H-0.5C-0.5 1.51472 1.51472 -0.5 4 -0.5V0.5ZM20 -0.5C22.4853 -0.5 24.5 1.51472 24.5 4H23.5C23.5 2.067 21.933 0.5 20 0.5V-0.5Z" fill="#E2E2EA" />
                                                </svg>
                                            </a>
                                        </li> :
                                        " "}
                                </>
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}