import React, { useEffect, useRef, useState } from 'react'
import TooltipText from '../../../components/Tooltip';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as InfoIcon } from '../../../assets/icons/info_icon.svg'
import Slider from "react-slick";
import SelectedUnitModal from '../Modal/SelectedUnitModal';

// function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={`w-12 h-12  z-10 cursor-pointer ${className}`}
//             onClick={onClick}
//         >
//             {/* <LeftIcon /> */}
//             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M24 4C24 1.79086 22.2091 0 20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M13.5858 12L9.29289 16.2929C8.90237 16.6834 8.90237 17.3166 9.29289 17.7071C9.68342 18.0976 10.3166 18.0976 10.7071 17.7071L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289C8.90237 6.68342 8.90237 7.31658 9.29289 7.70711L13.5858 12Z" fill="#171725" /><path d="M20 0.5H4V-0.5H20V0.5ZM0.5 4V20H-0.5V4H0.5ZM4 23.5H20V24.5H4V23.5ZM23.5 20V4H24.5V20H23.5ZM20 23.5C21.933 23.5 23.5 21.933 23.5 20H24.5C24.5 22.4853 22.4853 24.5 20 24.5V23.5ZM0.5 20C0.5 21.933 2.067 23.5 4 23.5V24.5C1.51472 24.5 -0.5 22.4853 -0.5 20H0.5ZM4 0.5C2.067 0.5 0.5 2.067 0.5 4H-0.5C-0.5 1.51472 1.51472 -0.5 4 -0.5V0.5ZM20 -0.5C22.4853 -0.5 24.5 1.51472 24.5 4H23.5C23.5 2.067 21.933 0.5 20 0.5V-0.5Z" fill="#E2E2EA" /></svg>
//         </div>
//     );
// }

// function SamplePrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={`w-12 h-12 z-10 cursor-pointer ${className}`}
//             onClick={onClick}
//         >
//             {/* <RightIcon /> */}
//             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z" fill="#F1F1F5" /><path fillRule="evenodd" clipRule="evenodd" d="M10.4142 12L14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071C14.3166 18.0976 13.6834 18.0976 13.2929 17.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L13.2929 6.29289C13.6834 5.90237 14.3166 5.90237 14.7071 6.29289C15.0976 6.68342 15.0976 7.31658 14.7071 7.70711L10.4142 12Z" fill="#171725" /><path d="M4 0.5H20V-0.5H4V0.5ZM23.5 4V20H24.5V4H23.5ZM20 23.5H4V24.5H20V23.5ZM0.5 20V4H-0.5V20H0.5ZM4 23.5C2.067 23.5 0.5 21.933 0.5 20H-0.5C-0.5 22.4853 1.51472 24.5 4 24.5V23.5ZM23.5 20C23.5 21.933 21.933 23.5 20 23.5V24.5C22.4853 24.5 24.5 22.4853 24.5 20H23.5ZM20 0.5C21.933 0.5 23.5 2.067 23.5 4H24.5C24.5 1.51472 22.4853 -0.5 20 -0.5V0.5ZM4 -0.5C1.51472 -0.5 -0.5 1.51472 -0.5 4H0.5C0.5 2.067 2.067 0.5 4 0.5V-0.5Z" fill="#E2E2EA" /></svg>
//         </div>
//     );
// }

// var settings = {
//     dots: false,
//     infinite: false,
//     margin: 0,
//     speed: 1000,
//     autoplay: false,
//     slidesToShow: 4,
//     slidesToScroll: 2,
//     autoplaySpeed: 3000,
//     arrows: true,
//     adaptiveHeight: true,
//     nextArrow: <SampleNextArrow />,
//     prevArrow: <SamplePrevArrow />,
// };

const RamaFacingStrip = {
    'Tower C': [
        {
            name: ''
        },
        {
            name: ''
        },
        {
            name: 'P'
        },
        {
            name: 'P'
        },
        {
            name: 'P'
        },
        {
            name: 'P'
        },
        {
            name: ''
        },
        {
            name: ''
        },

    ],
    'Tower D': [
        {
            name: 'A'
        },
        {
            name: 'P'
        },
        {
            name: 'P'
        },
        {
            name: 'P'
        },
        {
            name: 'P'
        },
        {
            name: 'AH'
        },
        {
            name: 'AH'
        },
        {
            name: 'AH'
        },
        {
            name: 'A'
        },
        {
            name: 'A'
        },
        {
            name: 'A'
        },
    ],
    'Tower E': [
        {
            name: 'A'
        },
        {
            name: 'A'
        },
        {
            name: 'AH'
        },
        {
            name: 'AH'
        },
        {
            name: 'AH'
        },
        {
            name: 'AH'
        },
        {
            name: 'A'
        },
        {
            name: 'A'
        },
    ]
}

const getClassName = (name) => {
    switch (name) {
        case 'Available':
            return 'bg-green100 !border !border-green';
        case 'Booked':
            return 'bg-blueHighlight !border !border-primary';
        case 'Unavailable':
            return '!border !border-grayLight !bg-grayLight';
        case 'Blocked':
            return '!border !border-yellow-dark bg-yellow-dark/10';
    }
}

const getClass = (status) => {
    switch (status) {
        case 'Available':
            return '!border-[#05A36F] hover:!bg-[#05A36F]/5 hover:!border-2 !bg-white'
        case 'Unavailable':
            return '!border-grayLight !bg-grayLight opacity-50'
        case 'Blocked':
            return '!border-yellow-dark !bg-yellow-dark/5 hover:!bg-yellow-dark/10 hover:!border-2'
        case 'Pending confirmation':
            return '!border-orange-dark !bg-orange-dark/5 hover:!bg-orange-dark/10 hover:!border-2'
        case 'Booked':
            return '!border-primary !bg-primary/5 hover:!bg-primary/10 hover:!border-2'
    }
}

const getStatusClass = (status) => {
    switch (status) {
        case 'Available':
            return 'text-green bg-green100'
        case 'Unavailable':
            return 'text-black700 bg-black200'
        case 'Blocked':
            return 'text-primary bg-blueHighlight'
        case 'Pending confirmation':
            return 'text-orange-dark bg-orange-dark/10'
        case 'Booked':
            return 'text-white bg-primary'
    }
}

const StatusName = {
    Blocked: 'Token',
    Unavailable: 'Hold'
}

const GridView = ({
    unitList,
    isUnitTypePlot,
    getSelectedUnitBookings,
    inventoryStats,
    inventoryStatsCount,
    setSelectedStatus,
    selectedStatus,
    unitNameWiseCount,
    gridProjectUnits,
    searchText,
    selectedTab,
    userProfile
}) => {
    const [selectedUnit, setSelectedUnit] = useState();
    const [showSelectedUnitModal, setShowSelectedUnitModal] = useState(false)
    const prevScrollTop = useRef(0); // To store the previous scroll position
    const scrollableDivRef = useRef(null); // Reference to the scrollable div
    const [isStatsVisible, setIsStatsVisible] = useState(true);

    const handleScroll = () => {
        if (scrollableDivRef.current) {
            const currentScrollTop = scrollableDivRef.current.scrollTop; // Get current scroll position

            if (currentScrollTop > prevScrollTop.current) {
                document.getElementById('inventory-stats').style.display = 'none'
                setIsStatsVisible(false);
            } else if (currentScrollTop < prevScrollTop.current) {
                document.getElementById('inventory-stats').style.display = 'flex'
                setIsStatsVisible(true)
            }

            prevScrollTop.current = currentScrollTop; // Update the previous scroll position
        }
    };

    useEffect(() => {
        const scrollableDiv = scrollableDivRef.current;

        if (scrollableDiv) {
            scrollableDiv.addEventListener('scroll', handleScroll); // Attach the scroll event listener

            return () => {
                scrollableDiv.removeEventListener('scroll', handleScroll); // Cleanup on component unmount
            };
        }
    }, []);

    const handleMouseEnter = (name, index) => {
        const el = document.getElementById(`${name}_${index}`);
        el.classList.remove('hidden');
        el.classList.add('inline-block');
        console.log(el.classList, 'classlist', `${name}_${index}`)
    }
    const handleMouseLeave = (name, index) => {
        const el = document.getElementById(`${name}_${index}`)
        el.classList.add('hidden');
        el.classList.remove('inline-block');
    }


    const handleSelectUnit = (floor) => {
        const selected = gridProjectUnits.find(list => list.floor_no === floor)
        setSelectedUnit(selected);
        setShowSelectedUnitModal(true);
    }
    const splitConfigName = (configName) => {
        if (!configName) {
            return '';
        }
        let name = configName.split('-');
        return name[1]
    }
    const mappedUnit = [
        'Sq.feet'
    ]
    const getAreaUnit = (config) => {
        if (config?.carpet_area !== null) {
            return <>
                <span>{splitConfigName(config?.unit_configuration)}</span>
                <span className='w-1 h-1 rounded-full bg-black700 mx-1'></span>
                <span>{config?.carpet_area} {mappedUnit.includes(config?.carpet_area_unit) ? 'sq.ft' : config?.carpet_area_unit}</span>
            </>
        } else if (config?.super_area !== null) {
            return <>
                <span>{splitConfigName(config?.unit_configuration)}</span>
                <span className='w-1 h-1 rounded-full bg-black700 mx-1'></span>
                <span>{config?.super_area} {mappedUnit.includes(config?.super_area_unit) ? 'sq.ft' : config?.super_area_unit}</span>
            </>
        } else if (config?.built_up_area !== null) {
            return <>
                <span>{splitConfigName(config?.unit_configuration)}</span>
                <span className='w-1 h-1 rounded-full bg-black700 mx-1'></span>
                <span>{config?.built_up_area} {mappedUnit.includes(config?.built_up_area_unit) ? 'sq.ft' : config?.built_up_area_unit}</span>
            </>
        }
    }

    return (
        <div className='!p-4 !pt-0'>
            <div className=''>
                <div
                    className='w-full  sm:w-[80%] flex items-center justify-between   bg-white gap-6 !mb-4 h-max border rounded-xl p-2.5' id='inventory-stats'>
                    <div
                        className={`flex items-center w-[130px] cursor-pointer text-xs ${!selectedStatus ? 'text-black font-semibold bg-black200' : 'text-black600 hover:text-black hover:bg-black100'}  p-1 rounded-md`}
                        onClick={() => setSelectedStatus(null)}
                    >
                        <div className='flex items-center cursor-pointer !mr-2'

                        >
                            <div className={`w-6 h-6 rounded-lg bg-white mr-1.5 border`}></div>
                            <p className='m-0 '>All</p>
                        </div>
                        <p className='m-0'>{inventoryStatsCount['total'] || '0'}</p>
                    </div>
                    {
                        inventoryStats.map((stat, index) => (
                            <div
                                className={`flex items-center cursor-pointer w-[130px] text-xs ${selectedStatus === stat.display_name ? 'text-black font-semibold bg-black200' : 'text-black600 hover:text-black hover:bg-black100'} p-1 rounded-md`}
                                key={stat.display_name}
                                onClick={() => setSelectedStatus(stat.display_name)}
                            >
                                <div className='flex items-center cursor-pointer !mr-2'

                                // onMouseOver={() => handleMouseEnter(stat.display_name, index)}
                                // onMouseLeave={() => handleMouseLeave(stat.display_name, index)}
                                >
                                    <div className={`w-6 h-6 rounded-lg mr-1.5 ${getClassName(stat.display_name)}`}></div>
                                    <p className='m-0' id={`${stat.display_name}_${index}`}>{StatusName[stat.display_name] ? StatusName[stat.display_name] : stat.display_name}</p>
                                </div>
                                <p className='m-0'>{inventoryStatsCount[stat.display_name] || '0'}</p>
                            </div>
                        ))
                    }
                    <div
                        className={`flex items-center cursor-pointer text-xs ${selectedStatus === 'Pending_confirmation' ? 'text-black font-semibold bg-black200' : 'text-black600 hover:text-black hover:bg-black100'} p-1 rounded-md`}
                        onClick={() => setSelectedStatus('Pending_confirmation')}
                    >
                        <div className='flex items-center  !mr-2'
                        >
                            <div className={`w-6 h-6 rounded-lg mr-1.5 !border !border-orange-dark bg-orange-dark/10`}></div>
                            {/* <TooltipText title={'Pending Confirmation'}> */}
                            <p className='m-0 '>Pending Confirmation</p>
                            {/* </TooltipText> */}
                        </div>
                        <p className='m-0'>{inventoryStatsCount['Pending_confirmation'] || '0'}</p>
                    </div>
                </div >
                {gridProjectUnits?.length > 1 && <div className={`border-collapse ${isStatsVisible ? '!h-[68vh]' : '!h-[75vh]'} w-full rounded-xl border bg-black100 relative overflow-auto`} ref={scrollableDivRef}>
                    <table class="">
                        <tbody>
                            {gridProjectUnits.map((list, index) => (
                                <tr key={`${list.floor_no}`} className={`flex items-center relative ${(index === 0 && userProfile.company_id === 596 && !selectedStatus) ? 'h-[141px]' : 'h-[117px]'}`}>
                                    <td className={`inventory-floor-shadow  bg-white !pl-6 sticky left-0 h-full z-10`}>
                                        <div
                                            className={`w-[86px] whitespace-nowrap h-full  rounded-xl cursor-pointer flex flex-col justify-center`}
                                            onClick={() => handleSelectUnit(list.floor_no)}
                                        >
                                            <div className='font-semibold text-black800 text-base leading-6'>Floor {list?.floor_no}</div>
                                            <div className='text-black700 text-xs font-medium leading-[22px]'>{`${list?.available_count || '0'}/${list?.units?.length}`} units</div>
                                        </div>
                                        <div className='border-line'></div>
                                    </td>
                                    <td className='w-full '>
                                        {(index === 0 && userProfile.company_id === 596 && !selectedStatus) && Object.keys(RamaFacingStrip).includes(selectedTab.name) && <div className='flex !pl-6  h-6 text-xs font-medium facing-strip-shadow !bg-white'>
                                            {RamaFacingStrip[selectedTab.name]?.map((fs, fsIndex) => (
                                                <div className='w-[140px] !mr-4 flex items-center justify-center'>
                                                    {fs.name}
                                                </div>
                                            ))}
                                        </div>}
                                        <div className='!ml-6 grid-slider flex items-center !py-5 !border-b !border-dashed !border-[#e5e7eb] ' key={`${index}_${list.floor_no}`}>
                                            {
                                                list?.units?.length > 0 && list?.units?.map((unit, uIndex) => (
                                                    <div key={unit.uuid}>
                                                        <div
                                                            className={`w-[140px] h-[74px] inline-block !p-2 !mr-4 rounded-xl inventory-grid-box-shadow !border cursor-pointer ${getClass(unit.status)}`}
                                                            onClick={() => {
                                                                getSelectedUnitBookings(unit.uuid, 'view', unit);
                                                            }}
                                                        >
                                                            <div className='flex justify-between items-center'>
                                                                <span className='font-bold text-[#344054] leading-6'>{unit?.name || '-'}</span>
                                                                {/* {unit.status === 'Pending confirmation' ? <TooltipText title={`${unit?.status} at ${unit?.booking_stage}`}>
                                                            <div className={`text-xs text-center rounded-lg p-1 capitalize flex items-center leading-[22px] ${getStatusClass(unit.status)}`}>
                                                                
                                                                {unit?.booking_stage === 'booking' ? 'Booked' : 'Token'} <InfoIcon className='!ml-1' />
                                                            </div>
                                                        </TooltipText> :
                                                            <div className={`text-xs text-center rounded-lg p-1 capitalize ${getStatusClass(unit?.status)}`}>
                                                                {unit?.status}
                                                            </div>
                                                        } */}
                                                            </div>
                                                            <div className='!mt-1'>
                                                                <p className='m-0 flex text-xs font-medium text-black700 items-center'>
                                                                    {unit?.configuration?.unit_type === 'Plot' &&
                                                                        <span>{unit?.configuration?.plot_area ? `${unit?.configuration?.plot_area} ${unit?.configuration?.plot_area_unit}` : '-'}</span>
                                                                    }
                                                                    {unit?.configuration?.unit_type !== 'Plot' && <>
                                                                        {getAreaUnit(unit?.configuration)}
                                                                    </>}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>}
                {gridProjectUnits?.length === 1 && <div className={`${isStatsVisible ? '!h-[68vh]' : '!h-[75vh]'} w-full rounded-xl border bg-black100 relative`} ref={scrollableDivRef}>
                    {gridProjectUnits.map((list, index) => (
                        <div key={`${list.floor_no}`} className={` relative flex h-full`}>
                            <div className='inventory-floor-shadow rounded-l-xl !mr-4 !bg-white !pl-4 flex items-center justify-center h-full'>
                                <div
                                    className={`w-[91px] whitespace-nowrap rounded-xl cursor-pointer flex flex-col justify-center`}
                                    onClick={() => handleSelectUnit(list.floor_no)}
                                >
                                    <div className='font-semibold text-black800 text-base leading-6'>Floor {list?.floor_no}</div>
                                    <div className='text-black700 text-xs font-medium leading-[22px]'>{list?.units?.length} units</div>
                                </div>
                            </div>
                            <div className='!py-5 !pl-4 border-b border-dashed h-[68vh] overflow-auto w-full '>
                                <div className=' grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 !gap-4 ' key={`${index}_${list.floor_no}`}>
                                    {
                                        list?.units?.length > 0 && list?.units?.map((unit, uIndex) => (
                                            <>
                                                <div
                                                    className={`w-[140px] h-[74px] inline-block !p-2 rounded-xl inventory-grid-box-shadow !border cursor-pointer ${getClass(unit.status)}`}
                                                    onClick={() => {
                                                        getSelectedUnitBookings(unit.uuid, 'view', unit);
                                                    }}
                                                    key={unit.uuid}
                                                >
                                                    <div className='flex justify-between items-center'>
                                                        <span className='font-bold text-[#344054] leading-6'>{unit?.name || '-'}</span>
                                                    </div>
                                                    <div className='!mt-1'>
                                                        <p className='m-0 flex text-xs font-medium text-black700 items-center'>
                                                            {unit?.configuration?.unit_type === 'Plot' &&
                                                                <span>{unit?.configuration?.plot_area ? `${unit?.configuration?.plot_area} ${unit?.configuration?.plot_area_unit}` : '-'}</span>
                                                            }
                                                            {unit?.configuration?.unit_type !== 'Plot' && <>
                                                                {getAreaUnit(unit?.configuration)}
                                                            </>}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
                {gridProjectUnits?.length === 0 && <div className={`border-collapse ${isStatsVisible ? '!h-[68vh]' : '!h-[75vh]'} w-full rounded-xl border bg-black100 relative overflow-auto`} ref={scrollableDivRef}>
                    {
                        !searchText && <div className='w-full h-full flex flex-col items-center justify-center' >
                            <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                            <p>
                                No Unit Available
                            </p>
                        </div>
                    }
                    {
                        searchText && <div className='w-full h-full flex items-center flex-col justify-center' >
                            <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                            <p>
                                No Unit Found
                            </p>
                        </div>
                    }
                </div>}
            </div >
            {
                showSelectedUnitModal && <SelectedUnitModal
                    show={showSelectedUnitModal}
                    closeModal={() => setShowSelectedUnitModal(false)}
                    selectedUnit={selectedUnit}
                    getSelectedUnitBookings={getSelectedUnitBookings}
                    getClassName={getClassName}
                    getStatusClass={getStatusClass}
                    getAreaUnit={getAreaUnit}
                    getClass={getClass}
                />
            }
        </div >
    )
}

export default GridView