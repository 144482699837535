import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import CancelCSV from "../../../../../components/Modals/cancel-csv";
import PopUpModal from "../../../../../components/Modals/PopUpModal";
import { createDatewithTime } from '../../../../../helpers/date'

const HousingAdsTable = ({
  // apiKey,
  pageIntegrationList,
  // inactiveProjectFormId,
  // viewForm,
  // makeLeadActive,
  // makeLeadInactive,
  // setEditLeadDetail,
  // integrationPermission,
  viewEmailSnippet,
  openProjectMappingModal
}) => {
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [selectedProjectFormUUID, setSelectedProjectFormUUID] = useState("");
  const [editProfileIdModal, setEditProfileIdModal] = useState(false);

  const copyFunction = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
  };

  const closeEditProfileIdModal = () => {
    setEditProfileIdModal(false)
  }

  return (
    <>
      {showAlertDialog ? (
        <PopUpModal
          setShow={showAlertDialog}
          show={setShowAlertDialog}
          heading={<>Integration Delete from Make?</>}
          body={
            <div className="p-3">
              <div className="mb-4">
                Make sure Google lead integration is deleted from Make before deleting
                the integration on propacity platform.
              </div>
              <div className="justify-content-end d-flex modal-footer">
                <Button
                  variant=""
                  className="mr-2 cancel"
                  onClick={() => setShowAlertDialog(!showAlertDialog)}
                >
                  Cancel
                </Button>
                <Button
                  variant=""
                  className='del-btn'
                  onClick={() => {
                    // inactiveProjectFormId(selectedProjectFormUUID);
                    setShowAlertDialog(!showAlertDialog);
                  }}
                >
                  Confirm Delete
                </Button>
              </div>
            </div>
          }
        />
      ) : null}
      <div className="housing-table mt-24 pl-24 h-full overflow-auto">
        <div className="def-table pl-0 pr-24 !h-full">
          <table className="table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Housing Project Name</th>
                <th>Profile ID</th>
                <th>Platform Project</th>
                <th>Created By</th>
                {/* <th>Total Leads</th> */}
                <th>Date Created</th>
                {/* <th>Status</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pageIntegrationList.map((page, index) => {
                return (
                  <tr key={String(index)}>
                    <td>{index + 1}</td>
                    <td>
                      {page.external_project.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p className='text-capitalize'>{page.external_project}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className='text-capitalize three-dots'>{page.external_project}</span>
                        </OverlayTrigger>
                        :
                        <span className='text-capitalize three-dots'>{page.external_project}</span>}


                    </td>
                    <td ><span className="three-dots">{page.profile_id}</span></td>
                    <td>
                      {page.project_name.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p className='text-capitalize'>{page.project_name}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className='text-capitalize three-dots'>{page.project_name}</span>
                        </OverlayTrigger>
                        :
                        <span className='text-capitalize three-dots'>{page.project_name}</span>}

                    </td>
                    <td>
                      {page.user_name.length > 20 ?
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="button-tooltip" >
                              <div className='info-msg'>
                                {/* <h4>Total Leads</h4> */}
                                <p className='text-capitalize'>{page.user_name}</p>
                              </div>
                            </Tooltip>
                          }
                        >
                          <span className='text-capitalize three-dots'>{page.user_name}</span>
                        </OverlayTrigger>
                        :
                        <span className='text-capitalize three-dots'>{page.user_name}</span>}


                    </td>
                    {/* <td>{page.count}</td> */}
                    <td>{createDatewithTime(new Date(page.created_on))}</td>
                    {/* <td>{page.status}</td> */}
                    <td className="dropdown view-more" onClick={() => { }}>

                      <button
                        className="bg-tr border-0 "
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                            fill="#B5B5BE"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                            fill="#B5B5BE"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                            fill="#B5B5BE"
                          />
                        </svg>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => setEditProfileIdModal(true)}
                        >
                          Edit Profile ID
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            openProjectMappingModal(page)
                          }}
                        >
                          Edit Project Mapping
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            viewEmailSnippet(page.profile_id)
                          }}
                        >
                          View Email Snippet
                        </a>


                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {editProfileIdModal && <CancelCSV
          show={editProfileIdModal}
          closeCancelModal={closeEditProfileIdModal}
          onSubmit={closeEditProfileIdModal}
          title={'Cannot Edit Profile ID'}
          bodyText={'For editing your profile id, please send an email at support@propacity.in'}
          submitButtonType=''
          submitButtonText='Close'
        />}
      </div>
    </>
  );
};

export default HousingAdsTable;
