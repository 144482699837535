import React from 'react';
import moment from 'moment';

const SVTYPES = {
    offline: 'Onsite',
    online: 'Video Call'
}

export default function LatestActivity({
    latestActivity
}) {

    const svType = () => {
        const type = SVTYPES[latestActivity?.siteVisit?.sv_type] || 'Onsite'
        return latestActivity?.siteVisit?.sv_type ? <span className='capitalize'> ({type}) </span> : ''
    }

    return (
        <div className="followups d-inline-flex flex-fill px-32 py-24 mt-20 radius-8 justify-content-between">
            {latestActivity.followup && latestActivity.followup.schedule_date && <div className="latest-followup">
                <h3 className="mb8 fz14 black-dark-600 po fw-regular">Pending Followup</h3>
                <h4 className="mb-0 black fz14 fw-po-medium po">{moment(latestActivity.followup.schedule_date).format('h:mm a, Do MMM YYYY')}</h4>
            </div>}

            {latestActivity.siteVisit && latestActivity.siteVisit.schedule_date && <div className="site-visit">
                <h3 className="mb8 fz14 black-dark-600 po fw-regular">Site Visit {latestActivity?.siteVisit?.is_completed ? 'Completed' : 'Scheduled'}</h3>
                <h4 className="mb-0 black fz14  fw-po-medium po">{moment(latestActivity?.siteVisit?.schedule_date).format('h:mm a, Do MMM YYYY')} {svType()}</h4>
            </div>}
        </div>
    )
}