import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import { editBrokerCode, getAllBrokersUnderRm, getCompaniesForAnalytics, getKnowlarityIvrCallerIdByCompanyId, getPlusAppFilterUsers } from '../../services/private/company.service';
import SliderModal from './SliderModal';
import InputSelect from '../InputGroup/InputSelect';
import { toast } from 'react-toastify';
import InputText from '../InputGroup/InputText';
import { validityUnit } from '../../helpers/enums';
import AsyncSelect from 'react-select/async';
import { getAllBrokerCompanyUsers } from '../../services/private/borker.service';
import DateTimePicker from '../InputGroup/DateTime';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: '#44444f',
        zIndex: 99,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        },
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
};

const LeadTaggingModal = ({ show, closeModal, uuid, updateData, selectedItem, title }) => {
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [taggingValidity, setTaggingValidity] = useState('30');
    const [taggingValidityUnit, setTaggingValidityUnit] = useState('days');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [tagExpiry, setTagExpiry] = useState('')

    useEffect(() => {
        if (selectedItem?.broker_company_name && selectedItem?.broker_company_id) {
            setSelectedCompany({
                label: selectedItem?.broker_company_name,
                id: selectedItem?.broker_company_id,
                value: selectedItem?.broker_company_id
            })

            getCompanyUsers(selectedItem?.broker_company_uuid)
        }
        if (selectedItem?.broker_user_name && selectedItem?.broker_user_id) {
            setSelectedUser({
                label: selectedItem?.broker_user_name,
                value: selectedItem?.broker_user_id,
                id: selectedItem?.broker_user_id
            })
        }
        if (selectedItem?.tag_expiry) {
            setTagExpiry(selectedItem?.tag_expiry);
        }
    }, [selectedItem])

    const loadOptions = (company) => {
        return getPlusAppFilterUsers({ searchText: company })
            .then((res) => {
                if (res.data.status === 200) {
                    const companies = res.data?.data.companies
                        .filter((company) => company.name)
                        .map(company => {
                            return {
                                ...company,
                                label: company.name,
                                value: company.uuid
                            }
                        });
                    return companies;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getCompanyUsers = async (uuid) => {
        const res = await getAllBrokerCompanyUsers(uuid);
        if (res.data.status === 200) {
            const mappedUser = res.data.data.map(i => ({ ...i, label: i.name, value: i.uuid }));
            setUserList(mappedUser)

        }
    }


    const handleSubmit = async () => {
        // if (!selectedCompany?.id || !selectedUser?.id || !taggingValidity) {
        //     toast.error('Please fill required fields.')
        // }
        const payload = {
            broker_user_id: selectedUser?.id || null,
            broker_company_id: selectedCompany?.id || null,
            tagging_validity: taggingValidity,
            tagging_validity_unit: taggingValidityUnit
        }
        const res = await editBrokerCode(uuid, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            updateData();
            closeModal()
        }
        if (res.data.status === 400) {
            toast.error(res.data.message);
            closeModal();
        }
    }
    useEffect(() => {
        // getCompanyUsers();
    }, []);

    console.log(selectedItem, 'selected COmpany')
    return (
        <SliderModal
            setShow={closeModal}
            show={show}
            title={`${title ? title : 'Lead Tagging'}`}
            body={<>
                <div className="modal-conents mt-4">
                    {/* <div className="d-flex justify-content-start align-items-start flex-column filter-sticky px-20 pb-3">
                        <h1 className='fz24 h-font fw-regular mb-0'>Advance call</h1>
                    </div> */}

                    <div className='px-3 h-[75vh] overflow-auto'>
                        <div className="from-group !mb-4">
                            <label className=''>Select CP Firm </label>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions
                                placeholder='Search and Select CP Firm'
                                styles={customStyles}
                                value={selectedCompany}
                                onChange={(e) => {
                                    if (e?.value) {
                                        setSelectedCompany(e)
                                        getCompanyUsers(e.value)

                                    } else {
                                        setSelectedCompany('');
                                        setSelectedUser('')
                                    }
                                }}
                                isClearable={true}
                                menuPosition={'fixed'}
                            />
                        </div>
                        <InputSelect
                            value={selectedUser}
                            options={userList}
                            onChange={(value) => {
                                setSelectedUser(value);
                            }}
                            placeholder="Select CP User"
                            label="Select CP User"
                        // required={true}

                        // isMulti={userProfile?.mulit_user_assign && count > 1}
                        />
                        <div className='!my-4'>
                            <label htmlFor="">Lead Tagging  <sup className='text-red'>*</sup></label>
                            <div className='grid grid-cols-2 gap-2'>
                                <InputText
                                    placeholder={'e.g. 30'}
                                    value={taggingValidity}
                                    onChange={(e) => {
                                        setTaggingValidity(e.target.value)
                                    }}
                                />
                                <InputSelect
                                    inputclass={'m-0'}
                                    placeholder={'Select Time'}
                                    value={taggingValidityUnit ? validityUnit.find(validity => validity.value === taggingValidityUnit) : ''}
                                    options={validityUnit}
                                    onChange={(value) => {
                                        setTaggingValidityUnit(value.value)
                                    }}
                                />
                            </div>
                        </div>
                        {/* {
                            selectedItem?.tag_expiry && <DateTimePicker
                                onValueChange={(e) => {
                                    setTagExpiry(e)
                                }}
                                label="Tag Expiry Date"
                                value={tagExpiry}
                                required={true}
                            />
                        } */}
                        <InputText
                            label='Name'
                            value={selectedItem?.name}
                            disable={true}
                        />
                        <InputText
                            label='Mobile'
                            value={selectedItem?.mobile}
                            disable={true}
                        />

                        <InputText
                            label='Project'
                            value={selectedItem?.project}
                            disable={true}
                        />


                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={closeModal}>
                            Cancel
                        </button>
                        <button className="pr-btn" onClick={() => handleSubmit()} >Confirm</button>
                    </div>
                </div>
            </>
            }
        />
    )
}

export default LeadTaggingModal;