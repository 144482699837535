import React, { useContext, useEffect, useState } from 'react';
import InputSelect from '../InputGroup/InputSelect'
import InputText from '../InputGroup/InputText'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as DownICon } from '../../assets/icons/Down.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as PlusPrimary } from '../../assets/icons/PlusPrimary.svg';
import { ReactComponent as Uploadicon } from '../../assets/icons/uploadicon.svg';
import { ReactComponent as Upload } from '../../assets/icons/upload.svg';
import { ReactComponent as FilePdf } from '../../assets/icons/FilePdf.svg';
import { ReactComponent as Imageplacehoder } from '../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import pdfimg from '../../assets/icons/pdfview.jpg';
import DateTimePicker from '../InputGroup/DateTime';
import DatePicker from 'react-datepicker';
import InputTextarea from '../InputGroup/InputTextarea';
import { getUnits, insertBooking } from '../../services/private/inventory.service';
import Consumer from '../../helpers/context'
import { getProjectsForFilter } from '../../services/private/leads.service';
import { ReligionList, SalutationList, CountryList, PaymentModeList, RelationList, CustomerDocumentList, customerDocList, coapplicantDocs, BankList, PaidBy, validityUnit } from '../../helpers/enums';
import { validationSchema } from './BookingBasicDetailValidation';
import MobileInput from '../InputGroup/MobileInput';
import { uploadCSV } from '../../services/private/csv.service';
import { uploadFile } from '../../services/public/public.service';
import style from '../../modules/Settings/Projects/style.module.css'
import '../../modules/Settings/Projects/style.css';
import { toNumber } from '../../helpers/helpers';
import InputRupees from '../InputGroup/InputRupees';
import AddDemand from '../Collection-Manager/Modal/AddDemand';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getPlusAppFilterUsers } from '../../services/private/company.service';
import { getAllBrokerCompanyUsers } from '../../services/private/borker.service';
import AsyncSelect from 'react-select/async';


const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const Purpose = [
    {
        value: 'End use',
        label: 'End use'
    },
    {
        value: 'Investment',
        label: 'Investment'
    }
]

let customerDoc = [...customerDocList, {
    fileName: 'Booking Form',
    type: '',
    url: '',
    size: '',
    is_private: false,
    extension: '',
    file_type: '',
    category: 'Booking Form',
    showCategory: false
},]

const BasicDetail = ({
    setSteps,
    setSelectedProject,
    setBookingId,
    lead,
    data,
    closeModal,
    selectedBooking,
    demandList,
    userEmail,
    setSelectedCP,
    setSelectedCPUser,
    initialProject,
    userProfile
}) => {

    const [isSpanClassAdded, setIsSpanClassAdded] = useState(false);
    const [isrotate, setIsrotate] = useState(false);
    const [paymentProof, setPaymentProof] = useState([{}]);
    const [coapplicant, setCoapplicant] = useState([{}]);
    const [projectList, setProjectList] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const [sameAsCustomerDetails, setSameAsCustomerDetails] = useState(false);
    const [sameAsCommunication, setSameAsCommunication] = useState(false);
    const [customerDocuments, setCustomerDocuments] = useState([]);
    const [paymentDocs, setPaymentDocs] = useState({});
    const [coApplicantUploadedDocs, setCoapplicantDocs] = useState([[...coapplicantDocs]]);;
    const [pdcsDoc, setPdcsDoc] = useState({});
    const [needCoapplicant, setNeedCoapplicant] = useState(false);
    const [needBuyer, setNeedBuyer] = useState(false);
    const [selectedDemand, setSelectedDemand] = useState({});
    const [sameAsCustomerCommunication, setSameAsCustomerCommunication] = useState(false);
    const [showRaiseDemand, setShowRaiseDemand] = useState(false);
    const [isProjectUnitAvailable, setIsProjectUnitAvailable] = useState(false);
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [taggingValidity, setTaggingValidity] = useState('30');
    const [taggingValidityUnit, setTaggingValidityUnit] = useState('days');
    const [selectedCompany, setSelectedCompany] = useState('');

    let { companyConfig, allowedPermissions } = useContext(Consumer);

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ ...item, label: item.name, value: item.id }))
            setProjectList(mapped);
        }
    }

    useEffect(() => {
        if (!selectedBooking) {
            customerDoc.map(item => item.url = '')
        }
        getProjects()
    }, []);

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            customer_name: '',
            customer_salutation: {
                label: 'MR',
                value: 'MR'
            },
            customer_dob: '',
            customer_father_name: '',
            customer_mother_name: '',
            customer_nationality: {
                label: 'Indian',
                value: 'indian'
            },
            customer_religion: '',
            customer_email: '',
            customer_mobile: '',
            customer_alternate_mobile: '',
            customer_profession: '',
            customer_communication_address: '',
            customer_permanent_address: '',
            customer_landmark: '',
            project: '',
            lead_source: '',
            scheduleDate: '',
            booking_amount: '',
            broker_user_id: '',
            broker_company_id: '',
            tagging_validity: '30',
            tagging_validity_unit: 'days'
            // customer_company_name: '',
            // customer_income: '',
            // customer_designation: '',
            // annual_income: '',
            // customer_purpose: '',
            // customer_office_mobile: '',
            // customer_office_address: '',
            // buyer_name: '',
            // buyer_email: '',
            // buyer_mobile: '',
            // buyer_landmark: '',
            // buyer_permanent_address: '',
            // buyer_dob: '',
            // buyer_communication_address: '',
            // account_no: '',
            // bank_name: '',
            // ifsc_code: '',
            // branch: '',
            // swift_code: '',
            // nre_account_no: '',
            // nro_account_no: '',
            // fcnr_account_no: '',
            // cheque_no: '',
            // cheque_date: '',
            // document_id: '',
            // cheque_amount: '',
            // customer_aadhaar_no: '',
            // customer_pan_no: '',
            // customer_booking_form_no: '',
            // customer_eoi_form_no: '',
            // customer_passport_no: '',
        },
        resolver: yupResolver(validationSchema)
    });

    const name = watch('customer_name');
    const mobile = watch('customer_mobile');
    const email = watch('customer_email');
    const landmark = watch('customer_landmark');
    const communicationAddress = watch('customer_communication_address');
    const permanentAddress = watch('customer_permanent_address');
    const buyerCommunicationAddress = watch('buyer_communication_address');
    const dob = watch('customer_dob');
    const scheduleDate = watch('scheduleDate');
    const project = watch('project');

    const getUnitDetails = async () => {
        const selectedProj = projectList.find(item => item.value === parseInt(project))
        const payload = {
            query: {
                searchText: '',
                status: 'Available'
            }
        }
        const res = await getUnits(selectedProj?.uuid, 500, 1, payload);
        if (res.data.status === 200) {
            if (res.data.data.units?.length > 0) {
                setIsProjectUnitAvailable(true)
            } else {
                toast.error('No Units available for this project.')
            }
        }
    }

    useEffect(() => {
        if (project) {
            getUnitDetails()
        }
    }, [project])

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setSourceList(companyConfig.Source)
            // setProjectList(companyConfig.Project)
        }
    }, [companyConfig]);


    useEffect(() => {
        if (Object.keys(data).length > 0 && !selectedBooking) {
            reset({
                customer_name: data.name,
                customer_email: data.email || userEmail,
                customer_mobile: data.mobile,
                customer_alternate_mobile: data.alternate_mobile,
                lead_source: data.source,
                project: (+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && initialProject) ? initialProject?.id : ''
            },
                { keepDefaultValues: true }
            )
        }
    }, [data, userEmail, selectedBooking, userProfile, initialProject]);

    console.log(selectedBooking, 'selectedBooking')
    // Prefill Customer previous details after EOI to Booking or edit Booking
    useEffect(() => {
        if (selectedBooking && Object.keys(selectedBooking).length > 0) {
            const { customer, coapplicant, customerBankDetails, project, booking, customerDocuments, transactions, broker } = selectedBooking;
            console.log(broker, 'broker')
            reset({
                customer_name: customer?.customer_name,
                customer_dob: customer?.customer_dob,
                customer_father_name: customer?.customer_father_name,
                customer_mother_name: customer?.customer_mother_name,
                customer_religion: customer?.customer_religion ? ReligionList.find(item => item.value === customer.customer_religion) : '',
                customer_email: customer?.customer_email,
                customer_mobile: customer?.customer_mobile || '',
                customer_alternate_mobile: customer?.customer_alternate_mobile || '',
                customer_profession: customer?.customer_profession,
                customer_communication_address: customer?.customer_communication_address,
                customer_permanent_address: customer?.customer_permanent_address,
                customer_landmark: customer?.customer_landmark,
                project: project?.id,
                lead_source: customer?.lead_source,
                scheduleDate: booking?.schedule_date,
                booking_amount: booking?.amount,
                broker_user_id: broker?.broker_uuid ? { label: broker?.name, value: broker?.id } : '',
                broker_company_id: broker?.company_id ? { label: broker?.company_name, value: broker?.company_id } : ''
                // customer_company_name: customer?.customer_company_name,
                // customer_income: customer?.customer_income,
                // customer_designation: customer?.customer_designation,
                // annual_income: customer?.annual_income,
                // customer_purpose: customer?.customer_purpose,
                // customer_office_mobile: customer?.customer_office_mobile || '',
                // customer_office_address: customer?.customer_office_address,
                // buyer_name: customer?.buyer_name,
                // buyer_email: customer?.buyer_email,
                // buyer_mobile: customer?.buyer_mobile,
                // buyer_landmark: customer?.buyer_landmark,
                // buyer_permanent_address: customer?.buyer_permanent_address,
                // buyer_dob: customer?.buyer_dob,
                // buyer_communication_address: customer?.buyer_communication_address,
                // account_no: customerBankDetails?.account_no,
                // bank_name: customerBankDetails?.bank_name,
                // ifsc_code: customerBankDetails?.ifsc_code,
                // branch: customerBankDetails?.branch,
                // swift_code: customerBankDetails?.swift_code,
                // nre_account_no: customerBankDetails?.nre_account_no,
                // nro_account_no: customerBankDetails?.nro_account_no,
                // fcnr_account_no: customerBankDetails?.fcnr_account_no,
                // cheque_no: customerBankDetails?.cheque_no,
                // cheque_date: customerBankDetails?.cheque_date,
                // document_id: customerBankDetails?.document_id,
                // cheque_amount: customerBankDetails?.cheque_amount,
                // customer_aadhaar_no: customer?.customer_aadhaar_no,
                // customer_pan_no: customer?.customer_pan_no,
                // customer_booking_form_no:,
                // customer_eoi_form_no:,
                // customer_passport_no: customer?.customer_passport_no,
            });
            setSelectedProject(projectList?.find(item => item.value === parseInt(project?.id)))
            if (transactions?.length > 0) {
                setPaymentProof(transactions?.length > 0 ? transactions : [{}]);
                transactions?.forEach((item, index) => {
                    let payment = { ...paymentDocs };
                    payment[index] = item.documents;
                    setPaymentDocs(payment);
                })
            }
            if (broker && broker?.company_uuid) {
                getCompanyUsers(broker?.company_uuid)
            }
        }
    }, [selectedBooking]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            if (errors?.scheduleDate) {
                toast.error(errors?.scheduleDate?.message)
            }
            if (errors?.customer_name) {
                toast.error(errors?.customer_name?.message)
            }
            if (errors?.customer_mobile) {
                toast.error(errors?.customer_mobile?.message)
            }
            if (errors?.project) {
                toast.error(errors?.project?.message)
            }
            if (errors?.lead_source) {
                toast.error(errors?.lead_source?.message)
            }
        }
    }, [errors]);

    const toggleSpanClass = () => {
        setIsSpanClassAdded(!isSpanClassAdded);
    };
    const toggleSpan = () => {
        setIsrotate(!isrotate);
    };
    // const spanClassName = isSpanClassAdded ? 'rotate-180' : '';

    // const handleChangeCustomerDetail = () => {
    //     setSameAsCustomerDetails(!sameAsCustomerDetails);
    //     if (!sameAsCustomerDetails) {
    //         setValue('buyer_name', name)
    //         setValue('buyer_email', email)
    //         setValue('buyer_mobile', mobile)
    //         setValue('buyer_landmark', landmark)
    //         setValue('buyer_dob', dob)
    //         setValue('buyer_permanent_address', permanentAddress)
    //         setValue('buyer_communication_address', communicationAddress)
    //     } else {
    //         setValue('buyer_name', null)
    //         setValue('buyer_email', null)
    //         setValue('buyer_mobile', null)
    //         setValue('buyer_landmark', null)
    //         setValue('buyer_dob', null)
    //         setValue('buyer_permanent_address', null)
    //         setValue('buyer_communication_address', null)
    //     }
    // }


    const uploadFileToS3 = async (file, fileIndex, docs, setDocs) => {
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: ''
            }
            let newDocs = { ...docs };
            if (!newDocs[fileIndex]) {
                newDocs[fileIndex] = [];
                newDocs[fileIndex].push(newFile);
            } else {
                newDocs[fileIndex].push(newFile);
            }
            setDocs(newDocs);

        }
    }

    const handleOnChange = async (e, fileIndex, docs, setDocs) => {
        const files = [...e.target.files];
        files.forEach((file, index) => {
            uploadFileToS3(file, fileIndex, docs, setDocs);
        });
    }

    const loadOptions = (company) => {
        return getPlusAppFilterUsers({ searchText: company })
            .then((res) => {
                if (res.data.status === 200) {
                    const companies = res.data?.data.companies
                        .filter((company) => company.name)
                        .map(company => {
                            return {
                                ...company,
                                label: company.name,
                                value: company.id
                            }
                        });
                    return companies;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getCompanyUsers = async (uuid) => {
        const res = await getAllBrokerCompanyUsers(uuid);
        if (res.data.status === 200) {
            const mappedUser = res.data.data.map(i => ({ ...i, label: i.name, value: i.id }));
            setUserList(mappedUser)

        }
    }

    // const handleUploadCoapplicantOtherDocs = async (e, fileName, fileIndex, index, state, setState) => {
    //     const file = e.target.files[0];
    //     const formData = new FormData();
    //     formData.append("file", file)
    //     const response = await uploadFile(formData);
    //     if (response.status === 200) {
    //         const newFile = {
    //             name: file.name,
    //             fileName: file.name,
    //             type: file.type,
    //             url: response.data.data.s3_url,
    //             size: file.size,
    //             is_private: false,
    //             extension: file.name.split('.').pop(),
    //             file_type: file.type,
    //             category: ''
    //         }
    //         let newDocs = { ...state };
    //         if (!newDocs[fileIndex]) {
    //             newDocs[fileIndex] = [];
    //             newDocs[fileIndex].push(newFile);
    //         } else {
    //             newDocs[fileIndex].push(newFile);
    //         }
    //         setState(newDocs)
    //         coapplicantDocs.push = newFile
    //         // if (!(selectedIndex >= 0)) {
    //         //     updateMediaFile(newFile);
    //         // }

    //     }
    // }

    // const handleCoapplicantDocumentUpload = async (e, fileName, fileIndex, index, state, setState, docType) => {
    //     const file = e.target.files[0];
    //     const formData = new FormData();
    //     formData.append("file", file)
    //     const response = await uploadFile(formData);
    //     if (response.status === 200) {
    //         const newFile = {
    //             name: file.name,
    //             fileName: fileName || file.name,
    //             ...(!docType) && { meta: fileName },
    //             type: file.type,
    //             url: response.data.data.s3_url,
    //             size: file.size,
    //             is_private: false,
    //             extension: file.name.split('.').pop(),
    //             file_type: file.type,
    //             category: !docType ? coApplicantUploadedDocs[fileIndex][index]?.category : '',
    //             docType: docType
    //         }
    //         let newDocs = [...state];
    //         if (!newDocs[fileIndex][index]) {
    //             newDocs[fileIndex] = [];
    //             newDocs[fileIndex].push(newFile);
    //         } else {
    //             newDocs[fileIndex][index] = newFile;
    //         }
    //         setState(newDocs)
    //         // if (index >= 0) {
    //         //     coapplicantDocs[index] = newFile
    //         // } else {
    //         //     coapplicantDocs.push(newFile)
    //         // }
    //         // if (!(selectedIndex >= 0)) {
    //         //     updateMediaFile(newFile);
    //         // }

    //     }
    // }

    const onFormSubmit = async (values) => {
        const selectedProj = projectList.find(item => item.value === parseInt(values.project))
        // let coapplicantData = coapplicant.filter(item => Object.keys(item).length > 0);

        // coapplicantData.forEach((item, index) => {
        //     item.documents = coApplicantUploadedDocs[index].filter(doc => doc.url)
        // })
        // pdcsData.forEach((item, index) => {
        //     item.documents = pdcsDoc[index];
        // })

        let transaction = paymentProof.filter(item => Object.keys(item).length > 0);
        // let coapplicantData = coapplicant.filter(item => Object.keys(item).length > 0)

        transaction.forEach((item, index) => {
            item.documents = paymentDocs[index]
        })

        const payload = {
            scheduleDate: new Date(values.scheduleDate),
            projectId: values.project,
            broker_user_id: values?.broker_user_id.value || null,
            broker_company_id: values?.broker_company_id.value || null,
            tagging_validity: values?.tagging_validity,
            tagging_validity_unit: values?.tagging_validity_unit,
            amount: values.booking_amount || null,
            customer: {
                lead_source: values.lead_source || null,
                customer_salutation: values.customer_salutation.value || null,
                customer_name: values.customer_name || null,
                customer_email: values.customer_email || null,
                customer_mobile: values.customer_mobile || null,
                customer_alternate_mobile: values.customer_alternate_mobile || null,
                customer_communication_address: values.customer_communication_address || null,
                customer_permanent_address: values.customer_permanent_address || null,
                customer_landmark: values.customer_landmark || null,
            },
            transaction: transaction.length > 0 ? transaction : null
        }

        if (!isProjectUnitAvailable) {
            toast.error('No Units available for this project.');
            return false;
        }

        const res = await insertBooking(lead.uuid || lead.id, payload)
        if (res.data.status === 200) {
            setBookingId(res.data.data.id);
            setSelectedProject(selectedProj);
            setSelectedCP(values?.broker_company_id.value);
            setSelectedCPUser(values?.broker_user_id.value);
            setSteps('UnitDetail');
            // No unit available check moved to above api call
            // if (isProjectUnitAvailable) {
            // } else {
            //     toast.error('No Units available for this project.')
            // }
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onFormSubmit)} id='booking-form' className='!pt-5 md:!px-6 !px-4 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                <div id="accordion">
                    <div className="">
                        <div className="flex items-center justify-between cursor-pointer" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={toggleSpanClass} >
                            <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                Customer Details
                            </h5>
                            <span className={`${isSpanClassAdded ? '' : 'rotate-180'}`}><DownICon /></span>
                        </div>

                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">

                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 !mt-3'>
                                <div>
                                    <Controller
                                        control={control}
                                        name='scheduleDate'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <div className={`form-group mt-0 ${errors?.scheduleDate ? 'invalid' : ''}  position-relative`}>
                                                    <label className=''>Booking Date  <sup>*</sup></label>
                                                    <div className='d-flex'>
                                                        <DatePicker
                                                            showTimeSelect={true}
                                                            selected={value ? new Date(value) : ''}
                                                            timeIntervals={15}
                                                            timeFormat="h:mm a"
                                                            placeholderText={'Select Date and Time'}
                                                            minDate={allowedPermissions.includes('past_date_booking_eoi') ? '' : new Date(moment().subtract(5, 'd'))}
                                                            onChange={onChange}
                                                            className={`form-control text-input ${errors?.scheduleDate ? 'error' : ''}`}

                                                        />
                                                    </div>
                                                    {errors?.scheduleDate && (
                                                        <>
                                                            <div className="input-feedback mt-8">{errors?.scheduleDate.message}
                                                            </div>
                                                        </>

                                                    )}
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='booking_amount'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputRupees
                                                    type="text"
                                                    label="Booking Amount"
                                                    placeholder="Booking Amount"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                    handleBlur={() => { }}
                                                    required={true}
                                                    error={errors?.booking_amount ? errors?.booking_amount.message : ''}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <label className='text-sm text-black700 !mb-2'>Name <sup>*</sup></label>
                                    <div className='d-flex gap-2'>

                                        <div>
                                            <Controller
                                                control={control}
                                                name='customer_salutation'
                                                render={({
                                                    field: { onChange, onBlur, value, ref },
                                                }) => (
                                                    <>
                                                        <InputSelect
                                                            placeholder='MR'
                                                            isMulti={false}
                                                            inputclass='w-[90px] basis-[90px] shrink-0	'
                                                            styles={customStyles}
                                                            options={SalutationList}
                                                            onChange={onChange}
                                                            value={value}

                                                        />

                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className='w-full'>
                                            <Controller
                                                control={control}
                                                name='customer_name'
                                                render={({
                                                    field: { onChange, onBlur, value, ref },
                                                }) => (
                                                    <>
                                                        <InputText
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            onChange={onChange}
                                                            value={value}
                                                            error={errors?.customer_name ? errors?.customer_name.message : ''}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_email'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Customer Email Id"
                                                    name="name"
                                                    label="Email"
                                                    inputclass='m-0 inter'
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_mobile'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <MobileInput
                                                    type="text"
                                                    label="Mobile"
                                                    placeholder="Enter Customer Mobile Number"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e.phone)
                                                    }}
                                                    error={errors?.customer_mobile ? errors.customer_mobile.message : ''}
                                                    required={true}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_alternate_mobile'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <MobileInput
                                                    type="text"
                                                    label="Alternate Mobile No."
                                                    placeholder="Enter Alternate Mobile No."
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value ? value : ''}
                                                    onChange={(e) => {
                                                        onChange(e.phone)
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='project'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='Project'
                                                    placeholder='Select Project'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    value={value ? projectList.find(item => item.value === value) : ''}
                                                    onChange={(value) => onChange(value.value)}
                                                    options={projectList}
                                                    error={errors?.project ? errors?.project.message : ''}
                                                    required={true}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                {/* <div>
                                    <Controller
                                        control={control}
                                        name='lead_source'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='Source'
                                                    placeholder='Select Source'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    value={value ? sourceList.find(item => item.value === value) : ''}
                                                    onChange={(value) => onChange(value.value)}
                                                    options={sourceList}
                                                    error={errors?.lead_source ? errors.lead_source.message : ''}
                                                    required={true}
                                                />
                                            </>
                                        )}
                                    />
                                </div> */}
                                <div className=' col-span-2 grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>

                                    <div>
                                        <Controller
                                            control={control}
                                            name='customer_communication_address'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputTextarea
                                                        type="text"
                                                        label="Communication Address"
                                                        placeholder="Enter Communication Address"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        row='2'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <div className='!mb-2 flex items-center justify-between leading-[22px] flex-wrap'>
                                            <span className='text-sm text-black700 inter'>Permanent Address</span>
                                            <div className='flex items-center text-sm text-black700 inter'>
                                                <input type="checkbox"
                                                    id='customer_address'
                                                    value={sameAsCustomerCommunication}
                                                    onChange={() => {
                                                        setSameAsCustomerCommunication(!sameAsCustomerCommunication)
                                                        if (!sameAsCustomerCommunication) {
                                                            setValue('customer_permanent_address', communicationAddress)
                                                        } else {
                                                            setValue('customer_permanent_address', '')
                                                        }
                                                    }} className='!mr-2 ' />
                                                <label htmlFor='customer_address' className='!p-1 !border !border-grayLight rounded-md inter m-0'>Same as Communication</label>
                                            </div>
                                        </div>
                                        <div>
                                            <Controller
                                                control={control}
                                                name='customer_permanent_address'
                                                render={({
                                                    field: { onChange, onBlur, value, ref },
                                                }) => (
                                                    <>
                                                        <InputTextarea
                                                            type="text"
                                                            // label="Permanent Address"
                                                            placeholder="Enter Permanent Address"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            row='2'
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>

                                </div>
                                {/* <div>
                                    <Controller
                                        control={control}
                                        name='customer_landmark'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Nearby Landmark"
                                                    placeholder="Enter Nearby Landmark"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div> */}
                                <div className="from-group">
                                    <label className=''>Select CP Firm </label>
                                    <Controller
                                        control={control}
                                        name='broker_company_id'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadOptions}
                                                    defaultOptions
                                                    placeholder='Search and Select CP Firm'
                                                    styles={customStyles}
                                                    value={value}
                                                    onChange={(e) => {
                                                        if (e?.value) {
                                                            onChange(e)
                                                            getCompanyUsers(e.uuid);
                                                            setValue('broker_user_id', '');

                                                        } else {
                                                            onChange('');
                                                            // setSelectedUser('')
                                                        }
                                                    }}
                                                    isClearable={true}
                                                    menuPosition={'fixed'}
                                                />
                                            </>
                                        )}
                                    />

                                </div>
                                <Controller
                                    control={control}
                                    name='broker_user_id'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (

                                        <>
                                            <InputSelect
                                                value={value}
                                                options={userList}
                                                onChange={(value) => {
                                                    onChange(value);
                                                }}
                                                placeholder="Select CP User"
                                                label="Select CP User"
                                            />
                                        </>
                                    )}
                                />
                                <div className=''>
                                    <label htmlFor="">Lead Tagging </label>
                                    <div className='grid grid-cols-2 gap-2'>
                                        <Controller
                                            control={control}
                                            name='tagging_validity'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        placeholder={'e.g. 30'}
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name='tagging_validity_unit'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputSelect
                                                        inputclass={'m-0'}
                                                        placeholder={'Select Time'}
                                                        value={value ? validityUnit.find(validity => validity.value === value) : ''}
                                                        options={validityUnit}
                                                        onChange={(value) => {
                                                            onChange(value.value)
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Payment Proof */}
                    {
                        paymentProof.map((proof, index) => (
                            <div className='!mt-4' key={String(index)}>
                                <div className='!mb-3 flex justify-between'>
                                    <h5 className="text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                                        Payment Proof {index + 1}
                                    </h5>
                                    {paymentProof.length > 1 &&
                                        <button className='border-0 bg-tr ' onClick={() => {
                                            const paymens = [...paymentProof];
                                            paymens.splice(index, 1)
                                            setPaymentProof(paymens);
                                        }}
                                        >
                                            <CloseIcon />
                                        </button>}
                                </div>
                                <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                    <InputSelect
                                        label='Select Demand'
                                        options={demandList}
                                        value={selectedDemand ? demandList.find(item => item.value === selectedDemand.value) : ''}
                                        onChange={(value) => {
                                            let proofs = [...paymentProof];
                                            proofs[index]['demand_id'] = value.id;
                                            proofs[index]['amount'] = toNumber(value.total_amount_due)
                                            setPaymentProof(proofs)
                                            setSelectedDemand(value)
                                        }}
                                    />
                                    <InputSelect
                                        label="Paid By"
                                        placeholder="Enter Paid By"
                                        type="text"
                                        name='paid_by'
                                        value={proof?.paid_by ? PaidBy.find(item => item.value === proof?.paid_by) : ''}
                                        inputclass='m-0 inter'
                                        onChange={(value) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index]['paid_by'] = value.value;
                                            setPaymentProof(payProofs)
                                        }}
                                        options={PaidBy}
                                    />
                                    <InputSelect
                                        label='Mode Of Payment'
                                        placeholder='Select Mode Of Payment'
                                        inputclass='m-0 inter'
                                        isMulti={false}
                                        value={proof?.mode ? PaymentModeList.find(item => item.value === proof?.mode) : ''}
                                        options={PaymentModeList}
                                        styles={customStyles}
                                        onChange={(value) => {
                                            let proofs = [...paymentProof];
                                            proofs[index]['mode'] = value.value;
                                            setPaymentProof(proofs)
                                        }}
                                    />
                                    <InputRupees
                                        label="Booking Amount"
                                        placeholder="Enter Amount"
                                        type="text"
                                        name='amount'
                                        value={proof?.amount}
                                        inputclass='m-0 inter'
                                        showRupees={true}
                                        onChange={(value) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index]['amount'] = value
                                            setPaymentProof(payProofs)
                                        }}
                                        handleBlur={() => { }}
                                    />
                                    {proof?.mode === 'cheque' && <InputText
                                        label="Cheque No."
                                        placeholder="Enter Cheque No."
                                        type="text"
                                        name='check_no'
                                        value={proof?.check_no}
                                        inputclass='m-0 inter'
                                        onChange={(e) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index][e.target.name] = e.target.value;
                                            setPaymentProof(payProofs)
                                        }}
                                    />}
                                    <InputText
                                        label="Transaction Id"
                                        placeholder="Enter Transaction Id"
                                        type="text"
                                        name='transaction_id'
                                        value={proof?.transaction_id}
                                        inputclass='m-0 inter'
                                        onChange={(e) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index][e.target.name] = e.target.value;
                                            setPaymentProof(payProofs)
                                        }}
                                    />
                                    <DateTimePicker
                                        label='Transaction Date'
                                        inputclass='mt-0'
                                        placeholder='Select Date'
                                        value={proof?.transaction_date}
                                        onValueChange={(date) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index]['transaction_date'] = date;
                                            setPaymentProof(payProofs)
                                        }}
                                        showTime={false}

                                    />
                                    {proof?.mode === 'cheque' && <DateTimePicker
                                        label='Cheque Date'
                                        inputclass='mt-0'
                                        placeholder='Select Date'
                                        value={proof?.cheque_date}
                                        onValueChange={(date) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index]['cheque_date'] = date;
                                            setPaymentProof(payProofs)
                                        }}
                                        showTime={false}
                                    />}
                                    <DateTimePicker
                                        label='Amount Received Date'
                                        inputclass='mt-0'
                                        placeholder='Select Date'
                                        value={proof?.amount_received_date}
                                        onValueChange={(date) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index]['amount_received_date'] = date;
                                            setPaymentProof(payProofs)
                                        }}
                                        showTime={false}
                                    />
                                    <InputSelect
                                        label="Bank Name"
                                        placeholder="Enter Bank Name"
                                        type="text"
                                        name='receiver_bank_name'
                                        value={proof?.receiver_bank_name ? BankList.find(item => item.value === proof?.receiver_bank_name) : ''}
                                        inputclass='m-0 inter'
                                        onChange={(value) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index]['receiver_bank_name'] = value.value;
                                            setPaymentProof(payProofs)
                                        }}
                                        options={BankList}
                                    />
                                    {proof?.mode !== 'upi' && <InputText
                                        label="Branch Name"
                                        placeholder="Enter Branch Name"
                                        type="text"
                                        name='receiver_bank_branch'
                                        value={proof?.receiver_bank_branch}
                                        inputclass='m-0 inter'
                                        onChange={(e) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index][e.target.name] = e.target.value;
                                            setPaymentProof(payProofs)
                                        }}
                                    />}
                                    {proof?.mode !== 'upi' && <InputText
                                        label="IFSC Code"
                                        placeholder="Enter IFSC Code"
                                        type="text"
                                        name='receiver_ifsc_code'
                                        value={proof?.receiver_ifsc_code}
                                        inputclass='m-0 inter'
                                        onChange={(e) => {
                                            let payProofs = [...paymentProof];
                                            payProofs[index][e.target.name] = e.target.value;
                                            setPaymentProof(payProofs)
                                        }}
                                    />}

                                    <div>
                                        <label className='text-sm text-black700 !mb-2 inter'>Payment Proof</label>
                                        <div className='relative group border border-grayLight h-[154px] rounded-lg flex items-center justify-center overflow-hidden cursor-pointer'>
                                            <input type="file" className='d-none' id={`upload-payment-proof-${index}`} accept="application/pdf, image/*" multiple onChange={(e) => handleOnChange(e, index, paymentDocs, setPaymentDocs)} />
                                            <button
                                                type='button'
                                                className='!bg-primary flex items-center justify-center py-1 px-2 text-white gap-1 rounded-lg text-sm font-medium'
                                                onClick={() => {
                                                    document.getElementById(`upload-payment-proof-${index}`).click();
                                                }}
                                            >
                                                <PluswhiteIcon /> Upload
                                            </button>

                                            {/* <div className="group-hover:flex absolute left-0 right-0 top-0 bottom-0 w-100 h-100 bg-black bg-opacity-30 hidden items-center justify-center">
                                                        <div className='flex items-end gap-x-1 relative '>
                                                            <button type='button' className='py-1 px-2.5 text-white text-xs font-medium  !bg-primary rounded-md  tracking-[0.5px] inline-flex items-center inter'><Pluswhite /> Replace</button>
                                                            <button type='button' className='trashbtn w-8 h-8 bg-white rounded-md inline-flex items-center justify-center'><TrashIcon /></button>
                                                        </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='!mt-4'>

                                    <div className='grid sm:grid-cols-3 grid-cols-1 gap-x-6	gap-y-4'>
                                        {paymentDocs[index]?.length > 0 && paymentDocs[index].map((doc, docIndex) => (
                                            <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                                <figure className='mb-0 !table !mx-auto relative group'>
                                                    {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                        <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                        : <img src={pdfimg} />}
                                                    <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                        <button
                                                            className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                            onClick={() => {
                                                                document.getElementById(`upload-payment-proof${index}`).click();
                                                            }}
                                                        ><PluswhiteIcon />Replace</button>

                                                        <button
                                                            className={`${style.delbtn} inter border-0 bg-white`}
                                                            onClick={() => {
                                                                let newFiles = { ...paymentDocs };
                                                                delete newFiles[index]
                                                                setPaymentDocs(newFiles);

                                                            }}
                                                        ><TrashIcon /></button>
                                                    </div>
                                                </figure>
                                                <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'> {doc.name}</p>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className="!mt-3">
                        <button
                            type='button'
                            className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2 inter'
                            onClick={() => {
                                setPaymentProof([...paymentProof, {}])
                            }}
                        >
                            <PlusPrimary /> Add Payment Proof</button>
                    </div>
                </div>
            </form>
            <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                <button
                    type='button'
                    className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center inter'
                    onClick={closeModal}
                >Cancel</button>
                <button type='submit' className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center inter' form='booking-form'>Next</button>
            </div>
        </>
    )
}

export default BasicDetail
