import React, { useEffect, useState, useRef } from 'react';
import { Image } from 'react-bootstrap';
import './CustomTable.css';
import { createDatewithTime } from '../../helpers/date';
import { getCompanyAgency, getMasterEnumsCall, getTimelineCall, knowlarityClickToCall, knowlarityClickToCallAdvanced, updateleadByKeyValue } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import dotMenuIcon from '../../assets/icons/dotmenu.svg';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import NoResultFound from '../NotFound/NoResultFound';
import ConfirmDeletion from '../Modals/ConfirmDeletion';
import { getSelectedValue } from '../../utils/selectedOptionUtils';
import LeadName from './TableColumns/LeadName';
import Projects from './TableColumns/Projects';
import SourceLabel from './TableColumns/SourceLabel';
import StatusDropDown from './TableColumns/StatusDropDown';
import DynamicIntent from './TableColumns/DynamicIntent';
import AssignedTo from './TableColumns/AssignedTo';
import checkAssignedTo from '../../utils/checkAssignedTo';
import MobileNo from './TableColumns/MobileNo';
import BookingsDoneModal from '../Modals/BookingsDoneModal';
import SortIconDisplay from './sortIconDisplay';
import UpdateFollowupModal from '../Modals/FollowupModal/UpdateFollowupModal';
import { useMemo } from 'react';
import { useCallback } from 'react';
import NotesDropDown from './TableColumns/NotesDropDown';
import RecentActivities from '../../modules/Lead Details/components/Recent Activities/RecentActivities';
import { Oval } from 'react-loader-spinner';
import MiniTimline from '../Modals/MiniTimline';
import RenderTableData from './RenderTableData';
import CustomColumnModal from '../Modals/CustomColumnModal';
import { getBookingDoneDetails, getEOIDetails, updateBookingApprovalStatus, updateEOIApprovalStatus } from '../../services/private/inventory.service';
import DetailsModal from '../../modules/Inventory/Modal/details';
import { checkIsSomeItemAvailableInList, mergeUniqueByKey, toINRFormat } from '../../helpers/helpers';
import BookingModal from '../Modals/BookingModal';
import ShareLeadAccessModal from '../Modals/ShareLeadAccessModal';
import CancelBooking from '../../modules/Inventory/Modal/cancelBooking';
import SharedWithUserName from './TableColumns/SharedWithUserName';
import AdvanceCallModal from '../Modals/AdvanceCallModal';
import LeadTaggingModal from '../Modals/LeadTaggingModal';
import NewBookingModal from '../Modals/NewBookingModal';


const getStatusClasses = (status) => {
    switch (status) {
        case 'rejected':
            return 'text-red bg-red-light'
        case 'approved':
            return 'text-green bg-green100'
        default:
            return 'text-orange-dark bg-orange-100'
    }
}

export default function NewBookingsTable({
    leadData,
    filters,
    startIndex,
    showCheck,
    updateData,
    selectedTableValues,
    setSelectedTableValues,
    setSelectedTableData,
    selectedTableData,
    handleUserDeletion,
    handleUserReassignLead,
    columns,
    col,
    count,
    searchText,
    sort,
    reAssignRole,
    intentStatuses,
    statuses,
    sourceStatuses,
    setLeadName,
    selectedLeadName,
    agencyList,
    showCreateLeadModal,
    bookingLeadsColumn,
    saveCurrentUserColumnPrefrence,
    handleChangeSelection,
    updateColumnList,
    dashboardColumns,
    allowedPermissions,
    isRolesModule,
    userProfile,
    callerList
}) {
    const [deadUuid, setDeadUuid] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedSingleUserIdFromTable, setSelectedSingleUserIdFromTable] = useState();
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [showFollowupModal, setShowFollowupModal] = useState(false);
    const [timeline, setTimeline] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState('');
    const [showTimeline, setShowTimeline] = useState(false);
    const [timelineLeadName, setTimelineLeadName] = useState('');
    const [showCustomColumn, setShowCustomColumn] = useState(false);
    const [columnsList, setColumnsList] = useState(bookingLeadsColumn);
    const [tableColumn, setTableColumn] = useState([]);
    const [isCustomColumnAvailable, setIsCustomColumnAvailable] = useState(false);
    const [reAssignAvailable, setReAssignAvailable] = useState(false);
    const [deleteAvailable, setDeleteAvailable] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState({});
    const [bookingStatus, setBookingStatus] = useState('pending');
    const [bookingUUId, setBookingUUID] = useState('');
    const [leadStatus, setLeadStatus] = useState('');
    const [showNewBookingModal, setShowNewBookingModal] = useState(false);
    const [showShareLeadAccessModal, setShowShareLeadAccessModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [showCancelBooking, setShowCancelBooking] = useState(false);
    const [selectedLead, setSelectedLead] = useState({});
    const [showAdvanceCallmodal, setShowAdvanceCallModal] = useState(false);
    const [showLeadTaggingModal, setShowLeadTaggingModal] = useState(false)

    const ROLE_NAME = localStorage.getItem('role');
    const tableRef = useRef();

    useEffect(() => {
        if (!isRolesModule) {
            if (ROLE_NAME === 'ADMIN') {
                setIsCustomColumnAvailable(true);
                setReAssignAvailable(true);
                setDeleteAvailable(true);
            }
            if (reAssignRole?.includes('re_assign_leads')) {
                setReAssignAvailable(true);
            }
        } else {
            if (allowedPermissions.includes('customise_column_self')) {
                setIsCustomColumnAvailable(true);
            }
            if (allowedPermissions.includes('re_assign_leads_single')) {
                setReAssignAvailable(true);
            }
            if (allowedPermissions.includes('delete_booking')) {
                setDeleteAvailable(true);
            }
        }
    }, [isRolesModule]);

    const handelChange = (e, data) => {
        if (e.target.checked) {
            setLeadName(data.name);
            setSelectedTableValues([...selectedTableValues, data.id]);
            setSelectedTableData([...selectedTableData, data]);
        } else {
            const filteredValues = selectedTableValues.filter((value) => value != data.id);
            const filteredData = selectedTableData.filter((value) => value.id != data.id);
            setSelectedTableValues(filteredValues);
            setSelectedTableData(filteredData);
            setLeadName('');
        }
    }

    const getBookingDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getBookingDoneDetails(uuid);
        if (res.data.status === 200) {
            if (userProfile?.company_id === 581 || res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
            }
        }
    }

    const getEOILeadDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getEOIDetails(uuid);
        if (res.data.status === 200) {
            if (userProfile?.company_id === 581 || res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
            }
        }
    }

    useEffect(() => {
        if (!selectedLeadName && selectedTableValues?.length === 1) {
            const leadVal = leadData.find((lead) => lead.id === selectedTableValues[0]);
            setLeadName(leadVal.name)
        }
    }, [selectedLeadName, selectedTableValues])

    useEffect(() => {
        if (selectedTableValues?.length >= leadData?.length) {
            const allSelected = leadData.every(lead => selectedTableValues.includes(lead.id))
            setSelectAll(allSelected)
        }
    }, [selectedTableValues, selectedTableData, leadData]);

    // const onHandleSelectAllLeads = () => {
    //     if (!selectAll) {
    //         const selectedVal = leadData.map((lead) => lead.id)
    //         // setSelectedTableValues(selectedVal);
    //         // setSelectedTableData(leadData);
    //         setSelectedTableValues([...selectedTableValues, ...selectedVal]);
    //         setSelectedTableData([...selectedTableData, ...leadData]);
    //     } else {
    //         setSelectedTableValues([])
    //         setSelectedTableData([]);
    //     }
    //     setSelectAll(!selectAll)
    // }
    const onHandleSelectAllLeads = () => {
        if (!selectAll) {
            const selectedVal = leadData.map((lead) => lead.id)
            // setSelectedTableValues([...selectedTableValues, ...selectedVal]);
            // setSelectedTableData([...selectedTableData, ...leadData]);
            setSelectedTableValues([...new Set([...selectedTableValues, ...selectedVal])]);
            const mergedLeads = mergeUniqueByKey(selectedTableData, leadData, 'id')
            setSelectedTableData([...mergedLeads]);
        } else {
            if (selectedTableValues?.length > leadData?.length) {
                const leadIds = leadData.map(lead => lead.id);
                const filtered = selectedTableValues.filter(item => !leadIds.includes(item))
                const filteredData = selectedTableData.filter(item => !leadIds.includes(item.id))
                setSelectedTableValues(filtered)
                setSelectedTableData(filteredData)
            } else {

                setSelectedTableValues([])
                setSelectedTableData([]);
            }
        }
        setSelectAll(!selectAll)
    }

    const handleRowPosition = (e, index) => {
        localStorage.setItem('rowIndex', index);
    }

    useEffect(() => {
        tableRef.current.scrollTop = parseInt(localStorage.getItem('rowIndex')) * 50;
    }, [leadData])

    useEffect(() => {
        const sortedList = bookingLeadsColumn.sort((a, b) => a.placement - b.placement)
        setTableColumn(sortedList);
    }, [bookingLeadsColumn]);

    useEffect(() => {
        const handleCopy = (e) => {
            // Optionally prevent default copy behavior
            e.preventDefault();
        };
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        document.addEventListener('copy', handleCopy);
        // document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('copy', handleCopy);
            // document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const isEOIDone = useCallback((status) => {
        const statusVal = getSelectedValue(status, statuses)
        return (statusVal?.label === 'EOI Completed' || statusVal?.label === 'Token Done')
    }, [statuses])

    const handleLeadTimeline = useCallback((data, index) => {
        setSelectedIndex(index)
        if (data.id) {
            setTimelineLeadName(data.name)
            getTimelineCall(data.id).then((res) => {
                if (res.data.status === 200) {
                    setTimeline(res.data.data)
                    setShowTimeline(true)
                }
            }).catch((err) => {
                console.log({ err })
            })
        }
    }, []);

    const closeTimelineDropdown = useCallback(() => {
        setSelectedIndex('');
        setTimeline([]);
        setShowTimeline(false);
    }, []);

    const selectSingleUserId = useCallback((data) => {
        setSelectedSingleUserIdFromTable(data);
    }, []);

    const setFollowupModal = useCallback((val) => {
        setShowFollowupModal(val);
    }, []);

    const closeCustomColumnModal = useCallback(() => {
        setShowCustomColumn(false)
    }, []);

    const handleChangeBookingStatus = async (payload) => {
        const res = await updateBookingApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            updateData();
            setShowDetailsModal(false)
        }
    }

    const handleChangeEOIStatus = async (status) => {
        const res = await updateEOIApprovalStatus(bookingUUId, { status });
        if (res.data.status === 200) {
            console.log(res.data.data);
            toast.success(res.data.message);
            updateData();
            setShowDetailsModal(false)
        }
    }

    const handleUpdateBooking = async (payload) => {
        const res = await updateBookingApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            updateData();
            setShowCancelBooking(false);
            setSelectedBooking({})
        }
    }

    const handleClickToCall = async (lead) => {
        const res = await knowlarityClickToCall(lead.id, {});
        if (res.data.status === 200) {
            toast.success(res.data.message)
        }
    }

    const handleAdvanceClickToCall = async (useAlternate, callerId) => {
        const payload = {
            use_alternate: useAlternate,
            caller_id: callerId
        }

        const res = await knowlarityClickToCall(selectedItem.id, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message)
            setSelectedItem({})
            setShowAdvanceCallModal(false)
        }
    }

    return (
        <>
            {showDeleteModal && selectedSingleUserIdFromTable.id && <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                title={`Delete ${selectedSingleUserIdFromTable.name}?`}
                deleteHandler={() => handleUserDeletion(selectedSingleUserIdFromTable.id)}
                subText={`After removal, unit ${selectedSingleUserIdFromTable?.unit_name}, unit ${selectedSingleUserIdFromTable?.unit_name} will be unblocked.`}
            />}

            {showBookingModal && selectedSingleUserIdFromTable.id && <BookingsDoneModal
                setShow={setShowBookingModal}
                show={showBookingModal}
                leadId={selectedSingleUserIdFromTable.id}
                onSuccess={updateData}
                isEdit={showBookingModal}
                bookingsData={selectedSingleUserIdFromTable}
            />}

            {showFollowupModal && selectedSingleUserIdFromTable.id && <UpdateFollowupModal
                setShow={setShowFollowupModal}
                show={showFollowupModal}
                leadId={selectedSingleUserIdFromTable.id}
                isEdit={showFollowupModal}
                onSuccess={updateData}
                intentOptions={intentStatuses}
                selectedIntent={selectedSingleUserIdFromTable.intent}
            />}
            <div className="all-leads !pt-1 md:!pl-5 !pl-4">
                <div
                    className={`lead-table h-full no-select ${leadData?.length === 0 ? 'overflow-hidden shownofound' : ''}`}
                    ref={tableRef}>

                    <table className="table booking-table">
                        <thead>
                            <tr>
                                <th
                                    className='cursor-pointer'
                                >
                                    {showCheck ?
                                        <>
                                            <input
                                                type="checkbox"
                                                onChange={onHandleSelectAllLeads}
                                                checked={selectAll}
                                                id='selectAll_leads'
                                                title='Select all'
                                                className='resasign-input'
                                            />

                                        </>
                                        :
                                        'S.No.'
                                    }
                                </th>
                                <th className='sticky-th'>
                                    Name
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Name' />
                                </th>

                                <th className={`${userProfile?.company_id === 581 ? 'sticky left-[252px]' : ''}`}>
                                    Mobile No.
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Mobile No.' />
                                </th>
                                <th>
                                    Email
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Email' />
                                </th>
                                <th>Approval Status</th>
                                <th>Booking Details</th>
                                <th>Booking Date</th>
                                <th>Booked Project</th>
                                {/* <th>Tower</th> */}
                                <th>Block</th>
                                <th>Floor</th>
                                <th>Configuration</th>
                                <th>Unit Type</th>
                                <th>Unit</th>
                                <th>Agreement Amt.</th>
                                <th>Final Price (i)</th>
                                <th>Booking ID</th>
                                <th>Booking By</th>
                                {/* <th>Broker/CP Name</th> */}
                                <th>Assigned to</th>
                                <th>Agreement Status</th>
                                <th>Loan Status</th>
                                <th>Communication Address</th>
                                <th>Amount Paid</th>
                                <th>View Payment Proof</th>
                                <th>Alternate Mobile No.</th>
                                <th>Developer Closing Manager</th>
                                <th>Developer Sourcing Manager</th>
                                <th>Developer Collection POC</th>
                                <th>Source</th>
                                <th>Added By</th>
                                <th>Approved By</th>
                                <th>Approval Time</th>
                                {userProfile?.allowed_modules?.includes('lead_sharing') && <th>View lead share</th>}
                                <th>Shared With User</th>
                                <th>Marked By</th>
                                <th>Marked Date</th>
                                <th>Lead Access Validity</th>
                                <th>Access</th>
                                {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <th>Lead Tagged</th>}
                                <th>Last CP tagged</th>
                                <th></th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                leadData && leadData?.length > 0 &&
                                leadData.map((data, index) => (
                                    <tr
                                        key={data.id + data.intent + data.status}
                                        id={`leads_table_${index}`}
                                        onClick={(e) => handleRowPosition(e, index)}
                                    >
                                        <td>
                                            {showCheck ?
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handelChange(e, data)}
                                                    checked={selectedTableValues.includes(data.id)}
                                                /> :
                                                index + startIndex
                                            }
                                        </td>
                                        <td className='sticky-td'>
                                            <LeadName
                                                data={data}
                                                intent={data.intent}
                                                intentStatuses={intentStatuses}
                                            />
                                        </td>
                                        <td className={`pr4 ${userProfile?.company_id === 581 ? 'sticky left-[252px]' : ''}`} style={{ maxWidth: '200px', overflow: 'hidden', }}>
                                            {<MobileNo mobileNo={data.mobile} />}
                                        </td>
                                        <td>
                                            {data.email ? <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id="button-tooltip" >
                                                        <div className='info-msg'>
                                                            {/* <h4>Total Leads</h4>*/}
                                                            <p className=''>{data.email}</p>
                                                        </div>
                                                    </Tooltip >
                                                }
                                            >
                                                <span className='three-dots cursor-pointer'>{data.email}</span>
                                            </OverlayTrigger > : '-'}
                                        </td>
                                        <td>
                                            <p className={`capitalize m-0 py-1 px-3 rounded-lg text-center ${data.booking_approval_status && getStatusClasses(data.booking_approval_status)}`}>
                                                {data.booking_approval_status || '-'}
                                            </p>
                                        </td>
                                        <td>
                                            <button
                                                className={`border ${data.booking_approval_status === 'pending' ? 'border-primary text-primary' : ''} py-1 px-3 rounded-lg`}
                                                onClick={() => {
                                                    setLeadStatus('booking')
                                                    setBookingStatus(data.booking_approval_status);
                                                    getBookingDetails(data.booking_uuid)

                                                }}>
                                                {(allowedPermissions.includes('verify_bookings_eoi') ? (data.booking_approval_status === 'pending' ? 'Verify Booking' : 'View Booking') : 'View Booking')}
                                            </button>

                                        </td>
                                        <td>
                                            {data.booking_date ? createDatewithTime(new Date(data.booking_date)) : '-'}
                                        </td>
                                        <td>
                                            {data?.booking_project_name || '-'}
                                        </td>
                                        <td>
                                            {data.unit_block || '-'}
                                        </td>
                                        <td>
                                            {data.unit_floor || '-'}
                                        </td>
                                        <td>
                                            {data?.unit_configurtion || '-'}
                                        </td>
                                        <td>
                                            {data?.unit_type || '-'}
                                        </td>
                                        <td>
                                            {data?.unit_name || '-'}
                                        </td>
                                        <td>
                                            {data?.booking_agreement_price ? toINRFormat(data?.booking_agreement_price) : '-'}
                                        </td>
                                        <td>
                                            {data?.booking_agreement_price ? toINRFormat(data?.booking_agreement_price) : '-'}
                                        </td>
                                        <td>
                                            {data?.booking_unique_id || '-'}
                                        </td>
                                        <td>
                                            {data?.created_by || '-'}
                                        </td>
                                        {/* <td>
                                            {data?.channel_partner_firm_name || '-'}
                                        </td> */}
                                        <td>
                                            {data.assigned_to || '-'}
                                        </td>
                                        <td>
                                            {data.agreement_status || '-'}
                                        </td>
                                        <td>
                                            {data?.loan_status || '-'}
                                        </td>
                                        <td>
                                            {data?.customer_communication_address || '-'}
                                        </td>
                                        <td>
                                            {data?.received_amount ? toINRFormat(data.received_amount) : '-'}
                                        </td>
                                        <td>
                                            <button
                                                className={`border ${data.booking_approval_status === 'pending' ? 'border-primary text-primary' : ''} py-1 px-3 rounded-lg`}
                                                onClick={() => {
                                                    if (data.booking_uuid) {
                                                        setLeadStatus('booking')
                                                        setBookingStatus(data.booking_approval_status);
                                                        getBookingDetails(data.booking_uuid)
                                                    }
                                                    if (data.eoi_uuid) {
                                                        setLeadStatus('eoi')
                                                        setBookingStatus(data.eoi_approval_status);
                                                        getEOILeadDetails(data.eoi_uuid)
                                                    }
                                                }}>
                                                {/* {data.booking_approval_status === 'pending' ? 'Verify Booking' : 'View Booking'} */}
                                                View Document
                                            </button>

                                        </td>
                                        <td>
                                            {data?.customer_alternate_mobile || '-'}
                                        </td>
                                        <td>
                                            {data?.closing_manager || '-'}
                                        </td>
                                        <td>
                                            {data?.sourcing_manager || '-'}
                                        </td>
                                        <td>
                                            {data?.poc || '-'}
                                        </td>
                                        <td>
                                            <SourceLabel
                                                allSources={sourceStatuses}
                                                sources={data.source}
                                            />
                                        </td>
                                        <td>
                                            {data?.created_by || '-'}
                                        </td>
                                        <td>
                                            {data?.approval_status_changed_by || '-'}
                                        </td>
                                        <td>
                                            {
                                                data?.approval_time ? createDatewithTime(new Date(data.approval_time)) : '-'
                                            }
                                        </td>
                                        {userProfile?.allowed_modules?.includes('lead_sharing') && <td>
                                            {
                                                data.shared_with_count > 0 ?
                                                    <div className='view-notes'>
                                                        <button className="btn btn-secondary dropdown-toggle" type="button" id=""
                                                            onClick={() => {
                                                                setSelectedItem(data)
                                                                setShowShareLeadAccessModal(true)
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                    : '-'
                                            }
                                        </td>}
                                        <td>
                                            {data?.shared_with_names ? <SharedWithUserName shareWithNameArr={data.shared_with_names} /> : '-'}
                                        </td>
                                        <td>
                                            {data?.status_changed_by_name || '-'}
                                        </td>
                                        <td>
                                            {
                                                data?.status_changed_on ? createDatewithTime(new Date(data?.status_changed_on)) : '-'
                                            }
                                        </td>
                                        <td>{data.validity ? `${data?.validity} ${data.validity_unit}` : '-'}</td>
                                        <td className='capitalize'>{data?.access ? data?.access === 'edit' ? 'Edit/View' : 'View' : '-'}</td>
                                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <td>
                                            <div className={`${data?.broker_user_name ? 'text-[#2064FA] bg-[#2064FA]/10' : 'text-[#667085] bg-[#F1F1F5]'}  text-xs rounded-lg text-center font-semibold !py-1.5 `}>
                                                {data?.broker_user_name ? 'Tagged' : 'Untagged'}
                                            </div>
                                        </td>}
                                        <td>{data?.last_tag_cp_user || '-'}</td>
                                        <td className="dropdown view-more">
                                            {(isEOIDone(data.status) || reAssignAvailable || deleteAvailable) &&
                                                < >
                                                    <button
                                                        className={`bg-tr border-0 ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''} `}
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        <div className="head-itme pb-2 m-0">Actions <span></span></div>
                                                        {/* <a className="dropdown-item" >
                                                            Payment History
                                                        </a>
                                                        <a className="dropdown-item" >
                                                            Cancel Booking
                                                        </a> */}
                                                        <a className="dropdown-item"
                                                            onClick={() => getBookingDetails(data.booking_uuid)}
                                                        >
                                                            View Details
                                                        </a>


                                                        <a className="dropdown-item" onClick={() => {
                                                            setTimeline([]);
                                                            handleLeadTimeline(data, index);
                                                        }}>
                                                            View Recent Activity
                                                        </a>
                                                        {/* {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                            setSelectedItem(data)
                                                            setShowLeadTaggingModal(true);
                                                        }}>
                                                            Edit Lead Tagging
                                                        </a>}
                                                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                            setSelectedItem(data)
                                                            setShowLeadTaggingModal(true);
                                                        }}>
                                                            Edit CP User
                                                        </a>} */}
                                                        {userProfile?.allowed_modules?.includes('lead_sharing') &&
                                                            checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads']) &&
                                                            <a className="dropdown-item" onClick={() => {
                                                                setSelectedItem(data)
                                                                setShowShareLeadAccessModal(true)
                                                            }}>

                                                                Share Lead Access
                                                            </a>}
                                                        <div className="head-itme">Edit
                                                            <span style={{ width: '80px' }}>
                                                            </span>
                                                        </div>


                                                        <a className="dropdown-item"
                                                            onClick={() => {
                                                                setShowNewBookingModal(true)
                                                                setSelectedBooking(data)
                                                            }}
                                                        >
                                                            Edit Booking
                                                        </a>
                                                        {isEOIDone(data.status) && <a className="dropdown-item" onClick={() => {
                                                            setShowFollowupModal(true)
                                                            setSelectedSingleUserIdFromTable(data)
                                                        }
                                                        }>
                                                            Edit Followup
                                                        </a>}

                                                        <a className="dropdown-item"
                                                            onClick={() => {
                                                                setShowCancelBooking(true)
                                                                setBookingUUID(data.booking_uuid)
                                                                setSelectedBooking(data)
                                                            }}
                                                        >
                                                            Cancel Booking
                                                        </a>

                                                        {reAssignAvailable &&
                                                            <a className="dropdown-item"
                                                                onClick={() => {
                                                                    setLeadName(data.name);
                                                                    handleUserReassignLead(data.id)
                                                                }}
                                                            >
                                                                Re-assign Lead
                                                            </a>}
                                                        {callerList.length > 0 &&
                                                            (ROLE_NAME === 'Super Admin' ||
                                                                (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                                    allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                                        className="dropdown-item" onClick={() => handleClickToCall(data)}
                                                                    >
                                                                Quick Call
                                                            </a>}
                                                        {callerList.length > 0 &&
                                                            (ROLE_NAME === 'Super Admin' ||
                                                                (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                                    allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                                        className="dropdown-item" onClick={() => {
                                                                            setSelectedItem(data);
                                                                            setShowAdvanceCallModal(true);
                                                                        }}>
                                                                Advanced Call
                                                            </a>}
                                                        {deleteAvailable &&
                                                            <a className="dropdown-item del-menu-itme" onClick={() => {
                                                                setSelectedSingleUserIdFromTable(data)
                                                                setShowDeleteModal(true)
                                                            }}>Delete Lead
                                                            </a>
                                                        }

                                                    </div>
                                                </>}
                                            {/* {selectedIndex === index && <div className="dropdown view-more">
                                                <div className="dropdown-menu mini-timeline show">
                                                    {timeline?.length > 0 && <RecentActivities ActivityTimeline={timeline} leadName={data.name} closeTimelineDropdown={closeTimelineDropdown} />}
                                                    {timeline?.length === 0 && <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                                                        <Oval
                                                            height={30}
                                                            width={30}
                                                            color="#0062FF"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                            ariaLabel='oval-loading'
                                                            secondaryColor="#0062FF"
                                                            strokeWidth={2}
                                                            strokeWidthSecondary={2}
                                                        />
                                                    </div>}
                                                </div>
                                            </div>} */}
                                        </td>
                                    </tr>
                                )
                                )
                            }

                        </tbody>
                    </table>
                    {leadData?.length === 0 && <NoResultFound count={count} filters={filters} searchText={searchText} showCreateLeadModal={showCreateLeadModal} />}
                    {showTimeline && <MiniTimline setShow={setShowTimeline}
                        show={showTimeline} ActivityTimeline={timeline} leadName={timelineLeadName} closeTimelineDropdown={closeTimelineDropdown} />}

                    {showCustomColumn &&
                        <CustomColumnModal
                            subHeading={'Booking Leads'}
                            activeColumnTab='Booking'
                            show={showCustomColumn}
                            setShow={closeCustomColumnModal}
                            updateColumnList={updateColumnList}
                            dashboardColumns={dashboardColumns}
                            tab={'Booking'}
                            handleChangeSelection={handleChangeSelection}
                            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                        />
                    }
                    {showDetailsModal && <DetailsModal
                        show={showDetailsModal}
                        unit={{}}
                        selectedBooking={selectedBooking}
                        closeModal={() => setShowDetailsModal(false)}
                        modalOpenFrom={'table'}
                        bookingStatus={bookingStatus}
                        ModalType={'Booking'}
                        handleChangeApprovalStatus={handleChangeBookingStatus}
                        handleSubmit={handleUpdateBooking}
                        updateData={updateData}
                        operation={allowedPermissions.includes('verify_bookings_eoi') ? '' : 'view only'}
                    />}
                    {
                        showNewBookingModal &&
                        // <BookingModal
                        <NewBookingModal
                            show={showNewBookingModal}
                            closeModal={() => setShowNewBookingModal(false)}
                            lead={selectedBooking}
                            data={selectedBooking}
                            onSuccess={() => {
                                setSelectedBooking({});
                                setShowNewBookingModal(false);
                                updateData();
                            }}
                            isEdit={showNewBookingModal}
                        />
                    }
                    {showShareLeadAccessModal &&
                        <ShareLeadAccessModal
                            show={showShareLeadAccessModal}
                            selectedItem={selectedItem}
                            closeModal={() => setShowShareLeadAccessModal(false)}
                            onSuccess={updateData}
                            allowedPermissions={allowedPermissions}
                        />
                    }
                    {showCancelBooking &&
                        <CancelBooking
                            show={showCancelBooking}
                            closeModal={() => {
                                setShowCancelBooking(false);
                                updateData();
                                setSelectedBooking([]);
                            }}
                            data={selectedBooking}
                            ModalType={'Booking'}
                            handleSubmit={handleUpdateBooking}
                            updateData={updateData}
                        />
                    }
                    {
                        showAdvanceCallmodal &&
                        <AdvanceCallModal
                            show={showAdvanceCallmodal}
                            closeModal={() => {
                                setSelectedItem({})
                                setShowAdvanceCallModal(false)
                            }}
                            handleSubmit={handleAdvanceClickToCall}
                            callerList={callerList}
                        />
                    }
                    {
                        showLeadTaggingModal &&
                        <LeadTaggingModal
                            show={showLeadTaggingModal}
                            uuid={selectedItem.id}
                            updateData={updateData}
                            selectedItem={selectedItem}
                            closeModal={() => {
                                setSelectedItem({})
                                setShowLeadTaggingModal(false)
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}