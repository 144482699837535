import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CustomColumnModal from "../../../../components/Modals/CustomColumnModal";
import EditRolesModal from "../../../../components/Modals/EditRolesModal";
import { createRoles, getColumnPreferenceNew, getMasterRoles, getRoles, saveColumnPreferenceNew, updateRoleDetails } from "../../../../services/private/company.service"
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/EditBlack.svg'
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ReactComponent as RoleDummyIcon } from '../../../../assets/icons/role_dummy.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import { ReactComponent as RightArrow } from '../../../../assets/icons/RightArrowwithoutbg.svg';
import { ReactComponent as Pageback } from '../../../../assets/icons/Pageback.svg';

import TooltipText from "../../../../components/Tooltip";

const NewRole = () => {
    const [currentRoleUUID, setCurrentRoleUUID] = useState('')
    const [ShowEditRoleModal, setShowEditRoleModal] = useState(false)
    // const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState();
    const [showCustomiseColumnsModal, setShowCustomiseColumnsModal] = useState(false);
    const [dashboardColumns, setDashboardColumns] = useState([]);
    const [role, setRole] = useState('')
    const [description, setDescription] = useState('')
    const [masterRoles, setMasterRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [error, setError] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const { uuid } = useParams();
    const searchParams = new URLSearchParams(location.search);

    const roleParam = searchParams.get('role');
    const roleName = searchParams.get('name');
    const roleDescription = searchParams.get('description');

    const getAllMasterRoles = async () => {
        const res = await getMasterRoles()
        if (res.data.status === 200) {
            const masterRole = res.data.data.filter(item => item.name !== 'Super Admin');
            setMasterRoles(masterRole)
        }
    }

    useEffect(() => {
        getAllMasterRoles()
    }, []);

    useEffect(() => {
        if (uuid && (roleParam || roleName || roleDescription)) {
            setRole(roleName || '');
            setSelectedRole(roleParam || '');
            setDescription(roleDescription || '');
        }
    }, [uuid, roleParam, roleName, roleDescription])

    const handleCreateRole = async () => {
        if (!role) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        if (!selectedRole) {
            toast.error('Please select Permission from Copy Permissions');
            return;
        }
        const rolePermission = masterRoles.find(item => item.name === selectedRole)

        try {
            const res = await createRoles({ name: role, description, permissions: rolePermission?.allowed_permissions });
            if (res.status === 200) {
                if (res.data.status === 200) {
                    toast.success(res.data.message)
                    // if (selectedRole !== 'custom') {
                    //     const role = masterRoles.find(item => item.name === selectedRole)
                    //     localStorage.setItem('allowedPermissions', JSON.stringify(role.allowed_permissions));
                    // }
                    history.push({
                        pathname: `/settings/user-roles/roles/new/permissions/${res.data.data.uuid}`,
                        search: `?role=${selectedRole}&from=new&name=${role}&description=${description}`,
                    })

                } else {
                    toast.warning(res.data.message)
                }
            }
        } catch (err) {
            toast.warning(err.response.data.message)
        }
    }

    const handleUpdateRole = async () => {
        if (!role) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        if (!selectedRole) {
            toast.error('Please select Permission from Copy Permissions');
            return;
        }
        try {
            const res = await updateRoleDetails(uuid, { name: role, description });
            if (res.status === 200) {
                if (res.data.status === 200) {
                    toast.success(res.data.message)
                    if (selectedRole !== 'custom') {
                        const role = masterRoles.find(item => item.name === selectedRole)
                        localStorage.setItem('allowedPermissions', JSON.stringify(role.allowed_permissions));
                    }
                    history.push({
                        pathname: `/settings/user-roles/roles/new/permissions/${res.data.data.uuid}`,
                        search: `?role=${selectedRole}&from=new&name=${role}&description=${description}`,
                    })

                } else {
                    toast.warning(res.data.message)
                }
            }
        } catch (err) {
            toast.warning(err.response.data.message)
        }
    }

    return (
        <>
            <div className="sm:hidden">
                <div className="flex items-center justify-center h-screen w-screen">
                    <div className="w-[240px] flex items-center justify-center flex-col">
                        <figure className="self-end">
                            <RoleDummyIcon />
                        </figure>

                        <p className="text-center font-semibold text-base">This module is only available on desktop version.</p>
                        <p className="text-center text-[#667085]">To work on this module please open it in desktop version.</p>
                    </div>

                </div>

            </div >
            <div className="roles w-100 hidden sm:flex flex-col h-full ">
                <div className="settings-title">
                    <div className="w-100">
                        <nav aria-label="breadcrumb" className='inner-page-breadcrumb'>
                            <ul className="d-flex align-items-center justify-content-start flex-row !mb-3 inter">
                                <li>
                                    <Link to='/settings/user-roles/roles'>Create New Role</Link>
                                </li>
                                <li className='flex items-center'><RightArrow />
                                    Assign Permissions
                                </li>
                                <li className='flex items-center'><RightArrow />
                                    Invite / Add Users
                                </li>
                            </ul>
                        </nav>



                        <div className="w-1/2">
                            <h1 className="fz24 mb-0 black font-medium inline-flex gap-x-2 items-center">
                                <Link

                                    to='/settings/user-roles/roles'
                                    className="w-8 h-8 hover:bg-black200 rounded inline-flex items-center justify-center titlebackbtn"
                                >
                                    <Pageback className="group-hover:stroke-black" />
                                </Link>
                                Create New Role</h1>
                            {/* <p className="fz16 black-dark-700 mb-0">
                                Lorem ipsum dolor sit amet consectetur. Praesent vitae pellentesque ut hendrerit semper odio ornare. Adipiscing facilisis risus nunc fringilla viverra.
                            </p> */}
                        </div>
                    </div>
                </div>
                <div className="h-full overflow-auto !pb-6 !pt-5">
                    <div className="w-3/5 flex pl-6 space-x-10">
                        <div className="w-1/2 space-y-4">
                            <div>
                                <label htmlFor="" className={`block ${error ? 'text-red' : ''}`}>Role Name <span className="text-red">*</span></label>
                                <input
                                    type="text"
                                    placeholder="e.g. Team Lead"
                                    className={`border py-2.5 px-3 rounded-md w-100 focus:outline-none focus:!border-primary`}
                                    style={{ borderColor: error ? '#FC5A5A' : '' }}
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                />
                                {error && <p className="text-red">Please Enter Role Name</p>}
                            </div>
                            <div>
                                <label htmlFor="" className="block">Description (Optional)</label>
                                <textarea
                                    type="text"
                                    placeholder="Enter Descriptions"
                                    className="border py-2.5 px-3 rounded-md w-100 border-grayLight focus:outline-none focus:!border-primary"
                                    rows={4} value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <p>Copy Permissions From<span className="text-red">*</span></p>
                            <div className="">

                                <div className="flex flex-col space-y-2.5 ">
                                    {
                                        masterRoles.map((item, index) => (
                                            <div className="flex justify-between cursor-pointer border rounded-xl p-3" key={item.uuid} onClick={() => setSelectedRole(item.name)}>
                                                <div className=" m-0 text-md leading-5 flex items-center">
                                                    <span>{item.name}</span>
                                                    {item.description && <TooltipText title={item.description}>
                                                        <InfoIcon className="ml-1" />
                                                    </TooltipText>}
                                                    {/* <p className="m-0 font-normal font-md">Lorem ipsum dolor sit amet consectetur. In pellentesque purus.</p> */}
                                                </div>
                                                <input type="radio" name='role' value={item.name} checked={selectedRole === item.name} className="h-5 w-5" />
                                            </div>
                                        ))
                                    }


                                    {/* <div className="flex justify-between border rounded-xl p-3">
                                <p className="font-semibold m-0 font-base">
                                    Marketing Lead
                                </p>
                                <input type="radio" className="h-5 w-5" />
                            </div>

                            <div className="flex justify-between border rounded-xl p-3">
                                <p className="font-semibold m-0 font-base">
                                    City Head
                                </p>
                                <input type="radio" className="h-5 w-5" />
                            </div>

                            <div className="flex justify-between border rounded-xl p-3">
                                <p className="font-semibold m-0 font-base">
                                    Sales Executive
                                </p>
                                <input type="radio" className="h-5 w-5" />
                            </div> */}

                                    <div className="flex justify-between border rounded-xl p-3">
                                        <p className=" m-0 font-md flex items-center">
                                            Custom New Role
                                            <TooltipText title={'Create a new role & define permissions either copied from existing role types or completely by yourself'}>
                                                <InfoIcon className="ml-1" />
                                            </TooltipText>
                                        </p>
                                        <input type="radio" name='role' value={'custom'} onChange={() => setSelectedRole('custom')} className="h-5 w-5" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={` flex flex-row items-center bg-white justify-between border-top border-color  w-100 space-x-3 p-3`}>
                    <Link className='border p-1 rounded-md text-black px-3 py-2'
                        to='/settings/user-roles/roles'
                    >Cancel</Link>
                    <button className='text-white bg-primary font-base rounded-lg flex px-[93px] py-2 font-medium'
                        onClick={() => {
                            if (uuid) {
                                handleUpdateRole();
                            } else {
                                handleCreateRole();
                            }

                        }}>
                        {uuid ? 'Update Role' : 'Create New Role'}
                    </button>
                </div>
            </div >
        </>
    );
}

export default NewRole;
