import React, { useCallback, useContext, useEffect, useState } from 'react'
import Tabs from '../../components/HOC/Tabs';
import CompanyAnalytics from './Company';
import CallsRequested from './CallsRequested';
import { getAllRequests, getCompaniesForAnalytics, getPlusAppFilterUsers, updateRm } from '../../services/private/company.service';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as SearchCloseIcon } from '../../assets/icons/searchClose.svg';
import { toast } from 'react-toastify';
import PlusAppFilterModal from '../../components/Modals/PlusAppFilterModal/PlusAppFilterModal';
import BannerList from './BannerList';
import { useLocation } from 'react-router-dom';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
import { SVEvents } from './SVEvents';
import { getAllBrokersSV } from '../../services/private/borker.service';
import AddNewCPFirm from './Modal/AddNewCPFirm';
import Consumer from '../../helpers/context';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';

const tabList = [
    { name: 'Channel Partners' },
    { name: 'Calls Requested' },
    { name: 'Banners' },
    { name: 'SV Events' }
]

const svTabList = [
    {
        name: 'Scheduled',
        value: 'scheduled'
    },
    {
        name: 'Completed',
        value: 'completed'
    },
    // {
    //     name: 'Attempts',
    //     value: 'attempt'
    // },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
]

const Dashboard = () => {
    const [selectedTab, setSelectedTab] = useState({ name: 'Channel Partners' });
    const [companyDetails, setCompanyDetails] = useState([]);
    const [allCompanyDetails, setAllCompanyDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filterShow, setfilterShow] = useState(false);
    const [filters, setFilters] = useState([]);
    const [callBookingList, setCallBookingList] = useState([]);
    const [companyStats, setCompanyStats] = useState({});
    const [selectedStats, setSelectedStats] = useState('Total')
    const [filterCount, setFilterCount] = useState(0);
    const [showFilterToaster, setShowFilterToaster] = useState(false);
    const [callRequestedCount, setCallRequestedCount] = useState('');
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1)
    const [leadsCount, setLeadsCount] = useState('');
    const [maxPage, setMaxPage] = useState(1);
    const [companyStartIndex, setCompanyStartIndex] = useState('');

    const [showingCallResults, setShowingCallResults] = useState(100);
    const [currentCallPage, setCurrentCallPage] = useState(1)
    const [callLeadsCount, setCallLeadsCount] = useState('');
    const [callMaxPage, setCallMaxPage] = useState(1);
    const [callStartIndex, setCallStartIndex] = useState('');
    const [selectedInternalTab, setSelectedInternalTab] = useState({
        name: 'Scheduled',
        value: 'scheduled'
    });
    const [internalTabRowCount, setInternalTabRowCount] = useState({})
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [svMaxPage, setSVMaxPage] = useState(1);
    const [svLeadCount, setSVLeadCount] = useState('');
    const [svCurrentPage, setSVCurrentPage] = useState(1);
    const [svShowingResults, setSVShowingResults] = useState(10);
    const [showAddCPFirm, setShowAddCPFirm] = useState(false);
    const [companyLoading, setCompanyLoading] = useState(false);

    const [tabRowCount, setTabRowCount] = useState({
        'Channel Partners': 0,
        'Calls Requested': 0
    })

    useDocumentTitle('CP Manager');
    let { allowedPermissions } = useContext(Consumer);

    const location = useLocation();

    useEffect(() => {
        if (location.search) {
            const params = location.search.split('=');
            setSelectedTab({ name: params[1] })
        } else {
            setSelectedTab({ name: 'Channel Partners' })
        }
    }, [location.search])

    const getCompanyData = async () => {
        setCompanyLoading(true)
        await getCompaniesForAnalytics(
            { filters, search: searchText },
            {
                perPage: showingResults,
                pageNumber: currentPage

            }).then(res => {
                if (res.status === 200) {
                    setCompanyLoading(false)
                    setCompanyDetails(res.data.data.companies)
                    setAllCompanyDetails(res.data.data.companies)

                    setCompanyStats(res.data.data.stat)
                    const totalPages = Math.ceil(
                        Number(res.data.data.stat[0].count) / showingResults
                    );
                    setTabRowCount({ ...tabRowCount, 'Channel Partners': res.data.data.stat[0].count })
                    setMaxPage(totalPages)
                    setLeadsCount(res.data.data.stat[0].count)
                    setCompanyStartIndex((currentPage - 1) * showingResults + 1);
                }
            }).catch((err) => {
                console.log("Something went wrong", err);
                setCompanyLoading(false)
            });
    }

    useEffect(() => {
        if (callLeadsCount && leadsCount) {
            setTabRowCount({
                'Channel Partners': leadsCount,
                'Calls Requested': callLeadsCount
            })
        }
    }, [callLeadsCount, leadsCount]);

    const getAllBookedRequest = async () => {
        try {
            const res = await getAllRequests({
                perPage: showingCallResults,
                pageNumber: currentCallPage,
                searchText
            });
            if (res.data.status === 200) {
                setCallBookingList(res.data.data.callRequested)
                setCallRequestedCount(res.data.data.count.count)
                const totalPages = Math.ceil(
                    Number(res.data.data.count.count) / showingCallResults
                );
                setCallLeadsCount(res.data.data.count.count)
                setCallMaxPage(totalPages)
                setCallStartIndex((currentCallPage - 1) * showingCallResults + 1)
            }
        } catch (err) {
            console.log({ err })
        }
    }

    const filterToaster = useCallback(() => {
        setShowFilterToaster(true)
    }, []);

    const handleClearSearch = () => {
        setSearchText('');
        getPlusAppFilterUsers({}).then((res) => {
            if (res.data.status === 200) {
                setCompanyDetails(res.data.data.companies)
                setAllCompanyDetails(res.data.data.companies)
            }

        }).catch((err) => {
            console.log({ err })
            toast.error(err.response?.data.message)
        }
        )
    }

    const filterData = (filter) => {
        setFilters(filter);
        // getPlusAppFilterUsers({ filters: filter }).then((res) => {
        //     if (res.data.status === 200) {
        //         setCompanyDetails(res.data.data.companies)
        //         setAllCompanyDetails(res.data.data.companies)
        //         toast.success('Filter Applied Successfully');
        //     }
        // })
        //     .catch((err) => {
        //         console.log({ err })
        //         toast.error(err.response?.data.message)
        //     })

    };

    // SV Events
    const getEvents = async () => {
        setIsLoading(true)
        const payload = {
            "pageNumber": currentPage,
            "perPage": showingResults,
            "searchText": searchText,
            "tab": selectedInternalTab?.value,
            filters: {}
        }
        try {
            const res = await getAllBrokersSV(payload);
            if (res.data.status === 200) {
                setIsLoading(false)
                setEvents(res.data.data?.entries);
                const totalPages = Math.ceil(
                    Number(res.data.data?.stats[selectedInternalTab.value]) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(res.data.data?.stats[selectedInternalTab.value]);
                let stats = {}
                svTabList.forEach(i => {
                    stats = { ...stats, [i.name]: `${res.data.data?.stats[i.value]}` }
                })
                setInternalTabRowCount(stats)
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }

    useEffect(() => {
        if (searchText) {
            if (selectedTab === 'Calls Requested') {
                // getAllBookedRequest()
            } else if (selectedTab.name === 'SV Events') {
                getEvents()
            } else {
                getCompanyData();
            }
        } else {
            if (selectedTab === 'Calls Requested') {
                // getAllBookedRequest()
            } else if (selectedTab.name === 'SV Events') {
                getEvents()
            } else {
                getCompanyData();
            }

        }
    }, [searchText, selectedTab]);

    // const handleStatsFilter = (value) => {
    //     setSelectedStats(value)
    //     let filterArr = []
    //     if (value === 'Free') {
    //         filterArr.push({
    //             'subscription_type': ['free']
    //         })
    //     }
    //     if (value === 'Premium') {
    //         filterArr.push({
    //             'subscription_type': ['premium']
    //         })
    //     }
    //     if (value === 'Total') {
    //         filterArr = []
    //     }
    //     if (value === 'Freemium') {
    //         filterArr.push({
    //             'subscription_type': ['freemium']
    //         })
    //     }
    //     // filterData(filterArr)
    //     setFilters(filterArr);
    // }

    useEffect(() => {
        if (selectedTab.name === 'Calls Requested') {
            // getAllBookedRequest()
            getCompanyData();
        } else if (selectedTab.name === 'SV Events') {
            getEvents()
        } else {
            getCompanyData();
        }

    }, [showingResults, currentPage, filters]);

    useEffect(() => {
        if (selectedTab?.name === 'SV Events' && selectedInternalTab)
            getEvents();
    }, [selectedInternalTab, selectedTab]);

    useEffect(() => {
        // getAllBookedRequest();
    }, [showingCallResults, currentCallPage]);

    useEffect(() => {
        setCurrentPage(1)
        setMaxPage(1)
    }, [selectedTab]);


    // useEffect(() => {
    //     getCompanyData();
    //     getAllBookedRequest();
    // }, [])


    return (
        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !border-b !border-grayLight">
                <div className="row d-flex d-flex flex-md-fill flex-wrap mb-2">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="text-xl m-0 black fw-po-medium">CP Manager</h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">
                        <div>
                            <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                                <SearchIcon className='mr-2' />
                                <input
                                    type="text"
                                    placeholder={selectedTab.name === 'SV Events' ? 'Search For SV Events' : 'Search For Users'}
                                    className=' w-100 focus:outline-none text-sm'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                {searchText &&
                                    <div className="cursor-pointer" onClick={() => handleClearSearch()}>
                                        <SearchCloseIcon />
                                    </div>}
                            </div>
                        </div>
                        {selectedTab.name === 'Channel Partners' && <div className="filter-btn mr-12">
                            <button className={`d-flex align-items-center ${filters?.length > 0 ? " border-0 pr-hover  active" : "border-0 btn"}`} type="button" onClick={() => setfilterShow(true)}>
                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                                <span className="text-capitalize">Filter {filters?.length > 0 ? ` (${filters?.length})` : " "}</span>

                            </button>
                        </div>}
                        {allowedPermissions.includes('cp_create_company') && <div className="dropdown view-more ">
                            <button
                                className="border-0 !w-[50px] !h-7 rounded-lg hover:bg-black100 flex items-center justify-center"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                        fill="#B5B5BE"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                        fill="#B5B5BE"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        // clipRule="evenodd"
                                        d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                        fill="#B5B5BE"
                                    />
                                </svg>
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-right w-auto"
                                aria-labelledby="dropdownMenuButton"

                            >
                                <a
                                    className="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setShowAddCPFirm(true)}
                                >
                                    Add CP Firm
                                </a>
                            </div>


                        </div>}
                    </div>
                </div>
                <Tabs
                    tabList={tabList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabName={selectedTab}
                    tabRowCount={tabRowCount}
                />
            </div>
            <div className='!mt-4 !px-5 dashboard-sv'>
                {selectedTab.name === 'Channel Partners' &&
                    <CompanyAnalytics
                        companyDetails={companyDetails}
                        getCompanyData={getCompanyData}
                        companyStats={companyStats}
                        // handleFilter={handleStatsFilter}
                        selectedStats={selectedStats}
                        showingResults={showingResults}
                        setShowingResults={setShowingResults}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        leadsCount={leadsCount}
                        maxPage={maxPage}
                        companyStartIndex={companyStartIndex}
                        isLoading={companyLoading}
                        searchText={searchText}
                    />}
                {selectedTab.name === 'Calls Requested' &&
                    <CallsRequested
                        getAllBookedRequest={getAllBookedRequest}
                        callBookingList={callBookingList}
                        showingResults={showingCallResults}
                        setShowingResults={setShowingCallResults}
                        currentPage={currentCallPage}
                        setCurrentPage={setCurrentCallPage}
                        leadsCount={callLeadsCount}
                        maxPage={callMaxPage}
                        callStartIndex={callStartIndex}
                    />}
                {selectedTab.name === 'Banners' && <BannerList />}
                {selectedTab.name === 'SV Events' && <SVEvents
                    events={events}
                    isLoading={isLoading}
                    searchText={searchText}
                    selectedTab={selectedInternalTab}
                    setSelectedTab={setSelectedInternalTab}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setShowingResults={setShowingResults}
                    showingResults={showingResults}
                    leadsCount={leadsCount}
                    maxPage={maxPage}
                    tabRowCount={internalTabRowCount}
                    setTabRowCount={setInternalTabRowCount}
                    tabList={svTabList}
                />}
                {selectedTab.name !== 'Banners' && <LeadsFooterBar
                    showingResults={showingResults}
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                    classname='!basis-0'
                />}
            </div>

            {filterShow && <PlusAppFilterModal
                show={filterShow}
                setShow={setfilterShow}
                handleFilter={setFilters}
                filterData={filterData}
                setFilterCount={setFilterCount}
                filtersData={filters}
                setCurrentPage={setCurrentPage}
                filterToaster={filterToaster}
            />}
            {
                showAddCPFirm && <AddNewCPFirm show={showAddCPFirm} closeModal={() => setShowAddCPFirm(false)} getCompanyData={getCompanyData} />
            }
        </div>
    )
}

export default Dashboard