import React from 'react'
import BasicModal from '../../../components/Modals/BasicModal';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import TooltipText from '../../../components/Tooltip';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info_icon.svg'

const SelectedUnitModal = ({ show, closeModal, selectedUnit, getSelectedUnitBookings, getClassName, getStatusClass, getClass, getAreaUnit }) => {

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-3 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4'>
                        <div>
                            <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>Floor {selectedUnit.floor_no}({`${selectedUnit?.available_count || '0'}/${selectedUnit?.units?.length}`})</h1>
                            {/* <p className='m-0 inter text-sm text-black700'>For Ankit</p> */}
                        </div>
                    </div>
                    <div className="flex gap-x-4">
                        {/* <button className='!border !border-grayLight hover:!border-primary bg-white rounded-lg hover:!bg-blue100 text-base font-medium inline-flex items-center gap-x-2 text-black700 hover:!text-primary w-[150px] h-11 justify-center pr-hover'>Edit unit <EditIcon /></button> */}
                        <button onClick={closeModal}><CloseIcon /></button>
                    </div>
                </div>
                <div className='flex justify-between !p-4 max-h-[70vh]  overflow-auto -translate-x-px'>
                    <div className='w-full  flex flex-wrap items-start justify-start !gap-4'>
                        {selectedUnit.units.map((unit, uIndex) => (
                            <div
                                className={`w-[131px] h-[74px] inline-block !p-2 rounded-xl bg-white inventory-grid-box-shadow !border cursor-pointer ${getClass(unit.status)}`}
                                onClick={() => {
                                    getSelectedUnitBookings(unit.uuid, 'view', unit);
                                }}
                                key={unit.uuid}
                            >
                                <div className='flex justify-between items-center !mt-1'>
                                    <span className='font-bold text-[#344054] leading-6'>{unit?.name || '-'}</span>
                                    {/* {unit.status === 'Pending confirmation' ? <TooltipText title={`${unit?.status} at ${unit?.booking_stage}`}>
                                     <div className={`text-xs text-center rounded-lg p-1 capitalize flex items-center leading-[22px] ${getStatusClass(unit.status)}`}>
                                         
                                         {unit?.booking_stage === 'booking' ? 'Booked' : 'Token'} <InfoIcon className='!ml-1' />
                                     </div>
                                 </TooltipText> :
                                     <div className={`text-xs text-center rounded-lg p-1 capitalize ${getStatusClass(unit?.status)}`}>
                                         {unit?.status}
                                     </div>
                                 } */}
                                </div>
                                <div>
                                    <p className='m-0 flex text-xs font-medium text-black700 items-center'>
                                        {unit?.configuration?.unit_type === 'Plot' &&
                                            <span>{unit?.configuration?.plot_area ? `${unit?.configuration?.plot_area} ${unit?.configuration?.plot_area_unit}` : '-'}</span>
                                        }
                                        {unit?.configuration?.unit_type !== 'Plot' && <>
                                            {getAreaUnit(unit?.configuration)}
                                        </>}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </BasicModal>
    )
}

export default SelectedUnitModal