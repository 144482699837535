import React, { Component } from 'react';
import { ACTIVITY_VIEWS } from '../Detail Sidebar/ACTIVITY_VIEWS';
import { action_getTimeline } from './actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from "moment";
import { getDisplayDateTime, toINRFormat } from '../../../../helpers/helpers';
import "./RecentActivities.css"
import { MAP_CURRENCY_NUMBER_TO_TEXT } from '../../../../utils/constants';
import EOIModal from '../../../../components/Modals/EOIModal';
import DetailsModal from '../../../Inventory/Modal/details';
import { getBookingDoneDetailsByLeadId, getDetailsByLeadId } from '../../../../services/private/inventory.service';
import { ReactComponent as FileTextIcon } from '../../../../assets/icons/file-text.svg';
import { ReactComponent as RupeesIcon } from '../../../../assets/icons/rupees.svg';
import { ReactComponent as IncomingCallIcon } from '../../../../assets/icons/Phone_incoming.svg';
import { ReactComponent as MissedCallIcon } from '../../../../assets/icons/Phone_missed.svg';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/play.svg';
import { ReactComponent as PushIcon } from '../../../../assets/icons/pause.svg';

const TIMELINE_EVENT_TYPE = {
    FOLLOWUP: 'FOLLOWUP',
    STATUS: 'STATUS',
    INTENT: 'INTENT',
    PROJECT: 'PROJECT',
    SITE_VISIT: 'SITE_VISIT',
    SOURCE: 'SOURCE',
    EOI_DONE: 'EOI_DONE',
    BOOKING_DONE: 'BOOKING_DONE',
    NOTE: 'NOTE',
    RE_ENGAGEMENT: 'RE_ENGAGEMENT',
    RE_ASSIGNED: 'RE_ASSIGNED',
    ASSIGNED: 'ASSIGNED',
    LEAD: 'LEAD',
    CUSTOMER_MOBILE: 'CUSTOMER_MOBILE',
    CUSTOMER_EMAIL: 'CUSTOMER_EMAIL',
    CUSTOMER_ALTERNATE_MOBILE: 'CUSTOMER_ALTERNATE_MOBILE',
    CUSTOMER_NAME: 'CUSTOMER_NAME',
    BUDGET: 'BUDGET',
    LEAD_ACCESS: 'LEAD_ACCESS',
    DEMAND: 'DEMAND',
    COLLECTION: 'COLLECTION',
    INCOMING_CALL: 'INCOMING_CALL',
    OUTGOING_CALL: 'OUTGOING_CALL',
    MISSED: 'MISSED',
    CONNECTED: 'CONNECTED',
    VOICEMAIL: 'VOICEMAIL',
    TAG_BROKER: 'TAG_BROKER',
    REMOVE_BROKER_TAG: 'REMOVE_BROKER_TAG'
}

const TIMELINE_ACTION_TYPE = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE'
}

const calculateTime = (seconds) => {
    let second = seconds
    let minutes = Math.floor(seconds / 60);
    // let hours = Math.floor(minutes / 60);

    second = second % 60;
    // if seconds are greater than 30, round minutes up (optional)
    minutes = second >= 30 ? minutes + 1 : minutes;

    // minutes = minutes % 60;

    // //  If you don't want to roll hours over, e.g. 24 to 00
    // //  comment (or remove) the line below
    // // commenting next line gets you `24:00:00` instead of `00:00:00`
    // // or `36:15:31` instead of `12:15:31`, etc.
    // hours = hours % 24;
    return `${minutes}:${second} mins`
}

class RecentActivities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leadUUID: this.props.match.params.id,
            showDetailsModal: false,
            selectedBooking: {},
            selectedRecording: '',
            isAudioPlay: false
        }

        this.getTimeline(this.state.leadUUID);

    }

    getTimeline = (uuid) => {
        this.props.getTimeline(uuid);
    }

    budgetMappingToText = (value) => {
        const price = value.split('-');
        return `${MAP_CURRENCY_NUMBER_TO_TEXT[price[0]]} ${price[1] != 'null' ? `- ${MAP_CURRENCY_NUMBER_TO_TEXT[price[1]]}` : ''}`
    }

    handleGetBookingDetails = async () => {
        if (!this.state.leadUUID) {
            return;
        }
        const res = await getBookingDoneDetailsByLeadId(this.state.leadUUID);
        if (res.data.status === 200) {
            this.setState({
                selectedBooking: res.data.data,
                showDetailsModal: true,
                modalType: 'Booking'
            })
        }
    }
    handleGetEoiDetails = async () => {
        if (!this.state.leadUUID) {
            return;
        }
        const res = await getDetailsByLeadId(this.state.leadUUID);
        if (res.data.status === 200) {
            this.setState({
                selectedBooking: res.data.data,
                showDetailsModal: true,
                modalType: 'EOI'
            })
        }
    }

    showName = (timeline) => {
        if (timeline?.system_created) {
            return 'System •'
        }

        return timeline?.created_by_name ? `${timeline?.created_by_name} •` : ''
    }
    getContent = (timeline) => {
        switch (timeline.event_type) {
            case TIMELINE_EVENT_TYPE.INCOMING_CALL:
                return <div className="box timeline_followup active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            {timeline.action === "MISSED" ? <MissedCallIcon /> : <IncomingCallIcon />}
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <div className='flex items-center justify-between mb-2'>
                                <p className='fz14 ro mb-0 black-dark-700 fw-regular d-flex'>
                                    {`IVR no.: `}
                                    <span>{timeline.ivr_number || '-'}</span>
                                </p>
                                {timeline.recording_url && !this.state.isAudioPlay && <button className='strokeprimary'
                                    onClick={() => {
                                        this.setState({
                                            selectedRecording: timeline.recording_url,
                                            isAudioPlay: true
                                        })
                                    }}
                                ><PlayIcon /></button>}
                                {this.state.isAudioPlay && <button
                                    className='strokeprimary'
                                    onClick={() => {
                                        this.setState({
                                            selectedRecording: '',
                                            isAudioPlay: false
                                        })
                                    }}
                                >
                                    <PushIcon /></button>}
                            </div>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Call Forwarded no .: `}
                                <span>{(timeline.call_forwarded_number) || '-'}</span>
                            </p>
                            {timeline.action !== "MISSED" && <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Call Duration: `}
                                <span>{timeline.call_duration ? calculateTime(timeline.call_duration) : '-'}</span>
                            </p>}
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Project: `}
                                <span>{(timeline.project_name).join(', ') || '-'}</span>
                            </p>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Source: `}
                                <span>{(timeline.source_name) || '-'}</span>
                            </p>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Assigned to: `}
                                <span>{(timeline.created_by_name) || '-'}</span>
                            </p>
                        </div>

                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.OUTGOING_CALL:
                return <div className="box timeline_followup active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <IncomingCallIcon />
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <div className='flex items-center justify-between mb-2'>
                                <p className='fz14 ro mb-0 black-dark-700 fw-regular d-flex'>
                                    {`IVR no.: `}
                                    <span>{timeline.ivr_number || '-'}</span>
                                </p>
                                {timeline.recording_url && !this.state.isAudioPlay && <button className='strokeprimary'
                                    onClick={() => {
                                        this.setState({
                                            selectedRecording: timeline.recording_url,
                                            isAudioPlay: true
                                        })
                                    }}
                                ><PlayIcon /></button>}
                                {this.state.isAudioPlay && <button
                                    className='strokeprimary'
                                    onClick={() => {
                                        this.setState({
                                            selectedRecording: '',
                                            isAudioPlay: false
                                        })
                                    }}
                                >
                                    <PushIcon /></button>}
                            </div>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Call Forwarded no .: `}
                                <span>{(timeline.call_forwarded_number) || '-'}</span>
                            </p>
                            {timeline.action !== "MISSED" && <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Call Duration: `}
                                <span>{timeline.call_duration ? calculateTime(timeline.call_duration) : '-'}</span>
                            </p>}
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Project: `}
                                <span>{(timeline.project_name).join(', ') || '-'}</span>
                            </p>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Source: `}
                                <span>{(timeline.source_name) || '-'}</span>
                            </p>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                {`Assigned to: `}
                                <span>{(timeline.project_name).join(', ') || '-'}</span>
                            </p>

                        </div>

                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>

            case TIMELINE_EVENT_TYPE.FOLLOWUP:
                return <div className="box timeline_followup active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M9.9987 4.16658C10.4589 4.16658 10.832 4.53968 10.832 4.99992V9.59235L14.2603 12.2588C14.6236 12.5413 14.689 13.0649 14.4065 13.4282C14.1239 13.7915 13.6004 13.8569 13.2371 13.5744L9.48708 10.6577C9.28409 10.4998 9.16536 10.2571 9.16536 9.99992V4.99992C9.16536 4.53968 9.53846 4.16658 9.9987 4.16658Z" fill="#0062FF" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.9987 0.833252C15.0613 0.833252 19.1654 4.93731 19.1654 9.99992C19.1654 15.0625 15.0613 19.1666 9.9987 19.1666C4.93609 19.1666 0.832031 15.0625 0.832031 9.99992C0.832031 4.93731 4.93609 0.833252 9.9987 0.833252ZM9.9987 2.49992C5.85656 2.49992 2.4987 5.85778 2.4987 9.99992C2.4987 14.1421 5.85656 17.4999 9.9987 17.4999C14.1408 17.4999 17.4987 14.1421 17.4987 9.99992C17.4987 5.85778 14.1408 2.49992 9.9987 2.49992Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0833 2.3335H2.91667C2.27233 2.3335 1.75 2.85583 1.75 3.50016V11.6668C1.75 12.3112 2.27233 12.8335 2.91667 12.8335H11.0833C11.7277 12.8335 12.25 12.3112 12.25 11.6668V3.50016C12.25 2.85583 11.7277 2.3335 11.0833 2.3335Z" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.33325 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.66675 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1.75 5.8335H12.25" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                {moment(timeline.schedule_date).format('h:mm a, Do MMM YYYY')}</p>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.5001 5.83665L7.00008 2.68665L3.50009 5.83665V11.148C3.50009 11.3628 3.6742 11.5369 3.88897 11.5369H4.79638V10.0952C4.79638 9.17173 5.55227 8.42576 6.48156 8.42576H7.5186C8.44789 8.42576 9.20378 9.17173 9.20378 10.0952V11.5369H10.1112C10.326 11.5369 10.5001 11.3628 10.5001 11.148V5.83665ZM11.7964 11.148C11.7964 12.0787 11.0419 12.8332 10.1112 12.8332H8.55563C8.19767 12.8332 7.90749 12.543 7.90749 12.185V10.0952C7.90749 9.89106 7.73527 9.72206 7.5186 9.72206H6.48156C6.26489 9.72206 6.09267 9.89106 6.09267 10.0952V12.185C6.09267 12.543 5.80249 12.8332 5.44453 12.8332H3.88897C2.95827 12.8332 2.20379 12.0787 2.20379 11.148V6.99989C1.93828 7.19947 1.55956 7.16649 1.33314 6.91491C1.09367 6.64884 1.11524 6.23902 1.38131 5.99955L6.56649 1.33289C6.81299 1.11104 7.18718 1.11104 7.43367 1.33289L12.6189 5.99955C12.8849 6.23902 12.9065 6.64884 12.667 6.91491C12.4406 7.16649 12.0619 7.19947 11.7964 6.99989V11.148Z" fill="#92929D" />
                                </svg>
                                <span>{(timeline.project_name).join(', ') || '-'}</span>
                            </p>
                            {timeline.remarks && <p className='fz14 ro mb4 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.08333 0.583496H8.83643C9.18832 0.583496 9.52141 0.742322 9.74293 1.01574L11.9898 3.78905C12.1582 3.99681 12.25 4.25609 12.25 4.52348V11.0835C12.25 12.3722 11.2053 13.4168 9.91667 13.4168H4.08333C2.79467 13.4168 1.75 12.3722 1.75 11.0835V2.91683C1.75 1.62817 2.79467 0.583496 4.08333 0.583496ZM9.11458 2.09348V3.7335H10.4433L9.11458 2.09348ZM4.08333 1.75016C3.439 1.75016 2.91667 2.2725 2.91667 2.91683V11.0835C2.91667 11.7278 3.439 12.2502 4.08333 12.2502H9.91667C10.561 12.2502 11.0833 11.7278 11.0833 11.0835V4.90016H9.11458C8.47025 4.90016 7.94792 4.37783 7.94792 3.7335V1.75016H4.08333Z" fill="#92929D" />
                                </svg>
                                Notes: {timeline.remarks}</p>}
                            {timeline.content && <p className='fz14 ro mb4 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.08333 0.583496H8.83643C9.18832 0.583496 9.52141 0.742322 9.74293 1.01574L11.9898 3.78905C12.1582 3.99681 12.25 4.25609 12.25 4.52348V11.0835C12.25 12.3722 11.2053 13.4168 9.91667 13.4168H4.08333C2.79467 13.4168 1.75 12.3722 1.75 11.0835V2.91683C1.75 1.62817 2.79467 0.583496 4.08333 0.583496ZM9.11458 2.09348V3.7335H10.4433L9.11458 2.09348ZM4.08333 1.75016C3.439 1.75016 2.91667 2.2725 2.91667 2.91683V11.0835C2.91667 11.7278 3.439 12.2502 4.08333 12.2502H9.91667C10.561 12.2502 11.0833 11.7278 11.0833 11.0835V4.90016H9.11458C8.47025 4.90016 7.94792 4.37783 7.94792 3.7335V1.75016H4.08333Z" fill="#92929D" />
                                </svg>
                                Reason: {timeline.content}</p>}
                        </div>

                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>

            case TIMELINE_EVENT_TYPE.DEMAND:
                return <div className="box timeline_followup active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.9987 4.16658C10.4589 4.16658 10.832 4.53968 10.832 4.99992V9.59235L14.2603 12.2588C14.6236 12.5413 14.689 13.0649 14.4065 13.4282C14.1239 13.7915 13.6004 13.8569 13.2371 13.5744L9.48708 10.6577C9.28409 10.4998 9.16536 10.2571 9.16536 9.99992V4.99992C9.16536 4.53968 9.53846 4.16658 9.9987 4.16658Z" fill="#0062FF" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.9987 0.833252C15.0613 0.833252 19.1654 4.93731 19.1654 9.99992C19.1654 15.0625 15.0613 19.1666 9.9987 19.1666C4.93609 19.1666 0.832031 15.0625 0.832031 9.99992C0.832031 4.93731 4.93609 0.833252 9.9987 0.833252ZM9.9987 2.49992C5.85656 2.49992 2.4987 5.85778 2.4987 9.99992C2.4987 14.1421 5.85656 17.4999 9.9987 17.4999C14.1408 17.4999 17.4987 14.1421 17.4987 9.99992C17.4987 5.85778 14.1408 2.49992 9.9987 2.49992Z" fill="#0062FF" />
                                </svg> */}
                            <FileTextIcon />
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex items-center'>
                                {/* <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.5001 5.83665L7.00008 2.68665L3.50009 5.83665V11.148C3.50009 11.3628 3.6742 11.5369 3.88897 11.5369H4.79638V10.0952C4.79638 9.17173 5.55227 8.42576 6.48156 8.42576H7.5186C8.44789 8.42576 9.20378 9.17173 9.20378 10.0952V11.5369H10.1112C10.326 11.5369 10.5001 11.3628 10.5001 11.148V5.83665ZM11.7964 11.148C11.7964 12.0787 11.0419 12.8332 10.1112 12.8332H8.55563C8.19767 12.8332 7.90749 12.543 7.90749 12.185V10.0952C7.90749 9.89106 7.73527 9.72206 7.5186 9.72206H6.48156C6.26489 9.72206 6.09267 9.89106 6.09267 10.0952V12.185C6.09267 12.543 5.80249 12.8332 5.44453 12.8332H3.88897C2.95827 12.8332 2.20379 12.0787 2.20379 11.148V6.99989C1.93828 7.19947 1.55956 7.16649 1.33314 6.91491C1.09367 6.64884 1.11524 6.23902 1.38131 5.99955L6.56649 1.33289C6.81299 1.11104 7.18718 1.11104 7.43367 1.33289L12.6189 5.99955C12.8849 6.23902 12.9065 6.64884 12.667 6.91491C12.4406 7.16649 12.0619 7.19947 11.7964 6.99989V11.148Z" fill="#92929D" />
                                </svg> */}
                                <RupeesIcon className='mr-2' />
                                <span>Demand Amount : </span>
                                <span>₹ {toINRFormat(Math.trunc(timeline.remarks))} </span>
                            </p>
                            {timeline.schedule_date && <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0833 2.3335H2.91667C2.27233 2.3335 1.75 2.85583 1.75 3.50016V11.6668C1.75 12.3112 2.27233 12.8335 2.91667 12.8335H11.0833C11.7277 12.8335 12.25 12.3112 12.25 11.6668V3.50016C12.25 2.85583 11.7277 2.3335 11.0833 2.3335Z" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.33325 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.66675 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1.75 5.8335H12.25" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                {moment(timeline.schedule_date).format('h:mm a, Do MMM YYYY')}
                            </p>}
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.5001 5.83665L7.00008 2.68665L3.50009 5.83665V11.148C3.50009 11.3628 3.6742 11.5369 3.88897 11.5369H4.79638V10.0952C4.79638 9.17173 5.55227 8.42576 6.48156 8.42576H7.5186C8.44789 8.42576 9.20378 9.17173 9.20378 10.0952V11.5369H10.1112C10.326 11.5369 10.5001 11.3628 10.5001 11.148V5.83665ZM11.7964 11.148C11.7964 12.0787 11.0419 12.8332 10.1112 12.8332H8.55563C8.19767 12.8332 7.90749 12.543 7.90749 12.185V10.0952C7.90749 9.89106 7.73527 9.72206 7.5186 9.72206H6.48156C6.26489 9.72206 6.09267 9.89106 6.09267 10.0952V12.185C6.09267 12.543 5.80249 12.8332 5.44453 12.8332H3.88897C2.95827 12.8332 2.20379 12.0787 2.20379 11.148V6.99989C1.93828 7.19947 1.55956 7.16649 1.33314 6.91491C1.09367 6.64884 1.11524 6.23902 1.38131 5.99955L6.56649 1.33289C6.81299 1.11104 7.18718 1.11104 7.43367 1.33289L12.6189 5.99955C12.8849 6.23902 12.9065 6.64884 12.667 6.91491C12.4406 7.16649 12.0619 7.19947 11.7964 6.99989V11.148Z" fill="#92929D" />
                                </svg>
                                <span>{(timeline.project_name).join(', ') || '-'}</span>
                            </p>
                            {/* {timeline.remarks && <p className='fz14 ro mb4 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.08333 0.583496H8.83643C9.18832 0.583496 9.52141 0.742322 9.74293 1.01574L11.9898 3.78905C12.1582 3.99681 12.25 4.25609 12.25 4.52348V11.0835C12.25 12.3722 11.2053 13.4168 9.91667 13.4168H4.08333C2.79467 13.4168 1.75 12.3722 1.75 11.0835V2.91683C1.75 1.62817 2.79467 0.583496 4.08333 0.583496ZM9.11458 2.09348V3.7335H10.4433L9.11458 2.09348ZM4.08333 1.75016C3.439 1.75016 2.91667 2.2725 2.91667 2.91683V11.0835C2.91667 11.7278 3.439 12.2502 4.08333 12.2502H9.91667C10.561 12.2502 11.0833 11.7278 11.0833 11.0835V4.90016H9.11458C8.47025 4.90016 7.94792 4.37783 7.94792 3.7335V1.75016H4.08333Z" fill="#92929D" />
                                </svg>
                                Notes: {timeline.remarks}</p>} */}
                        </div>

                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.COLLECTION:
                return <div className="box timeline_followup active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M9.9987 4.16658C10.4589 4.16658 10.832 4.53968 10.832 4.99992V9.59235L14.2603 12.2588C14.6236 12.5413 14.689 13.0649 14.4065 13.4282C14.1239 13.7915 13.6004 13.8569 13.2371 13.5744L9.48708 10.6577C9.28409 10.4998 9.16536 10.2571 9.16536 9.99992V4.99992C9.16536 4.53968 9.53846 4.16658 9.9987 4.16658Z" fill="#0062FF" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.9987 0.833252C15.0613 0.833252 19.1654 4.93731 19.1654 9.99992C19.1654 15.0625 15.0613 19.1666 9.9987 19.1666C4.93609 19.1666 0.832031 15.0625 0.832031 9.99992C0.832031 4.93731 4.93609 0.833252 9.9987 0.833252ZM9.9987 2.49992C5.85656 2.49992 2.4987 5.85778 2.4987 9.99992C2.4987 14.1421 5.85656 17.4999 9.9987 17.4999C14.1408 17.4999 17.4987 14.1421 17.4987 9.99992C17.4987 5.85778 14.1408 2.49992 9.9987 2.49992Z" fill="#0062FF" />
                                                </svg> */}
                            <FileTextIcon />
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex items-center'>
                                {/* <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.5001 5.83665L7.00008 2.68665L3.50009 5.83665V11.148C3.50009 11.3628 3.6742 11.5369 3.88897 11.5369H4.79638V10.0952C4.79638 9.17173 5.55227 8.42576 6.48156 8.42576H7.5186C8.44789 8.42576 9.20378 9.17173 9.20378 10.0952V11.5369H10.1112C10.326 11.5369 10.5001 11.3628 10.5001 11.148V5.83665ZM11.7964 11.148C11.7964 12.0787 11.0419 12.8332 10.1112 12.8332H8.55563C8.19767 12.8332 7.90749 12.543 7.90749 12.185V10.0952C7.90749 9.89106 7.73527 9.72206 7.5186 9.72206H6.48156C6.26489 9.72206 6.09267 9.89106 6.09267 10.0952V12.185C6.09267 12.543 5.80249 12.8332 5.44453 12.8332H3.88897C2.95827 12.8332 2.20379 12.0787 2.20379 11.148V6.99989C1.93828 7.19947 1.55956 7.16649 1.33314 6.91491C1.09367 6.64884 1.11524 6.23902 1.38131 5.99955L6.56649 1.33289C6.81299 1.11104 7.18718 1.11104 7.43367 1.33289L12.6189 5.99955C12.8849 6.23902 12.9065 6.64884 12.667 6.91491C12.4406 7.16649 12.0619 7.19947 11.7964 6.99989V11.148Z" fill="#92929D" />
                                                </svg> */}
                                <RupeesIcon className='mr-2' />
                                <span>Demand Amount : </span>
                                <span> ₹ {toINRFormat(Math.trunc(timeline.remarks))} </span>
                            </p>
                            {timeline.schedule_date && <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0833 2.3335H2.91667C2.27233 2.3335 1.75 2.85583 1.75 3.50016V11.6668C1.75 12.3112 2.27233 12.8335 2.91667 12.8335H11.0833C11.7277 12.8335 12.25 12.3112 12.25 11.6668V3.50016C12.25 2.85583 11.7277 2.3335 11.0833 2.3335Z" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.33325 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.66675 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1.75 5.8335H12.25" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                {moment(timeline.schedule_date).format('h:mm a, Do MMM YYYY')}
                            </p>}
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.5001 5.83665L7.00008 2.68665L3.50009 5.83665V11.148C3.50009 11.3628 3.6742 11.5369 3.88897 11.5369H4.79638V10.0952C4.79638 9.17173 5.55227 8.42576 6.48156 8.42576H7.5186C8.44789 8.42576 9.20378 9.17173 9.20378 10.0952V11.5369H10.1112C10.326 11.5369 10.5001 11.3628 10.5001 11.148V5.83665ZM11.7964 11.148C11.7964 12.0787 11.0419 12.8332 10.1112 12.8332H8.55563C8.19767 12.8332 7.90749 12.543 7.90749 12.185V10.0952C7.90749 9.89106 7.73527 9.72206 7.5186 9.72206H6.48156C6.26489 9.72206 6.09267 9.89106 6.09267 10.0952V12.185C6.09267 12.543 5.80249 12.8332 5.44453 12.8332H3.88897C2.95827 12.8332 2.20379 12.0787 2.20379 11.148V6.99989C1.93828 7.19947 1.55956 7.16649 1.33314 6.91491C1.09367 6.64884 1.11524 6.23902 1.38131 5.99955L6.56649 1.33289C6.81299 1.11104 7.18718 1.11104 7.43367 1.33289L12.6189 5.99955C12.8849 6.23902 12.9065 6.64884 12.667 6.91491C12.4406 7.16649 12.0619 7.19947 11.7964 6.99989V11.148Z" fill="#92929D" />
                                </svg>
                                <span>{(timeline.project_name).join(', ') || '-'}</span>
                            </p>
                            {/* {timeline.remarks && <p className='fz14 ro mb4 black-dark-700 fw-regular d-flex'>
                                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.08333 0.583496H8.83643C9.18832 0.583496 9.52141 0.742322 9.74293 1.01574L11.9898 3.78905C12.1582 3.99681 12.25 4.25609 12.25 4.52348V11.0835C12.25 12.3722 11.2053 13.4168 9.91667 13.4168H4.08333C2.79467 13.4168 1.75 12.3722 1.75 11.0835V2.91683C1.75 1.62817 2.79467 0.583496 4.08333 0.583496ZM9.11458 2.09348V3.7335H10.4433L9.11458 2.09348ZM4.08333 1.75016C3.439 1.75016 2.91667 2.2725 2.91667 2.91683V11.0835C2.91667 11.7278 3.439 12.2502 4.08333 12.2502H9.91667C10.561 12.2502 11.0833 11.7278 11.0833 11.0835V4.90016H9.11458C8.47025 4.90016 7.94792 4.37783 7.94792 3.7335V1.75016H4.08333Z" fill="#92929D" />
                                                </svg>
                                                Notes: {timeline.remarks}</p>} */}
                        </div>

                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.STATUS:
                return <div className="box timeline_status-change active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            {
                                timeline.title === 'Not Connected' && <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M28.8707 27.047C29.5839 25.9857 30 24.7081 30 23.3333C30 19.6514 27.0152 16.6667 23.3333 16.6667C21.9574 16.6667 20.6787 17.0835 19.6169 17.7978L28.8707 27.047ZM27.7732 28.3066L18.3579 18.8959C17.306 20.0745 16.6667 21.6293 16.6667 23.3333C16.6667 27.0152 19.6514 30 23.3333 30C25.0386 30 26.5943 29.3598 27.7732 28.3066ZM23.3333 15C27.9357 15 31.6667 18.731 31.6667 23.3333C31.6667 27.9357 27.9357 31.6667 23.3333 31.6667C18.731 31.6667 15 27.9357 15 23.3333C15 18.731 18.731 15 23.3333 15Z" fill="#0062FF" />
                                </svg>
                            }
                            {
                                timeline.title !== 'Not Connected' && <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M30.764 32.0294C31.6625 31.8297 32.2291 30.9394 32.0294 30.0409L31.3988 27.2032C32.0102 25.9586 32.3333 24.5851 32.3333 23.1667C32.3333 18.1041 28.2293 14 23.1667 14C18.1041 14 14 18.1041 14 23.1667C14 28.2293 18.1041 32.3333 23.1667 32.3333C24.5851 32.3333 25.9586 32.0102 27.2032 31.3988L30.0409 32.0294C30.279 32.0823 30.5259 32.0823 30.764 32.0294ZM30.6667 23.1667C30.6667 24.4167 30.3612 25.6207 29.7853 26.6977C29.6913 26.8734 29.6634 27.077 29.7067 27.2715L30.4024 30.4024L27.2715 29.7067C27.077 29.6634 26.8734 29.6913 26.6977 29.7853C25.6207 30.3612 24.4167 30.6667 23.1667 30.6667C19.0245 30.6667 15.6667 27.3088 15.6667 23.1667C15.6667 19.0245 19.0245 15.6667 23.1667 15.6667C27.3088 15.6667 30.6667 19.0245 30.6667 23.1667ZM20.6667 24.8333C21.5871 24.8333 22.3333 24.0871 22.3333 23.1667C22.3333 22.2462 21.5871 21.5 20.6667 21.5C19.7462 21.5 19 22.2462 19 23.1667C19 24.0871 19.7462 24.8333 20.6667 24.8333ZM27.3333 23.1667C27.3333 24.0871 26.5871 24.8333 25.6667 24.8333C24.7462 24.8333 24 24.0871 24 23.1667C24 22.2462 24.7462 21.5 25.6667 21.5C26.5871 21.5 27.3333 22.2462 27.3333 23.1667Z" fill="#0062FF" />
                                </svg>
                            }

                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'> Status Changed </h2>
                        <div className="innner-timeline-box">
                            <p className='mb-0 fz14 black-dark-700'>Status change to {timeline.title}</p>
                            {timeline.remarks && <p className='mb-0 fz14 black-dark-700'>Reason:  {timeline.remarks} </p>}
                            {timeline.content && <p className='mb-0 fz14 black-dark-700'>Comment:  {timeline.content} </p>}
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>

            case TIMELINE_EVENT_TYPE.LEAD_ACCESS:
                return <div className="box timeline_status-change active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* <circle cx="23" cy="23" r="23" fill="#FFF5ED"/> */}
                                <path fillRule="evenodd" clipRule="evenodd" d="M29.4407 17.7102C30.6241 19.1491 31.3346 20.9916 31.3346 23.0001C31.3346 27.6024 27.6037 31.3334 23.0013 31.3334C18.3989 31.3334 14.668 27.6024 14.668 23.0001C14.668 18.3977 18.3989 14.6667 23.0013 14.6667C24.995 14.6667 26.8251 15.3668 28.2593 16.5346L28.7183 16.0756L28.9365 14.9136C29.0214 14.4613 29.4569 14.1634 29.9093 14.2483C30.3616 14.3333 30.6595 14.7688 30.5745 15.2211L30.527 15.4744L30.7803 15.4269C31.2326 15.3419 31.6681 15.6398 31.7531 16.0921C31.838 16.5445 31.5401 16.98 31.0878 17.0649L29.8545 17.2964L29.4407 17.7102ZM27.073 17.7209C25.9468 16.8509 24.5345 16.3334 23.0013 16.3334C19.3194 16.3334 16.3346 19.3182 16.3346 23.0001C16.3346 26.682 19.3194 29.6667 23.0013 29.6667C26.6832 29.6667 29.668 26.682 29.668 23.0001C29.668 21.4521 29.1404 20.0273 28.2552 18.8958L27.0649 20.0861C27.6542 20.9065 28.0013 21.9128 28.0013 23.0001C28.0013 25.7615 25.7627 28.0001 23.0013 28.0001C20.2399 28.0001 18.0013 25.7615 18.0013 23.0001C18.0013 20.2386 20.2399 18.0001 23.0013 18.0001C24.0737 18.0001 25.0673 18.3377 25.8815 18.9124L27.073 17.7209ZM24.6764 20.1175C24.1842 19.8309 23.6119 19.6667 23.0013 19.6667C21.1604 19.6667 19.668 21.1591 19.668 23.0001C19.668 24.841 21.1604 26.3334 23.0013 26.3334C24.8423 26.3334 26.3346 24.841 26.3346 23.0001C26.3346 22.3743 26.1622 21.7889 25.8623 21.2886L24.6053 22.5457C24.6461 22.6901 24.668 22.8425 24.668 23.0001C24.668 23.9205 23.9218 24.6667 23.0013 24.6667C22.0808 24.6667 21.3346 23.9205 21.3346 23.0001C21.3346 22.0796 22.0808 21.3334 23.0013 21.3334C23.1423 21.3334 23.2793 21.3509 23.4101 21.3839L24.6764 20.1175Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'> {timeline.title} </h2>
                        <div className="innner-timeline-box">
                            {/* <p className='mb-0 fz14 black-dark-700'>Status change to {timeline.title}</p> */}
                            {timeline.content && <p className='mb-0 fz14 black-dark-700 truncate' >Shared with:  {timeline.content} </p>}
                            {timeline.remarks && <p className='mb-0 fz14 black-dark-700'>Remarks:  {timeline.remarks} </p>}
                            {timeline?.title === 'Lead Access Removed ' && <p className='mb-0 fz14 black-dark-700' >Access to lead removed from all shared users.</p>}
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.TAG_BROKER:
                return <div className="box timeline_status-change active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* <circle cx="23" cy="23" r="23" fill="#FFF5ED"/> */}
                                <path fillRule="evenodd" clipRule="evenodd" d="M29.4407 17.7102C30.6241 19.1491 31.3346 20.9916 31.3346 23.0001C31.3346 27.6024 27.6037 31.3334 23.0013 31.3334C18.3989 31.3334 14.668 27.6024 14.668 23.0001C14.668 18.3977 18.3989 14.6667 23.0013 14.6667C24.995 14.6667 26.8251 15.3668 28.2593 16.5346L28.7183 16.0756L28.9365 14.9136C29.0214 14.4613 29.4569 14.1634 29.9093 14.2483C30.3616 14.3333 30.6595 14.7688 30.5745 15.2211L30.527 15.4744L30.7803 15.4269C31.2326 15.3419 31.6681 15.6398 31.7531 16.0921C31.838 16.5445 31.5401 16.98 31.0878 17.0649L29.8545 17.2964L29.4407 17.7102ZM27.073 17.7209C25.9468 16.8509 24.5345 16.3334 23.0013 16.3334C19.3194 16.3334 16.3346 19.3182 16.3346 23.0001C16.3346 26.682 19.3194 29.6667 23.0013 29.6667C26.6832 29.6667 29.668 26.682 29.668 23.0001C29.668 21.4521 29.1404 20.0273 28.2552 18.8958L27.0649 20.0861C27.6542 20.9065 28.0013 21.9128 28.0013 23.0001C28.0013 25.7615 25.7627 28.0001 23.0013 28.0001C20.2399 28.0001 18.0013 25.7615 18.0013 23.0001C18.0013 20.2386 20.2399 18.0001 23.0013 18.0001C24.0737 18.0001 25.0673 18.3377 25.8815 18.9124L27.073 17.7209ZM24.6764 20.1175C24.1842 19.8309 23.6119 19.6667 23.0013 19.6667C21.1604 19.6667 19.668 21.1591 19.668 23.0001C19.668 24.841 21.1604 26.3334 23.0013 26.3334C24.8423 26.3334 26.3346 24.841 26.3346 23.0001C26.3346 22.3743 26.1622 21.7889 25.8623 21.2886L24.6053 22.5457C24.6461 22.6901 24.668 22.8425 24.668 23.0001C24.668 23.9205 23.9218 24.6667 23.0013 24.6667C22.0808 24.6667 21.3346 23.9205 21.3346 23.0001C21.3346 22.0796 22.0808 21.3334 23.0013 21.3334C23.1423 21.3334 23.2793 21.3509 23.4101 21.3839L24.6764 20.1175Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'> {'Lead tagged with'} </h2>
                        <div className="innner-timeline-box">
                            {/* <p className='mb-0 fz14 black-dark-700'>Status change to {timeline.title}</p> */}
                            {timeline.remarks && <p className='mb-0 fz14 black-dark-700'>Broker Company:  {timeline.remarks} </p>}
                            {timeline.content && <p className='mb-0 fz14 black-dark-700 truncate'>Tag Expiry Date:  {moment(timeline.content).format('Do MMM YYYY')} </p>}
                            {/* {timeline?.title === 'Lead Access Removed ' && <p className='mb-0 fz14 black-dark-700' >Access to lead removed from all shared users.</p>} */}
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>

            case TIMELINE_EVENT_TYPE.INTENT:
                return <div className="box timeline_intent active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* <circle cx="23" cy="23" r="23" fill="#FFF5ED"/> */}
                                <path fillRule="evenodd" clipRule="evenodd" d="M29.4407 17.7102C30.6241 19.1491 31.3346 20.9916 31.3346 23.0001C31.3346 27.6024 27.6037 31.3334 23.0013 31.3334C18.3989 31.3334 14.668 27.6024 14.668 23.0001C14.668 18.3977 18.3989 14.6667 23.0013 14.6667C24.995 14.6667 26.8251 15.3668 28.2593 16.5346L28.7183 16.0756L28.9365 14.9136C29.0214 14.4613 29.4569 14.1634 29.9093 14.2483C30.3616 14.3333 30.6595 14.7688 30.5745 15.2211L30.527 15.4744L30.7803 15.4269C31.2326 15.3419 31.6681 15.6398 31.7531 16.0921C31.838 16.5445 31.5401 16.98 31.0878 17.0649L29.8545 17.2964L29.4407 17.7102ZM27.073 17.7209C25.9468 16.8509 24.5345 16.3334 23.0013 16.3334C19.3194 16.3334 16.3346 19.3182 16.3346 23.0001C16.3346 26.682 19.3194 29.6667 23.0013 29.6667C26.6832 29.6667 29.668 26.682 29.668 23.0001C29.668 21.4521 29.1404 20.0273 28.2552 18.8958L27.0649 20.0861C27.6542 20.9065 28.0013 21.9128 28.0013 23.0001C28.0013 25.7615 25.7627 28.0001 23.0013 28.0001C20.2399 28.0001 18.0013 25.7615 18.0013 23.0001C18.0013 20.2386 20.2399 18.0001 23.0013 18.0001C24.0737 18.0001 25.0673 18.3377 25.8815 18.9124L27.073 17.7209ZM24.6764 20.1175C24.1842 19.8309 23.6119 19.6667 23.0013 19.6667C21.1604 19.6667 19.668 21.1591 19.668 23.0001C19.668 24.841 21.1604 26.3334 23.0013 26.3334C24.8423 26.3334 26.3346 24.841 26.3346 23.0001C26.3346 22.3743 26.1622 21.7889 25.8623 21.2886L24.6053 22.5457C24.6461 22.6901 24.668 22.8425 24.668 23.0001C24.668 23.9205 23.9218 24.6667 23.0013 24.6667C22.0808 24.6667 21.3346 23.9205 21.3346 23.0001C21.3346 22.0796 22.0808 21.3334 23.0013 21.3334C23.1423 21.3334 23.2793 21.3509 23.4101 21.3839L24.6764 20.1175Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>Intent Changed </h2>
                        <div className="innner-timeline-box">
                            <p className='mb-0 fz14 black-dark-700'>{timeline.title}</p>
                        </div>
                        <p className='fz12 black-dark-700 mb-0 mt4 fw-regular float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>

            case TIMELINE_EVENT_TYPE.NOTE:
                return <div className="box timeline_note active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.83333 0.833374H10.6235C11.1262 0.833374 11.602 1.06027 11.9185 1.45086L15.1283 5.41274C15.3688 5.70954 15.5 6.07993 15.5 6.46192V15.8334C15.5 17.6743 14.0076 19.1667 12.1667 19.1667H3.83333C1.99238 19.1667 0.5 17.6743 0.5 15.8334V4.16671C0.5 2.32576 1.99238 0.833374 3.83333 0.833374ZM11.0208 2.99049V5.33337H12.919L11.0208 2.99049ZM3.83333 2.50004C2.91286 2.50004 2.16667 3.24623 2.16667 4.16671V15.8334C2.16667 16.7538 2.91286 17.5 3.83333 17.5H12.1667C13.0871 17.5 13.8333 16.7538 13.8333 15.8334V7.00004H11.0208C10.1004 7.00004 9.35417 6.25385 9.35417 5.33337V2.50004H3.83333Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <p className='fz14 black-dark-700 mb4 ro fw-regular'>{timeline.remarks || '-'}</p>
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.SITE_VISIT:
                return <div className="box timeline_followup active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M23 25.4998C24.8409 25.4998 26.3333 24.0075 26.3333 22.1665C26.3333 20.3256 24.8409 18.8332 23 18.8332C21.1591 18.8332 19.6667 20.3256 19.6667 22.1665C19.6667 24.0075 21.1591 25.4998 23 25.4998ZM23 23.8332C22.0795 23.8332 21.3333 23.087 21.3333 22.1665C21.3333 21.246 22.0795 20.4998 23 20.4998C23.9205 20.4998 24.6667 21.246 24.6667 22.1665C24.6667 23.087 23.9205 23.8332 23 23.8332Z" fill="#0062FF" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.5 22.0582C15.5 17.9728 18.8609 14.6665 23 14.6665C27.1391 14.6665 30.5 17.9728 30.5 22.0582C30.5 24.0762 29.6745 25.9662 28.2459 27.3409L23.5844 31.9272C23.2602 32.2463 22.7399 32.2463 22.4156 31.9272L17.7478 27.3352C16.3204 25.9547 15.5 24.07 15.5 22.0582ZM27.0836 26.1464C28.1964 25.0756 28.8333 23.6172 28.8333 22.0582C28.8333 18.8994 26.2247 16.3332 23 16.3332C19.7753 16.3332 17.1667 18.8994 17.1667 22.0582C17.1667 23.6125 17.7997 25.0668 18.9115 26.1421L23 30.1642L27.0836 26.1464Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            {timeline.schedule_date && <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0833 2.3335H2.91667C2.27233 2.3335 1.75 2.85583 1.75 3.50016V11.6668C1.75 12.3112 2.27233 12.8335 2.91667 12.8335H11.0833C11.7277 12.8335 12.25 12.3112 12.25 11.6668V3.50016C12.25 2.85583 11.7277 2.3335 11.0833 2.3335Z" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.33325 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.66675 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1.75 5.8335H12.25" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                {moment(timeline.schedule_date).format('h:mm a, Do MMM YYYY')}</p>}
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.5001 5.83665L7.00008 2.68665L3.50009 5.83665V11.148C3.50009 11.3628 3.6742 11.5369 3.88897 11.5369H4.79638V10.0952C4.79638 9.17173 5.55227 8.42576 6.48156 8.42576H7.5186C8.44789 8.42576 9.20378 9.17173 9.20378 10.0952V11.5369H10.1112C10.326 11.5369 10.5001 11.3628 10.5001 11.148V5.83665ZM11.7964 11.148C11.7964 12.0787 11.0419 12.8332 10.1112 12.8332H8.55563C8.19767 12.8332 7.90749 12.543 7.90749 12.185V10.0952C7.90749 9.89106 7.73527 9.72206 7.5186 9.72206H6.48156C6.26489 9.72206 6.09267 9.89106 6.09267 10.0952V12.185C6.09267 12.543 5.80249 12.8332 5.44453 12.8332H3.88897C2.95827 12.8332 2.20379 12.0787 2.20379 11.148V6.99989C1.93828 7.19947 1.55956 7.16649 1.33314 6.91491C1.09367 6.64884 1.11524 6.23902 1.38131 5.99955L6.56649 1.33289C6.81299 1.11104 7.18718 1.11104 7.43367 1.33289L12.6189 5.99955C12.8849 6.23902 12.9065 6.64884 12.667 6.91491C12.4406 7.16649 12.0619 7.19947 11.7964 6.99989V11.148Z" fill="#92929D" />
                                </svg>
                                {(timeline.project_name).join(', ')}</p>
                            {timeline.remarks && <p className='fz14 ro mb4 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.08333 0.583496H8.83643C9.18832 0.583496 9.52141 0.742322 9.74293 1.01574L11.9898 3.78905C12.1582 3.99681 12.25 4.25609 12.25 4.52348V11.0835C12.25 12.3722 11.2053 13.4168 9.91667 13.4168H4.08333C2.79467 13.4168 1.75 12.3722 1.75 11.0835V2.91683C1.75 1.62817 2.79467 0.583496 4.08333 0.583496ZM9.11458 2.09348V3.7335H10.4433L9.11458 2.09348ZM4.08333 1.75016C3.439 1.75016 2.91667 2.2725 2.91667 2.91683V11.0835C2.91667 11.7278 3.439 12.2502 4.08333 12.2502H9.91667C10.561 12.2502 11.0833 11.7278 11.0833 11.0835V4.90016H9.11458C8.47025 4.90016 7.94792 4.37783 7.94792 3.7335V1.75016H4.08333Z" fill="#92929D" />
                                </svg>
                                Notes: {timeline.remarks}</p>}
                            {timeline.content && <p className='fz14 ro mb4 black-dark-700 fw-regular d-flex capitalize'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.08333 0.583496H8.83643C9.18832 0.583496 9.52141 0.742322 9.74293 1.01574L11.9898 3.78905C12.1582 3.99681 12.25 4.25609 12.25 4.52348V11.0835C12.25 12.3722 11.2053 13.4168 9.91667 13.4168H4.08333C2.79467 13.4168 1.75 12.3722 1.75 11.0835V2.91683C1.75 1.62817 2.79467 0.583496 4.08333 0.583496ZM9.11458 2.09348V3.7335H10.4433L9.11458 2.09348ZM4.08333 1.75016C3.439 1.75016 2.91667 2.2725 2.91667 2.91683V11.0835C2.91667 11.7278 3.439 12.2502 4.08333 12.2502H9.91667C10.561 12.2502 11.0833 11.7278 11.0833 11.0835V4.90016H9.11458C8.47025 4.90016 7.94792 4.37783 7.94792 3.7335V1.75016H4.08333Z" fill="#92929D" />
                                </svg>
                                {timeline.title === 'Site Visit Cancelled' ? `Reason: ${timeline.content}` : ''}
                                {(timeline.title === 'Site Visit Scheduled' || timeline.title === 'Site Visit Re-Scheduled') ? `Type: ${timeline.content}` : ''}
                                {timeline.title === 'Site Visit Completed' ? timeline.content : ''}
                                {/* {timeline.title === 'Site Visit Re-Scheduled' ? timeline.content : ''} */}
                            </p>
                            }
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div >
            // case TIMELINE_EVENT_TYPE.PROJECT:
            //     return <div className="box timeline_status active" key={timeline.id}>
            //     <div className="left">
            //         <div className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
            //             <path fillRule="evenodd" clipRule="evenodd" d="M4 0H12.1482C12.7514 0 13.3224 0.272273 13.7022 0.740983L17.554 5.49524C17.8425 5.8514 18 6.29587 18 6.75425V18C18 20.2091 16.2091 22 14 22H4C1.79086 22 0 20.2091 0 18V4C0 1.79086 1.79086 0 4 0ZM12.625 2.58854V5.4H14.9028L12.625 2.58854ZM4 2C2.89543 2 2 2.89543 2 4V18C2 19.1046 2.89543 20 4 20H14C15.1046 20 16 19.1046 16 18V7.4H12.625C11.5204 7.4 10.625 6.50457 10.625 5.4V2H4Z" fill="#0062FF" />
            //         </svg></div>
            //     </div>
            //     <div className="righ">
            //         <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
            //         {/* <p className='fz16 mb4'>Notes:  {timeline.remarks || '-'}</p> */}
            //         <p className='fz14 black-dark-700 mb4 ro fw-regular'>Project:  {(timeline.project_name).join(',')}</p>
            //         <p className='fz14 black-dark-700 mb4 ro fw-regular'>Updated by:  {timeline.created_by_name}.</p>
            //         <p className='fz12 ro fw-regular black-dark-700 mb-0 mt4'>{moment(timeline.created_on).format('h:mm a, Do MMMM YYYY')}</p>

            //     </div>
            // </div>

            case TIMELINE_EVENT_TYPE.SOURCE:
                return <div className="box timeline_status active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#E1E1E1" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.0013 13.8333H25.9443C26.439 13.8333 26.908 14.053 27.2247 14.433L30.9483 18.9014C31.1979 19.2009 31.3346 19.5785 31.3346 19.9684V28.8333C31.3346 30.6743 29.8423 32.1666 28.0013 32.1666H18.0013C16.1604 32.1666 14.668 30.6743 14.668 28.8333V17.1666C14.668 15.3257 16.1604 13.8333 18.0013 13.8333ZM29.668 28.8333V20.5H27.168C26.2475 20.5 25.5013 19.7538 25.5013 18.8333V15.5H18.0013C17.0808 15.5 16.3346 16.2462 16.3346 17.1666V28.8333C16.3346 29.7538 17.0808 30.5 18.0013 30.5H28.0013C28.9218 30.5 29.668 29.7538 29.668 28.8333ZM28.7221 18.8333L27.168 16.9684V18.8333H28.7221ZM19.668 24.6666C19.2077 24.6666 18.8346 24.2935 18.8346 23.8333C18.8346 23.3731 19.2077 23 19.668 23H26.3346C26.7949 23 27.168 23.3731 27.168 23.8333C27.168 24.2935 26.7949 24.6666 26.3346 24.6666H19.668ZM19.668 28C19.2077 28 18.8346 27.6269 18.8346 27.1666C18.8346 26.7064 19.2077 26.3333 19.668 26.3333H24.668C25.1282 26.3333 25.5013 26.7064 25.5013 27.1666C25.5013 27.6269 25.1282 28 24.668 28H19.668ZM19.668 21.3333C19.2077 21.3333 18.8346 20.9602 18.8346 20.5C18.8346 20.0397 19.2077 19.6666 19.668 19.6666H22.168C22.6282 19.6666 23.0013 20.0397 23.0013 20.5C23.0013 20.9602 22.6282 21.3333 22.168 21.3333H19.668Z" fill="#0062FF" />
                            </svg>

                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>

                        <div className="innner-timeline-box">
                            <p className='fz14 black-dark-700 mb4 ro fw-regular'>Source: {timeline.source_name}</p>
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.EOI_DONE:
                return <div className="box timeline_followup active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M30.6667 19.1667H15.6667V21.6667C15.6667 22.1269 16.0398 22.5 16.5 22.5H29.8333C30.2936 22.5 30.6667 22.1269 30.6667 21.6667V19.1667ZM19 17.5C19 16.1193 20.1193 15 21.5 15H24.8333C26.214 15 27.3333 16.1193 27.3333 17.5H30.6667C31.5871 17.5 32.3333 18.2462 32.3333 19.1667V27.5C32.3333 29.341 30.8409 30.8333 29 30.8333H17.3333C15.4924 30.8333 14 29.341 14 27.5V19.1667C14 18.2462 14.7462 17.5 15.6667 17.5H19ZM20.6667 17.5H25.6667C25.6667 17.0398 25.2936 16.6667 24.8333 16.6667H21.5C21.0398 16.6667 20.6667 17.0398 20.6667 17.5ZM20.6667 24.1667H16.5C16.2078 24.1667 15.9273 24.1165 15.6667 24.0244V27.5C15.6667 28.4205 16.4129 29.1667 17.3333 29.1667H29C29.9205 29.1667 30.6667 28.4205 30.6667 27.5V24.0244C30.406 24.1165 30.1255 24.1667 29.8333 24.1667H25.6667V25.5C25.6667 26.1443 25.1443 26.6667 24.5 26.6667H21.8333C21.189 26.6667 20.6667 26.1443 20.6667 25.5V24.1667ZM22.3333 25H24V24.1667H22.3333V25Z" fill="#0062FF" />
                            </svg>

                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular flex items-center '>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path d="M11.0833 2.3335H2.91667C2.27233 2.3335 1.75 2.85583 1.75 3.50016V11.6668C1.75 12.3112 2.27233 12.8335 2.91667 12.8335H11.0833C11.7277 12.8335 12.25 12.3112 12.25 11.6668V3.50016C12.25 2.85583 11.7277 2.3335 11.0833 2.3335Z" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.33325 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.66675 1.1665V3.49984" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1.75 5.8335H12.25" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                {moment(timeline.schedule_date).format('h:mm a, Do MMM YYYY')}</p>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.5001 5.83665L7.00008 2.68665L3.50009 5.83665V11.148C3.50009 11.3628 3.6742 11.5369 3.88897 11.5369H4.79638V10.0952C4.79638 9.17173 5.55227 8.42576 6.48156 8.42576H7.5186C8.44789 8.42576 9.20378 9.17173 9.20378 10.0952V11.5369H10.1112C10.326 11.5369 10.5001 11.3628 10.5001 11.148V5.83665ZM11.7964 11.148C11.7964 12.0787 11.0419 12.8332 10.1112 12.8332H8.55563C8.19767 12.8332 7.90749 12.543 7.90749 12.185V10.0952C7.90749 9.89106 7.73527 9.72206 7.5186 9.72206H6.48156C6.26489 9.72206 6.09267 9.89106 6.09267 10.0952V12.185C6.09267 12.543 5.80249 12.8332 5.44453 12.8332H3.88897C2.95827 12.8332 2.20379 12.0787 2.20379 11.148V6.99989C1.93828 7.19947 1.55956 7.16649 1.33314 6.91491C1.09367 6.64884 1.11524 6.23902 1.38131 5.99955L6.56649 1.33289C6.81299 1.11104 7.18718 1.11104 7.43367 1.33289L12.6189 5.99955C12.8849 6.23902 12.9065 6.64884 12.667 6.91491C12.4406 7.16649 12.0619 7.19947 11.7964 6.99989V11.148Z" fill="#92929D" />
                                </svg>
                                {(timeline.project_name).join(', ')}</p>
                            {timeline.remarks && <p className='fz14 ro mb4 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.08333 0.583496H8.83643C9.18832 0.583496 9.52141 0.742322 9.74293 1.01574L11.9898 3.78905C12.1582 3.99681 12.25 4.25609 12.25 4.52348V11.0835C12.25 12.3722 11.2053 13.4168 9.91667 13.4168H4.08333C2.79467 13.4168 1.75 12.3722 1.75 11.0835V2.91683C1.75 1.62817 2.79467 0.583496 4.08333 0.583496ZM9.11458 2.09348V3.7335H10.4433L9.11458 2.09348ZM4.08333 1.75016C3.439 1.75016 2.91667 2.2725 2.91667 2.91683V11.0835C2.91667 11.7278 3.439 12.2502 4.08333 12.2502H9.91667C10.561 12.2502 11.0833 11.7278 11.0833 11.0835V4.90016H9.11458C8.47025 4.90016 7.94792 4.37783 7.94792 3.7335V1.75016H4.08333Z" fill="#92929D" />
                                </svg>
                                Notes: {timeline.remarks}</p>}
                            <div className='flex justify-end'>
                                <button
                                    className={`py-1 px-3 rounded-lg hover:text-primary black-dark-700 `}
                                    onClick={() => {
                                        this.handleGetEoiDetails()

                                    }}>
                                    View Details
                                </button>
                            </div>
                        </div>

                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.BOOKING_DONE:
                return <div className="box timeline_status active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path d="M20.4097 22.5653L22.3023 24.4336L25.8482 20.1363C26.1411 19.7813 26.6663 19.731 27.0213 20.0239C27.3763 20.3168 27.4266 20.8421 27.1337 21.197L23.008 26.197C22.6965 26.5746 22.1281 26.6036 21.7798 26.2597L19.2388 23.7515C18.9113 23.4281 18.9079 22.9005 19.2312 22.573C19.5545 22.2454 20.0822 22.242 20.4097 22.5653Z" fill="#0062FF" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.8333 14H26.5C29.7217 14 32.3333 16.6117 32.3333 19.8333V26.5C32.3333 29.7217 29.7217 32.3333 26.5 32.3333H19.8333C16.6117 32.3333 14 29.7217 14 26.5V19.8333C14 16.6117 16.6117 14 19.8333 14ZM19.8333 15.6667C17.5321 15.6667 15.6667 17.5321 15.6667 19.8333V26.5C15.6667 28.8012 17.5321 30.6667 19.8333 30.6667H26.5C28.8012 30.6667 30.6667 28.8012 30.6667 26.5V19.8333C30.6667 17.5321 28.8012 15.6667 26.5 15.6667H19.8333Z" fill="#0062FF" />
                            </svg>

                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular flex items-center'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.6667 2.6665H3.33333C2.59695 2.6665 2 3.26346 2 3.99984V13.3332C2 14.0696 2.59695 14.6665 3.33333 14.6665H12.6667C13.403 14.6665 14 14.0696 14 13.3332V3.99984C14 3.26346 13.403 2.6665 12.6667 2.6665Z" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.6666 1.3335V4.00016" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.33337 1.3335V4.00016" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 6.6665H14" stroke="#92929D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                {moment(timeline.schedule_date).format('h:mm a, Do MMM YYYY')}</p>
                            <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 6.67031L8.00004 3.07031L4.00005 6.67031V12.7404C4.00005 12.9859 4.19903 13.1849 4.44449 13.1849H5.48153V11.5372C5.48153 10.4818 6.3454 9.62931 7.40745 9.62931H8.59263C9.65469 9.62931 10.5186 10.4818 10.5186 11.5372V13.1849H11.5556C11.8011 13.1849 12 12.9859 12 12.7404V6.67031ZM13.4815 12.7404C13.4815 13.8041 12.6193 14.6663 11.5556 14.6663H9.77782C9.36872 14.6663 9.03708 14.3347 9.03708 13.9256V11.5372C9.03708 11.3039 8.84025 11.1108 8.59263 11.1108H7.40745C7.15983 11.1108 6.963 11.3039 6.963 11.5372V13.9256C6.963 14.3347 6.63136 14.6663 6.22226 14.6663H4.44449C3.38083 14.6663 2.51856 13.8041 2.51856 12.7404V7.99973C2.21513 8.22783 1.7823 8.19013 1.52353 7.90261C1.24986 7.59853 1.27451 7.13017 1.57859 6.85649L7.50451 1.52316C7.78622 1.26962 8.21386 1.26962 8.49557 1.52316L14.4215 6.85649C14.7256 7.13017 14.7502 7.59853 14.4766 7.90261C14.2178 8.19014 13.7849 8.22783 13.4815 7.99973V12.7404Z" fill="#92929D" />
                                </svg>
                                {(timeline.project_name).join(', ')}</p>
                            {timeline.remarks && <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.08333 0.583496H8.83643C9.18832 0.583496 9.52141 0.742322 9.74293 1.01574L11.9898 3.78905C12.1582 3.99681 12.25 4.25609 12.25 4.52348V11.0835C12.25 12.3722 11.2053 13.4168 9.91667 13.4168H4.08333C2.79467 13.4168 1.75 12.3722 1.75 11.0835V2.91683C1.75 1.62817 2.79467 0.583496 4.08333 0.583496ZM9.11458 2.09348V3.7335H10.4433L9.11458 2.09348ZM4.08333 1.75016C3.439 1.75016 2.91667 2.2725 2.91667 2.91683V11.0835C2.91667 11.7278 3.439 12.2502 4.08333 12.2502H9.91667C10.561 12.2502 11.0833 11.7278 11.0833 11.0835V4.90016H9.11458C8.47025 4.90016 7.94792 4.37783 7.94792 3.7335V1.75016H4.08333Z" fill="#92929D" />
                                </svg>
                                Notes: {timeline.remarks}</p>}
                            {timeline.content &&
                                <>
                                    <p className='fz14 ro black-dark-700 mb-2 fw-regular d-flex'>
                                        <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 8 12" fill="none">
                                            <path d="M7.94531 3.40625L7.58594 4.64062H0.335938L0.695312 3.40625H7.94531ZM4.69531 12L0.5625 7.17188L0.554688 6.20312H2.46094C3.01302 6.20312 3.46875 6.11198 3.82812 5.92969C4.19271 5.74219 4.46615 5.48958 4.64844 5.17188C4.83073 4.85417 4.92188 4.4974 4.92188 4.10156C4.92188 3.65885 4.83594 3.27083 4.66406 2.9375C4.49219 2.59896 4.22135 2.33594 3.85156 2.14844C3.48698 1.95573 3.00781 1.85938 2.41406 1.85938H0.351562L0.71875 0.625H2.41406C3.32031 0.625 4.06771 0.763021 4.65625 1.03906C5.25 1.3099 5.69271 1.70573 5.98438 2.22656C6.27604 2.7474 6.42188 3.3776 6.42188 4.11719C6.42188 4.7526 6.29948 5.32292 6.05469 5.82812C5.8151 6.32812 5.41667 6.72135 4.85938 7.00781C4.30729 7.29427 3.5625 7.4375 2.625 7.4375L6.46875 11.9062V12H4.69531ZM7.94531 0.625L7.58594 1.85938H1.74219L2.10156 0.625H7.94531Z" fill="#92929D" />
                                        </svg>
                                        {`Paid Price: ${toINRFormat(parseInt(timeline.content.split('-')[0]))}`}
                                    </p>

                                    {timeline.content.split('-')[1] != 'undefined' ? <p className='fz14 ro mb-2 black-dark-700 fw-regular d-flex'>
                                        <svg className='mr-10' xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 8 12" fill="none">
                                            <path d="M7.94531 3.40625L7.58594 4.64062H0.335938L0.695312 3.40625H7.94531ZM4.69531 12L0.5625 7.17188L0.554688 6.20312H2.46094C3.01302 6.20312 3.46875 6.11198 3.82812 5.92969C4.19271 5.74219 4.46615 5.48958 4.64844 5.17188C4.83073 4.85417 4.92188 4.4974 4.92188 4.10156C4.92188 3.65885 4.83594 3.27083 4.66406 2.9375C4.49219 2.59896 4.22135 2.33594 3.85156 2.14844C3.48698 1.95573 3.00781 1.85938 2.41406 1.85938H0.351562L0.71875 0.625H2.41406C3.32031 0.625 4.06771 0.763021 4.65625 1.03906C5.25 1.3099 5.69271 1.70573 5.98438 2.22656C6.27604 2.7474 6.42188 3.3776 6.42188 4.11719C6.42188 4.7526 6.29948 5.32292 6.05469 5.82812C5.8151 6.32812 5.41667 6.72135 4.85938 7.00781C4.30729 7.29427 3.5625 7.4375 2.625 7.4375L6.46875 11.9062V12H4.69531ZM7.94531 0.625L7.58594 1.85938H1.74219L2.10156 0.625H7.94531Z" fill="#92929D" />
                                        </svg>
                                        {`Agreement Price: ${toINRFormat(parseInt(timeline.content.split('-')[1]))}`}
                                    </p> : null}
                                </>
                            }
                            <div className='flex justify-end'>
                                <button
                                    className={`py-1 px-3 rounded-lg hover:text-primary black-dark-700 `}
                                    onClick={() => {
                                        this.handleGetBookingDetails()
                                    }}>
                                    View Details
                                </button>
                            </div>

                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.RE_ENGAGEMENT:
                return <div className="box timeline_status active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4 0H12.1482C12.7514 0 13.3224 0.272273 13.7022 0.740983L17.554 5.49524C17.8425 5.8514 18 6.29587 18 6.75425V18C18 20.2091 16.2091 22 14 22H4C1.79086 22 0 20.2091 0 18V4C0 1.79086 1.79086 0 4 0ZM12.625 2.58854V5.4H14.9028L12.625 2.58854ZM4 2C2.89543 2 2 2.89543 2 4V18C2 19.1046 2.89543 20 4 20H14C15.1046 20 16 19.1046 16 18V7.4H12.625C11.5204 7.4 10.625 6.50457 10.625 5.4V2H4Z" fill="#0062FF" />
                        </svg> */}
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path d="M19.8867 21.8322C19.6808 21.603 19.3898 21.4684 19.0819 21.4598C18.7739 21.4514 18.476 21.5697 18.258 21.7871L15.5649 24.4802C15.0997 24.9452 14.8385 25.5759 14.8385 26.2336C14.8385 26.8916 15.0998 27.5223 15.5649 27.9873L18.2298 30.6549C18.4122 30.8423 18.6535 30.9614 18.9132 30.9922C19.1729 31.0227 19.4354 30.9632 19.6563 30.8235C19.9413 30.639 20.1274 30.3356 20.1629 29.9979C20.1983 29.6604 20.0793 29.3249 19.8389 29.0854L18.1064 27.3556H23.8177C25.4984 27.3568 27.1071 26.6732 28.2727 25.4625C29.4383 24.2518 30.0604 22.6186 29.9954 20.9392C29.8634 17.5976 27.0105 15.0002 23.6663 15.0002H19.9343C19.6483 14.9945 19.3704 15.095 19.1541 15.2823C18.9378 15.4695 18.7988 15.7301 18.7633 16.0139C18.7324 16.3304 18.8368 16.645 19.0511 16.88C19.2652 17.1149 19.5688 17.2482 19.8867 17.2467H23.8178C24.8959 17.2453 25.9273 17.6868 26.6705 18.4678C27.4138 19.2487 27.8039 20.3006 27.749 21.3772C27.6789 22.3985 27.2205 23.354 26.4682 24.048C25.7156 24.742 24.7263 25.1215 23.7027 25.1092H18.1062L19.7911 23.4243H19.7909C20.0064 23.2208 20.136 22.9428 20.1537 22.6471C20.1715 22.3513 20.0759 22.06 19.8865 21.8322L19.8867 21.8322Z" fill="#0062FF" stroke="white" strokeWidth="0.3" />
                            </svg>

                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <p className='fz14 black-dark-700 mb4 ro fw-regular'>
                                <b>Name:</b> {timeline.remarks || '-'}
                            </p>
                            <p className='fz14 black-dark-700 mb4 ro fw-regular'>
                                <b>Email:</b> {timeline.content || '-'}
                            </p>
                            <p className='fz14 black-dark-700 mb4 ro fw-regular'>
                                <b>Project:</b> {(timeline.project_name).join(', ') || '-'}
                            </p>
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.RE_ASSIGNED:
                return <div className="box timeline_reasign active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#E1E1E1" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.2827 31.2445L20.1558 30.336L20.1559 32.3737C20.155 32.3867 20.1626 32.4055 20.1885 32.4302C20.2667 32.505 20.4109 32.5242 20.5219 32.4832L21.2827 31.2445ZM22.5909 32.299L21.8444 33.5144C21.7973 33.5912 21.7379 33.6599 21.6687 33.7177C20.9098 34.352 19.7505 34.3172 19.0368 33.6349C18.6613 33.276 18.4605 32.7811 18.4908 32.3221L18.4893 29.1151L14.8231 26.1335C14.4614 25.8394 14.1827 25.4564 14.0142 25.022C13.4778 23.6392 14.1662 22.0846 15.5505 21.5499L30.1936 15.8936C30.4771 15.7841 30.7444 15.8324 30.9487 15.9692C31.2025 16.1317 31.3515 16.4228 31.3305 16.7273C31.3269 16.7855 31.3169 16.8439 31.2997 16.9018L27.3068 31.2868C26.9128 32.7062 25.4358 33.5377 24.0083 33.1501C23.6487 33.0524 23.3134 32.8815 23.0239 32.648L22.5909 32.299ZM20.5943 28.5489L24.0697 31.3505C24.1797 31.4391 24.3075 31.5043 24.445 31.5416C24.9909 31.6899 25.5528 31.3736 25.7007 30.841L28.7832 19.7357L20.5943 28.5489ZM19.2315 27.5694L27.4291 18.7497L16.1513 23.1046C15.6257 23.3076 15.365 23.896 15.568 24.4191C15.6318 24.5835 15.7375 24.7287 15.8747 24.8402L19.2315 27.5694Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        {timeline.remarks && <div className="innner-timeline-box">
                            <p className='mb-0 fz14 black-dark-700 fw-po-regular'>Comments: {timeline.remarks}</p>
                        </div>}
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'> {this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.ASSIGNED:
                return <div className="box timeline_reasign active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#E1E1E1" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.2827 31.2445L20.1558 30.336L20.1559 32.3737C20.155 32.3867 20.1626 32.4055 20.1885 32.4302C20.2667 32.505 20.4109 32.5242 20.5219 32.4832L21.2827 31.2445ZM22.5909 32.299L21.8444 33.5144C21.7973 33.5912 21.7379 33.6599 21.6687 33.7177C20.9098 34.352 19.7505 34.3172 19.0368 33.6349C18.6613 33.276 18.4605 32.7811 18.4908 32.3221L18.4893 29.1151L14.8231 26.1335C14.4614 25.8394 14.1827 25.4564 14.0142 25.022C13.4778 23.6392 14.1662 22.0846 15.5505 21.5499L30.1936 15.8936C30.4771 15.7841 30.7444 15.8324 30.9487 15.9692C31.2025 16.1317 31.3515 16.4228 31.3305 16.7273C31.3269 16.7855 31.3169 16.8439 31.2997 16.9018L27.3068 31.2868C26.9128 32.7062 25.4358 33.5377 24.0083 33.1501C23.6487 33.0524 23.3134 32.8815 23.0239 32.648L22.5909 32.299ZM20.5943 28.5489L24.0697 31.3505C24.1797 31.4391 24.3075 31.5043 24.445 31.5416C24.9909 31.6899 25.5528 31.3736 25.7007 30.841L28.7832 19.7357L20.5943 28.5489ZM19.2315 27.5694L27.4291 18.7497L16.1513 23.1046C15.6257 23.3076 15.365 23.896 15.568 24.4191C15.6318 24.5835 15.7375 24.7287 15.8747 24.8402L19.2315 27.5694Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        {timeline.remarks && <div className="innner-timeline-box">
                            <p className='mb-0 fz14 black-dark-700 fw-po-regular'>Comments: {timeline.remarks}</p>
                        </div>}
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'> {this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.REMOVE_BROKER_TAG:
                return <div className="box timeline_reasign active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* <circle cx="23" cy="23" r="23" fill="#FFF5ED"/> */}
                                <path fillRule="evenodd" clipRule="evenodd" d="M29.4407 17.7102C30.6241 19.1491 31.3346 20.9916 31.3346 23.0001C31.3346 27.6024 27.6037 31.3334 23.0013 31.3334C18.3989 31.3334 14.668 27.6024 14.668 23.0001C14.668 18.3977 18.3989 14.6667 23.0013 14.6667C24.995 14.6667 26.8251 15.3668 28.2593 16.5346L28.7183 16.0756L28.9365 14.9136C29.0214 14.4613 29.4569 14.1634 29.9093 14.2483C30.3616 14.3333 30.6595 14.7688 30.5745 15.2211L30.527 15.4744L30.7803 15.4269C31.2326 15.3419 31.6681 15.6398 31.7531 16.0921C31.838 16.5445 31.5401 16.98 31.0878 17.0649L29.8545 17.2964L29.4407 17.7102ZM27.073 17.7209C25.9468 16.8509 24.5345 16.3334 23.0013 16.3334C19.3194 16.3334 16.3346 19.3182 16.3346 23.0001C16.3346 26.682 19.3194 29.6667 23.0013 29.6667C26.6832 29.6667 29.668 26.682 29.668 23.0001C29.668 21.4521 29.1404 20.0273 28.2552 18.8958L27.0649 20.0861C27.6542 20.9065 28.0013 21.9128 28.0013 23.0001C28.0013 25.7615 25.7627 28.0001 23.0013 28.0001C20.2399 28.0001 18.0013 25.7615 18.0013 23.0001C18.0013 20.2386 20.2399 18.0001 23.0013 18.0001C24.0737 18.0001 25.0673 18.3377 25.8815 18.9124L27.073 17.7209ZM24.6764 20.1175C24.1842 19.8309 23.6119 19.6667 23.0013 19.6667C21.1604 19.6667 19.668 21.1591 19.668 23.0001C19.668 24.841 21.1604 26.3334 23.0013 26.3334C24.8423 26.3334 26.3346 24.841 26.3346 23.0001C26.3346 22.3743 26.1622 21.7889 25.8623 21.2886L24.6053 22.5457C24.6461 22.6901 24.668 22.8425 24.668 23.0001C24.668 23.9205 23.9218 24.6667 23.0013 24.6667C22.0808 24.6667 21.3346 23.9205 21.3346 23.0001C21.3346 22.0796 22.0808 21.3334 23.0013 21.3334C23.1423 21.3334 23.2793 21.3509 23.4101 21.3839L24.6764 20.1175Z" fill="#0062FF" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        {/* {timeline.remarks && <div className="innner-timeline-box">
                            <p className='mb-0 fz14 black-dark-700 fw-po-regular'>Comments: {timeline.remarks}</p>
                        </div>} */}
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'> {this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.LEAD:
                return <div className="box timeline_lead active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.4999 22.1668C23.8011 22.1668 25.6666 20.3013 25.6666 18.0002C25.6666 15.699 23.8011 13.8335 21.4999 13.8335C19.1987 13.8335 17.3333 15.699 17.3333 18.0002C17.3333 20.3013 19.1987 22.1668 21.4999 22.1668ZM21.4999 20.5002C20.1192 20.5002 18.9999 19.3809 18.9999 18.0002C18.9999 16.6195 20.1192 15.5002 21.4999 15.5002C22.8806 15.5002 23.9999 16.6195 23.9999 18.0002C23.9999 19.3809 22.8806 20.5002 21.4999 20.5002Z" fill="#0062FF" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.4999 23.0002C25.1818 23.0002 28.1666 25.9849 28.1666 29.6668V31.3335C28.1666 31.7937 27.7935 32.1668 27.3333 32.1668H15.6666C15.2063 32.1668 14.8333 31.7937 14.8333 31.3335V29.6668C14.8333 25.9849 17.818 23.0002 21.4999 23.0002ZM26.4999 30.5002V29.6668C26.4999 26.9054 24.2613 24.6668 21.4999 24.6668C18.7385 24.6668 16.4999 26.9054 16.4999 29.6668V30.5002H26.4999Z" fill="#0062FF" />
                                <path d="M30.6666 23.0002H32.3333C33.4444 23.0002 33.4444 21.3335 32.3333 21.3335H30.6666V19.6668C30.6666 18.5557 28.9999 18.5557 28.9999 19.6668V21.3335H27.3333C26.2221 21.3335 26.2221 23.0002 27.3333 23.0002H28.9999V24.6668C28.9999 25.7779 30.6666 25.7779 30.6666 24.6668V23.0002Z" fill="#0062FF" />
                            </svg>

                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            <p className='fz14 black-dark-700 mb4 ro fw-regular'><b>Project:</b>  {(timeline.project_name).join(', ') || '-'}</p>
                            <p className='fz14 black-dark-700 mb4 ro fw-regular'><b>Source:</b>  {timeline.source_name}</p>
                            {timeline.content === 'SELF' && <p className='fz14 black-dark-700 mb4 ro fw-regular'><b>Created:</b> Manual</p>}
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)}  {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>
                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.CUSTOMER_MOBILE:
                return <div className="box timeline_status active " key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path d="M27.5 15.6904C27.7189 15.4715 27.9787 15.2979 28.2647 15.1794C28.5506 15.061 28.8571 15 29.1667 15C29.4762 15 29.7827 15.061 30.0687 15.1794C30.3546 15.2979 30.6145 15.4715 30.8333 15.6904C31.0522 15.9092 31.2258 16.1691 31.3443 16.455C31.4627 16.741 31.5237 17.0475 31.5237 17.357C31.5237 17.6666 31.4627 17.973 31.3443 18.259C31.2258 18.545 31.0522 18.8048 30.8333 19.0237L19.5833 30.2737L15 31.5237L16.25 26.9404L27.5 15.6904Z" stroke="#0062FF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        {/* <p className='fz16 mb4'>Notes:  {timeline.remarks || '-'}</p> */}
                        <div className="innner-timeline-box">
                            <p className='fz14 black-dark-700 mb4 ro fw-regular'> Mobile number changed <br /> "{timeline.remarks?.slice(-10) || '-'}" to "<span className='font-weight-bold'>{timeline.content?.slice(-10)}</span>"</p>
                            {/* <p className='fz14 black-dark-700 mb4 ro fw-regular'>New:  {timeline.content}</p> */}
                        </div>

                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.CUSTOMER_ALTERNATE_MOBILE:
                return <div className="box timeline_status active change" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path d="M27.5 15.6904C27.7189 15.4715 27.9787 15.2979 28.2647 15.1794C28.5506 15.061 28.8571 15 29.1667 15C29.4762 15 29.7827 15.061 30.0687 15.1794C30.3546 15.2979 30.6145 15.4715 30.8333 15.6904C31.0522 15.9092 31.2258 16.1691 31.3443 16.455C31.4627 16.741 31.5237 17.0475 31.5237 17.357C31.5237 17.6666 31.4627 17.973 31.3443 18.259C31.2258 18.545 31.0522 18.8048 30.8333 19.0237L19.5833 30.2737L15 31.5237L16.25 26.9404L27.5 15.6904Z" stroke="#0062FF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">
                            {timeline.remarks && <p className='fz14 black-dark-700 mb4 ro fw-regular'>Mobile number changes from "{timeline.remarks?.slice(-10) || '-'}" to "<span className='font-weight-bold'>{timeline.content?.slice(-10)}</span>"</p>}
                            {!timeline.remarks && <p className='fz14 black-dark-700 mb4 ro fw-regular'>Mobile number updated to: "<span className='font-weight-bold'>{timeline.content?.slice(-10)}</span>"</p>}
                            {/* <p className='fz14 black-dark-700 mb4 ro fw-regular'>Old:  {timeline.remarks}</p> */}
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.CUSTOMER_EMAIL:
                return <div className="box timeline_status active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path d="M27.5 15.6904C27.7189 15.4715 27.9787 15.2979 28.2647 15.1794C28.5506 15.061 28.8571 15 29.1667 15C29.4762 15 29.7827 15.061 30.0687 15.1794C30.3546 15.2979 30.6145 15.4715 30.8333 15.6904C31.0522 15.9092 31.2258 16.1691 31.3443 16.455C31.4627 16.741 31.5237 17.0475 31.5237 17.357C31.5237 17.6666 31.4627 17.973 31.3443 18.259C31.2258 18.545 31.0522 18.8048 30.8333 19.0237L19.5833 30.2737L15 31.5237L16.25 26.9404L27.5 15.6904Z" stroke="#0062FF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">

                            <p className='fz14 black-dark-700 mb4 ro fw-regular'>Email changed from "{timeline.remarks || '-'}" to "<b>{timeline.content || '-'}</b>"</p>
                            {/* <p className='fz14 black-dark-700 mb4 ro fw-regular'>New:  {timeline.content}</p> */}
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.CUSTOMER_NAME:
                return <div className="box timeline_status active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path d="M27.5 15.6904C27.7189 15.4715 27.9787 15.2979 28.2647 15.1794C28.5506 15.061 28.8571 15 29.1667 15C29.4762 15 29.7827 15.061 30.0687 15.1794C30.3546 15.2979 30.6145 15.4715 30.8333 15.6904C31.0522 15.9092 31.2258 16.1691 31.3443 16.455C31.4627 16.741 31.5237 17.0475 31.5237 17.357C31.5237 17.6666 31.4627 17.973 31.3443 18.259C31.2258 18.545 31.0522 18.8048 30.8333 19.0237L19.5833 30.2737L15 31.5237L16.25 26.9404L27.5 15.6904Z" stroke="#0062FF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">

                            <p className='fz14 black-dark-700 mb4 ro fw-regular'>Name changed from "{timeline.remarks}" to "<b>{timeline.content}</b>"</p>
                            {/* <p className='fz14 black-dark-700 mb4 ro fw-regular'>New:  {timeline.content}</p> */}
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
            case TIMELINE_EVENT_TYPE.BUDGET:
                return <div className="box timeline_status active" key={timeline.id}>
                    <div className="left">
                        <div className="icon">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="23" cy="23" r="22.5" fill="white" stroke="#DEDEDE" />
                                <path d="M27.5 15.6904C27.7189 15.4715 27.9787 15.2979 28.2647 15.1794C28.5506 15.061 28.8571 15 29.1667 15C29.4762 15 29.7827 15.061 30.0687 15.1794C30.3546 15.2979 30.6145 15.4715 30.8333 15.6904C31.0522 15.9092 31.2258 16.1691 31.3443 16.455C31.4627 16.741 31.5237 17.0475 31.5237 17.357C31.5237 17.6666 31.4627 17.973 31.3443 18.259C31.2258 18.545 31.0522 18.8048 30.8333 19.0237L19.5833 30.2737L15 31.5237L16.25 26.9404L27.5 15.6904Z" stroke="#0062FF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div className="righ">
                        <h2 className='fz14 po mb-12 fw-po-medim black-dark-800'>{timeline.title}</h2>
                        <div className="innner-timeline-box">

                            <p className='fz14 black-dark-700 mb4 ro fw-regular'>Budget changed<br />from "<b className='m-0'>{timeline.remarks ? this.budgetMappingToText(timeline.remarks) : '-'}</b>" to "<b className='m-0'>{timeline.content ? this.budgetMappingToText(timeline.content) : '-'}</b>"</p>
                            {/* <p className='fz14 black-dark-700 mb4 ro fw-regular'>New:  {timeline.content}</p> */}
                        </div>
                        <p className='fz12 ro fw-regular black-dark-700 mb-0 mt-8 float-right'>{this.showName(timeline)} {moment(timeline.created_on).format('h:mm a, Do MMM YYYY')}</p>

                    </div>
                </div>
        }
    }

    displayTimeline = () => {
        if (this.props.ActivityTimeline?.length > 0) {
            return this.props.ActivityTimeline.map((timeline, index) => {
                return this.getContent(timeline)
                // return <div className="box active" key={index}>
                // </div>
            });
        }
        if (this.props.timeline.length > 0) {
            return this.props.timeline.map((timeline, index) => {
                return this.getContent(timeline)
                // return <div className="box active" key={index}>
                // </div>
            });
        }
    }

    render() {
        return (
            <>
                <div className="recent-activites">
                    <div className="row sticky-top bg-white">
                        <div style={{ width: '100%' }} className='d-flex justify-content-between align-items-center px-3'>
                            <h2 className="fz16 po black po fw-regular mb-3 mt-16 d">
                                Recent Activities {this.props.leadName && <span className="text-capitalize">({this.props.leadName})</span>}
                            </h2>
                            {this.props.leadName &&
                                <button
                                    type="button"
                                    className="close float-right"
                                    aria-label="Close"
                                    onClick={this.props.closeTimelineDropdown}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#000"></path></svg></button>}
                        </div>
                    </div>
                    {this.displayTimeline()}

                    {this.state.showDetailsModal && <DetailsModal
                        show={this.state.showDetailsModal}
                        unit={{}}
                        selectedBooking={this.state.selectedBooking}
                        closeModal={() => this.setState({ showDetailsModal: false })}
                        modalOpenFrom={'table'}
                        bookingStatus={{}}
                        ModalType={this.state.modalType}
                        handleChangeApprovalStatus={() => { }}
                        data={{}}
                        handleSubmit={() => { }}
                        updateData={() => { }}
                        operation={'view only'}
                    />}
                </div>
                {this.state.selectedRecording && <div className='!pr-5 fixed bottom-3 right-0 w-[95vw]'>
                    <audio controls className='w-full'>
                        <source src={this.state.selectedRecording} type="audio/mpeg" />
                    </audio>
                </div>}
            </>

        )
    }
}

function mapStateToProps(state) {
    return {
        timeline: state.timelineReducer.timeline
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTimeline: (leadUUID) => {
            if (leadUUID) {
                dispatch(action_getTimeline(leadUUID))
            }
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RecentActivities))