import moment from 'moment';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import EmployeePipelineTable from '../../components/Analytics/employee-pipeline-table';
import DeadLeadCard from '../../components/Analytics/dead-lead-card';
import PipelineAnalysisTable from '../../components/Analytics/pipeline-analysis-table';
import ProjectAnalysisTable from '../../components/Analytics/project-analysis-table';
import BookingEOI from '../../components/Analytics/booking-eoi-table';
import SourceAnalysisTable from '../../components/Analytics/source-analysis-table';
import ResultBar from '../../components/Analytics/top-result-bar';
import { getAllAdsByCompanyId, getAssignedToLead, getBookingLeads, getCountForConversionAnalysis, getDeadLeads, getEmployeeEventAnalysis, getEmployeeInsights, getEventsCount, getIntentLead, getLeadCountByProjectWithSource, getProjectLead, getProjectLeadWithStatus, getSourceLead, getStatusLead } from '../../services/private/analytics.service';
import './analytics.css';
import IntentAnalysisTable from '../../components/Analytics/intent-analysis-table';
import EmployeeInsight from '../../components/Analytics/employee-insight';
import ConversionAnalysis from '../../components/Analytics/conversion-analysis';
import SitevisiteTable from '../../components/Analytics/sitevisite-table';
import AverageResponseTime from '../../components/Analytics/average-response-time';
import CustomDateModal from '../../components/Modals/CustomDateModal';
import { getCompanyConfig, getUsersForSettings } from '../../services/private/company.service';
import SearchIcon from '../../assets/icons/search.svg'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FacebookInsights from '../../components/Analytics/facebook-insights';
import { getAllTeams } from '../../services/public/public.service';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';

const Analytics = () => {
    const [fromDate, setFromDate] = useState(moment(Date.now()).startOf('week').format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment(Date.now()).endOf('week').format("YYYY-MM-DD"));
    const [selectedDate, setSelectedDate] = useState('week');
    const [statusLeads, setStatusLeads] = useState([]);
    const [sourceLeads, setSourceLeads] = useState([]);
    const [intentLeads, setIntentLeads] = useState([]);
    const [projectLeadsWithStatus, setProjectLeadsWithStatus] = useState([]);
    const [projectLeadsWithSource, setProjectLeadsWithSource] = useState([]);
    const [assignedToLeads, setAssignedToLeads] = useState([]);
    const [showCustomDate, setShowCustomDate] = useState(false);
    const [deadLeads, setDeadLeads] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [allProject, setAllProject] = useState([]);
    const [bookingLeads, setBookingLeads] = useState([]);
    const [eventsCount, setEventsCount] = useState([]);
    const [usersList, setUsersList] = useState([{ label: 'Unassigned', value: null }]);
    const [teamsList, setTeamsList] = useState([]);
    const [allUser, setAllUser] = useState([]);
    const [allTeams, setAllTeams] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [employeeInsights, setEmployeeInsights] = useState([]);
    const [conversionCountAnalysis, setConversionCountAnalysis] = useState([]);
    const [searchEmployee, setSearchEmployee] = useState('');
    const [searchTeam, setSearchTeam] = useState('');
    const [searchProject, setSearchProject] = useState('');
    const [showEmployee, setShowEmployee] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const [showProject, setShowProject] = useState(false);
    const [selectedEmployeeName, setSelectedEmployeeName] = useState([]);
    const [selectedTeamName, setSelectedTeamName] = useState([]);
    const [selectedProjectName, setSelectedProjectName] = useState([]);
    const [allTimeData, setAllTimeData] = useState(false);

    const [projectWithStatusLoading, setProjectWithStatusLoading] = useState(false);
    const [statusLeadsLoading, setStatusLeadsLoading] = useState(false);
    const [sourceLeadsLoading, setSourceLeadsLoading] = useState(false);
    const [intentLeadsLoading, setIntentLeadsLoading] = useState(false);
    const [assignedToLeadsLoading, setAssignedToLeadsLoading] = useState(false);
    const [projectLeadsWithSourceLoading, setProjectLeadsWithSourceLoading] = useState(false);
    const [deadLeadsLoading, setDeadLeadsLoading] = useState(false);
    const [bookingLeadsLoading, setBookingLeadsLoading] = useState(false);
    const [eventCountsLoading, setEventCountsLoading] = useState(false);
    const [employeeInsightsLoading, setEmployeeInsightsLoading] = useState(false);
    const [conversionCountLoading, setConversionCountLoading] = useState(false);
    const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
    const [selectedTeamsList, setSelectedTeamsList] = useState([]);
    const [selectedProjectList, setSelectedProjectList] = useState([]);
    const [toggleState, setToggleState] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [leadButtonActive, setLeadButtonActive] = useState(false);
    const [fbCampaign, setFbCampaign] = useState([]);
    const [fbCampaignLoading, setFbCampaignLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [companyConfig, setCompanyConfig] = useState({});

    const analyticPage = useRef();
    const employeeRef = useRef();
    const teamRef = useRef();
    const projectRef = useRef();

    useDocumentTitle('Analytics');

    const handleOutsideClick = (e) => {
        if ((employeeRef.current && !employeeRef.current.contains(e.target))) {
            setShowEmployee(false);
            if (!isFilterApplied) {
                setSelectedEmployee([]);
                setSelectedEmployeeName([]);
            }
        }

        if (teamRef.current && !teamRef.current.contains(e.target)) {
            setShowTeam(false)
            if (!isFilterApplied) {
                setSelectedTeamsList([]);
                setSelectedTeamName([]);
            }
        }

        if (projectRef.current && !projectRef.current.contains(e.target)) {
            setShowProject(false)
        }
    }

    // const printDocument = () => {
    //     const input = document.getElementById('toPrint');
    //     html2canvas(input, {
    //         width: 1650,
    //         height: 2900
    //     }).then((canvas) => {
    //         var imgWidth = 410;
    //         var pageHeight = 420;
    //         let imgHeight = ((canvas.height * imgWidth) / 2454) * 1.24;
    //         var heightLeft = imgHeight;
    //         const imgData = canvas.toDataURL("image/png");

    //         const pdf = new jsPDF({
    //             orientation: "p",
    //             unit: "mm",
    //             format: [400, 425],
    //         });

    //         let position = 0;
    //         pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;

    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             pdf.addPage();
    //             pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //             heightLeft -= pageHeight;
    //         }
    //         pdf.save("Result.pdf");
    //     });
    // }

    const getAllStatusLeads = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setStatusLeadsLoading(true);
        await getStatusLead(payload).then((res) => {
            setStatusLeads(res.data.data);
        }).catch((err) => {
            console.log({ err })
        })
        setStatusLeadsLoading(false);
    }

    const getAllSourceLeads = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setSourceLeadsLoading(true)
        await getSourceLead(payload).then((res) => {
            setSourceLeads(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setSourceLeadsLoading(false)
    }

    const getAllIntentLeads = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setIntentLeadsLoading(true);
        await getIntentLead(payload).then((res) => {
            setIntentLeads(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setIntentLeadsLoading(false);
    }

    const getAllAssignedToLeads = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setAssignedToLeadsLoading(true);
        setLeadButtonActive(false);
        await getAssignedToLead(payload).then((res) => {
            setAssignedToLeads(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setAssignedToLeadsLoading(false);
    }


    const getAllProjectLeadsWithSource = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setProjectLeadsWithSourceLoading(true);
        await getLeadCountByProjectWithSource(payload).then((res) => {
            setProjectLeadsWithSource(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setProjectLeadsWithSourceLoading(false);
    }

    const getAllProjectLeadsWithStatus = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setProjectWithStatusLoading(true);
        await getProjectLeadWithStatus(payload).then((res) => {
            setProjectLeadsWithStatus(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setProjectWithStatusLoading(false);
    }

    const getAllDeadLeads = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setDeadLeadsLoading(true);
        await getDeadLeads(payload).then((res) => {
            setDeadLeads(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setDeadLeadsLoading(false);
    }

    const getAllBookingLeads = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setBookingLeadsLoading(true);
        await getBookingLeads(payload).then((res) => {
            setBookingLeads(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setBookingLeadsLoading(false);
    }

    const getAllEventsCount = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setEventCountsLoading(true);
        await getEventsCount(payload).then((res) => {
            setEventsCount(res.data.data);
            setEventCountsLoading(false);
            toast.success('Filter applied');
        }).catch((err) => {
            console.log({ err })
            setEventCountsLoading(false);
        })

    }

    const getAllEmployeeInsights = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setEmployeeInsightsLoading(true);
        await getEmployeeInsights(payload).then((res) => {
            setEmployeeInsights(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setEmployeeInsightsLoading(false);
    }

    const getAllCountForConversionAnalysis = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setConversionCountLoading(true);
        await getCountForConversionAnalysis(payload).then((res) => {
            setConversionCountAnalysis(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setConversionCountLoading(false);
    }

    const getAllEmployeeEventAnalysis = async () => {
        const payload = {
            fromDate,
            toDate,
            projects: selectedProject.length > 0 && selectedProject,
            assignedTo: selectedEmployee.length > 0 && selectedEmployee,
            teams: selectedTeam.length > 0 && selectedTeam,
            all_time: allTimeData
        }
        setAssignedToLeadsLoading(true);
        await getEmployeeEventAnalysis(payload).then((res) => {
            setAssignedToLeads(res.data.data)
        }).catch((err) => {
            console.log({ err })
        })
        setLeadButtonActive(true);
        setAssignedToLeadsLoading(false);
    }

    const getFacebookCampaign = async () => {
        setFbCampaignLoading(true)
        const res = await getAllAdsByCompanyId();
        setFbCampaignLoading(false);
        if (res.status === 200) {
            setFbCampaign(res.data.data);
        }
    }

    const getAllRecords = () => {
        setLeadButtonActive(false);
        getAllStatusLeads();
        getAllSourceLeads();
        getAllAssignedToLeads();
        getAllDeadLeads();
        getAllProjectLeadsWithStatus();
        getAllIntentLeads();
        getAllBookingLeads();
        getAllEventsCount();
        getAllProjectLeadsWithSource();
        getAllEmployeeInsights();
        getAllCountForConversionAnalysis();
        // getFacebookCampaign();
        // setShowEmployee(false);
        // setShowProject(false);
    }

    useEffect(() => {
        if (fromDate && toDate) {
            getAllRecords();
        }

    }, [fromDate, toDate, allTimeData, toggleState]);

    const getCompanyConfigData = async () => {
        await getCompanyConfig().then(res => {
            if (res.status === 200) {
                setProjectList(res.data.data.result.Project)
                setAllProject(res.data.data.result.Project);
                setCompanyConfig(res.data.data.result);
            }
        })
    }

    const getUserData = async () => {
        await getUsersForSettings().then(res => {
            if (res.status === 200) {
                const users = res.data.data.map(user => { return { label: user.name, value: user.id } });
                setAllUser([...usersList, ...users]);
                setUsersList([...usersList, ...users]);
                const userSelection = users.map(item => item.value);
                setSelectedEmployee([...userSelection, null]);
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getAll = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            const teams = res.data.data.map(team => { return { label: team.name, value: team.id } });
            setAllTeams([...teams]);
            setTeamsList([...teams]);
            const teamSelection = teams.map(item => item.value);
            setSelectedEmployee([...teamSelection]);
        }
    }

    useEffect(() => {
        if (window.innerWidth < 992) {
            setSelectedDate('custom');
            setIsMobile(true);
        }
        getCompanyConfigData();
        getUserData();
        getAll();
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return (() => document.removeEventListener('mousedown', handleOutsideClick))
    }, [isFilterApplied])

    const handleCurrentDate = useCallback(() => {
        setFromDate(moment(Date.now()).format("YYYY-MM-DD"));
        setToDate(moment(Date.now()).format("YYYY-MM-DD"));
        setSelectedDate('today');
        setAllTimeData(false);
        setToggleState(!toggleState);
    }, [toggleState]);

    const handleYesterdayDate = useCallback(() => {
        setFromDate(moment(Date.now()).subtract(1, 'day').format("YYYY-MM-DD"));
        setToDate(moment(Date.now()).subtract(1, 'day').format("YYYY-MM-DD"));
        setSelectedDate('yesterday');
        setAllTimeData(false);
        setToggleState(!toggleState);
    }, [toggleState]);

    const handleCurrentWeek = useCallback(() => {
        setFromDate(moment(Date.now()).startOf('week').format("YYYY-MM-DD"));
        setToDate(moment(Date.now()).endOf('week').format("YYYY-MM-DD"));
        setSelectedDate('week');
        setAllTimeData(false);
        setToggleState(!toggleState);
    }, [toggleState]);

    // const handleCurrentYear = useCallback(() => {
    //     setFromDate(moment(Date.now()).startOf('year').format("YYYY-MM-DD"));
    //     setToDate(moment(Date.now()).format("YYYY-MM-DD"));
    //     setSelectedDate('year')
    // }, []);

    const handleCurrentMonth = useCallback(() => {
        setFromDate(moment(Date.now()).startOf('month').format("YYYY-MM-DD"));
        setToDate(moment(Date.now()).endOf('month').format("YYYY-MM-DD"));
        setSelectedDate('month');
        setAllTimeData(false);
        setToggleState(!toggleState);
    }, [toggleState]);

    const handleCustomDate = useCallback((date) => {
        setFromDate(moment(date[0].startDate).format("YYYY-MM-DD"));
        setToDate(moment(date[0].endDate).format("YYYY-MM-DD"));
        setSelectedDate('custom');
        setAllTimeData(false);
        setToggleState(!toggleState);
        closeCustomDateModal();
    }, []);

    const closeCustomDateModal = useCallback(() => {
        setShowCustomDate(false);
    }, []);

    const handleChangeProject = useCallback((data) => {
        if (selectedProject.includes(data.value)) {
            const project = selectedProject.filter(item => item != data.value);
            const filteredName = selectedProjectName.filter(item => item.label != data.label);
            setSelectedProject(project);
            setSelectedProjectName(filteredName);
        } else {
            setSelectedProject([...selectedProject, data.value]);
            setSelectedProjectName([...selectedProjectName, data]);
        }
    }, [selectedProject]);

    const handleChangeEmployee = useCallback((data) => {
        if (selectedEmployee.includes(data.value)) {
            const employee = selectedEmployee.filter(item => item != data.value);
            const filteredName = selectedEmployeeName.filter(item => item.label != data.label);
            // const index = usersList.findIndex(item => item.value === data.value)
            setSelectedEmployee(employee);
            setSelectedEmployeeName(filteredName);
            // if (index < 0) {
            //     setUsersList([...usersList, data])
            // }

        } else {
            setSelectedEmployee([...selectedEmployee, data.value])
            setSelectedEmployeeName([...selectedEmployeeName, data])
        }
    }, [selectedEmployee]);

    const handleChangeTeam = useCallback((data) => {
        if (selectedTeam.includes(data.value)) {
            const team = selectedTeam.filter(item => item != data.value);
            const filteredName = selectedTeamName.filter(item => item.label != data.label);
            // const index = usersList.findIndex(item => item.value === data.value)
            setSelectedTeam(team);
            setSelectedTeamName(filteredName);
            // if (index < 0) {
            //     setUsersList([...usersList, data])
            // }
        } else {
            setSelectedTeam([...selectedTeam, data.value])
            setSelectedTeamName([...selectedTeamName, data])
        }
    }, [selectedTeam]);

    const handleSelectAllProject = useCallback(() => {
        const projectSelection = projectList.map(item => item.value);
        setSelectedProjectName([...projectList])
        setSelectedProject(projectSelection);
    }, [projectList]);

    const handleSelectAllEmployee = useCallback(() => {
        const userSelection = allUser.map(item => item.value);
        setSelectedEmployeeName([...allUser])
        setSelectedEmployee(userSelection);
    }, [usersList, allUser]);

    const handleSelectAllTeam = useCallback(() => {
        const teamSelection = allTeams.map(item => item.value);
        setSelectedTeamName([...allTeams])
        setSelectedTeam(teamSelection);
    }, [teamsList, allTeams]);

    const handleClearEmployeeSelection = useCallback(() => {
        if (selectedEmployeeList.length > 0) {
            setUsersList([...usersList, ...selectedEmployeeList])
            setSelectedEmployeeList([])
        }
        setSelectedEmployee([]);
        setSelectedEmployeeName([]);
        // setShowEmployee(false)
        getAllRecords();
    }, [usersList, selectedEmployeeList]);

    const handleClearTeamSelection = useCallback(() => {
        if (selectedTeamsList.length > 0) {
            setTeamsList([...teamsList, ...selectedTeamsList])
            setSelectedTeamsList([])
        }
        setSelectedTeam([]);
        setSelectedTeamName([]);
        // setShowEmployee(false)
        getAllRecords();
    }, [teamsList, selectedTeamsList]);

    const handleClearProjectSelection = useCallback(() => {
        if (selectedProjectList.length > 0) {
            setProjectList([...projectList, ...selectedProjectList])
            setSelectedProjectList([])
        }
        setSelectedProject([]);
        setSelectedProjectName([]);
        getAllRecords();
    }, [selectedProjectList, projectList]);

    const handleEmployeeSearch = useCallback((e) => {
        setSearchEmployee(e.target.value)
        const employees = allUser.filter(item => item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        setUsersList(employees)
    }, [allUser]);

    const handleTeamSearch = useCallback((e) => {
        setSearchTeam(e.target.value)
        const teams = allTeams.filter(item => item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        setTeamsList(teams)
    }, [allTeams]);

    const handleProjectSearch = useCallback((e) => {
        setSearchProject(e.target.value)
        const projects = allProject.filter(item => item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        setProjectList(projects)
    }, [allProject])

    const handleClearEmployeeSearch = useCallback(() => {
        setSearchEmployee('');
        setUsersList(allUser)
        // getAllRecords();
    }, [allUser]);

    const handleClearTeamSearch = useCallback(() => {
        setSearchTeam('');
        setTeamsList(allTeams)
        // getAllRecords();
    }, [allTeams]);

    const handleClearProjectSearch = useCallback(() => {
        setSearchProject('');
        setProjectList(allProject)
        getAllRecords();
    }, [allProject]);

    const handleCloseEmployee = useCallback(() => {
        if (selectedEmployee.length > 1) {
            const newItems = [];
            selectedEmployee.forEach(item => {
                const index = usersList.findIndex(list => { return list.value === item });
                if (index >= 0) {
                    newItems.push(usersList[index])
                    usersList.splice(index, 1)
                }
            })
            setSelectedEmployeeList([...selectedEmployeeList, ...newItems])
        } else {
            setUsersList([...allUser])
            setSelectedEmployeeList([])
        }
    }, [selectedEmployee, usersList]);

    const handleCloseTeam = useCallback(() => {
        if (selectedTeam.length > 1) {
            const newItems = [];
            selectedTeam.forEach(item => {
                const index = teamsList.findIndex(list => { return list.value === item });
                if (index >= 0) {
                    newItems.push(teamsList[index])
                    teamsList.splice(index, 1)
                }
            })
            setSelectedTeamsList([...selectedEmployeeList, ...newItems])
        } else {
            setTeamsList([...allUser])
            setSelectedTeamsList([])
        }
    }, [selectedTeam, teamsList]);

    const handleCloseProject = useCallback(() => {
        const newItems = []
        selectedProject.forEach(item => {
            const index = projectList.findIndex(list => list.value === item);
            newItems.push(projectList[index])
            projectList.splice(index, 1)
        })
        setSelectedProjectList(newItems);
    }, [selectedProject, projectList]);

    return (
        // close-and-expand add this className for open and close the sidebar
        < div className='an-bords'>
            <div className="an-main-area" ref={analyticPage}>
                <div className="an-title">
                    <div className="row">
                        <div className="col-xl-2 d-flex align-items-center justify-content-between">
                            <h1 className='fz24 black fw-po-medium mb-0 po'>Analytics</h1>
                            <div className='d-lg-none d-block custom-btns-group mr-0 ml-auto '>

                                <button className={`${selectedDate === 'custom' ? 'active' : ''} ml-auto mr-0`} onClick={() => setShowCustomDate(true)}>
                                    <svg className='mr4' xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 18 18" fill="none">
                                        <path d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 1.5V4.5" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 1.5V4.5" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M2.25 7.5H15.75" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>{selectedDate === 'custom' ? fromDate === toDate ? moment(new Date(fromDate)).format("Do MMM YY") : `${moment(new Date(fromDate)).format("Do MMM  YY")} - ${moment(new Date(toDate)).format("Do MMM  YY")}` : 'Custom Date'}
                                </button>

                            </div>
                        </div>
                        <div className="col-xl-10 justify-content-md-end d-flex">
                            <div className='d-flex flex-row align-items-center anla-filter-area'>
                                {/* <div className="d-flex align-itmes-center download-btn-group">
                                    <div className="assigned-drop mr-16" ref={teamRef}>
                                        <Dropdown align="start" show={showTeam}>
                                            {/* Add active class when filtter is applyed
                                            <Dropdown.Toggle
                                                variant="short"
                                                className={` outline-drop ${selectedTeamName.length > 0 ? 'active' : ' '}`}
                                                id="dropdown-autoclose-true"
                                                onClick={() => setShowTeam(!showTeam)}
                                            >
                                                {selectedTeamName.length === 0 && 'All Teams'}
                                                {selectedTeamName.length > 1 ?
                                                    <>
                                                        <span>{selectedTeamName[0]?.label}</span> +
                                                        <span>{selectedTeamName.length - 1 || ''} </span>
                                                    </>
                                                    : <span>{selectedTeamName[0]?.label || ''}</span>}
                                                <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#969698"></path></svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className="d-flex justify-content-between align-itmes-center flex-nowrap all-clear">
                                                    <a className='fz12 black-dark-700 fw-po-medium pr-hover' onClick={handleSelectAllTeam}>Select all</a>
                                                    <a className='fz12 black-dark-700 fw-po-medium del-hover m-0' onClick={handleClearTeamSelection}>Clear all</a>
                                                </div>
                                                <div className='drop-search'>
                                                    <div className={`form-group position-relative search-input ${searchTeam ? 'input-active' : ''} `}>
                                                        <div className="serch-icon">
                                                            <img src={SearchIcon} className="" />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            className={`searchinput`}
                                                            value={searchTeam}
                                                            onChange={(e) => handleTeamSearch(e)}
                                                        />
                                                        {searchTeam &&
                                                            <div className="close-icon" onClick={handleClearTeamSearch}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                                                </svg>
                                                            </div>}
                                                    </div>
                                                </div>
                                                <div className='input-list'>
                                                    {selectedTeamsList.length > 0 && selectedTeamsList.map((data, index) => {
                                                        return (
                                                            <div className='dropdown-item' key={`team_${data.label}_${data.value}`}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        id={`team_${data.label}_${data.value}`}
                                                                        type="checkbox"
                                                                        name={data.label}
                                                                        onChange={() => handleChangeTeam(data)}
                                                                        checked={selectedTeam.includes(data.value)}
                                                                    />
                                                                    <label className="form-check-label cursor-pointer" htmlFor={`team_${data.label}_${data.value}`}>
                                                                        {data.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {teamsList.length > 0 ? teamsList.map((data, index) => {
                                                        return (
                                                            <div className='dropdown-item' key={`employee_${data.label}_${data.value}`}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        id={`employee_${data.label}_${data.value}`}
                                                                        type="checkbox"
                                                                        name={data.label}
                                                                        onChange={() => handleChangeTeam(data)}
                                                                        checked={selectedTeam.includes(data.value)}
                                                                    />
                                                                    <label className="form-check-label cursor-pointer" htmlFor={`employee_${data.label}_${data.value}`}>
                                                                        {data.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : selectedTeamsList.length === 0 && <p>No Records Found</p>}
                                                </div>
                                                <div className="aply-btn">
                                                    <button className='pr-btn d-block' onClick={() => {
                                                        setIsFilterApplied(true);
                                                        getAllRecords();
                                                        handleCloseTeam();
                                                        setShowTeam(false);
                                                    }}>Apply filter</button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div> */}
                                <div className="d-flex align-itmes-center download-btn-group">
                                    <div className="assigned-drop mr-16" ref={employeeRef}>
                                        <Dropdown align="start" show={showEmployee}>
                                            {/* Add active class when filtter is applyed */}
                                            <Dropdown.Toggle variant="short" className={` outline-drop ${selectedEmployeeName.length > 0 ? 'active' : ' '}`} id="dropdown-autoclose-true" onClick={() => setShowEmployee(!showEmployee)}>
                                                {selectedEmployeeName.length === 0 && 'All Users'}
                                                {selectedEmployeeName.length > 1 ?
                                                    <>
                                                        <span>{selectedEmployeeName[0]?.label}</span> +
                                                        <span>{selectedEmployeeName.length - 1 || ''} </span>
                                                    </>
                                                    : <span>{selectedEmployeeName[0]?.label || ''}</span>}
                                                <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#969698"></path></svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className="d-flex justify-content-between align-itmes-center flex-nowrap all-clear">
                                                    <a className='fz12 black-dark-700 fw-po-medium pr-hover' onClick={handleSelectAllEmployee}>Select all</a>
                                                    <a className='fz12 black-dark-700 fw-po-medium del-hover m-0' onClick={handleClearEmployeeSelection}>Clear all</a>
                                                </div>
                                                <div className='drop-search'>
                                                    <div className={`form-group position-relative search-input ${searchEmployee ? 'input-active' : ''} `}>
                                                        <div className="serch-icon">
                                                            <img src={SearchIcon} className="" />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            className={`searchinput`}
                                                            value={searchEmployee}
                                                            onChange={(e) => handleEmployeeSearch(e)}
                                                        />
                                                        {searchEmployee &&
                                                            <div className="close-icon" onClick={handleClearEmployeeSearch}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                                                </svg>
                                                            </div>}
                                                    </div>
                                                </div>
                                                <div className='input-list'>
                                                    {selectedEmployeeList.length > 0 && selectedEmployeeList.map((data, index) => {
                                                        return (
                                                            <div className='dropdown-item' key={`employee_${data.label}_${data.value}`}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        id={`employee_${data.label}_${data.value}`}
                                                                        type="checkbox"
                                                                        name={data.label}
                                                                        onChange={() => handleChangeEmployee(data)}
                                                                        checked={selectedEmployee.includes(data.value)}
                                                                    />
                                                                    <label className="form-check-label cursor-pointer" htmlFor={`employee_${data.label}_${data.value}`}>
                                                                        {data.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {usersList.length > 0 ? usersList.map((data, index) => {
                                                        return (
                                                            <div className='dropdown-item' key={`employee_${data.label}_${data.value}`}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        id={`employee_${data.label}_${data.value}`}
                                                                        type="checkbox"
                                                                        name={data.label}
                                                                        onChange={() => handleChangeEmployee(data)}
                                                                        checked={selectedEmployee.includes(data.value)}
                                                                    />
                                                                    <label className="form-check-label cursor-pointer" htmlFor={`employee_${data.label}_${data.value}`}>
                                                                        {data.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : selectedEmployeeList.length === 0 && <p>No Records Found</p>}
                                                </div>
                                                <div className="aply-btn">
                                                    <button className='pr-btn d-block' onClick={() => {
                                                        setIsFilterApplied(true);
                                                        getAllRecords();
                                                        handleCloseEmployee();
                                                        setShowEmployee(false);
                                                    }}>Apply filter</button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    {/* <div className="assigned-drop" ref={projectRef}>
                                        <Dropdown align="start" className={"short-btn"} show={showProject}>
                                            <Dropdown.Toggle variant="short" className={`outline ${selectedProjectName.length > 0 ? 'active' : ' '}`} id="dropdown-autoclose-true" onClick={() => { setShowProject(!showProject) }}>
                                                {selectedProjectName.length === 0 && 'All Projects'}
                                                {selectedProjectName.length > 1 ?
                                                    <>
                                                        <span>{selectedProjectName[0]?.label}</span> +
                                                        <span>{selectedProjectName.length - 1 || ''} </span>
                                                    </>
                                                    : <span>{selectedProjectName[0]?.label || ''}</span>}
                                                <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#969698"></path></svg>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className="d-flex justify-content-between align-itmes-center flex-nowrap all-clear">
                                                    <a className='fz12 black-dark-700 fw-po-medium pr-hover' onClick={handleSelectAllProject}>Select all</a>
                                                    <a className='fz12 black-dark-700 fw-po-medium del-hover m-0' onClick={handleClearProjectSelection}>Clear all</a>
                                                </div>
                                                <div className='drop-search'>
                                                    <div className={`form-group position-relative search-input ${searchProject ? 'input-active' : ''} `}>
                                                        <div className="serch-icon">
                                                            <img src={SearchIcon} className="" />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            className={`searchinput`}
                                                            value={searchProject}
                                                            onChange={(e) => handleProjectSearch(e)}
                                                        />
                                                        {searchProject &&
                                                            <div className="close-icon" onClick={handleClearProjectSearch}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                                                </svg>
                                                            </div>}
                                                    </div>
                                                </div>
                                                <div className='input-list'>
                                                    {selectedProjectList.length > 0 && selectedProjectList.map((data, index) => {
                                                        return (
                                                            <div className='dropdown-item' key={`employee_${data.label}_${data.value}`}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        id={`employee_${data.label}_${data.value}`}
                                                                        type="checkbox"
                                                                        name={data.label}
                                                                        onChange={() => handleChangeEmployee(data)}
                                                                        checked={selectedProject.includes(data.value)}
                                                                    />
                                                                    <label className="form-check-label cursor-pointer" htmlFor={`employee_${data.label}_${data.value}`}>
                                                                        {data.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {projectList.length > 0 ? projectList.map((data, index) => {
                                                        return (
                                                            <div className='dropdown-item' key={`project_${data.label}_${data.value}`}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        id={`project_${data.label}_${data.value}`}
                                                                        type="checkbox" name={data.label}
                                                                        onChange={() => handleChangeProject(data)}
                                                                        checked={selectedProject.includes(data.value)}
                                                                    />
                                                                    <label className="form-check-label cursor-pointer" htmlFor={`project_${data.label}_${data.value}`}>
                                                                        {data.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : selectedProjectList.length === 0 && <p>No Records Found</p>}
                                                </div>
                                                <div className="aply-btn">
                                                    <button className='pr-btn d-block' onClick={() => {
                                                        getAllRecords();
                                                        handleCloseProject();
                                                        setShowProject(false);
                                                    }}>Apply filter</button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                                    {/* <button className='download-btn' onClick={printDocument}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M14 9V11.6667C14 12.0203 13.8595 12.3594 13.6095 12.6095C13.3594 12.8595 13.0203 13 12.6667 13H3.33333C2.97971 13 2.64057 12.8595 2.39052 12.6095C2.14048 12.3594 2 12.0203 2 11.6667V9" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.66675 5.66663L8.00008 8.99996L11.3334 5.66663" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8 9V1" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>Download PDF</button> */}
                                </div>
                                <div className='custom-btns-group d-flex flex-row align-items-center'>
                                    {!isMobile && <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="button-tooltip" >
                                                <div className='info-msg'>
                                                    Select custom date
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`${selectedDate === 'custom' ? 'active' : ''} d-md-flex align-items-md-center d-none`} onClick={() => setShowCustomDate(true)}>
                                            <svg className='mr4' xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 18 18" fill="none">
                                                <path d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12 1.5V4.5" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6 1.5V4.5" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.25 7.5H15.75" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>{selectedDate === 'custom' ? fromDate === toDate ? moment(new Date(fromDate)).format("Do MMM YY") : `${moment(new Date(fromDate)).format("Do MMM  YY")} - ${moment(new Date(toDate)).format("Do MMM  YY")}` : 'Custom Date'}
                                        </button>
                                    </OverlayTrigger>}
                                    {isMobile && <button className={`${selectedDate === 'custom' ? 'active' : ''} d-md-flex align-items-md-center d-none`} onClick={() => setShowCustomDate(true)}>
                                        <svg className='mr4' xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 18 18" fill="none">
                                            <path d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12 1.5V4.5" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6 1.5V4.5" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.25 7.5H15.75" stroke={`${selectedDate === 'custom' ? '#171725' : "#171725"}`} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>{selectedDate === 'custom' ? fromDate === toDate ? moment(new Date(fromDate)).format("Do MMM YY") : `${moment(new Date(fromDate)).format("Do MMM  YY")} - ${moment(new Date(toDate)).format("Do MMM  YY")}` : 'Custom Date'}
                                    </button>}

                                    {selectedDate === 'today' ?
                                        <button className={`${selectedDate === 'today' ? 'active' : ''}`} onClick={handleCurrentDate}>
                                            {moment(Date.now()).format("Do MMM YY")}
                                        </button>
                                        :
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="button-tooltip" >
                                                    <div className='info-msg'>
                                                        {moment(Date.now()).format("Do MMM YY")}
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <button className={`${selectedDate === 'today' ? 'active' : ''}`} onClick={handleCurrentDate}>Today</button>
                                        </OverlayTrigger>}

                                    {selectedDate === 'yesterday' ?
                                        <button className={`${selectedDate === 'yesterday' ? 'active' : ''}`} onClick={handleYesterdayDate}>
                                            {moment(Date.now()).subtract(1, 'day').format("Do MMM YY")}
                                        </button>
                                        : <OverlayTrigger
                                            placement="bottom"

                                            overlay={
                                                <Tooltip id="button-tooltip" >
                                                    <div className='info-msg'>
                                                        {moment(Date.now()).subtract(1, 'day').format("Do MMM YY")}
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <button className={`${selectedDate === 'yesterday' ? 'active' : ''}`} onClick={handleYesterdayDate}>
                                                Yesterday
                                            </button>
                                        </OverlayTrigger>
                                    }
                                    {selectedDate === 'week' ?
                                        <button className={`${selectedDate === 'week' ? 'active' : ''}`} onClick={handleCurrentWeek}>
                                            {`${moment(Date.now()).startOf('week').format("Do MMM YY")} -
                                                    ${moment(Date.now()).endOf('week').format("Do MMM YY")}`}
                                        </button> :
                                        <OverlayTrigger
                                            placement="bottom"

                                            overlay={
                                                <Tooltip id="button-tooltip" >
                                                    <div className='info-msg'>
                                                        {`${moment(Date.now()).startOf('week').format("Do MMM YY")} -
                                                    ${moment(Date.now()).endOf('week').format("Do MMM YY")}`}
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <button className={`${selectedDate === 'week' ? 'active' : ''}`} onClick={handleCurrentWeek}>
                                                This Week
                                            </button>
                                        </OverlayTrigger>}
                                    {selectedDate === 'month' ? <button className={`${selectedDate === 'month' ? 'active' : ''}`} onClick={handleCurrentMonth}>
                                        {`${moment(Date.now()).startOf('month').format("Do MMM YY")} -
                                                    ${moment(Date.now()).endOf('month').format("Do MMM YY")}`}
                                    </button> :
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="button-tooltip" >
                                                    <div className='info-msg'>
                                                        {`${moment(Date.now()).startOf('month').format("Do MMM YY")} -
                                                    ${moment(Date.now()).endOf('month').format("Do MMM YY")}`}
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <button className={`${selectedDate === 'month' ? 'active' : ''}`} onClick={handleCurrentMonth}>
                                                This Month
                                            </button>
                                        </OverlayTrigger>}

                                    <button className={`${selectedDate === 'allTime' ? 'active' : ''}`} onClick={() => {
                                        setAllTimeData(true);
                                        setSelectedDate('allTime');
                                        setToggleState(!toggleState)
                                    }}
                                    >
                                        All Time
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='toPrint' className='pt-2 pb-2'>
                    {/* Leads count */}
                    <ResultBar eventsCount={eventsCount[0]} loading={eventCountsLoading} />
                    <div className="qualification-source-table">
                        <div className='row d-flex flex-fill'>
                            <div className="col-xl-6 d-flex flex-fill">
                                <PipelineAnalysisTable
                                    statusLeads={statusLeads}
                                    loading={statusLeadsLoading}
                                    totalLead={eventsCount[0]?.total_leads}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                            <div className="col-xl-6 d-flex flex-fill">
                                <ProjectAnalysisTable
                                    projectLeadsWithSource={projectLeadsWithSource}
                                    loading={projectLeadsWithSourceLoading}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Employee-Pipeline-Analysis">
                        <div className="row d-flex flex-fill">
                            <div className="col-12 d-flex flex-fill">
                                <EmployeePipelineTable
                                    assignedToLeads={assignedToLeads}
                                    loading={assignedToLeadsLoading}
                                    getAllEmployeeEventAnalysis={getAllEmployeeEventAnalysis}
                                    getAllAssignedToLeads={getAllAssignedToLeads}
                                    leadButtonActive={leadButtonActive}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pipeline-ineent-table">
                        <div className="row d-flex flex-fill">
                            <div className="col-xl-6 d-flex flex-fill">
                                <SourceAnalysisTable
                                    sourceLeads={sourceLeads}
                                    loading={sourceLeadsLoading}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                            <div className="col-xl-6">
                                <IntentAnalysisTable
                                    intentLeads={intentLeads}
                                    loading={intentLeadsLoading}
                                    totalLead={eventsCount[0]?.total_leads}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Employee-Pipeline-Analysis">
                        <div className="row d-flex flex-fill">
                            <div className="col-12 d-flex flex-fill">
                                <EmployeeInsight
                                    employeeInsights={employeeInsights}
                                    loading={employeeInsightsLoading}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="an-table-area" >
                        <div className="row d-flex flex-fill">
                            <div className="col-xl-8 d-flex flex-fill">
                                <BookingEOI
                                    bookingLeads={bookingLeads}
                                    loading={bookingLeadsLoading}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                            <div className="col-xl-4 d-flex flex-fill">
                                <SitevisiteTable
                                    projectLeadsWithStatus={projectLeadsWithStatus}
                                    loading={projectWithStatusLoading}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="an-table-area">
                        <div className="row d-flex flex-fill">
                            {/* <div className="col-3 d-flex flex-fill">
                            <AverageResponseTime />
                        </div> */}
                            <div className="col-xl-3 d-flex flex-fill">
                                <DeadLeadCard
                                    deadLead={deadLeads}
                                    loading={deadLeadsLoading}
                                    companyConfig={companyConfig}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    selectedDate={selectedDate}
                                />
                            </div>
                            <div className="col-xl-9 d-flex flex-fill">
                                {/* <ConversionAnalysis conversionCountAnalysis={conversionCountAnalysis} totalLead={eventsCount[0]?.total_leads} loading={conversionCountLoading} /> */}
                                {/* <FacebookInsights fbCampaign={fbCampaign} loading={fbCampaignLoading} /> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            {showCustomDate && <CustomDateModal
                show={showCustomDate}
                closeCustomDateModal={closeCustomDateModal}
                handleCustomDate={handleCustomDate}
                companyConfig={companyConfig}
            />}
        </div >
    )
}
export default Analytics;
