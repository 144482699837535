import React, { useState, useEffect, useContext } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AnalyticsSkeleton from './analytics-skeleton';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downoadkit.svg';
import { CSVLink } from 'react-csv';
import Consumer from '../../helpers/context';
import { useHistory } from 'react-router-dom';

const ProjectAnalysisTable = ({ projectLeadsWithSource, loading, companyConfig, fromDate, toDate, selectedDate }) => {

    const [total, setTotal] = useState({
        facebook: 0,
        google: 0,
        portal: 0,
        otherTotal: 0,
        completeTotal: 0
    })
    const [tempData, setTempData] = useState([]);

    const history = useHistory();
    useEffect(() => {
        let totalCount = {
            facebook: 0,
            google: 0,
            portal: 0,
            otherTotal: 0,
            completeTotal: 0
        };
        let itemToSkipped = ['project', 'facebook', 'google', 'magicbricks', 'housing', 'acres_99', 'landing_page']

        if (Array.isArray(projectLeadsWithSource) && projectLeadsWithSource.length > 0) {

            projectLeadsWithSource.map(lead => {
                let portal = +(lead.acres_99 || 0) + +(lead.magicbricks || 0) + +(lead.housing || 0)
                let otherTotal = 0;
                let completeTotal = 0;
                Object.keys(lead).forEach(item => {
                    if (item != 'project') {
                        completeTotal += +(lead[item] || 0)
                    }
                    if (!itemToSkipped.includes(item)) {
                        otherTotal += +(lead[item] || 0)
                    }
                })
                lead.otherTotal = otherTotal;
                lead.portal = portal
                lead.completeTotal = completeTotal
                return lead;
            })
            projectLeadsWithSource.sort((a, b) => parseFloat(b.completeTotal) - parseFloat(a.completeTotal))
            projectLeadsWithSource.forEach(lead => {
                totalCount.facebook += +(lead.facebook || 0)
                totalCount.portal += +(lead.portal || 0)
                totalCount.google += (+(lead.google || 0) + +(lead.landing_page || 0))
                totalCount.otherTotal += +(lead.otherTotal || 0)
                totalCount.completeTotal += +(lead.completeTotal || 0)
            })
        }

        setTotal(totalCount);
    }, [projectLeadsWithSource])

    const exportData = () => {
        let temp = projectLeadsWithSource.map((lead, index) => {
            return {
                "Projects": lead.project || '',
                "Facebook": lead.facebook || '',
                "Google Ads": lead.google || '',
                "Landing Page": lead.landing_page || '',
                "99 Acres": lead.acres_99 || '',
                "Magicbricks": lead.magicbricks || '',
                "Housing": lead.housing || '',
                'Chat': lead.chat || '',
                'Cold Calling': lead.cold_calling || '',
                'Common Floor': lead.common_floor || '',
                'Company Website': lead.company_website || '',
                'CSV': lead.csv || '',
                'Email Campaign': lead.email_campaign || '',
                'Event': lead.event || '',
                'Hoarding': lead.hoarding || '',
                'IVR': lead.iver || '',
                'Linkedin': lead.linkedin || '',
                'Makaan': lead.makaan || '',
                'Newspaper': lead.newspaper || '',
                'Other': lead.other || '',
                'Pamphlet': lead.pamphlet || '',
                'Radio': lead.radio || '',
                'Reference': lead.reference || '',
                'Sms Campaign': lead.sms_campaign || '',
                'Taboola': lead.taboola || '',
                'Walk-In': lead.walk_in || '',
                'Whatsapp': lead.whatsapp || '',
                "Total": lead.completeTotal || '',
            }
        })
        setTempData(temp);

    };

    const handleSendMessage = () => {
        let message = '';
        projectLeadsWithSource.forEach(item => {
            if (item.project) {
                let total = (+item.facebook || 0) + (+item.google || 0)
                message += `Project #${item.project}
                Total Leads Received = ${(item.facebook != null ? +item.facebook : 0) + (item.google != null ? +item.google : 0)}
                Facebook - Lead from This Source (${(total && (item.facebook && item.facebook != null)) ? ((+item.facebook / total) * 100).toFixed(2) : 0})
                Google - Lead from This Source (${(total && (item.google && item.google != null)) ? ((+item.google / total) * 100).toFixed(2) : 0})`
            }
        })
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`)
    }

    const handleAddFilter = (lead, source) => {
        let proj = {}
        if (lead.project) {
            proj = companyConfig.Project.find(p => p.label === lead.project);
        }
        const src = companyConfig.Source.filter(s => source.includes(s.label));
        const payload = []
        if (proj?.value) {
            payload.push({
                Project: [proj?.value]
            })
        }
        if (src) {
            payload.push({
                Source: src.map(s => s.value)
            })
        }

        if (selectedDate !== 'allTime') {
            payload.push({
                'Created Date': {
                    From: fromDate,
                    To: toDate
                }
            })
        }

        localStorage.setItem('filter', JSON.stringify(payload));
        const link = document.createElement("a");
        link.href = '/';
        link.target = '_blank'
        link.click();
        // history.push('/');
    }

    return (
        <div className="table-card project-anlatic d-flex flex-fill flex-column">
            <div className="table-header d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                    <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>Project Analysis</h2>
                    <div className="info-alert">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" >
                                    <div className='info-msg'>
                                        {/* <h4>Total Leads</h4> */}
                                        <p>Project wise breakup of leads added in platform for selected date range into multiple sources.</p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <Button className='bg-tr border-0' bsstyle="default">  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
                                <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                            </svg>
                            </Button>
                        </OverlayTrigger>
                    </div>
                    {/* <p className='fz12 dark-black-700 mb-0'>This Week, All Projects</p> */}
                </div>

                {/* <button className='flex items-center border rounded-lg px-3 py-2'>
                    <DownloadIcon className='mr-1 h-5 w-5' /> CSV
                </button> */}
                <div className='flex'>
                    <CSVLink
                        className={`flex items-center !text-black hover:!text-black hover:bg-[#F6F6F6] border rounded px-3 py-1.5 text-sm gap-x-1`}
                        data={tempData}
                        onClick={exportData}
                        filename={'project_analysis.csv'}>
                        CSV <DownloadIcon />
                    </CSVLink>
                    <a
                        onClick={() => {
                            handleSendMessage()
                            // window.open(`https://api.whatsapp.com/send?text=Here's address for project - ${details.project?.name},  ${details?.project?.google_address}`)
                        }}
                        target="_blank" className="align-self-center ml-3 whatsapp position-relative ">
                        <img src={`${process.env.PUBLIC_URL}/whatsapp.svg`} className='mx-auto d-table' />
                        <div className="whatsapp-hover">
                            WhatsApp
                        </div>
                    </a>

                </div>
            </div>

            {projectLeadsWithSource?.length > 0 && <div className="scroll-table">
                <table className="table table-striped table-bg">
                    <thead className='!z-0'>
                        <tr>
                            <th style={{ width: '210px' }}>PROJECTS</th>
                            <th style={{ width: '120px' }}>Facebook</th>
                            <th style={{ width: '120px' }}>PORTALS</th>
                            <th style={{ width: '156px' }}>Landing Page</th>
                            <th style={{ width: '90px' }}>Other</th>
                            <th style={{ width: '90px' }}>Total</th>
                        </tr>
                    </thead>
                    {!loading &&
                        <>
                            <tbody>

                                {projectLeadsWithSource.map((lead, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ width: '210px' }}> {lead.project || '-'} </td>
                                            <td style={{ width: '120px' }} className='cursor-pointer' onClick={() => handleAddFilter(lead, ['Facebook Ads'])}>{lead.facebook || '-'}</td>
                                            <td style={{ width: '120px' }}
                                                className={`${lead.portal ? 'cursor-pointer' : ''}`}
                                                onClick={() => {
                                                    let source = [];
                                                    if (lead?.acres_99) {
                                                        source.push('99 Acres')
                                                    }
                                                    if (lead?.magicbricks) {
                                                        source.push('Magicbricks')
                                                    }
                                                    if (lead?.housing) {
                                                        source.push('Housing')
                                                    }
                                                    handleAddFilter(lead, source)
                                                }}
                                            >
                                                {lead.portal ? <OverlayTrigger
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip id="button-tooltip" >
                                                            <div className='info-msg' style={{ width: '100px' }}>
                                                                <p>LEADS</p>
                                                                <p className="d-flex justify-content-between mt-2" >
                                                                    <span>99 Acres</span>
                                                                    <span>{lead.acres_99 || '-'}</span>
                                                                </p>
                                                                <p className="d-flex justify-content-between mt-2">
                                                                    <span>Magicbricks</span>
                                                                    <span>{lead.magicbricks || '-'}</span>
                                                                </p>
                                                                <p className="d-flex justify-content-between mt-2">
                                                                    <span>Housing</span>
                                                                    <span>{lead.housing || '-'}</span>
                                                                </p>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span >{lead.portal}</span>
                                                </OverlayTrigger> : '-'}
                                            </td>
                                            <td style={{ width: '156px' }} className={`${(lead.google || lead.landing_page) ? 'cursor-pointer' : ''}`} onClick={() => {
                                                let source = [];
                                                if (lead.google) {
                                                    source.push('Google Ads')
                                                }
                                                if (lead.landing_page) {
                                                    source.push('Landing Page')
                                                }
                                                handleAddFilter(lead, source)
                                            }
                                            }>
                                                {lead.google || lead.landing_page ? <OverlayTrigger
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip id="button-tooltip" >
                                                            <div className='info-msg' style={{ width: '100px' }}>
                                                                <p>LEADS</p>
                                                                <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Google Ads</span>
                                                                    <span>{lead.google || '-'}</span>
                                                                </p>
                                                                <p className="d-flex justify-content-between mt-2">
                                                                    <span>Landing Page</span>
                                                                    <span>{lead.landing_page || '-'}</span>
                                                                </p>

                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span >{((+lead.google || 0) + (+lead.landing_page || 0)) || '-'}</span>
                                                </OverlayTrigger> : '-'}
                                            </td>
                                            <td style={{ width: '90px' }}>

                                                {lead.otherTotal ? <OverlayTrigger
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip id="button-tooltip" >
                                                            <div className='info-msg' style={{ width: '100px' }}>
                                                                <p>LEADS</p>
                                                                {lead.chat && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Chat</span>
                                                                    <span>{lead.chat || '-'}</span>
                                                                </p>}
                                                                {lead.cold_calling && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Cold Calling</span>
                                                                    <span>{lead.cold_calling || '-'}</span>
                                                                </p>}
                                                                {lead.common_floor && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Common Floor</span>
                                                                    <span>{lead.common_floor || '-'}</span>
                                                                </p>}
                                                                {lead.company_website && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Company Website</span>
                                                                    <span>{lead.company_website || '-'}</span>
                                                                </p>}
                                                                {lead.csv && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>CSV</span>
                                                                    <span>{lead.csv || '-'}</span>
                                                                </p>}
                                                                {lead.email_campaign && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Email Campaign</span>
                                                                    <span>{lead.email_campaign || '-'}</span>
                                                                </p>}
                                                                {lead.event && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Event</span>
                                                                    <span>{lead.event || '-'}</span>
                                                                </p>}
                                                                {lead.hoarding && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Hoarding</span>
                                                                    <span>{lead.hoarding || '-'}</span>
                                                                </p>}
                                                                {lead.iver && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Iver</span>
                                                                    <span>{lead.iver || '-'}</span>
                                                                </p>}
                                                                {lead.linkedin && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Linkedin</span>
                                                                    <span>{lead.linkedin || '-'}</span>
                                                                </p>}
                                                                {lead.makaan && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Makaan</span>
                                                                    <span>{lead.makaan || '-'}</span>
                                                                </p>}
                                                                {lead.newspaper && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Newspaper</span>
                                                                    <span>{lead.newspaper || '-'}</span>
                                                                </p>}
                                                                {lead.other && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Other</span>
                                                                    <span>{lead.other || '-'}</span>
                                                                </p>}
                                                                {lead.pamphlet && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Pamphlet</span>
                                                                    <span>{lead.pamphlet || '-'}</span>
                                                                </p>}
                                                                {lead.radio && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Radio</span>
                                                                    <span>{lead.radio || '-'}</span>
                                                                </p>}
                                                                {lead.reference && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Reference</span>
                                                                    <span>{lead.reference || '-'}</span>
                                                                </p>}
                                                                {lead.sms_campaign && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>SMS Campaign</span>
                                                                    <span>{lead.sms_campaign || '-'}</span>
                                                                </p>}
                                                                {lead.taboola && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Taboola</span>
                                                                    <span>{lead.taboola || '-'}</span>
                                                                </p>}
                                                                {lead.walk_in && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Walk In</span>
                                                                    <span>{lead.walk_in || '-'}</span>
                                                                </p>}
                                                                {lead.whatsapp && <p className="d-flex justify-content-between mt-2" >
                                                                    <span>Whatsapp</span>
                                                                    <span>{lead.whatsapp || '-'}</span>
                                                                </p>}
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span >{lead.otherTotal || '-'}</span>
                                                </OverlayTrigger> : '-'}
                                            </td>
                                            <td style={{ width: '90px' }}>{lead.completeTotal || '-'}</td>
                                        </tr>)
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{ width: '210px' }}>Total</td>
                                    <td style={{ width: '120px' }}>{total.facebook}</td>
                                    <td style={{ width: '120px' }}>{total.portal}</td>
                                    <td style={{ width: '156px' }}>{total.google}</td>
                                    <td style={{ width: '90px' }}>{total.otherTotal}</td>
                                    <td style={{ width: '90px' }}>{total.completeTotal}</td>
                                </tr>
                            </tfoot>
                        </>
                    }
                    {loading &&
                        <tbody>
                            {Array(6).fill().map((data, index) => {
                                return (
                                    <AnalyticsSkeleton key={String(index)} />
                                )
                            })}
                        </tbody>}
                </table>
            </div>}
            {(!projectLeadsWithSource || projectLeadsWithSource?.totalLeads === 0) && <div className='record-notfound text-center'>
                <figure className='d-table mx-auto'>
                    <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                </figure>
                <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
            </div>}
            {/* {loading &&
                <div className="bg-white p-4">
                    {Array(7).fill().map((data, index) => {
                        return (
                            <AnalyticsSkeleton key={String(index)} />
                        )
                    })}
                </div>} */}
        </div>
    )
}

export default ProjectAnalysisTable