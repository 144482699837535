import React, { useContext, useEffect, useState } from 'react'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as PrintIcon } from '../../../assets/icons/printIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/editgray.svg';
import { ReactComponent as DownIcon } from '../../../assets/icons/Down.svg';
import { checkIsSomeItemAvailableInList, getUnitTotalPrice, toINRFormat } from '../../../helpers/helpers';
import BasicModal from '../../../components/Modals/BasicModal';
import { createDatewithTime } from '../../../helpers/date';
import moment from 'moment';
import TooltipText from '../../../components/Tooltip';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import CancelBooking from './cancelBooking';
import RejectBooking from './rejectBooking';
import pdfimg from '../../../assets/icons/pdfview.jpg';
import Consumer from '../../../helpers/context';
import BookingModal from '../../../components/Modals/BookingModal';

// const getStatusClass = (status) => {
//     switch (status) {
//         case 'Available':
//             return 'text-green bg-green100'
//         case 'Unavailable':
//             return 'text-black700 bg-black200'
//         case 'Blocked':
//             return 'text-primary bg-blueHighlight'
//         case 'Pending confirmation':
//             return 'text-primary bg-blueHighlight'
//         case 'Booked':
//             return 'text-white bg-primary'
//     }
// }

const getStatusClass = (status) => {
    switch (status) {
        case 'Available':
            return 'text-green bg-green100'
        case 'Unavailable':
            return 'text-black700 bg-black200'
        case 'Blocked':
            // return 'text-primary bg-blueHighlight'
            return 'text-yellow-dark !bg-yellow-dark/10'
        case 'Pending confirmation':
            return 'text-orange-dark bg-orange-dark/10'
        case 'Booked':
            return 'text-white bg-primary'
    }
}

const StatusName = {
    Blocked: 'Token',
    Unavailable: 'Hold'
}

const BookingStage = {
    eoi: 'Token',
    booking: 'Booking'
}

const BookindDetailsModal = ({ show,
    closeModal,
    handleViewMore,
    selectedBooking,
    editModal,
    allowedPermission,
    operation,
    ModalType,
    bookingStatus,
    getProjectUnitLists,
    selectedTab,
    getBlockWiseUnits
}) => {

    const [documents, setDocuments] = useState([]);
    const [selectdImg, setSelectdImg] = useState();
    const [showLightModal, setShowLightModal] = useState(false);
    const [showCancelBooking, setShowCancelBooking] = useState(false);
    const [showRejectBookingModal, setShowRejectBookingModal] = useState(false);
    const [showBookingModal, setShowBookingModal] = useState(false)

    const { userProfile } = useContext(Consumer);
    const { agreement, bookingUnit, channelPartner, project, coapplicant, bookingPricing, customer, developer, booking, transactions, customerDocuments } = selectedBooking

    console.log(selectedBooking, 'selected');

    useEffect(() => {
        let transactionDoc = []
        if (customerDocuments || transactions.length > 0) {
            transactions.forEach((transaction) => {
                if (transaction?.documents?.length > 0) {
                    transaction?.documents.forEach((item) => {
                        transactionDoc.push(item);
                    })
                }
            })
            if (customerDocuments && customerDocuments?.length > 0) {
                transactionDoc = [...customerDocuments, ...transactionDoc]
            }
            setDocuments([...transactionDoc])
        }
    }, [transactions, customerDocuments]);


    // console.log(bookingUnit, 'Booked', selectedBooking)
    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-4 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex gap-x-4 items-start '>
                        <div>
                            <h1 className='text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-0 inter'>Unit {bookingUnit?.name || '-'} | {bookingUnit?.block || '-'}</h1>
                            <p className='m-0 inter text-sm text-black700'>For {customer?.customer_name}</p>
                        </div>
                        <div className={`text-[10px]  font-medium inter py-2.5 !px-3 rounded ${getStatusClass(bookingUnit?.status)}`}>
                            {/* {bookingUnit?.status || '-'} */}
                            {bookingUnit?.status ?
                                bookingUnit?.status === 'Pending confirmation' ? `${bookingUnit?.status} (${BookingStage[bookingUnit?.booking_stage]})`
                                    : StatusName[bookingUnit?.status] ? `${StatusName[bookingUnit?.status]}` : bookingUnit?.status
                                : '-'}
                        </div>
                    </div>
                    <div className="flex gap-x-4">
                        {allowedPermission.includes('edit_inventory_unit') && <button
                            className='!border !border-grayLight hover:!border-primary bg-white rounded-lg hover:!bg-blue100 text-base font-medium inline-flex items-center gap-x-2 text-black700 hover:!text-primary w-[150px] h-11 justify-center pr-hover'
                            onClick={editModal}
                        >Edit unit <EditIcon /></button>}
                        <button onClick={closeModal}><CloseIcon /></button>
                    </div>
                </div>

                <div className='!px-6 !pt-5 pb-8 h-full overflow-y-auto overflow-x-hidden inter'>
                    {allowedPermission?.includes('view_unit_booking_detail') && <>
                        <p className='m-0 text-xl font-semibold text-textblack'>Booking Details</p>
                        <div className='grid grid-cols-3 gap-y-4 !mt-3 '>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Name</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{customer?.customer_name}</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Mobile</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{customer?.customer_mobile}</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Email</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{customer?.customer_email || '-'}</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Agreement Amount</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>₹ {toINRFormat(bookingPricing?.total_price || '-')}</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Booking Date</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{moment(booking?.schedule_date).format('Do MMM YYYY')} </p>
                            </div>
                            {/* <div>
                            <p className='m-0 text-sm text-black700 inter'>Salutation</p>
                            <p className='m-0 text-base font-semibold text-textblack inter'>₹ 6254000</p>
                        </div> */}
                        </div>
                        <div className='!mt-3 !mb-4 flex justify-end'>
                            <button className='text-sm text-textblack inline-flex gap-x-2 items-center pr-hover' onClick={handleViewMore}>View More <DownIcon className='-rotate-90' /></button>
                        </div>
                    </>}

                    <div className='!mt-5 !mb-3'>
                        <p className='m-0 text-xl font-semibold'>Unit Details</p>
                        <div className='grid grid-cols-3 gap-y-4 mt-3'>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Project</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{project?.name || '-'}</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Block</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.block ? bookingUnit?.block : '-'}</p>
                            </div>
                            {bookingUnit?.configuration.unit_type !== 'Plot' && <div>
                                <p className='m-0 text-sm text-black700 inter'>Carpet Area</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.configuration.carpet_area ? `${bookingUnit?.configuration.carpet_area} ${bookingUnit?.configuration.carpet_area_unit}` : '-'}</p>
                            </div>}
                            {bookingUnit?.configuration.unit_type === 'Plot' && <div>
                                <p className='m-0 text-sm text-black700 inter'>Plot Area</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.configuration.plot_area ? `${bookingUnit?.configuration.plot_area} ${bookingUnit?.configuration.plot_area_unit}` : '-'}</p>
                            </div>}
                            {bookingUnit?.configuration.unit_type !== 'Plot' && <div>
                                <p className='m-0 text-sm text-black700 inter'>Balcony Area</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.configuration.balcony_area ? `${bookingUnit?.configuration.balcony_area} ${bookingUnit?.configuration.balcony_area_unit}` : '-'}</p>
                            </div>}

                            {bookingUnit?.configuration.unit_type !== 'Plot' && <div>
                                <p className='m-0 text-sm text-black700 inter'>Super Built Up Area</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.configuration.super_area ? `${bookingUnit?.configuration.super_area} ${bookingUnit?.configuration.super_area_unit}` : '-'} </p>
                            </div>}
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Base Price</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'> {bookingUnit?.base_price ? `₹ ${bookingUnit?.base_price}` : '-'}</p>
                            </div>
                            {bookingUnit?.floor_no && <div>
                                <p className='m-0 text-sm text-black700 inter'>Floor Number</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.floor_no || '-'}</p>
                            </div>}
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Furnishing</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.furnishing || '-'}</p>
                            </div>
                            {bookingUnit?.plc_charge1 && <div>
                                <p className='m-0 text-sm text-black700 inter'>PLC Charges 1</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>₹ {bookingUnit?.plc_charge1}</p>
                            </div>}
                            {bookingUnit?.plc_charge2 && <div>
                                <p className='m-0 text-sm text-black700 inter'>PLC Charges 2</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>₹ {bookingUnit?.plc_charge2}</p>
                            </div>}
                            {bookingUnit?.plc_charge3 && <div>
                                <p className='m-0 text-sm text-black700 inter'>PLC Charges 3</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>₹ {bookingUnit?.plc_charge3}</p>
                            </div>}
                            {bookingUnit?.plc_charge4 && <div>
                                <p className='m-0 text-sm text-black700 inter'>PLC Charges 4</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>₹ {bookingUnit?.plc_charge4}</p>
                            </div>}
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Unit Number</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.name || '-'}</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>Facing</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.facing || '-'}</p>
                            </div>
                            <div>
                                <p className='m-0 text-sm text-black700 inter'>All Pricing Charges</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>₹ {toINRFormat(bookingPricing?.total_price || '-')}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='!mt-10'>
                        <h2 class="inter text-textblack text-xl !mb-4 font-semibold">Media</h2>
                        <div className='grid sm:grid-cols-3 gap-x-6'>
                            <div>
                                <div className='!border border-grayLight rounded h-[210px] group relative'>
                                    <img src="" alt="" />
                                    <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                                </div>
                                <p className='text-textblack text-base font-semibold inter !mt-1'>Site Plan</p>
                            </div>
                            <div>
                                <div className='!border border-grayLight rounded h-[210px] group relative'>
                                    <img src="" alt="" />
                                    <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                                </div>
                                <p className='text-textblack text-base font-semibold inter !mt-1'>Site Plan</p>
                            </div>
                            <div>
                                <div className='!border border-grayLight rounded h-[210px] group relative'>
                                    <img src="" alt="" />
                                    <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                                </div>
                                <p className='text-textblack text-base font-semibold inter !mt-1'>Site Plan</p>
                            </div>
                            <div>
                                <div className='!border border-grayLight rounded h-[210px] group relative'>
                                    <img src="" alt="" />
                                    <div className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-whtie !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'><DownloadIcon /></div>
                                </div>
                                <p className='text-textblack text-base font-semibold inter !mt-1'>Site Plan</p>
                            </div>

                        </div>
                    </div> */}

                    {documents?.length > 0 && <div className='!mt-10'>
                        <h2 className="inter text-textblack text-xl !mb-4 font-semibold">Media</h2>
                        <div className='grid sm:grid-cols-3 gap-x-6'>
                            {
                                documents.map((item, index) => (
                                    <div key={item.uuid}>
                                        <div
                                            className='!border border-grayLight rounded h-[210px] group  cursor-pointer relative flex items-center justify-center overflow-hidden'
                                        >
                                            {!['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) &&
                                                <img
                                                    onClick={() => {
                                                        if (!['video/mp4'].includes(item?.file_type)) {
                                                            setSelectdImg(index)
                                                            setShowLightModal(true)
                                                        } else {
                                                            window.open(item.url, '_blank')
                                                        }
                                                    }}
                                                    src={item.url} alt="" className='w-full' />}
                                            {['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) &&
                                                <img
                                                    onClick={() => {
                                                        window.open(item.url, '_blank')

                                                    }}
                                                    src={pdfimg} alt="" className='w-full' />}
                                            <div
                                                className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-grayLight !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'
                                                onClick={() => {
                                                    const link = document.createElement("a");
                                                    link.href = item.url;
                                                    link.download = `${item.name}`;
                                                    link.click();
                                                }}
                                            >
                                                <DownloadIcon />
                                            </div>
                                        </div>
                                        <TooltipText title={item?.name}>
                                            <p className='text-textblack text-base font-semibold inter !mt-1 w-100 truncate'>
                                                {item?.category}
                                            </p>
                                        </TooltipText>
                                    </div>
                                ))
                            }
                        </div>
                    </div>}
                </div>
            </div>

            <div className='!px-6 !py-4 flex sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                <div className='w-full'>
                    <p className='m-0 inter sm:text-xl text-lg text-textblack font-semibold inter'>Total Price : ₹ {toINRFormat(bookingPricing?.total_price || '-')}</p>
                    <p className='m-0 text-sm inter text-black700 !mt-1 inter'>For {bookingUnit?.name || '-'}</p>
                </div>
                <div className='flex w-full justify-end gap-x-3'>
                    {bookingUnit.status === 'Blocked' && <button
                        className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center hover:!bg-black100 !bg-white'
                        onClick={() => setShowCancelBooking(true)}
                    >Cancel Token</button>}
                    {(bookingUnit.status !== "Booked" && bookingUnit.booking_stage !== "booking") && <button className='pr-btn' onClick={() => setShowBookingModal(true)}> Book Unit</button>}
                    {(bookingUnit.status === "Booked" && bookingUnit.booking_stage === "booking") && <button className='pr-btn' onClick={() => setShowCancelBooking(true)}> Cancel Booking</button>}
                    {/* <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center hover:!bg-black100 !bg-white items-center inline-flex gap-x-2 justify-center'>
                        Print Details
                        <PrintIcon />
                    </button> */}
                </div>
                {showLightModal && <Lightbox
                    open={showLightModal}
                    close={() => setShowLightModal(false)}
                    slides={documents?.filter(item => (!['video/mp4', 'application/pdf'].includes(item?.file_type))).map(item => ({ src: item.url }))}
                    carousel={{
                        finite: true
                    }}
                    index={selectdImg}
                    plugins={[Thumbnails]}
                // video={{
                //     controls: true,
                //     playsInline: true,
                //     autoPlay: false,
                //     loop: false,
                //     muted: true,
                //     disablePictureInPicture: false,
                //     disableRemotePlayback: false,
                //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
                //     crossOrigin: '',
                //     preload: '',
                // }}
                />}

                {showCancelBooking &&
                    <CancelBooking
                        show={showCancelBooking}
                        closeModal={() => {
                            getProjectUnitLists();
                            getBlockWiseUnits(selectedTab?.uuid);
                            setShowCancelBooking(false);
                            closeModal();
                        }}
                        data={selectedBooking}
                        ModalType={bookingUnit.status === "Booked" ? 'Booking' : 'Token'}
                        updateData={() => { }}
                    />
                }
                {showRejectBookingModal &&
                    <RejectBooking
                        show={showRejectBookingModal}
                        closeModal={() => setShowRejectBookingModal(false)}
                        data={''}
                        ModalType={ModalType}
                        // handleSubmit={handleChangeApprovalStatus}
                        updateData={() => { }}
                    />
                }
                {
                    showBookingModal && <BookingModal
                        show={showBookingModal}
                        closeModal={() => setShowBookingModal(false)}
                        lead={selectedBooking.lead}
                        data={{}}
                        onSuccess={() => { }}
                    />
                }
            </div>
        </BasicModal >
    )
}

export default BookindDetailsModal