import request from "../../helpers/requests";
import { API_URLS, API_METHOD } from '../../config/apiUrl';

export async function isVaildUrlService(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.isValidInvite}/${uuid}`, null, null, null);
}

export async function companySignupService(uuid, payload) {
    return request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.companySignup}/${uuid}`, null, payload, null);
}

export async function getDeadReasons() {
    return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.getDeadReasons}`, null, null, null)
}

export async function userLogin(email, password) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.userLogin}`, null, { email, password }, null)
}

export async function verifyOtpForLogin(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.auth.verifyOtpForLogin}`, null, payload, null)
}

export async function sendOtpForLogin(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.auth.sendOtpForLogin}`, null, payload, null)
}

export async function verifyEmail(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.isValidInvite}/${uuid}`, null, null, null)
}

export async function verifyLinkForSignUp(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.verifyEmail}/${uuid}`, null, null, null)
}
export async function getStates() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.getStates}`, null, null, null);
}

export async function getCities() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.getCities}`, null, null, null);
}

export async function getCitiesFromState(state) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.getCitiesFromState}`, null, {
        state
    }, null);
}

export async function forgotPassword(email) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.forgotPassword}`, null, {
        email
    }, null)
}

export async function forgotPasswordChange(uuid, password) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.forgotPasswordChange}/${uuid}`, null, {
        password
    }, null)
}


export async function sendForgotPasswordOTPEmail(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.public.sendForgotPasswordOTPEmail}`, null, payload, null)
}
export async function forgotPasswordChangeOTP(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.public.forgotPasswordChangeOTP}`, null, payload, null)
}

export async function getAllCities(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.getAllCities}?searchText=${searchText}`, null, null, null)
}

export async function getAllLiveWorkshops() {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.workshops.getAllWorkshops}`)
}

export async function getWorkshopById(id) {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.workshops.getWorkshopById}/${id}`)
}

export async function getWorkshopMentorsByWorkshopId(uuid) {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.workshops.getWorkshopMentorsById}/${uuid}`)
}

export async function sentEmailVerifyOTP(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.sendOTP}/${uuid}`, null, payload, null)
}

export async function verifyEmailOTP(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.verifyOTP}/${uuid}`, null, payload, null)
}
export async function resendEmailOTP(uuid,) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.resendOTP}/${uuid}`, null, null, null)
}

export async function sigupPassword(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.setSignupPassword}/${uuid}`, null, payload, null)
}

export async function getEmail(uuid,) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.getSignupEmail}/${uuid}`, null, null, null)
}

export async function getAllGuides(searchText) {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.guide.get}`, searchText, null, null)
}

export async function getAllCourses(name) {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.courses.get}`, name, null, null)
}

export async function getCourseById(uuid) {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.courses.getCourseById}/${uuid}`, null, null, null)
}

export async function getCourseContent(uuid) {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.courses.getCourseContent}/${uuid}`, null, null, null)
}
export async function getAllFaqs(uuid) {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.courses.getAllFaqs}/${uuid}`, null, null, null)
}

export async function getCategory() {
    return await request(API_METHOD.GET, `${process.env.REACT_APP_DEV_API_URL}${API_URLS.courses.getLcActiveCategories}`, null, null, null)
}

// Subscription api's

export async function insertSubscriptionPaymentPlan(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.subscriptions.insert}`, null, payload, null)
}

export async function getAllSubscription() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.subscriptions.getAll}`, null, null, null)
}

export async function updatePaymentPlan(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.subscriptions.updatePaymentPlan}/${uuid}`, null, payload, null)
}
// LaunchPad api's
export async function getAllLaunchPad(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.launchPad.get}`, null, payload, null)
}

export async function eventRegisteration(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.launchPad.insertEventRegisteration}/${uuid}`, null, {}, null)
}
export async function getOTPForSeatReserve(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.launchPad.sendOTP}/${uuid}`, null, null, null)
}

export async function uploadFile(formData) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.public.uploadFiles}`, null, formData, null)
}


export async function getS3SignedURL(formData) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.public.getS3SignedURL}`, formData, null, null)
}


export async function updateKycDocument(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateKycDocuments}`, null, payload, null)
}
export async function verifyOTP(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.verifyOTP}`, null, payload, null)
}
export async function updateInterests(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateInterests}`, null, payload, null)
}
export async function getUserKycStatus() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getKycStatus}`, null, null, null)
}

export async function getReraStatus(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.getReraDocumentByState}`, null, payload, null)
}

export async function insertReraDocument(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertAndUpdateReraDocument}`, null, payload, null)
}

export async function getAllDevelopers() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllDevelopers}`, null, null, null)
}

export async function getAllReraDocuments() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllReraDocuments}`, null, null, null)
}

export async function getAllProjectsForCRM(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.getProjectsForCRM}`, null, payload, null)
}

export async function addToProjectList(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.addToList}/${uuid}`, null, null, null)
}

export async function getCRMProjectsDetail(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectsDetailForCRM}/${uuid}`, null, null, null)
}

export async function insertProjectRegisteration(uuid) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectRegisteration}/${uuid}`, null, null, null)
}

export async function downloadAllMedia(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.downloaAlldMedia}/${uuid}`, null, null, null)
}

// Create Broker Project

export async function insertProjectBasicDetail(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectBasicDetail}`, null, payload, null)
}

export async function getProjectBasicDetailsByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectBasicDetailsByUUID}/${uuid}`, null, null, null)
}

export async function updateBasicDetailsByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateBasicDetailsByUUID}/${uuid}`, null, payload, null)
}

export async function insertConfiguration(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertConfiguration}/${uuid}`, null, payload, null)
}

export async function getProjectConfigurations(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectConfigurations}/${uuid}`, null, null, null)
}

export async function updateConfigurationByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateConfigurationByUUID}/${uuid}`, null, payload, null)
}

export async function updateLocation(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateLocation}/${uuid}`, null, payload, null)
}

export async function updateAmenitiesAndBrancByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAmenitiesAndBrancByUUID}/${uuid}`, null, payload, null)
}

export async function updateDeveloperDetailsByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateDeveloperDetailsByUUID}/${uuid}`, null, payload, null)
}

export async function updateOfferesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateOfferesByUUID}/${uuid}`, null, payload, null)
}


export async function insertBankDetail(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertBankDetail}/${uuid}`, null, payload, null)
}

export async function getProjectBankDetails(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectBankDetails}/${uuid}`, null, null, null)
}

export async function updateBankDetailByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateBankDetailByUUID}/${uuid}`, null, payload, null)
}

export async function insertPaymentPlan(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPaymentPlan}/${uuid}`, null, payload, null)
}

export async function getProjectPaymentPlans(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectPaymentPlans}/${uuid}`, null, null, null)
}

export async function updatePaymentPlanByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePaymentPlanByUUID}/${uuid}`, null, payload, null)
}

export async function insertProjectMedia(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectMedia}/${uuid}`, null, payload, null)
}

export async function getProjectMedia(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectMedia}/${uuid}`, null, null, null)
}

export async function updateProjectMedia(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectMedia}/${uuid}`, null, payload, null)
}

export async function getAmenities() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAmenities}`, null, null, null)
}

export async function getAmenitiesWithImage() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAmenitiesWithImage}`, null, null, null)
}

export async function getUnitType(property) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUnitType}`, { property_type: property }, null, null)
}

export async function getPreferredTenant(property) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPreferredTenant}`, { property_type: property }, null, null)
}

export async function getMediaName() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getMediaName}`, null, null, null)
}

export async function getDocumentNames() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getDocumentNames}`, null, null, null)
}

export async function insertProjectAdditionalCost(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectAdditionalCost}/${uuid}`, null, payload, null)
}
export async function getProjectProjectAdditionalCosts(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectProjectAdditionalCosts}/${uuid}`, null, null, null)
}

export async function updateProjectAdditionalCostByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectAdditionalCostByUUID}/${uuid}`, null, payload, null)
}

export async function updateProjectAdditionalDetailsByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectAdditionalDetailsByUUID}/${uuid}`, null, payload, null)
}

export async function deleteBankDetailByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deleteBankDetailByUUID}/${uuid}`, null, null, null)
}

export async function deleteConfigurationByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deleteConfigurationByUUID}/${uuid}`, null, null, null)
}

export async function deleteMediaByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deleteMediaByUUID}/${uuid}`, null, null, null)
}

export async function deletePaymentPlanByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deletePaymentPlanByUUID}/${uuid}`, null, null, null)
}

export async function deleteAdditionalCostByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deleteAdditionalCostByUUID}/${uuid}`, null, null, null)
}

// export async function updateProjectAdditionalCostByUUID(uuid, payload) {
//     return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectAdditionalCostByUUID}/${uuid}`, null, payload, null)
// }

export async function getUsersForProjectLead() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUsersForProjectLead}`, null, null, null)
}

export async function getUsersForProjectRouting(lead, project) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUsersForProjectRouting}`, { project, lead }, null, null)
}

export async function addUserToProjectRouting(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.addUserToProjectRouting}`, null, payload, null)
}

export async function removeUserFromProjectRouting(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.removeUserFromProjectRouting}`, null, payload, null)
}

export async function insertProjectLatestUpdates(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectLatestUpdates}/${uuid} `, null, payload, null)
}

export async function updateProjectLatestUpdates(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectLatestUpdates}/${uuid}`, null, payload, null)
}

export async function getProjectLatestUpdates(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectLatestUpdates}/${uuid}`, null, null, null)
}

export async function deleteProjectLatestUpdates(uuid) {
    return await request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.company.deleteProjectLatestUpdates}/${uuid}`, null, null, null)
}



export async function setProjectLead(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.setProjectLead}/${uuid}`, null, payload, null)
}

export async function getProjectLead(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectLead}/${uuid}`, null, null, null)
}

export async function getProjectSpecifications(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectSpecifications}/${uuid}`, null, null, null)
}

export async function getPaymentPlanMilestonesByProjectId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPaymentPlanMilestonesByProjectId}/${uuid}`, null, null, null)
}

export async function getPaymentPlanMilestones(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPaymentPlanMilestones}/${uuid}`, null, null, null)
}


export async function getMilestoneDetail(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getMilestoneDetail}/${uuid}`, null, null, null)
}

export async function insertPaymentMilestone(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPaymentMilestone}`, null, payload, null)
}

export async function getPaymentMilestone(searchText = '') {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPaymentMilestone}`, { searchText }, null, null)
}

export async function updateSpecifications(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectSpecification}/${uuid}`, null, payload, null)
}


export async function deleteProjectSpecifications(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deleteProjectSpecifications}/${uuid}`, null, null, null)
}


export async function insertBrokerage(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertBrokerage}`, null, payload, null)
}

export async function updateBrokerageSlabByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateBrokerageSlabByUUID}/${uuid}`, null, payload, null)
}


export async function findAllBrokerageForProject(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.findAllBrokerageForProject}/${uuid}`, null, null, null)
}

export async function deleteBrokerageByUUID(uuid) {
    return await request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.company.deleteBrokerageByUUID}/${uuid}`, null, null, null)
}

export async function insertOffer(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertOffer}`, null, payload, null)
}

export async function updateOfferByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateOfferByUUID}/${uuid}`, null, payload, null)
}
export async function deleteOfferByUUID(uuid) {
    return await request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.company.deleteOfferByUUID}/${uuid}`, null, null, null)
}

export async function findOffersByprojectId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.findOffersByprojectId}/${uuid}`, null, null, null)
}

export async function getProjectBlocks(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectBlocks}/${uuid}`, null, null, null)
}

export async function getInventoryUnits(uuid, perPage, pageNumber, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.getInventoryUnits}/${uuid}`, { perPage, pageNumber }, payload, null)
}

//Princing module

export async function insertPricingEOI(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingEOI}/${uuid}`, null, payload, null)
}
export async function getPricingEOI(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingEOI}/${uuid}`, null, null, null)
}

export async function insertPricingBasePrice(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingBasePrice}/${uuid}`, null, payload, null)
}
export async function getPricingBasePrice(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingBasePrice}/${uuid}`, null, null, null)
}

export async function getMultiplePriceingBasePrice(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.getMultiplePriceingBasePrice}`, null, payload, null)
}

export async function insertPricingAmenityCharges(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingAmenityCharges}/${uuid}`, null, payload, null)
}
export async function getPricingAmenityCharges(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingAmenityCharges}/${uuid}`, null, null, null)
}

export async function insertPricingStampDuty(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingStampDuty}/${uuid}`, null, payload, null)
}
export async function getPricingStampDuty(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingStampDuty}/${uuid}`, null, null, null)
}

export async function insertPricingRegisterationCharges(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingRegisterationCharges}/${uuid}`, null, payload, null)
}
export async function getPricingRegisterationCharges(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingRegisterationCharges}/${uuid}`, null, null, null)
}
export async function insertPricingLegalCharges(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingLegalCharges}/${uuid}`, null, payload, null)
}
export async function getPricingLegalCharges(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingLegalCharges}/${uuid}`, null, null, null)
}

export async function insertPricingEDCCharges(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingEDCCharges}/${uuid}`, null, payload, null)
}
export async function getPricingEDCCharges(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingEDCCharges}/${uuid}`, null, null, null)
}
export async function insertPricingIDCCharges(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingIDCCharges}/${uuid}`, null, payload, null)
}
export async function getPricingIDCCharges(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingIDCCharges}/${uuid}`, null, null, null)
}
export async function insertPricingIFDCCharges(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingIFDCCharges}/${uuid}`, null, payload, null)
}
export async function getPricingIFDCCharges(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingIFDCCharges}/${uuid}`, null, null, null)
}
export async function insertPricingGSTCharges(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingGSTCharges}/${uuid}`, null, payload, null)
}
export async function getPricingGSTCharges(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingGSTCharges}/${uuid}`, null, null, null)
}
export async function insertPricingDelayedInterest(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingDelayedInterest}/${uuid}`, null, payload, null)
}
export async function getPricingDelayedInterest(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingDelayedInterest}/${uuid}`, null, null, null)
}

export async function changeAmenityPriceInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changeAmenityPriceInclusion}/${uuid}`, null, payload, null)
}
export async function changeStampDutyInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changeStampDutyInclusion}/${uuid}`, null, payload, null)
}
export async function changeRegisterationChargeInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changeRegisterationChargeInclusion}/${uuid}`, null, payload, null)
}
export async function changeEDCChargeInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changeEDCChargeInclusion}/${uuid}`, null, payload, null)
}
export async function changeIDCChargeInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changeIDCChargeInclusion}/${uuid}`, null, payload, null)
}
export async function changeIFDCChargeInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changeIFDCChargeInclusion}/${uuid}`, null, payload, null)
}

export async function changeGSTInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changeGSTInclusion}/${uuid}`, null, payload, null)
}
export async function changeLegalChargeInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changeLegalChargeInclusion}/${uuid}`, null, payload, null)
}
export async function changePLCChargeInclusion(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.changePLCChargeInclusion}/${uuid}`, null, payload, null)
}

export async function insertPricingBooking(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingBooking}/${uuid}`, null, payload, null)
}
export async function getPricingBooking(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingBooking}/${uuid}`, null, null, null)
}
export async function insertPricingPLCCharges(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertPricingPLCCharges}/${uuid}`, null, payload, null)
}
export async function getPricingPLCCharges(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingPLCCharges}/${uuid}`, null, null, null)
}
// Update Pricing modules
export async function updatePricingEOIByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingEOIByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingBasePriceByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingBasePriceByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingAmenityChargesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingAmenityChargesByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingEDCChargesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingEDCChargesByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingIDCChargesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingIDCChargesByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingIFDCChargesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingIFDCChargesByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingStampDutyByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingStampDutyByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingRegisterationChargesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingRegisterationChargesByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingLegalChargesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingLegalChargesByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingGSTChargesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingGSTChargesByUUID}/${uuid}`, null, payload, null)
}

export async function updatePricingDelayedInterestByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingDelayedInterestByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingBookingByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingBookingByUUID}/${uuid}`, null, payload, null)
}
export async function updatePricingPLCChargesByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePricingPLCChargesByUUID}/${uuid}`, null, payload, null)
}

// Get Unit configuration in pricing module
export async function getPricingEOIByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingEOIByUUID}/${uuid}`, null, null, null)
}
export async function getPricingBasePriceByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingBasePriceByUUID}/${uuid}`, null, null, null)
}
export async function getPricingAmenityChargesByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingAmenityChargesByUUID}/${uuid}`, null, null, null)
}

export async function getPricingEDCChargesByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingEDCChargesByUUID}/${uuid}`, null, null, null)
}
export async function getPricingIDCChargesByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingIDCChargesByUUID}/${uuid}`, null, null, null)
}
export async function getPricingIFDCChargesByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingIFDCChargesByUUID}/${uuid}`, null, null, null)
}
export async function getPricingBookingByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingBookingByUUID}/${uuid}`, null, null, null)
}
export async function getPricingPLCChargesByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPricingPLCChargesByUUID}/${uuid}`, null, null, null)
}

// Project team page

export async function getAllTeams() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllTeams}`, null, null, null)
}

export async function insertTeam(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertTeam}`, null, payload, null)
}

export async function updateTeam(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateTeam}/${uuid}`, null, payload, null)
}

export async function addToProject(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.addToProject}`, null, payload, null)
}

export async function getTeamUsers(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getTeamUsers}/${uuid}`, null, null, null)
}

export async function updateTeamLead(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateTeamLead}/${uuid}`, null, payload, null)
}


export async function getProjectTeams(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectTeams}/${uuid}`, null, null, null)
}

export async function deleteTeamFromProject(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.deleteTeamFromProject}`, null, payload, null)
}

export async function getTeamDetail(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getTeamDetail}/${uuid}`, null, null, null)
}


export async function deleteTeamUser(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.deleteTeamUser}/${uuid}`, null, payload, null)
}

//  Listing Project Api's
export async function insertBasicDetail(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertBasicDetail}`, null, payload, null)
}

export async function updateListingProjectDetailsByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateBasicDetailsByUUID}/${uuid}`, null, payload, null)
}

export async function insertProjectHighlights(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectHighlights}/${uuid}`, null, payload, null)
}

export async function getProjectHighlights(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectHighlights}/${uuid}`, null, null, null)
}

export async function updateProjectHighlights(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectHighlights}/${uuid}`, null, payload, null)
}


export async function insertProjectBuilderInfo(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectBuilderInfo}`, null, payload, null)
}

export async function getProjectBuilderInfo() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectBuilderInfo}`, null, null, null)
}

export async function updateProjectBuilderInfo(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectBuilderInfo}/${uuid}`, null, payload, null)
}


export async function insertProjectSEOInfo(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectSEOInfo}/${uuid}`, null, payload, null)
}

export async function getProjectSEOInfo(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectSEOInfo}/${uuid}`, null, null, null)
}

export async function updateProjectSEOInfo(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectSEOInfo}/${uuid}`, null, payload, null)
}

export async function insertListingProjectMedia(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectMedia}/${uuid}`, null, payload, null)
}


export async function setProjectFaq(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.setProjectFaq}/${uuid}`, null, payload, null)
}

export async function getProjectFaqs(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectFaqs}/${uuid}`, null, null, null)
}

export async function setProjectSeoInfo(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.setProjectSeoInfo}/${uuid}`, null, payload, null)
}

export async function insertProjectLocationAdvantages(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectLocationAdvantages}/${uuid}`, null, payload, null)
}

export async function getProjectLocationAdvantages(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectLocationAdvantages}/${uuid}`, null, null, null)
}

export async function updateProjectLocationAdvantages(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectLocationAdvantages}/${uuid}`, null, payload, null)
}


export async function insertProjectSpecifications(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectSpecifications}/${uuid}`, null, payload, null)
}

export async function insertProjectReviews(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectReviews}/${uuid}`, null, payload, null)
}

export async function getProjectReviews(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectReviews}/${uuid}`, null, null, null)
}

export async function updateProjectReviews(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectReviews}/${uuid}`, null, payload, null)
}

export async function getListingProjects(payload, searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getListingProjects}`, { searchText }, payload, null)
}

export async function updateProjectStatusByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectStatusByUUID}/${uuid}`, null, payload, null)
}
export async function saveListingFloorPlanDescription(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.saveListingFloorPlanDescription}/${uuid}`, null, payload, null)
}

export async function getProjectDetails(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectDetails}/${uuid}`, null, null, null)
}

export async function updateListingConfigurationByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateConfigurationByUUID}/${uuid}`, null, payload, null)
}

export async function insertProjectRera(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProjectRera}/${uuid}`, null, payload, null)
}

export async function getProjectRera(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectRera}/${uuid}`, null, null, null)
}

export async function updateProjectReraByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectReraByUUID}/${uuid}`, null, payload, null)
}

export async function deleteProjectRera(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deleteProjectRera}/${uuid}`, null, null, null)
}
export async function insertNotificationDevice(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertNotificationDevice}`, null, payload, null)
}