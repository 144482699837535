import React, { useContext, useState, useEffect } from 'react';
import SliderModal from '../SliderModal';
import { addFinalFollowupSchema, addFollowupSchema } from '../../../validators/leads.validator';
import { Formik } from 'formik';
import DateTimePicker from '../../InputGroup/DateTime';
import moment from 'moment';
import { getActiveFollowupByLeadId, insertFollowup } from '../../../services/private/followups.service';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { reassignLeads, searchLeadByName } from '../../../services/private/leads.service';
import InputSelect from '../../InputGroup/InputSelect';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusID, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../services/private/company.service';
import { validityUnit } from '../../../helpers/enums';
import InputText from '../../InputGroup/InputText';
import { ReactComponent as PlusPrimaryIcon } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import Consumer from '../../../helpers/context';
import { checkIsArrayEmpty, checkIsSomeItemAvailableInList } from '../../../helpers/helpers';
import { Oval } from 'react-loader-spinner';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const NotConnectedReasons = [
    {
        label: 'Not Responding',
        value: 'Not Responding'
    },
    {
        label: 'Ringing',
        value: 'Ringing'
    },
    {
        label: 'Busy',
        value: 'Busy'
    },
    {
        label: 'Not Reachable',
        value: 'Not Reachable'
    },
    {
        label: 'Switch Off',
        value: 'Switch Off'
    },
    {
        label: 'Call Back',
        value: 'Call Back'
    },
    {
        label: 'Call disconnected',
        value: 'Call disconnected'
    }
]

const InProgessReasons = [
    {
        label: 'Details shared',
        value: 'Details shared'
    },
    {
        label: 'Interested / Followup',
        value: 'Interested / Followup'
    }
]


export default function UpdateFollowupModal({
    show,
    setShow,
    leadId,
    onSuccess,
    leadName,
    intentOptions,
    selectedIntent,
    selectedStatus,
    isEdit,
    statusLabel,
    statusId,
    reassindedUserUuid
}) {

    const [followupData, setFollowupData] = useState({});

    // this is a hack update it when you have time :) @ankit
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([
        {
            access: 'edit',
            validity: 30,
            validity_unit: 'days'
        }
    ]);
    const [statusShareUsers, setStatusShareUsers] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);
    const [assignedTo, setAssignedTo] = useState();
    const [usersForAssigned, setUsersForAssigned] = useState([])

    const { userProfile, allowedPermissions } = useContext(Consumer);
    const ROLE_NAME = localStorage.getItem('role');

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.uuid
                    }
                });
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)
            }
        })
    }

    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(leadId);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                setAssignedTo(res.data.data?.user_uuid)
            } else {
                setAssignedTo(reassindedUserUuid)
            }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: statusId });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.ID))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers])

    useEffect(() => {
        if (leadId) {
            getActiveFollowup(leadId)
            setSelectedLeadId(leadId)
            if (userProfile?.allowed_modules?.includes('lead_sharing')) {
                getShareLeadAccess()
            }
        }
        if (statusId && userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccessUsingStatusId();
            getShareLeadAccessAssignedToByStatus();
        }
        getAllUsers();
    }, [leadId, statusId]);

    const getAllLeadsForInput = async (leadName) => {
        return searchLeadByName(leadName).then(res => {
            if (res.status === 200) {
                const leadOptionsReq = res.data.data.map((data) => {
                    return {
                        label: <>
                            <span>
                                {data.name}
                            </span>
                            <span>
                                {" (" + data.mobile + ") "}
                            </span>
                        </>,
                        value: data.uuid
                    }
                })
                return (leadOptionsReq)
            }
        })
    }

    const getActiveFollowup = (leadId) => {
        getActiveFollowupByLeadId(leadId).then(res => {
            if (res.status === 200) {
                if (res.data.data) {
                    setFollowupData(res.data.data)
                }
            }
        })
    }

    const handleReassignModalSubmit = () => {
        reassignLeads([selectedLeadId], assignedTo, '').then(res => {
            if (res.status === 200) {
                // toast.success("Lead Reassigned")
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const handleSubmitLeadShare = async () => {
        let payload = users.filter(item => item.user_id);
        // let count = 0
        // payload.forEach(item => {
        //     if (!item.user_id || !item.validity || !item.access || !item.validity_unit) {
        //         count++
        //     }
        // })
        // if (count > 0) {
        //     toast.error('Please fill all values');
        //     return;
        // }

        let res = {}

        payload = payload.map(item => ({
            ...item,
            validity: item.validity || '30',
            access: item.access || 'edit',
            validity_unit: item.validity_unit || 'days',
            expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString()
        }))
        res = await saveLeadAccess(leadId, { status_id: statusId, users: payload })
        if (res.data.status === 200) {
            // toast.success(res.data.message);
            onSuccess()
            setShow(false);
            setIsSubmitting(false);
        }
    }

    const updateFollowupCall = async (scheduleDate, remarks, leadIdSelected, intentId, info) => {
        setIsSubmitting(true);
        await insertFollowup(selectedLeadId, scheduleDate, remarks, intentId, info).then(res => {
            if (res.status === 200) {
                toast.success("Followup Updated")
                if (assignedTo) {
                    handleReassignModalSubmit();
                }

                if (!checkIsArrayEmpty(users)) {
                    handleSubmitLeadShare();
                } else {
                    onSuccess();
                    setShow(false);
                    setIsSubmitting(false);
                }
            }
        })
        setIsSubmitting(false);
    }

    const handleSumitForm = (values) => {
        if (followupData && followupData.schedule_date && !values.remarks) {
            toast.error('Please Enter Comments');
            return;
        }
        updateFollowupCall(values.scheduleDate, values.remarks, values.leadId, values.intent[0]?.value, values.info?.value)
    }

    const intentVal = useMemo(() => {
        if ((statusLabel === 'Not Connected' || statusLabel === 'In Progress') && !selectedStatus) {
            const intent = intentOptions.filter((intent) => intent.label === 'Cold Lead');
            return intent
        }
        if (statusLabel === 'Final Negotiation') {
            const intent = intentOptions.filter((intent) => intent.label === 'Hot Lead');
            return intent
        }

        if (selectedIntent) {
            const intent = intentOptions.filter((intent) => intent.value === selectedIntent);
            return intent
        }
    }, []);

    const isDisable = useMemo(() => {
        if (ROLE_NAME === 'Super Admin') {
            return false;
        }

        return !allowedPermissions.includes('edit_lead_sharing')

    }, [allowedPermissions, ROLE_NAME])

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                isEdit && followupData && followupData.schedule_date ? `Edit Followup ${statusLabel ? (statusLabel) : ''}` : `Followup (${statusLabel})`
            }
            body={
                <>
                    <Formik
                        initialValues={{
                            scheduleDate: isEdit && followupData.schedule_date ? followupData.schedule_date : '',
                            remarks: followupData.remarks && isEdit ? followupData.remarks : '',
                            leadId: followupData.customername || leadName ? { label: followupData.customername || leadName, value: followupData?.uuid || leadId } : '',
                            intent: intentVal || '',
                            info: followupData?.info ? statusLabel === 'Not Connected' ? NotConnectedReasons.find(r => r.value === followupData?.info) : InProgessReasons.find(r => r.value === followupData?.info) : '',
                        }}
                        onSubmit={(values, actions) => {
                            handleSumitForm(values)
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={(statusLabel === 'Not Connected' || statusLabel === 'In Progress') ? addFollowupSchema : addFinalFollowupSchema}
                        enableReinitialize
                    >
                        {
                            (props) => {
                                const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                return (
                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5 !pb-6'>
                                            <div className={`form-group ${errors['leadId'] ? 'invalid' : ''}  position-relative`}>
                                                <label className=''>Lead Name</label>
                                                {!leadId ?
                                                    <>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            loadOptions={getAllLeadsForInput}
                                                            defaultOptions
                                                            value={values['leadId']}
                                                            touched={touched['leadId']}
                                                            error={errors['leadId']}
                                                            onChange={(e) => {
                                                                const value = { target: { name: "leadId", value: e } }
                                                                handleChange(value)
                                                                setSelectedLeadId(e.value)
                                                                getActiveFollowup(e.value)
                                                            }}
                                                        // onInputChange={getAllLeadsForInput}
                                                        />
                                                        {errors['leadId'] && (
                                                            <>
                                                                <div className="input-feedback mt-8">{errors['leadId']}
                                                                </div>
                                                            </>

                                                        )}
                                                    </>
                                                    : <div className='text-capitalize fz16 fw-po-medium'>
                                                        {followupData.customername || leadName}
                                                    </div>
                                                }
                                            </div>
                                            {(followupData.schedule_date || !isEdit) && <DateTimePicker
                                                handleBlur={handleBlur}
                                                onValueChange={(e) => {
                                                    const changeVal = {
                                                        target: {
                                                            value: e,
                                                            name: "scheduleDate"
                                                        }
                                                    }
                                                    handleChange(changeVal)
                                                }}
                                                label="Select Followup Date & Time"
                                                name="scheduleDate"
                                                error={errors['scheduleDate']}
                                                value={values['scheduleDate'] ? values['scheduleDate'] : ''}
                                            />}
                                            {isEdit && !followupData.schedule_date && <Skeleton count={1} height="30px" width="100%" />}
                                            {(intentVal || !isEdit) && <InputSelect
                                                index={4}
                                                name="intent"
                                                maxMenuHeight="150px"
                                                styles={customStyles}
                                                error={errors['intent']}
                                                touched={touched['intent']}
                                                options={intentOptions}
                                                placeholder="Select Intent"
                                                value={values.intent}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "intent",
                                                            value: [e]
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                label='Intent'
                                            />}
                                            {isEdit && !intentVal && < Skeleton count={1} height="30px" width="100%" />}
                                            {(statusLabel === 'Not Connected' || statusLabel === 'In Progress') &&
                                                <InputSelect
                                                    index={4}
                                                    name="reason"
                                                    error={errors['info']}
                                                    maxMenuHeight="150px"
                                                    styles={customStyles}
                                                    touched={touched['info']}
                                                    options={statusLabel === 'Not Connected' ? NotConnectedReasons : InProgessReasons}
                                                    placeholder="Select Reason"
                                                    value={values.info}
                                                    onChange={(e) => {
                                                        const value = {
                                                            target: {
                                                                name: "info",
                                                                value: e
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                    label='Reason'
                                                />}
                                            {/* {isEdit && < Skeleton count={1} height="30px" width="100%" />} */}

                                            <div className='flex items-center justify-between !mt-4 !mb-2'>
                                                <label className='m-0'>Comments {followupData && followupData.schedule_date ? '' : '(optional)'}</label>
                                                <button
                                                    type='button'
                                                    className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                                    onClick={() => {
                                                        const value = {
                                                            target: {
                                                                name: "remarks",
                                                                value: ''
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                >Clear <CloseIcon className='h-3 w-3' /></button>
                                            </div>
                                            <textarea
                                                className="form-control mb-16"
                                                name="remarks"
                                                value={values['remarks']}

                                                placeholder="Enter any follow up Comments..."
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                defaultValue={followupData.remarks}
                                            >

                                            </textarea>
                                            {values && values.scheduleDate ?
                                                <div className="w-100 text-center px-24" >
                                                    <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-0'>We will remind you <span className='black fw-po-medium'>15 mins</span> prior to <span className='black fw-po-medium'>{moment(values.scheduleDate).format("hh:mm a, Do MMM YYYY")}</span></p>
                                                </div>
                                                : " "}

                                            {userProfile?.allowed_modules?.includes('lead_sharing') &&
                                                checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing']) &&
                                                <div className='pt-3'>
                                                    <div className='!mt-4'>
                                                        <InputSelect
                                                            label={'Assigned User'}
                                                            placeholder={'Select User'}
                                                            options={usersForAssigned}
                                                            styles={customStyles}
                                                            value={assignedTo ? usersForAssigned.find(user => user.value === assignedTo) : ''}
                                                            onChange={(value) => {
                                                                setAssignedTo(value.value);
                                                            }}
                                                            disable={isDisable}
                                                        />
                                                    </div>
                                                    <label className='!mt-4'>Shared With</label>
                                                    {
                                                        users.map((item, index) => (
                                                            <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                                                <div className='flex justify-between items-center'>
                                                                    <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                                                    {allowedPermissions.includes('edit_lead_sharing') && <button
                                                                        type='button'
                                                                        className='!text-primary text-base font-medium'
                                                                        onClick={() => {
                                                                            let newUsers = [...users];
                                                                            newUsers.splice(index, 1);
                                                                            if (users.length === 1) {
                                                                                setUsers([{}])
                                                                            } else {
                                                                                setUsers([...newUsers])
                                                                            }
                                                                        }}
                                                                    >Remove</button>}
                                                                </div>
                                                                <div>
                                                                    <InputSelect
                                                                        placeholder={'Select User'}
                                                                        options={allUsers}
                                                                        styles={customStyles}
                                                                        value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                                                        onChange={(value) => {
                                                                            let newUsers = [...users];
                                                                            newUsers[index].user_id = value.value;
                                                                            // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                                            // setAllUsers(filteredUser)
                                                                            setUsers([...newUsers]);
                                                                        }}
                                                                        disable={isDisable}
                                                                    />
                                                                    <div className='mt-3'>
                                                                        <label htmlFor={`edit_${index}`} className='mr-4'>
                                                                            <input
                                                                                type="radio"
                                                                                className='mr-2'
                                                                                name={`permission_${index}`}
                                                                                id={`edit_${index}`}
                                                                                value='edit'
                                                                                checked={item.access === 'edit'}
                                                                                onChange={() => {
                                                                                    let newUsers = [...users];
                                                                                    newUsers[index].access = 'edit'
                                                                                    setUsers(newUsers);
                                                                                }}
                                                                                disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                                            />
                                                                            <span>Edit Access</span>
                                                                        </label>
                                                                        <label htmlFor={`view_${index}`}>
                                                                            <input
                                                                                type="radio"
                                                                                className='mr-2'
                                                                                name={`permission_${index}`}
                                                                                id={`view_${index}`}
                                                                                value='view'
                                                                                checked={item.access === 'view'}
                                                                                onChange={() => {
                                                                                    let newUsers = [...users];
                                                                                    newUsers[index].access = 'view'
                                                                                    setUsers(newUsers);
                                                                                }}
                                                                                disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                                            />
                                                                            <span>View Only</span>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <label htmlFor="">Validity</label>
                                                                        <div className='grid grid-cols-2 gap-2'>
                                                                            <InputText
                                                                                placeholder={'e.g. 30'}
                                                                                value={item.validity}
                                                                                onChange={(e) => {
                                                                                    let newUsers = [...users];
                                                                                    newUsers[index].validity = e.target.value
                                                                                    setUsers(newUsers);
                                                                                }}
                                                                                disable={isDisable}
                                                                            />
                                                                            <InputSelect
                                                                                inputclass={'m-0'}
                                                                                styles={customStyles}
                                                                                placeholder={'Select Time'}
                                                                                value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                                                options={validityUnit}
                                                                                onChange={(value) => {
                                                                                    let newUsers = [...users];
                                                                                    newUsers[index].validity_unit = value.value;
                                                                                    setUsers(newUsers);
                                                                                }}
                                                                                disable={isDisable}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <button
                                                        type='button'
                                                        className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold disabled:bg-transparent disabled:hover:bg-transparent'
                                                        onClick={() => setUsers([...users, {
                                                            access: 'edit',
                                                            validity: 30,
                                                            validity_unit: 'days'
                                                        }])}
                                                        disabled={isDisable}
                                                    >
                                                        <PlusPrimaryIcon />Add another
                                                    </button>
                                                </div>}
                                        </div>
                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                                            <button
                                                type='button'
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                            >
                                                Cancel
                                            </button>
                                            {!isSubmitting && <button
                                                className="pr-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isEdit && followupData && followupData.schedule_date ? "Update Followup" : "Create Followup"}
                                            </button>}
                                            {isSubmitting && <button className="pr-btn flex items-center justify-center" type='button'>
                                                <Oval
                                                    height={24}
                                                    width={24}
                                                    color="#ffffff"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#ffffff"
                                                    strokeWidth={4}
                                                    strokeWidthSecondary={4}
                                                />
                                            </button>}
                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>

                </>
            }
        />
    )
}