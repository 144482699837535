import React, { useCallback, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downoadkit.svg';
import { CSVLink } from 'react-csv';

const IntentAnalysisTable = ({ intentLeads, totalLead, companyConfig, fromDate, toDate, selectedDate }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [formattedData, setFormattedData] = useState(null);
    const [tempData, setTempData] = useState([]);

    useEffect(() => {
        let formattedData = [
            { tooltip: 'New Lead', value: intentLeads ? parseInt(intentLeads.newLead || '0') : 0, color: '#0062FF' },
            { tooltip: 'Hot Lead', value: intentLeads ? parseInt(intentLeads.hotLead || '0') : 0, color: '#82C43C' },
            { tooltip: 'Warm Lead', value: intentLeads ? parseInt(intentLeads.warmLead || '0') : 0, color: '#FF974A' },
            { tooltip: 'Cold Lead', value: intentLeads ? parseInt(intentLeads.coldLead || '0') : 0, color: '#50B5FF' },
            { tooltip: 'Dead Lead', value: intentLeads ? parseInt(intentLeads.deadLead || '0') : 0, color: '#FC5A5A' }
        ]
        setFormattedData(formattedData)
    }, [intentLeads]);

    const makeTooltipContent = (entry) => {
        return <div>
            <p className='mb4'>LEADS</p>
            <p className="d-flex justify-content-between mb-0">
                <span>{entry.tooltip}</span>
                <span className='ml-4'>{`${entry.value} (${((entry.value * 100) / totalLead).toFixed(2)}%)`}</span>
            </p>
        </div>
    }

    const exportData = () => {
        let temp = [
            { Intent: 'New', Leads: formattedData[0]?.value || '' },
            { Intent: 'Hot', Leads: formattedData[1]?.value || '' },
            { Intent: 'Warm', Leads: formattedData[2]?.value || '' },
            { Intent: 'Cold', Leads: formattedData[3]?.value || '' },
            { Intent: 'Dump', Leads: formattedData[4]?.value || '' },
        ];

        setTempData(temp);

    };

    const handleAddFilter = (intent) => {
        let proj = {}
        let usersStatus = [];
        let intentLead = []
        if (intent === "Dead Lead") {
            usersStatus = companyConfig.Status.filter(s => s.label === intent);
        } else {
            intentLead = companyConfig.Intent.filter(s => s.label === intent);
        }

        const payload = []

        if (selectedDate !== 'allTime') {
            payload.push({
                'Created Date': {
                    From: fromDate,
                    To: toDate
                }
            })
        }

        if (usersStatus.length > 0) {
            payload.push({
                Status: usersStatus.map(s => s.value)
            })
        }
        if (intentLead.length > 0) {
            payload.push({
                Intent: intentLead.map(s => s.value)
            })
        }

        localStorage.setItem('filter', JSON.stringify(payload));
        const link = document.createElement("a");
        link.href = '/';
        link.target = '_blank'
        link.click();
        // history.push('/');
    }

    return (
        <div className="table-card Pipeline-Analysis-table d-flex flex-fill flex-column intent-chart">
            <div className="table-header d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                    <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>Intent Analysis</h2>
                    <div className="info-alert">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" >
                                    <div className='info-msg'>
                                        {/* <h4>Total Leads</h4> */}
                                        <p>Current Intent wise breakup of leads added in platform for selected date range.</p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <Button className='bg-tr border-0' bsstyle="default">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
                                    <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                    <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                </svg>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                {/* <button className='flex items-center border rounded-lg px-3 py-2'>
                    <DownloadIcon className='mr-1 h-5 w-5' /> CSV
                </button> */}
                <CSVLink
                    className={` flex items-center !text-black hover:!text-black hover:bg-[#F6F6F6] border rounded px-3 py-1.5 text-sm gap-x-1`}
                    data={tempData}
                    onClick={exportData}
                    filename={'intent_analysis.csv'}>
                    CSV <DownloadIcon />
                </CSVLink>
            </div>
            {formattedData && intentLeads && <div className='map_list justify-content-center'>
                <div className="map" style={{ height: '260px', width: '260px' }} data-tip="" data-for="chart">
                    <PieChart
                        data={formattedData}
                        center={[50, 50]}
                        lineWidth={60}
                        radius={50}
                        onMouseOver={(_, index) => {
                            setSelectedIndex(index)
                        }}
                        onMouseOut={() => {
                            setSelectedIndex(null);
                        }}
                    />
                    <ReactTooltip
                        id="chart"
                        backgroundColor={"#162C50"}
                        getContent={() =>
                            typeof selectedIndex === 'number' ? makeTooltipContent(formattedData[selectedIndex]) : null
                        }
                    />
                </div>
                <div>

                    <ul>
                        <div className="dead-count pl-0">
                            <p>Total Leads </p>
                            <h5>{totalLead}</h5>
                        </div>
                        <li className='cursor-pointer' onClick={() => handleAddFilter("New Lead")} ><span className='new'></span>New Lead ({formattedData[0]?.value})</li>
                        <li className='cursor-pointer' onClick={() => handleAddFilter("Hot Lead")}><span className='hot'></span>Hot Lead ({formattedData[1]?.value})</li>
                        <li className='cursor-pointer' onClick={() => handleAddFilter("Warm Lead")}><span className='warm'></span>Warm Lead ({formattedData[2]?.value})</li>
                        <li className='cursor-pointer' onClick={() => handleAddFilter("Cold Lead")}><span className='cold'></span>Cold Lead ({formattedData[3]?.value})</li>
                        <li className='cursor-pointer' onClick={() => handleAddFilter("Dead Lead")}><span className='dead-lead'></span>Dead Lead ({formattedData[4]?.value})</li>
                    </ul>
                </div>
            </div>
            }
            {(!intentLeads || intentLeads?.length === 0) && <div className='record-notfound text-center'>
                <figure className='d-table mx-auto'>
                    <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                </figure>
                <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
            </div>}
        </div>
    )
}

export default IntentAnalysisTable