import React, { useContext, useState, useEffect, useCallback } from 'react';
import SliderModal from './SliderModal';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import InputText from '../InputGroup/InputText';
import { addLeadSchemaMultiProject, addLeadSchemaSingleProject } from '../../validators/leads.validator';
import { Form, Formik } from 'formik';
import InputSelect from '../InputGroup/InputSelect';
import Consumer from "../../helpers/context";
import { checkLeadExistence, createNewLead } from '../../services/private/leads.service';
import { getAllProjects, getUsersForSettings } from '../../services/private/company.service';
import MobileInput from '../InputGroup/MobileInput';
import LeadDuplicateModal from './LeadDuplicateModal';
import AsyncSelect from 'react-select/async';
import { getCities } from '../../services/private/csv.service';
import { getStates } from '../../services/public/public.service';
import { Oval } from 'react-loader-spinner';

// working
export default function AddLeadModal({
    show,
    setShow,
    updateLeads,
    getAllLeadsCount,
    projectPermission,
    closeCreateLeadModal
}) {

    const [projectList, setProjectList] = useState();
    const [loading, setLoading] = useState(false);
    const [usersList, setUsersList] = useState([])
    const [showLeadDuplicationModal, setShowLeadDuplicateModal] = useState(false);
    const [duplicateData, setDuplicateData] = useState();
    const [states, setStates] = useState([]);
    const [existingLeadData, setExistingLeadData] = useState();
    const [mobileError, setMobileError] = useState('');
    const [errorMobileType, setErrorMobileType] = useState('');
    const [altMobileError, setAltMobileError] = useState('')

    let { companyConfig, allowedPermissions, userProfile } = useContext(Consumer);
    const userId = localStorage.getItem('ID');
    const ROLE_NAME = localStorage.getItem('role')

    const getAllProjectsByCompanyId = async () => {
        await getAllProjects().then((res) => {
            if (res.status === 200) {
                const projects = res.data.data.map((project) => ({ label: project.name, value: project.id }));
                setProjectList(projects);
            }
        })
    }

    const getUserData = async () => {
        await getUsersForSettings().then(res => {
            if (res.status === 200) {
                const users = res.data.data.map(user => { return { label: user.name, value: user.id } });
                setUsersList([...users]);
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    useEffect(() => {
        getAllProjectsByCompanyId();
        getUserData();
        // getAllStates();
    }, [])

    const closeLeadDuplicationModal = useCallback(() => {
        setShowLeadDuplicateModal(false);
    }, []);

    const addLeadData = async (payload) => {
        const sourceId = payload["Lead Source"].value;

        let projectId = []
        if (userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && !Array.isArray(payload["Project Name"])) {
            projectId = [payload["Project Name"].value]
        } else {
            projectId = payload["Project Name"].map((data) => {
                return data.value
            })
        }
        console.log(projectId, 'project id')
        setLoading(true);
        await createNewLead({
            name: payload.name,
            email: payload.email,
            mobile: payload.mobile,
            sourceId: sourceId,
            projectId: projectId,
            assignedTo: payload['Assigned User'].value,
            city: payload.city.value,
            // state: payload.state.value,
            alternateMobile: payload.altmobile
        }).then(res => {
            if (res.data.status === 200) {
                toast.success(`New Lead ${payload.name} is added`)
                setShow(false);
                getAllLeadsCount();
                updateLeads();
            }
            if (res.data.status === 304 && res.data.data) {
                setShowLeadDuplicateModal(true);
                setDuplicateData({ ...res.data.data, currentLeadName: payload.name })
            }
            if (res.data.status === 304 && !res.data.data) {
                toast.error(`${payload.name} cannot be created with ${payload.mobile} as it already exists in the platform`);
            }
        }).catch(e => {
            toast.error(e.response.data.message)
            console.error(e.response.data)
        })
        setLoading(false);
    }

    const handleCheckmobile = async (mobile, type) => {
        setErrorMobileType(type)
        const numericNumber = mobile.replace(/\D/g, '');
        const res = await checkLeadExistence({ mobile: numericNumber })
        if (res.data.status === 200) {
            if (res.data.data?.isDuplicate) {
                // setMobileError(res.data.data?.isDuplicate)
                if (res.data.data?.lead && Object.keys(res.data.data?.lead).length > 0) {
                    setExistingLeadData(res.data.data?.lead)
                    const msg = `A Lead ${res.data.data?.lead.name} ${res.data.data?.lead?.email ? 'with email' + res.data.data?.lead?.email : ''} already exists ${res.data.data?.lead?.assigned_to ? 'with owner ' + res.data.data?.lead?.assigned_to : ''}`
                    if (type === 'alt_mobile') {
                        setAltMobileError(msg);
                    } else {
                        setMobileError(msg);
                    }
                } else {
                    if (type === 'alt_mobile') {
                        setAltMobileError('Lead already exists in the platform');
                    } else {
                        setMobileError('Lead already exists in the platform');
                    }

                }
            }
        }
    }

    // const getAllStates = async () => {
    //     getStates().then((res) => {
    //         if (res.status === 200) {
    //             const states = res.data.data.map((data) => {
    //                 return {
    //                     label: data.name,
    //                     value: data.name,
    //                 };
    //             });
    //             setStates(states);
    //         }
    //     });
    // };

    const closeModal = useCallback(() => {
        setShow(false);
        closeCreateLeadModal();
    }, []);

    const handleLeadRoutes = useCallback((url) => {
        window.open(url, "_blank")
    }, []);

    const loadOptions = (city) => {
        return getCities(city)
            .then((res) => {
                if (res.data.status === 200) {

                    const cities = res.data?.data.map((city) => {
                        return {
                            label: city.name,
                            value: city.name
                        }
                    });
                    return cities;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <>
            <SliderModal
                show={show}
                setShow={closeModal}
                title={
                    "Create New Lead"
                }
                body={
                    <>
                        <Formik
                            initialValues={{
                                name: '',
                                mobile: '',
                                altmobile: '',
                                email: '',
                                'Lead Source': '',
                                'Project Name': '',
                                'Assigned User': ((ROLE_NAME === 'Super Admin' || allowedPermissions?.includes('filter_assigned_to')) || ROLE_NAME === 'ADMIN') ? usersList.find(item => item.value === parseInt(userId)) : '',
                                state: '',
                                city: ''
                            }}
                            validationSchema={userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID ? addLeadSchemaSingleProject : addLeadSchemaMultiProject}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize
                            onSubmit={async (values, actions) => {
                                if (mobileError) {
                                    return
                                }
                                await addLeadData(values)
                            }}
                        >
                            {
                                (props) => {
                                    const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                    return (

                                        <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                            <div className='h-full overflow-auto !px-5 !pb-10'>
                                                <InputText
                                                    value={values['name']}
                                                    onChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    index={0}
                                                    error={errors['name']}
                                                    touched={touched['name']}
                                                    type="text"
                                                    placeholder="e.g. Ravi Gupta"
                                                    name="name"
                                                    label="Lead Name"
                                                    required={true}
                                                />
                                                <MobileInput
                                                    index={1}
                                                    // countryCode="+91"
                                                    type='text'
                                                    value={values['mobile']}
                                                    onChange={(e) => {
                                                        const value = {
                                                            target: {
                                                                name: "mobile", value: e.phone
                                                            }
                                                        }
                                                        handleChange(value)
                                                        if (mobileError) {
                                                            setMobileError('');
                                                            setExistingLeadData()
                                                        }
                                                    }}
                                                    handleBlur={(e) => {
                                                        // const value = { target: { name: "mobile", value: `+91${e.target.value}` } }
                                                        // handleBlur(value)                                                        
                                                        handleCheckmobile(e.target.value, 'mobile')
                                                    }}
                                                    error={errors['mobile'] || mobileError}
                                                    touched={touched['mobile']}
                                                    placeholder="e.g. 9810139281"
                                                    name="mobile"
                                                    label="Mobile No."
                                                    required={true}
                                                    leadExisted={existingLeadData}
                                                />
                                                <MobileInput
                                                    index={1}
                                                    // countryCode="+91"
                                                    type='text'
                                                    value={values['altmobile']}
                                                    onChange={(e) => {
                                                        const value = {
                                                            target: {
                                                                name: "altmobile", value: e.phone
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                    handleBlur={(e) => {
                                                        // const value = { target: { name: "mobile", value: `+91${e.target.value}` } }
                                                        // handleBlur(value)                                                        
                                                        handleCheckmobile(e.target.value, 'alt_mobile')
                                                    }}
                                                    // error={errors['altmobile']}
                                                    error={errors['altmobile'] || altMobileError}
                                                    touched={touched['altmobile']}
                                                    placeholder="e.g. 9810139281"
                                                    name="altmobile"
                                                    label="Alt. Mobile No."
                                                    leadExisted={existingLeadData}

                                                />
                                                {/* <InputText
                                            value={values['mobile']}
                                            onChange={handleChange}
                                            handleBlur={handleBlur}
                                            index={1}
                                            error={errors['mobile']}
                                            touched={touched['mobile']}
                                            type="text"
                                            placeholder="e.g. 9810139281"
                                            name="mobile"
                                            label="Mobile No."

                                        /> */}
                                                <InputText
                                                    value={values['email']}
                                                    onChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    index={2}
                                                    error={errors['email']}
                                                    touched={touched['email']}
                                                    type="text"
                                                    placeholder="e.g. ravi@gmail.com"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    label="Email Id (Optional)"
                                                />

                                                <InputSelect
                                                    index={4}
                                                    label="Lead Source"
                                                    name="Lead Source"
                                                    maxMenuHeight="150px"
                                                    error={errors['Lead Source']}
                                                    touched={touched['Lead Source']}
                                                    value={values['Lead Source']}
                                                    options={[...companyConfig.Source]}
                                                    placeholder="Select Source (e.g. Facebook)"
                                                    onChange={(e) => {
                                                        const value = { target: { name: "Lead Source", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    onBlur={() => {
                                                        handleBlur({ target: { name: "Lead Source" } })
                                                    }}
                                                    required={true}
                                                />

                                                <InputSelect
                                                    index={4}
                                                    label="Project Name"
                                                    name="Project Name"
                                                    maxMenuHeight="150px"
                                                    menuPlacement="top"
                                                    error={errors['Project Name']}
                                                    touched={touched['Project Name']}
                                                    isMulti={userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID ? false : true}
                                                    value={values['Project Name']}
                                                    options={projectList}
                                                    placeholder="Select Project (e.g. Piramal)"
                                                    onChange={(e) => {
                                                        const value = { target: { name: "Project Name", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    onBlur={() => {
                                                        handleBlur({ target: { name: "Project Name" } })
                                                    }}
                                                    required={true}
                                                />
                                                {(projectList?.length === 0 && (projectPermission?.includes('write') || allowedPermissions.includes('create_project'))) && <Link to='/projects'>+Create new project</Link>}

                                                {((ROLE_NAME === 'Super Admin' || allowedPermissions?.includes('filter_assigned_to')) || ROLE_NAME === 'ADMIN') && <InputSelect
                                                    index={5}
                                                    label="Assigned User"
                                                    menuPlacement="top"
                                                    maxMenuHeight="150px"
                                                    name="Assigned User"
                                                    error={errors['Assigned User']}
                                                    touched={touched['Assigned User']}
                                                    isMulti={false}
                                                    value={values['Assigned User']}
                                                    options={usersList}
                                                    placeholder="Select Assigned User"
                                                    onChange={(e) => {
                                                        const value = { target: { name: "Assigned User", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    onBlur={() => {
                                                        handleBlur({ target: { name: "Assigned User" } })
                                                    }}
                                                />}
                                                <div className='form-group'>
                                                    <label htmlFor="">City</label>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        loadOptions={loadOptions}
                                                        defaultOptions
                                                        placeholder='Search and Select City'
                                                        // styles={customStyles}
                                                        onChange={(e) => {
                                                            const value = { target: { name: "city", value: e } }
                                                            handleChange(value)
                                                        }}
                                                        menuPosition='fixed'
                                                    />
                                                </div>
                                                {/* <InputSelect
                                                    index={4}
                                                    label="State"
                                                    name="State"
                                                    maxMenuHeight="150px"
                                                    menuPlacement="top"
                                                    error={errors['state']}
                                                    touched={touched['state']}
                                                    value={values['state']}
                                                    options={states}
                                                    placeholder="Select State"
                                                    onChange={(e) => {
                                                        const value = { target: { name: "state", value: e } }
                                                        handleChange(value)
                                                    }}
                                                    onBlur={() => {
                                                        handleBlur({ target: { name: "state" } })
                                                    }}

                                                /> */}
                                            </div>
                                            <div className="form-inline justify-content-between px-20 filter-ok-cancel">

                                                <button
                                                    className="cancel outline-btn"
                                                    onClick={() => {
                                                        setShow(false);
                                                        closeCreateLeadModal();
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                {!loading && <button
                                                    className="pr-btn"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    Create
                                                </button>}
                                                {loading && <button className="pr-btn flex items-center justify-center" type='button'>
                                                    <Oval
                                                        height={24}
                                                        width={24}
                                                        color="#ffffff"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#ffffff"
                                                        strokeWidth={4}
                                                        strokeWidthSecondary={4}
                                                    />
                                                </button>}
                                            </div>
                                        </form>
                                    )
                                }}
                        </Formik>

                    </>
                }
            />
            {showLeadDuplicationModal &&
                <LeadDuplicateModal
                    show={showLeadDuplicationModal}
                    closeModal={closeLeadDuplicationModal}
                    duplicateData={duplicateData}
                    handleLeadRoutes={handleLeadRoutes}
                    leadChangedFrom='new'
                />}
        </>
    )
}