import React, { Component } from 'react';

import AmplifySdk from '../../helpers/amplify';
import AppContext from '../../helpers/context';
import '../../App.css';
import { withRouter } from 'react-router-dom';
import './NavBar.css';
import { getSubscription, getUserProfile, removeDeviceByFirebaseToken } from '../../services/private/company.service';
import { Link } from 'react-router-dom';
import CountDown from './CountDown';
import SubscriptionExpiryModal from '../Modals/subscriptionExpiryModal';
import AreYouSureModal from '../Modals/AreYouSureModal';
import { Crisp } from "crisp-sdk-web";
import TooltipText from '../Tooltip';

const Amplify = AmplifySdk();

const getSubText = () => {
  if (localStorage.getItem('fb_steps') && +localStorage.getItem('fb_steps') < 4) {
    return `Do you wish to cancel Integration? Changes that you have made may not have been saved.`
  }
  if (localStorage.getItem('project_state') === 'create') {
    return "Do you want to cancel the project creation? Any unsaved changes you made may be lost."
  }
}
class NavAndSideBar extends Component {
  static context = AppContext
  state = {
    currentSelected: 'leads',
    name: "",
    subcriptionExpire: false,
    subcriptionExpiryDate: '',
    redirectURL: '',
    showRedirectConfirmationModal: false,
    currentRoute: this.props.location.pathname.split('/'),
    logo: '',
    companyName: ''
  }
  constructor(props) {
    super(props);
  }

  crispIntialize(username, companyName, useremail, mobile) {
    // console.log(process.env.CRISP_WEBSITE_ID, 'process.env.CRISP_WEBSITE_ID');
    Crisp.configure('1377a361-8d1b-4270-ba51-1298f3abf640', {
      autoload: false
    });
    Crisp.user.setEmail(useremail);
    Crisp.user.setNickname(username);
    Crisp.user.setCompany(companyName);
    Crisp.user.setPhone(mobile);
    Crisp.session.setData({
      company: companyName,
      mobile: mobile,
      plan: "free"
    });

    Crisp.load();

  }

  async getUserProfileData() {
    await getUserProfile().then(res => {
      if (res.status === 200) {
        localStorage.setItem('role', res.data.data.role_name)
        localStorage.setItem('user', res.data.data.uuid)
        localStorage.setItem('company', res.data.data.company_name)
        localStorage.setItem('ID', res.data.data.id)
        if (res.data.data.operational_cities) {
          localStorage.setItem('city', res.data.data.operational_cities)
        }
        if (res.data.data.interested_property_type) {
          localStorage.setItem('propertyType', res.data.data.interested_property_type)
        }
        this.setState({
          ...this.state,
          name: res.data.data.name,
          companyName: res.data.data.company.name,
          logo: res.data.data.company.logo,
          subcriptionExpire: res.data.data.subscription_expired
        })
        this.crispIntialize(res.data.data.name, res.data.data.company_name, res.data.data.email, res.data.data.mobile);
      }
    })
  }

  // async getSubscriptionExpiry() {
  //   await getSubscription().then(res => {
  //     if (res.status === 200) {
  //       this.setState({ subcriptionExpiryDate: res.data.data })
  //     }
  //   })
  // }

  componentDidMount() {
    this.getUserProfileData();
    // this.getSubscriptionExpiry();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.setState({ currentRoute: this.props.location.pathname.split('/') });
    }
  }

  closeSideBar() {
    const el = document.getElementById("main-container");
    el.classList.remove("open-sidebar");
    document.getElementById('hamburger_icon').style.display = 'block';
    document.getElementById('close_icon').style.display = 'none';
  };

  handleRemoveToken = async () => {
    let token = localStorage.getItem('fbToken');
    const res = await removeDeviceByFirebaseToken({ token });
    if (res.data.status === 200) {
      console.log(res.data.message)
      window.location.reload();
    }
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ authorized, setAuthorized }) => (
          <>
            {/* {this.state.subcriptionExpiryDate && <CountDown expiryDate={this.state.subcriptionExpiryDate} />} */}
            <header>
              <div className="container-fluid">
                <div className="row align-items-center">

                  <div className="col-lg-6 col-md-6 col-sm-6 col-8 pl-0 d-flex">
                    <div className='d-md-none mr-3'>
                      <button onClick={this.props.toggleSidebar} id='hamburger_icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                          <path d="M4.8125 21.4375H23.1875M4.8125 14.4375H23.1875M4.8125 7.4375H23.1875" stroke="black" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                      <button onClick={this.closeSideBar} id='close_icon' style={{ display: 'none' }}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z"
                            fill="#171725"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <a onClick={() => {
                      if ((this.state.currentRoute.includes('new-integration')
                        || this.state.currentRoute[this.state.currentRoute.length - 1].split('?').includes('new-integration')) &&
                        (localStorage.getItem('fb_steps') && +localStorage.getItem('fb_steps') < 4)) {
                        this.setState({
                          redirectURL: '/leads',
                          showRedirectConfirmationModal: true
                        })
                        return;
                      }
                      this.props.history.push('/');
                    }} className="header-logo position-relative d-flex flex-row align-items-center">
                      <img src={`${process.env.PUBLIC_URL}/assets/Logo.png`} />
                      <div className="beta">BETA</div>
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-4 d-flex align-items-center justify-content-end pr-0">
                    <div className="contact-us d-none">
                      <div className="dropdown">
                        <button className="border-0 px-0 mr-16" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M11.0005 21.9999C8.08318 21.9999 5.28548 20.8411 3.2227 18.7782C1.15984 16.7155 0.000976562 13.9177 0.000976562 11.0005C0.000976562 8.08326 1.15984 5.28548 3.2227 3.2227C5.2854 1.15984 8.08326 0.000976562 11.0005 0.000976562C13.9177 0.000976562 16.7154 1.15984 18.7782 3.2227C20.8411 5.2854 21.9999 8.08326 21.9999 11.0005C21.9966 13.9166 20.8366 16.7123 18.7746 18.7743C16.7125 20.8364 13.9167 21.9963 11.0007 21.9997L11.0005 21.9999ZM11.0005 1.69347C8.53203 1.69347 6.16465 2.67403 4.41928 4.4194C2.67391 6.16492 1.69335 8.53239 1.69335 11.0006C1.69335 13.4688 2.67391 15.8364 4.41928 17.5818C6.1648 19.3271 8.53227 20.3077 11.0005 20.3077C13.4686 20.3077 15.8363 19.3271 17.5816 17.5818C19.327 15.8362 20.3076 13.4688 20.3076 11.0006C20.3046 8.53309 19.3232 6.16752 17.5784 4.42254C15.8335 2.6778 13.4678 1.69634 11.0004 1.69339L11.0005 1.69347Z" fill="#92929D" />
                            <path d="M10.9964 14.385C10.7721 14.385 10.5568 14.2959 10.3981 14.1372C10.2395 13.9786 10.1503 13.7635 10.1503 13.5391C10.2601 12.4641 10.8091 11.4823 11.6672 10.8259C12.1928 10.3005 12.6885 9.80461 12.6885 9.30886C12.6885 8.7043 12.366 8.14581 11.8425 7.84351C11.3189 7.54124 10.6739 7.54124 10.1503 7.84351C9.62682 8.14578 9.30429 8.7043 9.30429 9.30886C9.30429 9.61113 9.14303 9.8904 8.88125 10.0415C8.61948 10.1926 8.29711 10.1926 8.03535 10.0415C7.77358 9.89037 7.6123 9.61112 7.6123 9.30886C7.6123 8.09993 8.2572 6.98273 9.30425 6.37821C10.3513 5.77369 11.6414 5.77365 12.6885 6.37821C13.7355 6.98277 14.3804 8.09993 14.3804 9.30886C14.2707 10.3838 13.7218 11.3657 12.8635 12.0221C12.3382 12.5475 11.8424 13.0432 11.8424 13.5391C11.8424 13.7634 11.7533 13.9786 11.5946 14.1372C11.436 14.2959 11.2207 14.385 10.9964 14.385H10.9964Z" fill="#92929D" />
                            <path d="M11.999 16.0766C11.999 16.5865 11.5855 17 11.0757 17C10.5658 17 10.1523 16.5865 10.1523 16.0766C10.1523 15.5668 10.5658 15.1533 11.0757 15.1533C11.5855 15.1533 11.999 15.5668 11.999 16.0766Z" fill="#92929D" />
                          </svg>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <button className='dropdown-item'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E1E1E1" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.2175 11.5646C12.4181 11.5646 13.3914 10.5913 13.3914 9.39071C13.3914 8.19009 12.4181 7.2168 11.2175 7.2168C10.0169 7.2168 9.04361 8.19009 9.04361 9.39071C9.04361 10.5913 10.0169 11.5646 11.2175 11.5646ZM11.2175 10.6951C10.4971 10.6951 9.91317 10.1111 9.91317 9.39071C9.91317 8.67034 10.4971 8.08636 11.2175 8.08636C11.9379 8.08636 12.5219 8.67034 12.5219 9.39071C12.5219 10.1111 11.9379 10.6951 11.2175 10.6951Z" fill="#696974" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.2175 11.9994C13.1385 11.9994 14.6958 13.5567 14.6958 15.4777V16.3472C14.6958 16.5874 14.5011 16.782 14.261 16.782H8.17404C7.93392 16.782 7.73926 16.5874 7.73926 16.3472V15.4777C7.73926 13.5567 9.29653 11.9994 11.2175 11.9994ZM13.8262 15.9124V15.4777C13.8262 14.0369 12.6583 12.869 11.2175 12.869C9.77678 12.869 8.60882 14.0369 8.60882 15.4777V15.9124H13.8262Z" fill="#696974" />
                            <path d="M16.0001 11.9994H16.8697C17.4494 11.9994 17.4494 11.1298 16.8697 11.1298H16.0001V10.2603C16.0001 9.68057 15.1306 9.68057 15.1306 10.2603V11.1298H14.261C13.6813 11.1298 13.6813 11.9994 14.261 11.9994H15.1306V12.869C15.1306 13.4487 16.0001 13.4487 16.0001 12.869V11.9994Z" fill="#696974" />
                          </svg>Contact Us</button>
                          <button className='dropdown-item'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E1E1E1" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.8234 6.29176C11.9379 6.2504 12.0633 6.2504 12.1778 6.29176L16.8735 7.98741C17.0802 8.06207 17.218 8.25831 17.218 8.47813V11.9999C17.218 14.8693 15.4812 16.799 12.1392 17.7202C12.0497 17.7449 11.9551 17.7453 11.8654 17.7212C8.52125 16.8238 6.7832 14.8927 6.7832 11.9999V8.47813C6.7832 8.25831 6.92098 8.06207 7.12774 7.98741L11.8234 6.29176ZM12.0006 7.3372L7.82668 8.84444V11.9999C7.82668 14.3459 9.17544 15.8816 11.9988 16.6757C14.8244 15.8607 16.1745 14.3239 16.1745 11.9999V8.84444L12.0006 7.3372ZM11.5048 9.35478C11.5048 9.06663 11.7384 8.83304 12.0265 8.83304C12.3147 8.83304 12.5482 9.06663 12.5482 9.35478V12.8048C12.5482 13.093 12.3147 13.3265 12.0265 13.3265C11.7384 13.3265 11.5048 13.093 11.5048 12.8048V9.35478ZM12.0006 15.1303C11.7124 15.1303 11.4789 14.8967 11.4789 14.6086C11.4789 14.3204 11.7124 14.0868 12.0006 14.0868C12.2887 14.0868 12.5223 14.3204 12.5223 14.6086C12.5223 14.8967 12.2887 15.1303 12.0006 15.1303Z" fill="#696974" />
                          </svg>Report an issue</button>
                          <button className='dropdown-item'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E1E1E1" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.0002 15.248L9.33958 16.637C8.9566 16.837 8.51046 16.5114 8.58399 16.0857L9.09129 13.1485L6.94166 11.0677C6.63017 10.7662 6.80095 10.2383 7.23002 10.1764L10.2037 9.74732L11.533 7.07274C11.7248 6.68669 12.2755 6.68669 12.4674 7.07274L13.7966 9.74732L16.7704 10.1764C17.1994 10.2383 17.3702 10.7662 17.0587 11.0677L14.9091 13.1485L15.4164 16.0857C15.4899 16.5114 15.0438 16.837 14.6608 16.637L12.0002 15.248ZM11.7587 14.1969C11.91 14.1179 12.0904 14.1179 12.2417 14.1969L14.2086 15.2238L13.8339 13.0543C13.8045 12.8842 13.8611 12.7106 13.9851 12.5906L15.5691 11.0574L13.3767 10.741C13.2071 10.7165 13.0603 10.6103 12.984 10.4568L12.0002 8.47725L11.0164 10.4568C10.9401 10.6103 10.7933 10.7165 10.6237 10.741L8.4313 11.0574L10.0152 12.5906C10.1392 12.7106 10.1959 12.8842 10.1665 13.0543L9.79178 15.2238L11.7587 14.1969Z" fill="#696974" />
                          </svg>Request a feature</button>
                        </div>
                      </div>
                    </div>
                    <div className='mr-2 border-r-2 pr-2'>
                      {
                        this.state.logo ?
                          <div className='m-0 h-[40px] w-[175px]'>
                            <img src={this.state.logo} className='w-[175px] object-cover h-[40px]' alt="company logo" />
                          </div>
                          :
                          <TooltipText title={'Click to add logo'}>
                            <Link to='/settings/company-details/company-info' className='text-black700 font-medium'>
                              {this.state.companyName}
                            </Link>
                          </TooltipText>
                      }
                    </div>
                    <div className="user_profile">
                      <div className="btn-group">
                        <button type="button " className="px-0 " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <div className="user-block">
                            <div className="profile-circle">{this.state.name.charAt(0).toUpperCase()}</div>
                            <div className="profile-name">
                              <h1 className='h-font fw-po-semi'>{this.state.name}</h1>
                            </div>
                            <div className="profile-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.0284 7C19.4351 7 19.6717 7.45968 19.4353 7.79062L12.9069 16.9304C12.7075 17.2096 12.2925 17.2096 12.0931 16.9304L5.56473 7.79062C5.32835 7.45969 5.56491 7 5.97159 7L19.0284 7Z" fill="#696974" />
                              </svg>
                            </div>
                          </div>

                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                          <button className="dropdown-item" type="button" onClick={() => {
                            if ((this.state.currentRoute.includes('new-integration') ||
                              this.state.currentRoute[this.state.currentRoute.length - 1].split('?').includes('new-integration')) &&
                              (localStorage.getItem('fb_steps') && +localStorage.getItem('fb_steps') < 4)) {
                              this.setState({
                                redirectURL: '/settings/my-profile/personal-info',
                                showRedirectConfirmationModal: true
                              })
                              return;
                            }
                            this.props.history.push("/settings/my-profile/personal-info")
                          }
                          }>Settings</button>
                          <button className="dropdown-item" type="button" onClick={(e) => {
                            if ((this.state.currentRoute.includes('new-integration') ||
                              this.state.currentRoute[this.state.currentRoute.length - 1].split('?').includes('new-integration')) &&
                              (localStorage.getItem('fb_steps') && +localStorage.getItem('fb_steps') < 4)) {
                              this.setState({
                                redirectURL: 'logout',
                                showRedirectConfirmationModal: true
                              })
                              return;
                            }
                            this.signOut(e, setAuthorized)
                          }

                          }>Log Out</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {this.state.subcriptionExpire && <SubscriptionExpiryModal show={this.state.subcriptionExpire} setShow={() => { }} />}
            {this.state.showRedirectConfirmationModal && <AreYouSureModal
              show={this.state.showRedirectConfirmationModal}
              subText={getSubText()}
              closeModal={() => this.setState({ showRedirectConfirmationModal: false })}
              confirmClose={() => {
                localStorage.removeItem('fb_steps');
                if (this.state.redirectURL === 'logout') {
                  this.signOut()
                } else {
                  this.props.history.push(this.state.redirectURL);
                }
                this.setState({ showRedirectConfirmationModal: false });
              }}
            />}
          </>
        )}
      </AppContext.Consumer>
    )
  }



  async signOut(evt, setAuthorized) {
    try {
      // as discussed with ankit
      this.handleRemoveToken();
      localStorage.clear();
      // this.props.history.push('/login');
      // window.location.reload();
      // if (evt !== undefined && evt.preventDefault) evt.preventDefault();
      // await Amplify.Auth.signOut();
      // setAuthorized(false)

    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  navigateToRoute(route) {

    this.setState({ currentSelected: route });
    this.props.history.push(route)

  }
}

export default withRouter(NavAndSideBar);
