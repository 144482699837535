
import React, { useCallback } from 'react'
import { Oval } from 'react-loader-spinner';
import InputSelect from '../InputGroup/InputSelect'
import Secure from '../Secure';


const Integration = ({
    updateStep,
    projectMapping,
    addMoreProject,
    projects,
    profileId,
    updateProfileId,
    updateExternalProject,
    updateProjectId,
    addNewPageIntegration,
    profileIdError,
    editProjectMappping,
    updateProjectMapping,
    projectErrorList,
    projectError,
    loading,
    intergrationName,
    profileLabel,
    projectLabel,
    isInValidProfile,
    subHeading,
    toggleUnmappedLead,
    unmappedLead
}) => {
    const handleOnBlur = useCallback((value, currentIndex) => {
        if (currentIndex > 0) {
            const fieldIndex = projectMapping.findIndex((proj, index) => (proj.externalProject === value && currentIndex != index));
            if (fieldIndex >= 0) console.log('match found')
        }
    }, []);

    return (
        <>
            <div className="body-modal">
                <div className="card mw-100 w-100">
                    <div className="card-header d-flex align-items-start">
                        <figure className='mb-0'>
                            <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                        </figure>
                        <div>
                            <h1 className='fz14 fw-po-semi mb4 black-dark-700'>Important Note :</h1>
                            <p className='fz14 mb-0 black-dark-700'>{subHeading}</p>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='profile-import'>
                            <div className={`form-group ${profileIdError ? 'invalid' : ''}`}>
                                <label className='black fz16 flex items-center' htmlFor="">{profileLabel} ({intergrationName}) <sup>*</sup>
                                    {intergrationName === 'Housing.com' && <button className='infobox '>
                                        <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        <div className="infocard housing_infocard">
                                            Get your profile id from your housing.com sales representative
                                        </div>
                                    </button>}
                                </label>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder={`Enter ${profileLabel}`}
                                    value={profileId}
                                    onChange={(e) => updateProfileId(e.target.value)}
                                    disabled={editProjectMappping}
                                />
                                {profileIdError &&
                                    <div className="input-feedback mt-8">
                                        Please enter <span className='text-lowercase'>{profileLabel}</span>
                                    </div>}
                                {isInValidProfile &&
                                    <div className="input-feedback mt-8">
                                        Please enter valid email id
                                    </div>
                                }
                            </div>
                        </div>
                        <h2 className='fz16 black fw-po-medium mt-24 flex items-center' >Project Mapping <sup>*</sup>
                            <button className='infobox'>
                                <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <div className="infocard housing_infocard">
                                    Map your <span className='text-lowercase'>{intergrationName}</span> projects with the propacity platform projects
                                </div>
                            </button>
                        </h2>
                        <div className="project-maping mt-0" id='project_mapping'>
                            <div className="row heading m-0">
                                <div className="col-md-5 col-sm-6 pl-0 flex items-center">
                                    {projectLabel} Projects  <button className='infobox ml-1'>
                                        <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                        <div className="infocard housing_infocard">
                                            Copy exact project name from <span className='text-lowercase'>{intergrationName}</span> dashboard & paste in each project box below
                                        </div>
                                    </button>
                                </div>
                                <div className="col-md-2 d-md-block d-none"></div>
                                <div className="col-md-5 col-sm-6 pl-1 flex items-center">
                                    Propacity Projects   <button className='infobox ml-1'>
                                        <svg width="20" height="20" className="" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#767676" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                        <div className="infocard housing_infocard">
                                            Projects you have created on the platform under settings                                    </div>
                                    </button>
                                </div>
                            </div>
                            {projectMapping.map((field, index) => {
                                return (
                                    <div className="row input-row" key={index}>
                                        <div className="col-md-5 col-sm-6">
                                            <label className='d-md-none'>{projectLabel} Projects</label>
                                            <input
                                                type="text"
                                                placeholder={`Enter project ${index + 1}`}
                                                className='form-control'
                                                value={field.externalProject}
                                                onChange={(e) => {
                                                    updateExternalProject(e.target.value, index)
                                                }}
                                                onBlur={(e) => handleOnBlur(e.target.value, index)}
                                            />
                                            {projectErrorList.length > 0 && projectErrorList[index] &&
                                                <div className="input-feedback mt-8">
                                                    {projectErrorList[index]['externalProject']}
                                                </div>}

                                            {projectError && index === 0 && <div className="input-feedback mt-8">
                                                Please enter project
                                            </div>}
                                        </div>
                                        <div className="col-md-2 d-md-block d-none"></div>
                                        <div className="col-md-5 col-sm-6 pr-1">
                                            <label htmlFor="" className='d-md-none'>Propacity Projects </label>
                                            <InputSelect
                                                index={4}
                                                name="project"
                                                id="project"
                                                value={projects.find(proj => proj.value === field.projectId)}
                                                options={projects}
                                                placeholder="Search & Select Project"
                                                onChange={(e) => {
                                                    updateProjectId(e.value, index)
                                                }}
                                            />
                                            {projectErrorList.length > 0 && projectErrorList[index] &&
                                                <div className="input-feedback mt-8">
                                                    {projectErrorList[index]['projectId']}
                                                </div>}
                                            {projectError && index === 0 && <div className="input-feedback mt-8">
                                                Please select project
                                            </div>}
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                        {!editProjectMappping && <button className='bg-tr add-btn ml-auto black-dark-600 pr-hover' onClick={addMoreProject}>+ Add New</button>}
                        {!editProjectMappping && <div className="card-footer-btm border-0 mt-2">
                            <div className="row d-flex align-items-center">
                                <div className="col-md-10">
                                    <h1 className='fz14 black fw-po-semibold mb4'>Unmapped Projects :</h1>
                                    <p className='fz14 dark-black-700 mb-0'>{`Allow Propacity platform to receive leads from the ${projectLabel} projects/listings not added on platform.`} </p>
                                </div>
                                <div className="col-md-2 d-flex justify-content-end">
                                    <button
                                        type="button"
                                        className={`btn btn-lg btn-toggle ${unmappedLead ? 'active' : ''} mr-2`}
                                        data-toggle="button"
                                        aria-pressed="true"
                                        autoComplete="off"
                                        value={unmappedLead}
                                        onClick={(e) => toggleUnmappedLead(e)}
                                    >
                                        <div className="handle"></div>
                                        <span className="on ">{unmappedLead ? 'YES' : 'NO'}</span>
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </div>

                </div>

                <div className="footer-modal d-flex align-items-center justify-content-center">
                    <button className='pr-btn fz16 py-8 px-40 d-flex align-items-center' onClick={() => {
                        if (editProjectMappping) {
                            updateProjectMapping();
                        } else {
                            addNewPageIntegration();
                        }
                    }}
                        disabled={loading}
                    >
                        <span className='mr-2 ml-0'>{editProjectMappping ? 'Update' : 'Next'}</span>
                        {loading && <Oval
                            height={20}
                            width={20}
                            color="#ffffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffffff"
                            strokeWidth={4}
                            strokeWidthSecondary={4}

                        />}
                    </button>
                </div>

            </div >
            <Secure />
        </>
    )
}

export default Integration;