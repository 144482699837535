import React, { useEffect, useState } from 'react'
import { getAllBrokerCompanyUsers, getAllBrokersSV } from '../../../services/private/borker.service';
import { useParams, useLocation, Link } from 'react-router-dom';
import { ReactComponent as PagebackIcon } from '../../../assets/icons/Pageback.svg';
import useDocumentTitle from '../../../custom hooks/useDocumentTitile';
import Tabs from '../../../components/HOC/Tabs';
import CompanyUsers from './CompanyUsers';
import { SVEvents } from '../../PropacityPlus/SVEvents';
import AddBrokerCompanyUser from './Modal/AddBrokerCompanyUser';

const tabList = [
    { name: 'Users' },
    { name: 'SV Events' }
]
const svTabList = [
    {
        name: 'Scheduled',
        value: 'scheduled'
    },
    {
        name: 'Completed',
        value: 'completed'
    },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
]

const BorkerCompanyUsers = () => {
    const [users, setUsers] = useState([]);
    const [name, setName] = useState('');
    const [selectedTab, setSelectedTab] = useState({
        name: 'Users'
    })
    const [tabRowCount, setTabRowCount] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showingResults, setShowingResults] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
    const [leadsCount, setLeadsCount] = useState('');
    const [maxPage, setMaxPage] = useState(1);
    const [events, setEvents] = useState([]);
    const [internalTabRowCount, setInternalTabRowCount] = useState({});
    const [selectedInternalTab, setSelectedInternalTab] = useState({
        name: 'Scheduled',
        value: 'scheduled'
    })
    const [searchText, setSearchText] = useState('');
    const [id, setId] = useState('');
    const [showAddBrokerCompanyUser, setShowAddBrokerCompanyUser] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})
    const [loading, setLoading] = useState(false);
    const { uuid } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);



    useDocumentTitle(name);

    useEffect(() => {
        if (searchParams.get('company')) {
            setName(searchParams.get('company'))
        }
        if (searchParams.get('id')) {
            setId(searchParams.get('id'))
        }
        if (searchParams.get('tab')) {
            setSelectedTab({ name: searchParams.get('tab') })
        }
        if (searchParams.get('internalTab')) {
            const tab = svTabList.find(i => i.name === searchParams.get('internalTab'))
            setSelectedInternalTab(tab);
        }

    }, [searchParams]);

    console.log(searchParams.get('tab'), 'searchParams.ge', selectedTab)
    const getEvents = async () => {
        setIsLoading(true)
        const payload = {
            "pageNumber": currentPage,
            "perPage": showingResults,
            "searchText": searchText,
            "tab": selectedInternalTab?.value,
            filters: {
                cpFirm: [
                    {
                        id: id,
                        uuid: uuid
                    }
                ]
            }
        }
        try {
            const res = await getAllBrokersSV(payload);
            if (res.data.status === 200) {
                setIsLoading(false)
                setEvents(res.data.data?.entries);
                const totalPages = Math.ceil(
                    Number(res.data.data?.stats[selectedInternalTab.value]) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(res.data.data?.stats[selectedInternalTab.value]);
                let stats = {}
                svTabList.forEach(i => {
                    stats = { ...stats, [i.name]: `${res.data.data?.stats[i.value]}` }
                })
                setInternalTabRowCount(stats)
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }

    const getUsers = async () => {
        setLoading(true);
        const res = await getAllBrokerCompanyUsers(uuid);
        if (res.data.status === 200) {
            setLoading(false);
            console.log(res.data.data, 'userss');
            setUsers(res.data.data);
        }
    }

    useEffect(() => {
        if (uuid) {
            getUsers();

        }

    }, [uuid])

    useEffect(() => {
        if (uuid && id) {
            getEvents();
        }
    }, [uuid, id, selectedInternalTab])

    return (

        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !border-b !border-grayLight">
                <div className="row d-flex d-flex flex-md-fill flex-wrap !mb-4">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="text-xl m-0 black fw-po-medium flex items-center ">
                            <Link to='/cp-manager' className='!mr-2'>
                                <PagebackIcon />
                            </Link>
                            {name}
                        </h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all subscriptions for plus app users</p> */}
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end items-center d-flex flex-md-fill">
                        <div className='!mr-2'>
                            <button className='border rounded-lg p-2' onClick={() => {
                                const payload = []
                                payload.push({
                                    'broker_company_id': { "id": id, "name": name, "uuid": uuid }
                                })
                                const filters =
                                    localStorage.setItem('filter', JSON.stringify(payload))
                                window.open('/')
                            }}> View Tagged Leads</button>
                        </div>
                        <div className="dropdown view-more ">

                            <button
                                className="border-0 !w-[50px] !h-7 rounded-lg hover:bg-black100 flex items-center justify-center"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                        fill="#B5B5BE"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                        fill="#B5B5BE"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        // clipRule="evenodd"
                                        d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                        fill="#B5B5BE"
                                    />
                                </svg>
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-right w-auto"
                                aria-labelledby="dropdownMenuButton"

                            >
                                <a
                                    className="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setShowAddBrokerCompanyUser(true)}
                                >
                                    Add User
                                </a>
                            </div>


                        </div>
                    </div>
                </div>
                <Tabs
                    tabList={tabList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    tabName={selectedTab}
                    tabRowCount={tabRowCount}
                />
            </div>
            <div className='!mt-4 !px-6'>
                {selectedTab.name === 'Users' && <CompanyUsers
                    users={users}
                    setShowAddBrokerCompanyUser={setShowAddBrokerCompanyUser}
                    setSelectedUser={setSelectedUser}
                    isLoading={loading}
                />}
                {selectedTab.name === 'SV Events' && <SVEvents
                    events={events}
                    isLoading={isLoading}
                    searchText={searchText}
                    selectedTab={selectedInternalTab}
                    setSelectedTab={setSelectedInternalTab}
                    currentPage={maxPage}
                    setCurrentPage={setCurrentPage}
                    setShowingResults={setShowingResults}
                    showingResults={showingResults}
                    leadsCount={leadsCount}
                    maxPage={maxPage}
                    tabRowCount={internalTabRowCount}
                    setTabRowCount={setInternalTabRowCount}
                    tabList={svTabList}
                />}
            </div>
            {/* <UserLimitExeedModal show={false} /> */}
            {showAddBrokerCompanyUser && <AddBrokerCompanyUser
                show={showAddBrokerCompanyUser}
                closeModal={() => {
                    setSelectedUser({})
                    setShowAddBrokerCompanyUser(false)
                }}
                getUsers={getUsers}
                selectedUser={selectedUser}
                uuid={uuid}
            />}
        </div>
    )
}

export default BorkerCompanyUsers