import React from 'react'
import { Modal } from 'react-bootstrap';

const UserLimitExeedModal = ({ show, closeModal, onSubmit }) => {
    return (
        <Modal
            className="enablereengagementmodal del-modal modal-background"
            show={show} onHide={closeModal}
            centered
            backdrop='static'
            keyboard={false}
        >
            <Modal.Body>
                <div className='csv-export-modal'>
                    <figure className="d-table mx-auto mb-16">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.7195 5.57031C29.7 2.49189 34.2004 2.49189 36.1809 5.57031L61.6039 45.088C63.7577 48.4358 61.354 52.8403 57.3733 52.8403H6.52714C2.54638 52.8403 0.142719 48.4358 2.29647 45.088L27.7195 5.57031Z" fill="#FC5A5A" />
                            <path d="M29.332 21.3327V34.666C29.332 36.1388 30.5259 37.3327 31.9987 37.3327C33.4715 37.3327 34.6654 36.1388 34.6654 34.666V21.3327C34.6654 19.8599 33.4715 18.666 31.9987 18.666C30.5259 18.666 29.332 19.8599 29.332 21.3327Z" fill="white" />
                            <path d="M34.6654 42.666C34.6654 44.1388 33.4715 45.3327 31.9987 45.3327C30.5259 45.3327 29.332 44.1388 29.332 42.666C29.332 41.1933 30.5259 39.9993 31.9987 39.9993C33.4715 39.9993 34.6654 41.1933 34.6654 42.666Z" fill="white" />
                        </svg>
                    </figure>
                    <h5 className='fz24 black ls1 po fw-po-medium'>User limit exausted</h5>
                    <p className='black-dark-800 fz16 po'>Maximum limit of users allowed in your subscription has been exausted. If you wish to add more users please contact support.</p>
                    <div className="justify-content-center d-flex modal-footer">
                        <button variant="" className='pr-btn text-white px-16' onClick={() => { }}>
                            Renew Subscription
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UserLimitExeedModal;