import React, { useState, useEffect, useRef } from "react";
import { Image, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import AddLeadModal from "../../../components/Modals/AddLeadModal";
import FilterModal from "../../../components/Modals/FilterModal/FilterModal";

import searchIcon from "../../../assets/icons/search.svg";
import searchClose from "../../../assets/icons/searchClose.svg";
import LeadsTopBarTab from "./LeadsTopBarTab";

import Dropdown from 'react-bootstrap/Dropdown'
import StatusDropDownDynamic from "../../../components/Status Dropdown/StatusDropdownDynamic";
import UpdateFollowupModal from "../../../components/Modals/FollowupModal/UpdateFollowupModal";
import UpdateSiteVistModal from "../../../components/Modals/SiteVistModal/UpdateSiteVisitModal";
import ConfirmDeletion from "../../../components/Modals/ConfirmDeletion";
import RefreshIcon from '../../../assets/icons/refresh.svg'
import { useCallback } from "react";
import ExportDashboardLeads from "./Modal/ExportDashboardLeads";

const OPTIONS = [
    { value: "Date", label: "Created Date" },
    { value: "Mobile No.", label: "Mobile No." },
    { value: "Name", label: "Name" },
    { value: "Project", label: "Project" },
    { value: "Source", label: "Source" },
    { value: "Status", label: "Status" },
    { value: "Intent", label: "Intent" }
]

const columnEnum = {
    'Assigned To': 'Assigned User',
    'dead_lead_date': 'Marked Date'
}

export default function LeadsTopBar({
    setShowCheck,
    handleLeadTypeChange,
    setText,
    text,
    col,
    setCol,
    sort,
    setSort,
    filters,
    setFilters,
    updateLeads,
    getAllLeadsCount,
    showMerge,
    setShowMerge,
    showDelete,
    setShowDelete,
    showReassign,
    setShowReassign,
    handleDeleteClicked,
    handleMergeClicked,
    handleReassignClicked,
    selectedTableValues,
    leadType,
    count,
    reAssignRole,
    refreshTime,
    setSelectedTableValues,
    setCurrentPage,
    sortToaster,
    filterToaster,
    projectPermission,
    showDeleteModal,
    setShowDeleteModal,
    newLeadModal,
    closeCreateLeadModal,
    propertyEnums,
    allowedPermissions,
    isRolesModule,
    setShowTeamReassign,
    showTeamReassign,
    handleTeamReassignClicked,
    setShowBulkLeadShare,
    showBulkLeadShare,
    handleBulkLeadsClicked,
    userProfile,
    selectedTableData,
    setSelectedTableData,
    showBulkLeadSourceChange,
    setShowBulkLeadSourceChange,
    handleLeadsSourceClicked,
    loadingCount,
    getExportLeadsData,
    fbFormList,
    leadCount,
    showExportLeadModal,
    setShowExportLeadModal,
    renderFilterList,
    deleteLeading,
    handleSiteVisitEventFilter,
    removeTabsFilter
}) {
    const [addLeadShow, setAddLeadShow] = useState(false);
    const [filterShow, setfilterShow] = useState(false);
    const [followupShow, setFollowupShow] = useState(false);
    const [siteVisitShow, setSiteVisitShow] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [btnAsc, setBtnAsc] = useState(sort === 'asc' ? true : false);
    const [btnDes, setBtnDes] = useState(sort === 'desc' ? true : false);
    const [filterCount, setFilterCount] = useState(0);
    const [sortingOptions, setSortingOptions] = useState(OPTIONS)
    const [showTripleDot, setShowTripleDot] = useState(true);
    const [showReAssignOption, setShowReAssignOption] = useState(false);
    const [showDeleteOption, setShowDeleteOption] = useState(false);
    const [searchText, setSearchText] = useState(text);

    const sortRef = useRef();
    const ROLE_NAME = localStorage.getItem('role');

    useEffect(() => {
        setSearchText(text)
    }, [text])
    useEffect(() => {
        if (!isRolesModule) {
            if (ROLE_NAME === 'ADMIN') {
                setShowReAssignOption(true);
                setShowDeleteOption(true);
            }

        } else {
            if (allowedPermissions?.includes('re_assign_leads_bulk')) {
                setShowReAssignOption(true);
            }
            if (allowedPermissions?.includes('delete_leads_bulk')) {
                setShowDeleteOption(true);
            }
        }
    }, [isRolesModule, allowedPermissions, ROLE_NAME]);

    const handleMouseClick = (e) => {
        if (sortRef.current && !sortRef.current.contains(e.target)) {
            setShowSort(false)
        }
    }

    useEffect(() => {
        if (showMerge || showDelete || showReassign || showBulkLeadShare || showTeamReassign || showTeamReassign || showBulkLeadSourceChange) {
            setShowTripleDot(false);
        } else {
            setShowTripleDot(true);
        }
    }, [showMerge, showDelete, showReassign, showTeamReassign, showBulkLeadShare, showTeamReassign, showBulkLeadSourceChange])

    useEffect(() => {
        let options = [...OPTIONS]
        if (leadType === 'dead_leads') {
            options = [...options, { value: "Marked Date", label: "Marked Date" }]
        }
        if ((reAssignRole?.includes('re_assign_leads') || ROLE_NAME === 'ADMIN')) {
            options = [...options, { value: "Assigned To", label: "Assigned User" }]
        }

        setSortingOptions(options);
    }, [reAssignRole, ROLE_NAME, OPTIONS, leadType]);

    useEffect(() => {
        setAddLeadShow(newLeadModal)
    }, [newLeadModal])

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseClick)
        return (() => document.removeEventListener('mousedown', handleMouseClick))
    }, [])

    const handleSearchChange = (e) => {
        setCurrentPage(1);
        setSearchText(e.target.value);
        let debounceTimer;
        const debounceSearch = () => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                setText(e.target.value);
            }, 1000); // Adjust the delay time (in milliseconds) as needed
        };
        debounceSearch();
        localStorage.setItem('currentPage', 1);
        localStorage.setItem('searchText', e.target.value);
    }

    const handleSortColChange = (e) => {
        const value = e.value === 'Marked Date' ? 'dead_lead_date' : e.value
        setCol(value)
        localStorage.setItem('col', value)
        localStorage.removeItem('currentPage')
        sortToaster();
        setCurrentPage(1);
        if (!sort) {
            localStorage.setItem('sort', 'asc')
            setSort("asc");
            setBtnAsc(true);
        }
    }

    const setCancelClicked = () => {
        setShowReassign(false)
        setShowDelete(false)
        setShowMerge(false)
        setShowCheck(false)
        setShowTeamReassign(false)
        setShowBulkLeadShare(false)
        setSelectedTableValues([]);
        setSelectedTableData([]);
        setShowBulkLeadSourceChange(false);
    }

    const mergeClick = () => {
        setShowMerge(true);
        setShowCheck(true);
    }
    const deleteClick = () => {
        setShowDelete(true);
        setShowCheck(true);
    }

    const reAssignClick = () => {
        setShowReassign(true);
        setShowCheck(true);
    }
    const reAssignTeamClick = () => {
        setShowTeamReassign(true);
        setShowCheck(true);
    }

    const handleBulkLeadShare = () => {
        setShowBulkLeadShare(true);
        setShowCheck(true)
    }

    const handleBulkSourceChange = () => {
        setShowBulkLeadSourceChange(true)
        setShowCheck(true)
    }

    const handleSortChange = e => {
        if (col) {
            if (e == "asc") {
                setBtnAsc(true);
                setBtnDes(false);
            } else {
                setBtnAsc(false);
                setBtnDes(true);
            }
            localStorage.setItem('sort', e);
            setSort(e);
            sortToaster();
            setCurrentPage(1)
        }
    }
    const handelClearSearch = () => {
        setText("");
        localStorage.removeItem('currentPage');
        localStorage.removeItem('searchText');
        // updateLeads();
    }

    const handleReassign = () => {
        if (selectedTableValues.length > 0) {
            handleReassignClicked()
        } else {
            toast.error(" Please select leads to reassign.");
        }
    }

    const handleTeamReassign = () => {
        if (selectedTableValues.length > 0) {
            handleTeamReassignClicked()
        } else {
            toast.error(" Please select leads to reassign.");
        }
    }

    const handleBulkLead = () => {
        if (selectedTableValues.length > 0) {
            handleBulkLeadsClicked()
        } else {
            toast.error(" Please select leads to reassign.");
        }
    }

    const handleBulkLeadSourceChange = () => {
        if (selectedTableValues.length > 0) {
            handleLeadsSourceClicked()
        } else {
            toast.error(" Please select leads to change source.");
        }
    }
    const handleDelete = () => {
        if (selectedTableValues.length > 0) {
            setShowDeleteModal(true)
        } else {
            toast.error(" Please select leads for deletion.");
        }
    }

    return (
        <>
            {addLeadShow && <AddLeadModal
                show={addLeadShow}
                setShow={setAddLeadShow}
                updateLeads={updateLeads}
                getAllLeadsCount={getAllLeadsCount}
                projectPermission={projectPermission}
                closeCreateLeadModal={closeCreateLeadModal}
            />}
            {filterShow && <FilterModal
                show={filterShow}
                setShow={setfilterShow}
                handleFilter={setFilters}
                setFilterCount={setFilterCount}
                filtersData={filters}
                leadType={leadType}
                reAssignRole={reAssignRole}
                setCurrentPage={setCurrentPage}
                filterToaster={filterToaster}
                propertyEnums={propertyEnums}
                isRolesModule={isRolesModule}
                allowedPermissions={allowedPermissions}
                fbFormList={fbFormList}
            />}

            {siteVisitShow && <UpdateSiteVistModal
                show={siteVisitShow}
                setShow={setSiteVisitShow}
                onSuccess={updateLeads}
            />
            }
            {followupShow && <UpdateFollowupModal
                show={followupShow}
                setShow={setFollowupShow}
                onSuccess={() => updateLeads()}
            />}

            {showDeleteModal && <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                deleteHandler={handleDeleteClicked}
                deleteLeading={deleteLeading}
                subText={`${leadType === 'booking_only' || leadType === 'eoi_only' ? 'After removal, all units will be marked unblocked.' : ''}`}
            />}

            <div className="lead-head md:!pl-5 !pl-4" id="leadHeader">
                <div className="lead-title mb-12">
                    <div className="row align-items-center">
                        <div className="col-md-5 d-flex align-items-center flex-wrap">
                            <h1 className="mb-0 mr-2 fz24 black h-font fw-po-medium">Leads Manager</h1>
                            <p className="mb-0 font-italic black">
                                Last Updated: {moment(refreshTime).format('h :mm a, Do MMM YY')}
                                <span className="text-underline cursor-pointer pr-hover ml-1" onClick={() => {
                                    getAllLeadsCount();
                                    updateLeads();
                                }}>
                                    refresh
                                </span>
                            </p>
                        </div>
                        <div className="col-md-7">
                            <div
                                className="d-flex flex-md-row align-items-md-center justify-content-md-end flex-md-nowrap flex-wrap flex-column"
                            >

                                {
                                    showMerge ?
                                        <div className="merge-del-btn">
                                            <div className="mr-8 d-flex flex-row">
                                                <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleMergeClicked}>
                                                    <span className="mr-8"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.81818 11.2727C11.8265 11.2727 13.4545 9.64467 13.4545 7.63636C13.4545 5.62806 11.8265 4 9.81818 4C7.80987 4 6.18182 5.62806 6.18182 7.63636C6.18182 9.64467 7.80987 11.2727 9.81818 11.2727ZM9.81818 9.81818C8.6132 9.81818 7.63636 8.84135 7.63636 7.63636C7.63636 6.43138 8.6132 5.45455 9.81818 5.45455C11.0232 5.45455 12 6.43138 12 7.63636C12 8.84135 11.0232 9.81818 9.81818 9.81818Z" fill="white" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.81818 12C13.0315 12 15.6364 14.6049 15.6364 17.8182V19.2727C15.6364 19.6744 15.3108 20 14.9091 20H4.72727C4.32561 20 4 19.6744 4 19.2727V17.8182C4 14.6049 6.60489 12 9.81818 12ZM14.1818 18.5455V17.8182C14.1818 15.4082 12.2282 13.4545 9.81818 13.4545C7.40821 13.4545 5.45455 15.4082 5.45455 17.8182V18.5455H14.1818Z" fill="white" />
                                                        <path d="M17.8182 12H19.2727C20.2424 12 20.2424 10.5455 19.2727 10.5455H17.8182V9.09091C17.8182 8.12121 16.3636 8.12121 16.3636 9.09091V10.5455H14.9091C13.9394 10.5455 13.9394 12 14.9091 12H16.3636V13.4545C16.3636 14.4242 17.8182 14.4242 17.8182 13.4545V12Z" fill="white" />
                                                    </svg></span>Merge
                                                </button>
                                                <button className="cancel-btn !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                                            </div></div> : " "
                                }

                                {
                                    showReassign ?
                                        <div className="merge-del-btn">
                                            <div className="mr-12 d-flex flex-row">
                                                <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleReassign}>Re-assign to User ({selectedTableValues.length})</button>
                                                <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                                            </div></div> : " "
                                }

                                {
                                    showTeamReassign ? <div className="merge-del-btn">
                                        <div className="mr-12 d-flex flex-row">
                                            <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleTeamReassign}>Re-assign to Team ({selectedTableData.length})</button>
                                            <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                                        </div></div> : " "
                                }
                                {
                                    showBulkLeadShare ? <div className="merge-del-btn">
                                        <div className="mr-12 d-flex flex-row">
                                            <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleBulkLead}>Leads Share Access ({selectedTableValues.length})</button>
                                            <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                                        </div></div> : " "
                                }
                                {
                                    showBulkLeadSourceChange ? <div className="merge-del-btn">
                                        <div className="mr-12 d-flex flex-row">
                                            <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleBulkLeadSourceChange}>Leads Source Change ({selectedTableValues.length})</button>
                                            <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                                        </div></div> : " "
                                }
                                {
                                    showDelete ?
                                        <div className="merge-del-btn">
                                            <div className="mr-20 flex">
                                                <button className="border-0 del-btn !rounded-tr-none !rounded-br-none" onClick={handleDelete}>Delete Leads ({selectedTableValues.length}) </button>
                                                <button className="cancel-btn onlydelete ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                                            </div></div> : " "
                                }

                                <div className="serch-and-filter-blcok d-flex ">
                                    <div className={`form-group position-relative search-input mr-12 ${text ? 'input-active' : ''} `}>
                                        <div className="serch-icon">
                                            <Image src={searchIcon} />
                                        </div>
                                        <input type="text" placeholder="Search" className="searchinput" onChange={handleSearchChange} value={searchText} />
                                        {text &&
                                            <div className="close-icon" onClick={handelClearSearch}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                                </svg>
                                            </div>}
                                    </div>

                                    <div className="d-flex flex-row align-items-center">
                                        <div className="mr-12" ref={sortRef}>
                                            <Dropdown align="end" className={"short-btn"} show={showSort}>
                                                <Dropdown.Toggle variant="short" id="dropdown-autoclose-true" className={`${col ? "active" : " "} d-flex`} onClick={() => { setShowSort(true) }}>
                                                    <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M4 6.00012H13" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 12.0001H11" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 18.0001H11" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15 15.0001L18 18.0001L21 15.0001" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M18 6.00012V18.0001" stroke="#696974" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                    <span className="text-capitalize">Sort</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="filter-heading d-flex align-items-center justify-content-between mb-12">
                                                        <h3 className="fz14 black mb-0"> Sort by</h3>
                                                        <button type="button" onClick={() => setShowSort(!showSort)} className="close float-right" aria-label="Close">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#000">
                                                                </path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div className="form-group">
                                                        <StatusDropDownDynamic
                                                            value={{ value: col, label: (col == 'Assigned To' || col === 'dead_lead_date') ? columnEnum[col] : col }}
                                                            onChange={handleSortColChange}
                                                            options={sortingOptions}
                                                            placeholder='Select Column'
                                                        />
                                                    </div>
                                                    {
                                                        col ?
                                                            <>
                                                                <div className="mt-12 a-z-short">
                                                                    <button className={`${btnAsc ? "active" : ""}`} onClick={() => handleSortChange("asc")} >
                                                                        Ascending
                                                                    </button>
                                                                    <button className={`${btnDes ? "active" : ""}`} onClick={() => handleSortChange("desc")} >
                                                                        Descending
                                                                    </button>
                                                                </div>
                                                                <div className="remove mt-22">
                                                                    <a onClick={() => {
                                                                        setCol("")
                                                                        setSort("")
                                                                        setBtnAsc(false);
                                                                        setBtnDes(false);
                                                                        localStorage.removeItem('sort');
                                                                        localStorage.removeItem('col');
                                                                        toast.success('Sort removed')
                                                                    }} className="d-flex align-items-center fz14  ml-auto float-right justify-content-end black-dark-700">
                                                                        <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.10904 3.13331L5.55709 1.78916C5.64783 1.51693 5.90259 1.33331 6.18954 1.33331H9.78954C10.0765 1.33331 10.3313 1.51693 10.422 1.78916L10.87 3.13331H13.9895C14.3577 3.13331 14.6562 3.43179 14.6562 3.79998C14.6562 4.16817 14.3577 4.46665 13.9895 4.46665H13.4181L12.9289 12.7841C12.8667 13.8412 11.9913 14.6666 10.9323 14.6666H5.04675C3.9878 14.6666 3.11238 13.8412 3.0502 12.7841L2.56094 4.46665H2.00016C1.63197 4.46665 1.3335 4.16817 1.3335 3.79998C1.3335 3.43179 1.63197 3.13331 2.00016 3.13331H5.10904ZM6.51449 3.13331H9.46459L9.30904 2.66665H6.67005L6.51449 3.13331ZM12.0825 4.46665H3.89658L4.38123 12.7058C4.40196 13.0582 4.69377 13.3333 5.04675 13.3333H10.9323C11.2853 13.3333 11.5771 13.0582 11.5979 12.7058L12.0825 4.46665ZM9.12417 6.15839C9.14714 5.79092 9.46366 5.51164 9.83113 5.53461C10.1986 5.55758 10.4779 5.87409 10.4549 6.24157L10.1549 11.0416C10.1319 11.409 9.81543 11.6883 9.44796 11.6653C9.08048 11.6424 8.80121 11.3259 8.82417 10.9584L9.12417 6.15839ZM7.15491 10.9584C7.17788 11.3259 6.8986 11.6424 6.53113 11.6653C6.16366 11.6883 5.84714 11.409 5.82417 11.0416L5.52417 6.24157C5.50121 5.87409 5.78048 5.55758 6.14796 5.53461C6.51543 5.51164 6.83194 5.79092 6.85491 6.15839L7.15491 10.9584Z" fill="#696974" />
                                                                        </svg> Clear Sort
                                                                    </a>
                                                                </div>
                                                            </>
                                                            : " "
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>


                                        <div className="filter-btn mr-12">
                                            <button className={`d-flex align-items-center ${filters.length > 0 ? " border-0 pr-hover  active" : "border-0 btn"}`} type="button" onClick={() => setfilterShow(true)}>
                                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                                                <span className="text-capitalize">Filter {filters.length > 0 ? ` (${filters.length})` : " "}</span>

                                            </button>
                                        </div>

                                        {
                                            // showMerge || showReassign || showDelete ? <button className="bg-tr border-0 " style={{ opacity: '0' }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            //     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            //         <path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE" />
                                            //         <path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE" />
                                            //         <path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE" />
                                            //     </svg>
                                            // </button> :

                                            showTripleDot && <div className="dropdown view-more d-flex">
                                                <button className="bg-tr border-0 px-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#696974" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#696974" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#696974" />
                                                    </svg>
                                                </button>
                                                <div className="dropdown-menu w-auto dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                    <div className="head-itme pb-2 m-0">Create <span></span></div>
                                                    <a className="dropdown-item mb-8" onClick={() => setAddLeadShow(true)}> New Lead </a>
                                                    {/* {userProfile?.company_id === 581 && userProfile?.role?.allowed_permissions?.includes('export_leads_without_approval') && <a className="dropdown-item mb-8" onClick={() => setShowExportLeadModal(true)}> Export Leads </a>} */}
                                                    {/* {ROLE_NAME === 'ADMIN' && */}
                                                    {(showDeleteOption || showReAssignOption) && <>
                                                        <div className="head-itme">Bulk Actions <span style={{ width: '80px' }}></span></div>
                                                        {/* <a className="dropdown-item" onClick={mergeClick}>Merge Leads</a> */}
                                                        {showReAssignOption && <a className="dropdown-item" onClick={reAssignTeamClick}>Re-assign Leads to User</a>}
                                                        {/* {showReAssignOption && <a className="dropdown-item" onClick={reAssignTeamClick}>Re-assign Leads to Team</a>} */}
                                                        {showDeleteOption && <a className="dropdown-item mb-0" onClick={deleteClick}>Delete Leads</a>}
                                                    </>}
                                                    {userProfile?.role?.allowed_permissions?.includes('bulk_lead_sharing') && <a className="dropdown-item" onClick={handleBulkLeadShare}>Bulk Lead Share</a>}
                                                    {userProfile?.allowed_modules?.includes('bulk_source_update') && userProfile?.role?.allowed_permissions?.includes('bulk_source_change') && <a className="dropdown-item" onClick={handleBulkSourceChange}>Bulk Leads Source Change</a>}
                                                    {/* <a className="dropdown-item" onClick={() => setFollowupShow(true)}> New Followup </a>
                                                    <a className="dropdown-item" onClick={() => setSiteVisitShow(true)}> New Site Visit </a> */}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LeadsTopBarTab
                    handleLeadTypeChange={handleLeadTypeChange}
                    count={count}
                    leadType={leadType}
                    allowedPermissions={allowedPermissions}
                    loadingCount={loadingCount}
                    handleSiteVisitEventFilter={handleSiteVisitEventFilter}
                    removeTabsFilter={removeTabsFilter}
                />
                {
                    showExportLeadModal && <ExportDashboardLeads
                        show={showExportLeadModal}
                        closeModal={() => setShowExportLeadModal(false)}
                        handleSubmit={getExportLeadsData}
                        count={count}
                        filters={filters}
                        leadCount={leadCount}
                        renderFilterList={renderFilterList}
                    />
                }
            </div>
        </>
    )
}