import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { markCompletedCall, reassignLeads, updateSiteVisitCall } from '../../../services/private/leads.service';
import DateTimePicker from '../../InputGroup/DateTime';
import InputSelect from '../../InputGroup/InputSelect';
import SiteVisiteDoneModal from '../../Modals/SiteVisiteDoneModal';
import GreenCheck from '../../../assets/icons/green_check.svg';
import { validityUnit } from '../../../helpers/enums';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../../services/private/company.service';
import { checkIsSomeItemAvailableInList } from '../../../helpers/helpers';
import InputText from '../../InputGroup/InputText';
import { ReactComponent as PlusPrimaryIcon } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',

        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        color: state.isSelected ? '#44444F' : '#44444F',
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

export default function ShowSiteVisit({
    siteVisit,
    reloadSiteVists,
    latestActivity,
    projectsOptions,
    leadInfoData,
    intentOptions,
    leadAccess,
    userProfile,
    allowedPermissions
}) {

    const [scheduleDate, setScheduleDate] = useState(siteVisit.schedule_date ? new Date(siteVisit.schedule_date) : '');
    const [editSiteVisit, setEditSiteVisit] = useState(false);
    const [remarks, setRemarks] = useState(siteVisit.remarks);
    const [followupScheduleDate, setFollowupScheduleDate] = useState(latestActivity?.followup?.schedule_date)
    const [projects, setProjects] = useState(siteVisit.projects.map(proj => { return { value: proj.project_id, label: proj.name } }))
    const [deleteModal, setDeleteModal] = useState(false);
    const [showSvCompleted, setShowSvCompleted] = useState(false)
    const [svCompleted, setSvCompleted] = useState(false)
    const [error, setError] = useState(false)
    const [selectedIntent, setSelectedIntent] = useState((leadInfoData.intent && leadInfoData.intent?.display_name != 'New Lead') ? {
        label: leadInfoData.intent?.display_name,
        value: leadInfoData.intent?.id
    } : '')
    const [followupErrorMsg, setFollowupErrorMsg] = useState('')
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([{}]);
    const [allUsers, setAllUsers] = useState([]);
    const [assignedTo, setAssignedTo] = useState('');
    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);
    const [statusShareUsers, setStatusShareUsers] = useState([]);

    const params = useParams();

    useEffect(() => {
        if (editSiteVisit) {
            const projects = siteVisit.projects.map(proj => { return { value: proj.project_id, label: proj.name } })
            setProjects(projects)
        }
    }, [editSiteVisit, siteVisit]);

    useEffect(() => {
        setFollowupScheduleDate(latestActivity?.followup?.schedule_date)
    }, [latestActivity?.followup?.schedule_date])

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.uuid
                    }
                });
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData);
            }
        })
    }

    useEffect(() => {
        // reloadSiteVists();
        getAllUsers();
    }, []);

    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(params?.id);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: leadInfoData?.status?.id });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                setAssignedTo(res.data.data?.user_uuid)
            } else {
                setAssignedTo(leadInfoData?.lead?.assignedTo?.uuid)
            }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: leadInfoData?.status?.id });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.ID))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers]);

    useEffect(() => {
        if (userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccess();
        }
        if (leadInfoData?.status?.id && userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccessAssignedToByStatus();
            getShareLeadAccessUsingStatusId();
        }
    }, [leadInfoData, userProfile]);

    const handleReassignModalSubmit = () => {
        reassignLeads([params.id], assignedTo, '').then(res => {
            if (res.status === 200) {
                toast.success("Lead Reassigned")
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const handleSubmitLeadShare = async () => {
        let payload = users.filter(item => item.user_id);
        let count = 0
        payload.forEach(item => {
            if (!item.user_id || !item.validity || !item.access || !item.validity_unit) {
                count++
            }
        })
        if (count > 0) {
            toast.error('Please fill all values');
            return;
        }

        let res = {}

        payload = payload.map(item => ({ ...item, expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString() }))
        res = await saveLeadAccess(params.id, { status_id: null, users: payload })

        if (res.data.status === 200) {
            toast.success(res.data.message);
            reloadSiteVists();
            window.location.reload();
        }
    }

    const updateSiteVisit = async () => {
        if (!scheduleDate || !followupScheduleDate || projects.length === 0 || Object.keys(selectedIntent).length === 0) {
            setError(true);
            return;
        } else {
            setError(false)
        }

        if (moment(followupScheduleDate) >= moment(scheduleDate)) {
            setFollowupErrorMsg('Followup Date should be before SV Scheduled Date');
            setError(true);
            return;
        } else {
            setFollowupErrorMsg('');
            setError(false);
        }

        const projectId = projects.map(proj => proj.value);
        setLoading(true);
        await updateSiteVisitCall(siteVisit.uuid, {
            scheduleDate,
            remarks,
            followupScheduleDate,
            projectId,
            intentId: selectedIntent.value
        }).then(res => {
            if (res.status === 200) {
                toast.success("Site Visit Updated")
                if (userProfile?.allowed_modules?.includes('lead_sharing')) {
                    handleSubmitLeadShare();
                    if (assignedTo) {
                        handleReassignModalSubmit();
                    }
                } else {
                    reloadSiteVists();
                    window.location.reload();
                }

            }
        }).catch(err => {
            setLoading(false);
            console.log(err, 'err')
        })
        setLoading(false);
    }

    const getReminderMessage = () => {
        // Remind 10 minutes before
        if (followupScheduleDate) {
            return <p className='text-center px-16 fz14 mt-16 black-dark-700 mb-16'>We will remind you <span className='black fw-po-medium'>15 mins</span>  prior to <span className='black fw-po-medium'>{moment(followupScheduleDate).format('h:mm a, Do MMMM YYYY')}</span>.</p>
            // <p className="text-center px-16 fz14 mt-16 black-dark-700 mb-0">
            //     We will remind you 15 mins before <span className='black fw-po-medium'>{moment(followupScheduleDate).subtract(15, 'minutes').format('LT')}</span> on <span className='black fw-po-medium'>{moment(followupScheduleDate).format('LL')}</span>.
            // </p>
        }
    }

    return (
        <div className={`${editSiteVisit ? 'active' : ''} card-activity  mb-20 py-12 px-12`} >
            {/* edit box */}
            {
                editSiteVisit ?
                    <div className={`edit-follow w-100 px-2.5`}>
                        <div className="part-one">
                            <div className="row align-items-center mb-8">
                                <div className="col-8">
                                    <h2 className='black-dark-700 fz16 mb-0 fw-po-medium po flex items-center'>
                                        <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.5 15C14.7091 15 16.5 13.2091 16.5 11C16.5 8.79086 14.7091 7 12.5 7C10.2909 7 8.5 8.79086 8.5 11C8.5 13.2091 10.2909 15 12.5 15ZM12.5 13C11.3954 13 10.5 12.1046 10.5 11C10.5 9.89543 11.3954 9 12.5 9C13.6046 9 14.5 9.89543 14.5 11C14.5 12.1046 13.6046 13 12.5 13Z" fill="#FC5A5A"></path><path fillRule="evenodd" clipRule="evenodd" d="M3.5 10.8701C3.5 5.96756 7.53307 2 12.5 2C17.4669 2 21.5 5.96756 21.5 10.8701C21.5 13.2916 20.5094 15.5597 18.795 17.2093L13.2013 22.7128C12.8122 23.0957 12.1879 23.0957 11.7987 22.7129L6.19735 17.2024C4.48444 15.5459 3.5 13.2842 3.5 10.8701ZM17.4003 15.7759C18.7357 14.4909 19.5 12.7409 19.5 10.8701C19.5 7.07953 16.3696 4 12.5 4C8.63037 4 5.5 7.07953 5.5 10.8701C5.5 12.7352 6.25964 14.4804 7.59383 15.7707L12.5 20.5972L17.4003 15.7759Z" fill="#FC5A5A"></path></svg>
                                        Edit Site Visit
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <InputSelect
                                    index={4}
                                    name="project"
                                    label='Select Projects'
                                    isMulti={true}
                                    options={projectsOptions}
                                    placeholder="Select Project"
                                    error={error && !(projects?.length) && 'Project is required'}
                                    value={projects}
                                    onChange={(e) => {
                                        setProjects(e)
                                    }}
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-12">
                                    <DateTimePicker
                                        onValueChange={(e) => setScheduleDate(e)}
                                        label="Select Site Visit Date & Time"
                                        name="scheduleDate"
                                        value={scheduleDate}
                                        error={error && !scheduleDate && 'Schedule date is required'}
                                    />
                                </div>
                            </div>
                            <div className='mt-12'>
                                <InputSelect
                                    index={2}
                                    label="Intent"
                                    name="Intent"
                                    value={selectedIntent}
                                    options={intentOptions}
                                    placeholder="Select intent"
                                    onChange={(e) => { setSelectedIntent(e) }}
                                    error={error && (Object.keys(selectedIntent)?.length === 0) && 'Intent is required'}
                                // onBlur={()=>{
                                //     handleBlur({target : { name: "Lead Source"}})
                                // }}
                                />
                            </div>
                        </div>
                        <div className="part-tow py-12">
                            <div className="row align-items-center mb-8">
                                <div className="col-12">
                                    <h2 className='black-dark-700 fz16 mb-0 fw-po-medium po flex items-center justify-between'>
                                        <label htmlFor="" className='flex items-center'>

                                            <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7 1H15.1482C15.7514 1 16.3224 1.27227 16.7022 1.74098L20.554 6.49524C20.8425 6.8514 21 7.29587 21 7.75425V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5C3 2.79086 4.79086 1 7 1ZM15.625 3.58854V6.4H17.9028L15.625 3.58854ZM7 3C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V8.4H15.625C14.5204 8.4 13.625 7.50457 13.625 6.4V3H7Z" fill="#FF974A" />
                                            </svg>
                                            Edit Note

                                        </label>
                                        {/* <div className='flex items-center justify-between !mt-4 !mb-2'> */}
                                        <button
                                            type='button'
                                            className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                            onClick={() => {
                                                setRemarks('')
                                            }}
                                        >Clear <CloseIcon className='h-3 w-3' /></button>
                                        {/* </div> */}
                                    </h2>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className='edit-textarea'>
                                        <textarea
                                            cols="30"
                                            rows="2"
                                            className='form-control border-0'
                                            placeholder="Enter any followup comments..."
                                            value={remarks}
                                            onChange={(e) => setRemarks(e.target.value)}></textarea>
                                    </div>
                                </div>
                            </div>

                            {/* Followup Date */}
                            <div className="form-group date-time ml-0 mt-16">
                                <div className="row  align-items-center">
                                    <div className="col-md-12 mb-12">
                                        <h3 className="black-dark-700 fz16 mb-0 fw-po-medium po flex items-center">
                                            <svg className="mr-12" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M15.7 4V3C15.7 2.44772 16.1477 2 16.7 2C17.2523 2 17.7 2.44772 17.7 3V4.00491C19.8162 4.10912 21.5 5.85793 21.5 8V17C21.5 19.2091 19.7091 21 17.5 21H7.5C5.29086 21 3.5 19.2091 3.5 17V8C3.5 5.79086 5.29086 4 7.5 4H7.7V3C7.7 2.44772 8.14772 2 8.7 2C9.25228 2 9.7 2.44772 9.7 3V4H11.7V3C11.7 2.44772 12.1477 2 12.7 2C13.2523 2 13.7 2.44772 13.7 3V4H15.7ZM15.7 6H13.7C13.7 6.55228 13.2523 7 12.7 7C12.1477 7 11.7 6.55228 11.7 6H9.7C9.7 6.55228 9.25229 7 8.7 7C8.14772 7 7.7 6.55228 7.7 6H7.5C6.39543 6 5.5 6.89543 5.5 8V17C5.5 18.1046 6.39543 19 7.5 19H17.5C18.6046 19 19.5 18.1046 19.5 17V8C19.5 6.96291 18.7106 6.11019 17.7 6.00987C17.6947 6.55761 17.249 7 16.7 7C16.1477 7 15.7 6.55228 15.7 6ZM9.5 12C8.94772 12 8.5 11.5523 8.5 11C8.5 10.4477 8.94772 10 9.5 10H16.5C17.0523 10 17.5 10.4477 17.5 11C17.5 11.5523 17.0523 12 16.5 12H9.5ZM9.5 16C8.94772 16 8.5 15.5523 8.5 15C8.5 14.4477 8.94772 14 9.5 14H14.5C15.0523 14 15.5 14.4477 15.5 15C15.5 15.5523 15.0523 16 14.5 16H9.5Z" fill="#3DD598"></path></svg>
                                            Edit Followup
                                        </h3>
                                    </div>
                                    <div className="col-md-12">
                                        <DateTimePicker
                                            onValueChange={(e) => setFollowupScheduleDate(e)}
                                            label="Select Followup Date & Time"
                                            name="scheduleDate"
                                            value={followupScheduleDate}
                                            error={error && (followupErrorMsg || !followupScheduleDate && 'Follow up date is required.')}
                                        />

                                    </div>
                                </div>
                                {getReminderMessage()}
                            </div>
                            {userProfile?.allowed_modules?.includes('lead_sharing') && checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing']) &&
                                <>
                                    <div className='mt-3' >
                                        <InputSelect
                                            label={'Assigned User'}
                                            placeholder={'Select User'}
                                            styles={customStyles}
                                            options={usersForAssigned}
                                            value={assignedTo ? usersForAssigned.find(user => user.value === assignedTo) : ''}
                                            onChange={(value) => {
                                                setAssignedTo(value.value);
                                            }}
                                            disable={!allowedPermissions.includes('edit_lead_sharing')}
                                        />
                                    </div>
                                    <label className='mt-3'>Shared With</label>
                                    {
                                        users.map((item, index) => (
                                            <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                                <div className='flex justify-between items-center form-group'>
                                                    <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                                    {allowedPermissions.includes('edit_lead_sharing') && <button
                                                        type='button'
                                                        className='!text-primary text-base font-medium'
                                                        onClick={() => {
                                                            let newUsers = [...users];
                                                            newUsers.splice(index, 1);
                                                            if (users.length === 1) {
                                                                setUsers([{}])
                                                            } else {
                                                                setUsers([...newUsers])
                                                            }

                                                        }}
                                                    >Remove</button>}
                                                </div>
                                                <div className='form-group'>
                                                    <InputSelect
                                                        placeholder={'Select User'}
                                                        options={allUsers}
                                                        styles={customStyles}
                                                        value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                                        onChange={(value) => {
                                                            let newUsers = [...users];
                                                            newUsers[index].user_id = value.value;
                                                            // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                            // setAllUsers(filteredUser)
                                                            setUsers([...newUsers]);
                                                        }}
                                                        disable={!allowedPermissions.includes('edit_lead_sharing')}
                                                    />
                                                </div>
                                                <div className='mt-3 flex'>
                                                    <label htmlFor={`edit_${index}`} className='mr-4 flex items-center'>
                                                        <input
                                                            type="radio"
                                                            className='mr-2'
                                                            name={`permission_${index}`}
                                                            id={`edit_${index}`}
                                                            value='edit'
                                                            checked={item.access === 'edit'}
                                                            onChange={() => {
                                                                let newUsers = [...users];
                                                                newUsers[index].access = 'edit'
                                                                setUsers(newUsers);
                                                            }}
                                                            disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                        />
                                                        Edit Access
                                                    </label>
                                                    <label htmlFor={`view_${index}`} className='mr-4 flex items-center'>
                                                        <input
                                                            type="radio"
                                                            className='mr-2'
                                                            name={`permission_${index}`}
                                                            id={`view_${index}`}
                                                            value='view'
                                                            checked={item.access === 'view'}
                                                            onChange={() => {
                                                                let newUsers = [...users];
                                                                newUsers[index].access = 'view'
                                                                setUsers(newUsers);
                                                            }}
                                                            disabled={!allowedPermissions.includes('edit_lead_sharing')}
                                                        />
                                                        View Only
                                                    </label>
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor="">Validity</label>
                                                    <div className='grid grid-cols-2 gap-2'>
                                                        <InputText
                                                            placeholder={'e.g. 30'}
                                                            value={item.validity}
                                                            onChange={(e) => {
                                                                let newUsers = [...users];
                                                                newUsers[index].validity = e.target.value
                                                                setUsers(newUsers);
                                                            }}
                                                            disable={!allowedPermissions.includes('edit_lead_sharing')}
                                                        />
                                                        <InputSelect
                                                            inputclass={'m-0'}
                                                            styles={customStyles}
                                                            placeholder={'Select Time'}
                                                            value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                            options={validityUnit}
                                                            onChange={(value) => {
                                                                let newUsers = [...users];
                                                                newUsers[index].validity_unit = value.value;
                                                                setUsers(newUsers);
                                                            }}
                                                            disable={!allowedPermissions.includes('edit_lead_sharing')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <button
                                        type='button'
                                        className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold'
                                        onClick={() => setUsers([...users, {}])}
                                        disable={!allowedPermissions.includes('edit_lead_sharing')}
                                    >
                                        <PlusPrimaryIcon />Add another
                                    </button>
                                </>}
                            <div className="save-cancel-btn d-flex aling-items-center justify-content-end">
                                <button className="cancel-btn outline-btn" onClick={() => {
                                    setError(false);
                                    setEditSiteVisit(false)
                                }}>Cancel</button>
                                <button className="save-btn pr-btn" onClick={updateSiteVisit} disabled={loading}>Save</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='d-flex'>

                        <div className={`icon mr-0 mt-1`}>
                            {!siteVisit.is_completed &&
                                <input type="checkbox"
                                    value={svCompleted}
                                    className="form-control" onClick={() => setShowSvCompleted(true)}
                                    disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view')}
                                />}
                            {siteVisit.is_completed &&
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.99844 2.40002H17.9984C19.9867 2.40002 21.5984 4.0118 21.5984 6.00002V18C21.5984 19.9882 19.9867 21.6 17.9984 21.6H5.99844C4.01021 21.6 2.39844 19.9882 2.39844 18V6.00002C2.39844 4.0118 4.01021 2.40002 5.99844 2.40002Z" fill="#3DD598" />
                                    <path d="M8.83208 11.136C8.36042 10.6705 7.60064 10.6754 7.13506 11.147C6.66947 11.6187 6.67439 12.3785 7.14605 12.8441L10.1952 15.854C10.6968 16.3491 11.5152 16.3074 11.9638 15.7637L16.9146 9.7637C17.3364 9.25251 17.264 8.49618 16.7528 8.07438C16.2416 7.65258 15.4853 7.72504 15.0635 8.23622L10.9476 13.2243L8.83208 11.136Z" fill="white" />
                                </svg>
                            }
                        </div>
                        <div className={`desc position-relative ml-12`}>
                            <div className="col-10 px-0">
                                <h2 className='black fz16 mb-0 fw-po-medium po'>
                                    {`${siteVisit.is_completed ? 'Site Visit Completed' : 'Site Visit Scheduled'}`}
                                </h2>

                            </div>
                            {!siteVisit.is_completed && <div className={`edit-delte d-flex col-2 flex-row align-items-center position-absolute`}>
                                {/* Edit button */}
                                <button className="bg-tr border-0 pr-hover" onClick={() => setEditSiteVisit(true)} disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && leadAccess === 'view')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.8841 6.33009L15.8131 5.40113L13.7655 3.35357L12.8365 4.28254L14.8841 6.33009ZM13.7118 7.5024L11.6642 5.45485L4.00467 13.1144L3.53341 15.6332L6.05222 15.162L13.7118 7.5024ZM17.0349 4.27833C17.655 4.89843 17.655 5.90382 17.0349 6.52393L7.04308 16.5157C6.92487 16.6339 6.77369 16.7136 6.60937 16.7444L2.64842 17.4855C2.07565 17.5926 1.574 17.091 1.68116 16.5182L2.42224 12.5573C2.45299 12.3929 2.53269 12.2418 2.6509 12.1236L12.6427 2.13175C13.2628 1.51165 14.2682 1.51165 14.8883 2.13175L17.0349 4.27833ZM16.6749 17.5H9.99171C8.8916 17.5 8.8916 15.8333 9.99171 15.8333H16.6749C17.775 15.8333 17.775 17.5 16.6749 17.5Z" fill="#92929D" />
                                    </svg>
                                </button>

                                {/* Delete button */}
                                {/* data-toggle="modal" data-target="#deletemodal" */}
                                {/* onClick={e => this.deleteSiteVisit(siteVisit.uuid)} */}
                                {/* <button className="bg-tr border-0 del-hover" data-toggle="modal" data-target="#deleteSiteVisitModal" onClick={()=>showDeleteModal(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.38605 3.91666L6.94612 2.23647C7.05954 1.89619 7.37799 1.66666 7.73668 1.66666H12.2367C12.5954 1.66666 12.9138 1.89619 13.0273 2.23647L13.5873 3.91666H17.4867C17.9469 3.91666 18.32 4.28976 18.32 4.75C18.32 5.21024 17.9469 5.58333 17.4867 5.58333H16.7724L16.1609 15.9801C16.0831 17.3015 14.9889 18.3333 13.6652 18.3333H6.30819C4.9845 18.3333 3.89024 17.3015 3.81251 15.9801L3.20093 5.58333H2.49996C2.03972 5.58333 1.66663 5.21024 1.66663 4.75C1.66663 4.28976 2.03972 3.91666 2.49996 3.91666H6.38605ZM8.14287 3.91666H11.8305L11.6361 3.33333H8.33732L8.14287 3.91666ZM15.1029 5.58333H4.87048L5.4763 15.8823C5.50221 16.3227 5.86696 16.6667 6.30819 16.6667H13.6652C14.1064 16.6667 14.4712 16.3227 14.4971 15.8823L15.1029 5.58333ZM11.405 7.69802C11.4337 7.23867 11.8293 6.88958 12.2887 6.91829C12.748 6.947 13.0971 7.34264 13.0684 7.80198L12.6934 13.802C12.6647 14.2613 12.269 14.6104 11.8097 14.5817C11.3504 14.553 11.0013 14.1574 11.03 13.698L11.405 7.69802ZM8.9434 13.698C8.9721 14.1574 8.62301 14.553 8.16367 14.5817C7.70433 14.6104 7.30868 14.2613 7.27997 13.802L6.90497 7.80198C6.87627 7.34264 7.22536 6.947 7.6847 6.91829C8.14404 6.88958 8.53969 7.23867 8.5684 7.69802L8.9434 13.698Z" fill="#92929D" />
                        </svg>
                    </button> */}
                            </div>}
                            <p className='fz14 mb-0 black-dark-700 mt4'>
                                {siteVisit.is_completed ? `Completed: ${moment(siteVisit.completed_time).format("hh:mm a, Do MMMM YYYY")} ` : `Due: ${moment(siteVisit.schedule_date).format("hh:mm a, Do MMMM YYYY")}`}
                            </p>
                            {siteVisit.remarks && <h1 className="fz14 black-dark-700 ro fw-regular mb-0">Note: {siteVisit.remarks} </h1>}

                            {siteVisit.projects.length > 0 && siteVisit.projects.map((proj, index) => {
                                return <p key={String(proj.project_id)} className="mb-0">{`Project ${index + 1}:`} {proj.name}</p>
                            })}
                            {followupScheduleDate && <p className='fz14 black-dark-700 ro fw-regular mb-0'>Reminder : We will remind you to followup
                                at {moment(followupScheduleDate).format("hh:mm a, Do MMMM YYYY")}
                            </p>}

                        </div>
                    </div>
            }
            {showSvCompleted && <SiteVisiteDoneModal
                show={showSvCompleted}
                setShow={setShowSvCompleted}
                leadId={params.id}
                onSuccess={() => {
                    setSvCompleted(true);
                    reloadSiteVists();
                    window.location.reload();
                }}
                intentOptions={intentOptions}
                selectedIntent={leadInfoData.intent?.id}
            />}
        </div>
    )
}