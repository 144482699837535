import React, { useEffect, useState, useContext, useCallback } from "react";
import moment from 'moment'
import DynamicEditinput from "../../../../../components/InputGroup/DynamicEditInput";
import { useParams } from 'react-router-dom';
import { getLeadByUuid, updateLeadByIdAndKey } from "../../../../../services/private/leads.service";
import { updateCustomerByIdAndKey, updateCustomerByIdAndKey2 } from "../../../../../services/private/customer.service";
import { toast } from "react-toastify";
import Consumer from '../../../../../helpers/context'
import DynamicDropdown from "../../../../../components/InputGroup/DynamicDropdown";
import BudgetPicker from "../../../../Lead Details/components/Info Components/Budget Picker/BudgetPicker";
import MultiSelectProject from "../../../../../components/InputGroup/MultiSelectProject";
import DynamicMobileInput from "../../../../../components/InputGroup/DynamicMobileInput";
import LeadDuplicateModal from "../../../../../components/Modals/LeadDuplicateModal";
import { ALL_REGEX } from "../../../../../config/static";
import ReengageSourceView from "../../../../../components/InputGroup/ReengageSourceView";

export default function BasicInfoTab(props) {

    const params = useParams();
    const [editActive, setEditActive] = useState({})
    const [leadInfoData, setLeadInfoData] = useState({})
    const [leadInfo, setLeadInfo] = useState({})
    const [customerInfoData, setCustomerInfoData] = useState({ data: {} });
    const [customerInfo, setCustomerInfo] = useState();
    const [projectInfoData, setProjectInfoData] = useState([]);
    const [projectInfo, setProjectInfo] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showLeadDuplicationModal, setShowLeadDuplicateModal] = useState(false);
    const [duplicateData, setDuplicateData] = useState();
    const [reengageSource, setEngageSource] = useState([])

    let { companyConfig, userProfile } = useContext(Consumer);


    const getLeadDetails = async () => {
        await getLeadByUuid(params.id).then(res => {
            console.log(res.data.data, 'respoce')
            if (res.status === 200) {
                setCustomerInfoData(res.data.data.customer)
                setCustomerInfo(res.data.data.customer)
                setLeadInfoData(res.data.data.lead)
                setLeadInfo(res.data.data.lead)
                setProjectInfoData(res.data.data.projects)
                setProjectInfo(res.data.data.projects)
                setEngageSource(res.data.data?.reEngagedSources || [])
            }
        })
    }

    const handleEditActive = (name, value) => {
        const index = Object.values(editActive).indexOf(true)
        if (index !== -1 && !value) {
            setEditActive({ ...editActive, [name]: value })
        }
        if (index === -1 && value) {
            setEditActive({ ...editActive, [name]: value })
        }
    }

    console.log(leadInfoData, 'leadInfoData');

    const handleValueChange = (name, value) => {
        let data = { ...customerInfoData.data };
        data = { ...data, [name]: value };
        setCustomerInfoData({ ...customerInfoData, data: data })
    }


    const handleCloseCustomerInfo = useCallback((name) => {
        let data = { ...customerInfoData.data };
        data = { ...data, [name]: customerInfo?.data[name] };
        setCustomerInfoData({ ...customerInfoData, data: data })
    }, [customerInfoData, customerInfo])

    const handleLeadValueChange = (name, value) => {
        let data = leadInfoData.data;
        data = { ...data, [name]: value };
        setLeadInfoData({ ...customerInfoData, data: data })
    }

    const handleCloseLeadInfo = (name) => {
        let data = leadInfoData.data;
        data = { ...data, [name]: leadInfo?.data[name] };
        setLeadInfoData({ ...customerInfoData, data: data })
    }

    useEffect(() => {
        getLeadDetails()
    }, [])

    const saveCustomerData = async (key, value, label) => {

        if (!value) {
            toast.error(`Can't save empty value.`)
            return;
        }

        if (key === 'email') {
            if (!ALL_REGEX.EMAIL.test(value)) {
                toast.error('Please enter valid email id')
                return;
            }
        }
        if (customerInfo?.data[key] === customerInfoData.data[key]) {
            handleEditActive(key, false)
            return;
        }

        await updateCustomerByIdAndKey(customerInfoData.data.uuid, {
            key, value
        }).then(res => {
            if (res.data.status === 200) {
                toast.success(`${label} updated`)
                getLeadDetails();
                handleEditActive(key === 'alternate_mobile' ? 'alt_mobile' : key, false);
            }
            if (key === 'mobile' && res.data.status === 304 && res.data.data.leadInfo) {
                setShowLeadDuplicateModal(true);
                setDuplicateData({ ...res.data.data.leadInfo, currentLeadName: customerInfoData.data.name })
            }
            if (key === 'mobile' && res.data.status === 304 && !res.data.data.leadInfo) {
                toast.error(`${customerInfoData.data.name} cannot be updated with ${value} as it already exists in the platform`);
                handleCloseCustomerInfo('mobile')
            }
        })
    }

    const saveLeadData = async (key, value, label) => {
        const keyVal = {
            project_id: 'project',
            source_id: 'source'
        }
        if (!value || (key === 'project_id' && value.length === 0)) {
            toast.error(`Can't save empty value.`)
            return;
        }

        if (key !== 'project_id' && leadInfoData?.data[key] === leadInfo.data[key]) {
            handleEditActive(keyVal[key], false)
            return;
        }

        await updateLeadByIdAndKey(params.id, {
            key, value
        }).then(res => {
            if (res.status === 200) {
                toast.success(`${label} updated`)
                handleEditActive(keyVal[key], false);
                getLeadDetails();
            }
        })
    }

    const updateCustomerRangeInfo = async (key, value) => {
        if (Object.keys(value).length === 0) {
            return;
        }
        await updateCustomerByIdAndKey2(customerInfoData.data.uuid, value).then((res) => {
            if (res.status === 200) {
                toast.success(`Budget updated`);
                handleEditActive(key, false);
                getLeadDetails();
            }
        }).catch((err) => {
            console.log(err, "Something went wrong on updating customer info");
        })
    }

    const handleDateChange = (e, name) => {
        setSelectedDate({ ...selectedDate, [name]: e })
    }

    const selectedValue = useCallback((dropdownList, valueToFind) => {
        return dropdownList.find((d) => d.value === valueToFind)
    }, []);

    const closeLeadDuplicationModal = () => {
        setShowLeadDuplicateModal(false);
        handleCloseCustomerInfo('mobile');
    };

    const handleLeadRoutes = (url) => {
        window.open(url, "_blank");
        handleCloseCustomerInfo('mobile');
        setShowLeadDuplicateModal(false);
    };

    return (
        <>
            <div className={props.show ? 'tab-pane fade show active ' : 'd-none'} id="basic" role="tabpanel" aria-labelledby="basic-tab">
                <div className={props.currentPage == 1 ? '' : 'd-none'}>
                    <div className="row">

                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicMobileInput
                                editActive={editActive.mobile}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('mobile')
                                    }
                                    handleEditActive("mobile", activeState)
                                }}
                                heading="Mobile"
                                name="Mobile"
                                placeholder="XXXXXXXXXX"
                                isEditable={true}
                                value={customerInfoData.data.mobile}
                                // countryCode='+91'
                                type="text"
                                onValueChange={({ phone }) => {
                                    handleValueChange("mobile", phone)
                                }
                                }
                                // handleBlur={(e) => handleValueChange("mobile", `${e.target.value ? '+91' + e.target.value : ''}`)}
                                onSave={() => {
                                    if (customerInfoData.data.mobile.length === 0) {
                                        toast.error('Can’t save empty mobile number.')
                                        return;
                                    }
                                    if (customerInfoData.data.mobile.length < 10) {
                                        toast.error('Enter a valid mobile number.')
                                        return;
                                    }
                                    saveCustomerData("mobile", customerInfoData.data.mobile, 'Mobile')
                                    // handleEditActive("mobile", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                editActive={editActive.email}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('email')
                                    }
                                    handleEditActive("email", activeState)
                                }}
                                heading="Email"
                                name="Email"
                                placeholder="Email Id"
                                isEditable={true}
                                value={customerInfoData.data.email}
                                type="text"
                                onValueChange={(value) => handleValueChange("email", value)}
                                onSave={() => {
                                    if (!ALL_REGEX.EMAIL.test(customerInfoData.data.email)) {
                                        toast.error('Please enter a valid email.')
                                        return;
                                    }
                                    saveCustomerData("email", customerInfoData.data.email, 'Email')
                                    // handleEditActive("email", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />

                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            {/* Projects */}
                            <MultiSelectProject
                                editActive={editActive.project}
                                heading="Projects"
                                name="Project"
                                isEditable={true}
                                enableEditing={false}
                                options={props.projectList}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        setProjectInfoData(projectInfo)
                                    }
                                    handleEditActive("project", activeState)
                                }}
                                projectInfoData={projectInfoData}
                                onValueChange={(value) => setProjectInfoData([...value])}
                                onSave={() => {
                                    saveLeadData("project_id", projectInfoData.map(info => info.id), 'Projects')
                                    // handleEditActive("project", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <BudgetPicker
                                heading="Budget"
                                editActive={editActive.budget}
                                isEditable={true}
                                enableEditing={false}
                                minimumBudget={customerInfoData.data?.minimum_budget}
                                maximumBudget={customerInfoData.data?.maximum_budget}
                                onMinValueChange={(value) => handleValueChange("minimum_budget", value)}
                                onMaxValueChange={(value) => handleValueChange("maximum_budget", value)}
                                onClickHandler={() => { }}
                                onEditClick={() => handleEditActive("budget", true)}
                                editDeactive={() => {
                                    handleCloseCustomerInfo('minimum_budget')
                                    handleCloseCustomerInfo('maximum_budget')
                                    handleEditActive("budget", false)
                                }}
                                onSave={(value) => {
                                    updateCustomerRangeInfo("budget", value)
                                    // handleEditActive("budget", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicDropdown
                                editActive={editActive.source}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseLeadInfo('source_id')
                                    }
                                    handleEditActive("source", activeState)
                                }}
                                heading="Source"
                                name="Source"
                                placeholder="e.g Google"
                                isEditable={true}
                                value={selectedValue(companyConfig.Source, leadInfoData?.data?.source_id)}
                                optionConfig={[...companyConfig.Source]}
                                onValueChange={(value) => handleLeadValueChange("source_id", value.value)}
                                onSave={() => {
                                    saveLeadData("source_id", leadInfoData?.data?.source_id, 'Source')
                                    // handleEditActive("source", false)
                                }}
                                menuPlacement="bottom"
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                editActive={editActive.createDate}
                                setEditActive={(activeState) => handleEditActive("create date", activeState)}
                                heading="Created Date"
                                name="Created Date"
                                placeholder="Created Date"
                                isEditable={false}
                                value={moment(customerInfoData.data.created_on).add('5:30').format('h:mm a, Do MMM YYYY')}
                                type="text"
                                onValueChange={(value) => handleValueChange("created_on", value)}
                                onSave={() => {
                                    saveCustomerData("created_on", customerInfoData.data.created_on, 'Created Date')
                                    // handleEditActive("create date", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <DynamicEditinput
                                heading="Assigned User"
                                isEditable={false}
                                value={leadInfoData && leadInfoData.assignedTo ? leadInfoData.assignedTo.name : "-"}
                                enableEditing={false}
                                onClickHandler={() => { }}
                            />
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 name-input">
                            <DynamicMobileInput
                                editActive={editActive.alt_mobile}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('alternate_mobile')
                                    }
                                    handleEditActive("alt_mobile", activeState)
                                }}
                                inputKey="alternet_no"
                                heading="Alt Mobile No"
                                placeholder="Alt Mobile No"
                                isEditable={true}
                                enableEditing={false}
                                // countryCode='+91'
                                value={customerInfoData.data.alternate_mobile}
                                onValueChange={({ phone }) => handleValueChange("alternate_mobile", phone)}
                                // handleBlur={(e) => handleValueChange("alternate_mobile", `${e.target.value ? '+91' + e.target.value : ''}`)}
                                onSave={() => {
                                    saveCustomerData("alternate_mobile", customerInfoData.data.alternate_mobile, 'Alt Mobile No')
                                    // handleEditActive("alt_mobile", false)
                                }}
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <ReengageSourceView
                                heading="Reengage Source"
                                name="Reengage Source"
                                infoData={reengageSource}

                            />
                            {/* <DynamicDropdown

                                editActive={editActive.possession}
                                setEditActive={(activeState) => {
                                    if (!activeState) {
                                        handleCloseCustomerInfo('possession')
                                    }
                                    handleEditActive("possession", activeState)
                                }}
                                heading="Possession Preference "
                                name="Possession Preference "
                                placeholder="Possession Preference "
                                isEditable={true}
                                value={selectedValue(props.customerInfo?.enum_possession, customerInfoData.data.possession)}
                                optionConfig={props.customerInfo?.enum_possession}
                                onValueChange={(value) => handleValueChange("possession", value.value)}
                                onSave={() => {
                                    saveCustomerData("possession", customerInfoData.data.possession, 'Possession Preference')
                                    // handleEditActive("possession", false)
                                }}
                                menuPlacement="top"
                                userProfile={userProfile}
                                leadAccess={props.leadAccess}
                            /> */}
                        </div>
                    </div>
                    {/*<div className="row mb-24">

                         <div className="col">
                            <DynamicEditinput
                                editActive={editActive.configuration}
                                setEditActive={(activeState) => handleEditActive("configuration", activeState)}
                                heading="Configuration"
                                name="Configuration"
                                placeholder="Configuration"
                                isEditable={true}
                                value={customerInfoData.data.configuration}
                                type="text"
                                onValueChange={(value) => handleValueChange("configuration", value)}
                                onSave={() => {
                                    saveCustomerData("configuration", customerInfoData.data.configuration)
                                    handleEditActive("configuration", false)
                                }}
                            />
                        </div> 
                    </div>*/}

                </div>
                {/* Next page slide */}
                {/* <div className={props.currentPage === 2 ? '' : 'd-none'}>
                    <div className="row mb-24">
                        
                        <div className="col">
                            <DynamicDropdown
                                editActive={editActive.property_type}
                                setEditActive={(activeState) => handleEditActive("property_type", activeState)}
                                heading="Property Type"
                                name="Property Type"
                                placeholder="Property Type"
                                 isEditable={true}
                                 value={selectedValue(props.customerInfo?.enum_property_type,  customerInfoData.data.property_type) }
                                 optionConfig={props.customerInfo?.enum_property_type}
                                 onValueChange={(value) => handleValueChange("property_type", value.value)}
                                 onSave={() => {
                                    saveCustomerData("property_type", customerInfoData.data.property_type)
                                    handleEditActive("property_type", false)
                                 }}
                             />
                        </div>
                        <div className="col">
                             <DynamicDropdown
                                editActive={editActive.requirement_type}
                                setEditActive={(activeState) => handleEditActive("requirement_type", activeState)}
                                heading="Requirement Type"
                                name="Requirement Type"
                                placeholder="Requirement Type"
                                isEditable={true}
                                value={selectedValue(props.customerInfo?.enum_property_category, customerInfoData.data.property_category)}
                                optionConfig={props.customerInfo?.enum_property_category}
                                onValueChange={(value) => handleValueChange("property_category", value.value)}
                                onSave={() => {
                                     saveCustomerData("property_category", customerInfoData.data.property_category)
                                     handleEditActive("requirement_type", false)
                                 }}
                             />
                        </div>
                    </div>
                    <div className="row mb-24">
                        
                        <div className="col">
                            <DynamicEditinput
                                heading="Lead Location"
                                editActive={editActive.lead_location}
                                setEditActive={(activeState) => handleEditActive("lead_location", activeState)}
                                isEditable={true}
                                value={customerInfoData.data.preferred_location}
                                enableEditing={false}
                                onClickHandler={() => {}}
                                onValueChange={(value) => handleValueChange("preferred_location", value)}
                                onSave={() => {
                                     saveCustomerData("preferred_location", customerInfoData.data.preferred_location)
                                     handleEditActive("lead_location", false)
                                 }}
                            />
                        </div>
                        <div className="col">
                            <TextCalendar
                                inputKey="expected_close_date"
                                heading="Expected Close Date"
                                placeholder="Expected Close Date"
                                editActive={editActive.expected_close_date}
                                isEditable={true}
                                enableEditing={false}
                                value={leadInfoData && leadInfoData.data ? leadInfoData.data?.expected_close_date : '-'}
                                // dateValue={this.props.leadDetail?.data?.expected_close_date ? moment(this.props.leadDetail?.data?.expected_close_date).add(1, 'day') : moment()}
                                onValueChange={(value) => handleLeadValueChange('expected_close_date', value)}
                                onEditClick={() => handleEditActive("expected_close_date", true)}
                                editDeactive={() => handleEditActive("expected_close_date", false)}
                                onSave={() => {
                                    saveLeadData("expected_close_date", leadInfoData.data?.expected_close_date)
                                    handleEditActive("expected_close_date", false)
                                }}   
                            />
                        </div>
                    </div>
                    <div className="row mb-24">
                        <div className="col">
                            <DynamicEditinput
                                heading="Last Updated Date"
                                isEditable={false}
                                value={leadInfoData.last_followup_date}
                                enableEditing={false}
                                onClickHandler={() => { }}
                            />
                        </div>
                        <div className="col">
                            <DynamicEditinput
                                heading="Last Site Visit Done"
                                isEditable={false}
                                value={"-"}
                                enableEditing={false}
                                onClickHandler={() => { }}
                            />
                        </div>
                        
                        <div className="col">
                            <DynamicEditinput
                                heading="Last Updated By"
                                isEditable={false}
                                // value={this.props.leadDetail?.updatedBy?.name}
                                // value={leadInfoData.updated_by}
                                value={leadInfoData && leadInfoData.data ? leadInfoData.data.updated_by : "-"}
                                enableEditing={false}
                                onClickHandler={() => { }}
                            />
                        </div>
                    </div>
                </div> */}
                {/* <div className={props.currentPage === 3 ? '' : 'd-none'}>
                    <div className="row mb-24">
                        
                    </div>
                </div> */}
            </div>
            {showLeadDuplicationModal &&
                <LeadDuplicateModal
                    show={showLeadDuplicationModal}
                    closeModal={closeLeadDuplicationModal}
                    duplicateData={duplicateData}
                    handleLeadRoutes={handleLeadRoutes}
                    leadChangedFrom='detailPage'
                />}
        </>
    )
}
