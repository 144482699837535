import React, { useEffect, useState } from 'react'
import { getProjectPaymentPlans } from '../../services/public/public.service';
import { updateBookingPaymentPlan, updateEOIPaymentPlan } from '../../services/private/inventory.service';
import { createDatewithTime } from '../../helpers/date';
import { toast } from 'react-toastify';
import { toINRFormat } from '../../helpers/helpers';

const Paymentplan = ({ setSteps, eoiId, selectedProject, unit, data, totalPrice, selectedBooking, setPaymentPlanForRaiseDemand }) => {
    const [paymentPlans, setPaymentPlans] = useState([]);
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState({});
    const [allPaymentplans, setAllPaymentPlans] = useState([]);

    const getPaymentPlans = async () => {
        const res = await getProjectPaymentPlans(selectedProject.uuid);
        if (res.data.status === 200) {
            setPaymentPlans(res.data.data);
            setAllPaymentPlans(res.data.data);
        }
    }

    useEffect(() => {
        if ((selectedBooking && Object.keys(selectedBooking).length > 0) && (selectedBooking?.booking?.approval_status &&
            !(selectedBooking?.booking?.approval_status === 'rejected' || selectedBooking?.booking?.approval_status === 'cancelled'))) {
            const { paymentPlan } = selectedBooking;
            if (paymentPlan) {
                let plans = { ...paymentPlan, uuid: paymentPlan?.project_payment_plan }
                setPaymentPlans([plans]);
                setSelectedPaymentPlan(plans);
            }
        }
    }, [selectedBooking, allPaymentplans, data])

    useEffect(() => {
        if (selectedProject.uuid) {
            getPaymentPlans();
        }

    }, [selectedProject])

    const hanndleSubmit = async () => {
        if (Object.keys(selectedPaymentPlan).length === 0) {
            toast.warning('Please Select Payment Plan.');
            return;
        }
        const res = await updateEOIPaymentPlan(eoiId, { plan_id: selectedPaymentPlan.uuid });
        if (res.data.status === 200) {
            console.log(res.data.data, 'payment plan page')
            setPaymentPlanForRaiseDemand(selectedPaymentPlan)
            setSteps('EOIOther')
        }
    }

    return (
        <>
            <div className='!pt-5 sm:!px-6 px-3 pb-8 h-full overflow-auto'>
                <h2 className="inter text-textblack text-lg !mb-5 font-semibold">Choose Payment Plan for {selectedProject?.name}</h2>
                <div className='flex flex-col gap-5'>
                    {
                        paymentPlans.map((plan, index) => (
                            <div className='!border border-grayLight rounded-lg !p-4 hover:!border-primary hover:bg-[#F9FAFD]' onClick={() => setSelectedPaymentPlan(plan)}>
                                <div className='flex justify-between inter items-center !mb-6'>
                                    <div className='flex !gap-2 items-center'>
                                        <h3 className='text-base text-textblack font-semibold m-0'>{plan?.name}</h3>
                                        <h5 className='text-sm text-textblack font-normal m-0'>{plan?.milestones?.length} Milestones</h5>
                                    </div>
                                    <div className='flex items-center'>
                                        <input
                                            type="radio"
                                            name="payment"
                                            id=""
                                            checked={selectedPaymentPlan.uuid === plan.uuid}
                                            // onChange={() => setSelectedPaymentPlan(plan)}
                                            className='w-6 h-6' />
                                    </div>
                                </div>
                                <div className='def-table plantable h-auto !overflow-auto !p-0'>
                                    <table className="table m-0">
                                        <thead>
                                            <tr>
                                                <th className='w-auto text-left !min-w-fit !max-w-fit'>Milestones</th>
                                                <th>Description</th>
                                                <th>Due Date</th>
                                                <th>Part Payment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                plan?.milestones?.length > 0 && plan.milestones.map((milestone, index) => (

                                                    <tr>
                                                        <td className='!w-auto text-left !min-w-fit	!pl-2 !max-w-fit' style={{ paddingLeft: '8px' }}>{index + 1}</td>
                                                        <td>{milestone.description}</td>
                                                        <td>{milestone?.due_date ? createDatewithTime(new Date(milestone.due_date)) : '-'}</td>
                                                        <td>{milestone.payment_percentage ? `${milestone.payment_percentage}%` : `₹ ${toINRFormat(milestone.payment_amount)}`}</td>
                                                    </tr>

                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))
                    }

                    {/* <div className='!border border-grayLight rounded-lg !p-4 hover:!border-primary hover:bg-[#F9FAFD]'>
                        <div className='flex justify-between inter items-center !mb-6'>
                            <div className='flex !gap-2 items-center'>
                                <h3 className='text-base text-textblack font-semibold m-0'>Payment Plan 1</h3>
                                <h5 className='text-sm text-textblack font-normal m-0'>12 Milestones</h5>
                            </div>
                            <div className='flex items-center'>
                                <input type="radio" name="" id="" className='w-6 h-6' />
                            </div>
                        </div>
                        <div className='def-table plantable h-auto !overflow-auto !p-0'>
                            <table className="table m-0">
                                <thead>
                                    <tr>
                                        <th className='w-auto text-left !min-w-fit'>Milestones</th>
                                        <th>Description</th>
                                        <th>Due Date</th>
                                        <th>%</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='!w-auto text-left !min-w-fit	!pl-2' style={{ paddingLeft: '8px' }}>Milestone 1</td>
                                        <td>Lorem ipsum dolor sit amet..</td>
                                        <td>07/06/2023</td>
                                        <td>10%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}

                    <div className='!border-t border-b !border-grayLigh !pt-4 !pb-4 '>
                        <div className='flex flex-row justify-between items-center inter'>
                            <div className="sm:text-xl text-lg leading-6 text-textblack font-semibold">Grand Total</div>
                            <div className='sm:text-xl text-lg font-semibold leading-6 text-textblack text-right'>
                                <div>₹ {toINRFormat(totalPrice)}</div>
                                <div className='sm:text-sm text-xs text-black700 font-normal'>For {data?.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                <div>
                    <h3 className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Total Price : ₹ {toINRFormat(totalPrice)}</h3>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For {unit?.name}</p>
                </div>
                <div className='flex gap-3 items-center justify-end'>
                    <button
                        className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center'
                        onClick={() => setSteps('Additional')}
                    >
                        Previous
                    </button>
                    <button
                        className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center'
                        onClick={hanndleSubmit}
                    >
                        Next
                    </button>

                </div>
            </div>
        </>
    )
}

export default Paymentplan
