import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as PlusPrimary } from '../../assets/icons/PlusPrimary.svg';

import InputText from '../InputGroup/InputText';
import DateTimePicker from '../InputGroup/DateTime';
import InputTextarea from '../InputGroup/InputTextarea';
import { ReactComponent as Uploadicon } from '../../assets/icons/uploadicon.svg';
import { ReactComponent as Upload } from '../../assets/icons/upload.svg';
import { ReactComponent as Imageplacehoder } from '../../assets/icons/Imageplacehoder.svg';
import { ReactComponent as FilePdf } from '../../assets/icons/FilePdf.svg';
import { ReactComponent as DownICon } from '../../assets/icons/Down.svg';
import { ReactComponent as Pluswhite } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import pdfimg from '../../assets/icons/pdfview.jpg';
import { eoiAdditionalInfo, getUnits, insertEOIDone } from '../../services/private/inventory.service';
import { getProjectsForFilter } from '../../services/private/leads.service';
import Consumer from '../../helpers/context'
import DatePicker from 'react-datepicker';
import { ReligionList, SalutationList, CountryList, PaymentModeList, customerDocList, coapplicantDocs, CustomerDocumentList, RelationList, PaidBy, BankList } from '../../helpers/enums';

import MobileInput from '../InputGroup/MobileInput';
import { uploadFile } from '../../services/public/public.service';
import style from '../../modules/Settings//Projects/style.module.css'
import '../../modules/Settings/Projects/style.css';
import InputRupees from '../InputGroup/InputRupees';
import moment from 'moment';
import { toast } from 'react-toastify';
import InputSelect from '../InputGroup/InputSelect';
import { validationSchema } from './BasicDetailValidation';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

let customerDoc = [...customerDocList, {
    fileName: 'EOI Form',
    type: '',
    url: '',
    size: '',
    is_private: false,
    extension: '',
    file_type: '',
    category: 'EOI Form',
    showCategory: false
},]

const Purpose = [
    {
        value: 'End use',
        label: 'End use'
    },
    {
        value: 'Investment',
        label: 'Investment'
    }
]

const AdditionalInfo = ({ eoiId, setSteps, selectedBooking }) => {

    const [isSpanClassAdded, setIsSpanClassAdded] = useState(false);
    const [needCoapplicant, setNeedCoapplicant] = useState(false);
    const [needBuyer, setNeedBuyer] = useState(false);
    const [sameAsCustomerCommunication, setSameAsCustomerCommunication] = useState(false);
    const [coapplicant, setCoapplicant] = useState([{}]);
    const [coApplicantUploadedDocs, setCoapplicantDocs] = useState([[...coapplicantDocs]]);
    const [sameAsCommunication, setSameAsCommunication] = useState(false);
    const [customerDocuments, setCustomerDocuments] = useState([]);

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            customer_salutation: {
                label: 'MR',
                value: 'MR'
            },
            customer_dob: '',
            customer_father_name: '',
            customer_mother_name: '',
            customer_nationality: {
                label: 'Indian',
                value: 'indian'
            },
            customer_religion: '',
            customer_email: '',
            customer_mobile: '',
            customer_alternate_mobile: '',
            customer_profession: '',
            customer_communication_address: '',
            customer_permanent_address: '',
            customer_landmark: '',
            project: '',
            lead_source: '',
            scheduleDate: '',
            followupScheduleDate: '',
            validity: '',
            customer_company_name: '',
            customer_income: '',
            customer_designation: '',
            annual_income: '',
            customer_purpose: '',
            customer_office_mobile: '',
            customer_office_address: '',
            buyer_name: '',
            buyer_email: '',
            buyer_mobile: '',
            buyer_landmark: '',
            buyer_permanent_address: '',
            buyer_communication_address: '',
            buyer_dob: '',
            account_no: '',
            bank_name: '',
            ifsc_code: '',
            branch: '',
            swift_code: '',
            nre_account_no: '',
            nro_account_no: '',
            fcnr_account_no: '',
            cheque_no: '',
            cheque_date: '',
            document_id: '',
            cheque_amount: '',
            remarks: '',
            customer_aadhaar_no: '',
            customer_pan_no: '',
            // customer_booking_form_no: '',
            customer_eoi_form_no: '',
            customer_passport_no: '',

        },
        // resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        if (selectedBooking && Object.keys(selectedBooking).length > 0) {
            const { customer, coapplicant, customerBankDetails, project, booking, customerDocuments, transactions } = selectedBooking;
            reset({
                customer_dob: customer?.customer_dob,
                customer_father_name: customer?.customer_father_name,
                customer_mother_name: customer?.customer_mother_name,
                customer_religion: customer?.customer_religion ? ReligionList.find(item => item.value === customer.customer_religion) : '',
                customer_alternate_mobile: customer?.customer_alternate_mobile || '',
                customer_profession: customer?.customer_profession,
                customer_company_name: customer?.customer_company_name,
                customer_income: customer?.customer_income,
                customer_designation: customer?.customer_designation,
                annual_income: customer?.annual_income,
                customer_purpose: customer?.customer_purpose,
                customer_office_mobile: customer?.customer_office_mobile || '',
                customer_office_address: customer?.customer_office_address,
                buyer_name: customer?.buyer_name,
                buyer_email: customer?.buyer_email,
                buyer_mobile: customer?.buyer_mobile,
                buyer_landmark: customer?.buyer_landmark,
                buyer_permanent_address: customer?.buyer_permanent_address,
                buyer_dob: customer?.buyer_dob,
                buyer_communication_address: customer?.buyer_communication_address,
                buyer_relation: customer?.buyer_relation ? { label: customer?.buyer_relation, value: customer?.buyer_relation } : '',
                // account_no: customerBankDetails?.account_no,
                // bank_name: customerBankDetails?.bank_name,
                // ifsc_code: customerBankDetails?.ifsc_code,
                // branch: customerBankDetails?.branch,
                // swift_code: customerBankDetails?.swift_code,
                // nre_account_no: customerBankDetails?.nre_account_no,
                // nro_account_no: customerBankDetails?.nro_account_no,
                // fcnr_account_no: customerBankDetails?.fcnr_account_no,
                // cheque_no: customerBankDetails?.cheque_no,
                // cheque_date: customerBankDetails?.cheque_date,
                // document_id: customerBankDetails?.document_id,
                // cheque_amount: customerBankDetails?.cheque_amount,
                customer_aadhaar_no: customer?.customer_aadhaar_no,
                customer_pan_no: customer?.customer_pan_no,
                // customer_booking_form_no:,
                // customer_eoi_form_no:,
                customer_passport_no: customer?.customer_passport_no
            });
            if (customer?.buyer_name || customer?.buyer_email || customer?.buyer_mobile || customer?.buyer_landmark || customer?.buyer_permanent_address || customer?.buyer_dob || customer?.buyer_communication_address) {
                setNeedBuyer(true)
            }
            setCoapplicant(coapplicant?.length > 0 ? coapplicant : [{}]);
            setNeedCoapplicant(coapplicant?.length > 0 ? true : false);
            setCustomerDocuments(customerDocuments ? customerDocuments : []);

            // prefill co-applicant documents 
            if (coapplicant?.length > 0) {
                let copplicant = [];
                coapplicant?.forEach((item, index) => {
                    let docs = []
                    if (item?.documents?.length > 0) {
                        coapplicantDocs.forEach(doc => {
                            item.documents.forEach(itemDoc => {
                                if (itemDoc.meta === doc.fileName) {
                                    docs.push(itemDoc);
                                } else {
                                    docs.push(doc);
                                }
                            })
                        })
                    }
                    copplicant.push(docs);
                })
                setCoapplicantDocs(copplicant);
            }

            // prefill customer documents
            if (customerDocuments?.length > 0) {
                customerDocuments?.forEach(item => {
                    customerDoc.forEach((doc, index) => {
                        if (doc.fileName === item?.meta) {
                            customerDoc[index] = {
                                fileName: item.meta,
                                meta: item.meta,
                                type: item.file_type,
                                url: item.url,
                                size: item.size,
                                is_private: false,
                                extension: item.extension,
                                file_type: item.file_type,
                                category: item.category,
                                showCategory: false
                            }
                        }
                        if (item?.docType === 'other') {
                            customerDoc.push(item);
                        }
                    })
                })
            }
        }
    }, [selectedBooking]);

    const buyerCommunicationAddress = watch('buyer_communication_address');

    const toggleSpanClass = () => {
        setIsSpanClassAdded(!isSpanClassAdded);
    };

    const handleUploadOtherDocs = async (e, state, setState) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: '',
                showCategory: true,
                docType: 'other'
            }
            setState([...state, newFile])
            customerDoc.push(newFile)
            // if (!(selectedIndex >= 0)) {
            //     updateMediaFile(newFile);
            // }

        }
    }

    const handleCustomerDocumentUpload = async (e, fileName, index, state, setState) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: fileName,
                meta: fileName,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: customerDoc[index].category || ''
            }
            const stateIndex = state.findIndex(f => f.fileName === fileName)
            if (stateIndex > -1) {
                let docState = [...state];
                docState[stateIndex] = newFile;
                setState(docState);
            } else {
                setState([...state, newFile])
            }
            customerDoc[index] = newFile
            // if (!(selectedIndex >= 0)) {
            //     updateMediaFile(newFile);
            // }

        }
    }

    const handleCoapplicantDocumentUpload = async (e, fileName, fileIndex, index, state, setState, docType) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: fileName || file.name,
                ...(!docType) && { meta: fileName },
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: !docType ? coApplicantUploadedDocs[fileIndex][index]?.category : '',
                docType: docType
            }
            let newDocs = [...state];
            if (!newDocs[fileIndex][index]) {
                newDocs[fileIndex] = [];
                newDocs[fileIndex].push(newFile);
            } else {
                newDocs[fileIndex][index] = newFile;
            }
            setState(newDocs)
            // if (index >= 0) {
            //     coapplicantDocs[index] = newFile
            // } else {
            //     newFile.docType = 'other'
            //     coapplicantDocs.push(newFile)
            // }
            // if (!(selectedIndex >= 0)) {
            //     updateMediaFile(newFile);
            // }

        }
    }

    const onFormSubmit = async (values) => {
        let coapplicantData = coapplicant.filter(item => Object.keys(item).length > 0);

        coapplicantData.forEach((item, index) => {
            item.documents = coApplicantUploadedDocs[index].filter(doc => doc.url)
        })
        const payload = {
            customer: {
                // customer_landmark: values.customer_landmark || null,
                customer_nationality: values.customer_nationality?.value || null,
                customer_religion: values.customer_religion.value || null,
                customer_dob: values.customer_dob || null,
                customer_father_name: values.customer_father_name || null,
                customer_mother_name: values.customer_mother_name || null,
                customer_profession: values.customer_profession || null,
                customer_company_name: values.customer_company_name || null,
                customer_income: values.customer_income || null,
                customer_designation: values.customer_designation || null,
                customer_office_address: values.customer_office_address || null,
                customer_office_mobile: values.customer_office_mobile || null,
                customer_purpose: values.customer_purpose || null,
                buyer_salutation: values.buyer_salutation?.value || null,
                buyer_name: values.buyer_name || null,
                buyer_email: values.buyer_email || null,
                buyer_mobile: values.buyer_mobile || null,
                buyer_communication_address: values.buyer_communication_address || null,
                buyer_permanent_address: values.buyer_permanent_address || null,
                buyer_landmark: values.buyer_landmark || null,
                buyer_dob: values.buyer_dob || null,
                buyer_relation: values.buyer_relation?.value || null,
                customer_aadhaar_no: values.customer_aadhaar_no || null,
                customer_pan_no: values.customer_pan_no || null,
                customer_eoi_form_no: values.customer_eoi_form_no || null,
                customer_passport_no: values.customer_passport_no || null,
                documents: customerDocuments
            },
            coapplicant: coapplicantData,

        }
        console.log(payload, 'pyaload')
        // setSteps('EOIPaymentplan');
        const res = await eoiAdditionalInfo(eoiId, payload)
        if (res.data.status === 200) {
            setSteps('EOIPaymentplan');
        }
    }

    return (
        <>

            <form onSubmit={handleSubmit(onFormSubmit)} id='eoi-unit-additional' className='!pt-5 md:!px-6 !px-4 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                <div id="accordion">
                    <div className="">
                        <div
                            className="flex items-center justify-between cursor-pointer"
                            id="headingOne"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            onClick={toggleSpanClass}
                        >
                            <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                Customer Details
                            </h5>
                            <span className={`${isSpanClassAdded ? '' : 'rotate-180'}`}><DownICon /></span>
                        </div>
                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 !mt-3'>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_nationality'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='Nationality'
                                                    placeholder='Select Nationality'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    onChange={onChange}
                                                    value={value}
                                                    options={CountryList}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_religion'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label='Religion'
                                                    placeholder='Select Religion'
                                                    inputclass='m-0 inter'
                                                    isMulti={false}
                                                    styles={customStyles}
                                                    options={ReligionList}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_dob'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                {/* <DateTimePicker
                                                    label='Date of Birth'
                                                    inputclass='mt-0'
                                                    value={value}
                                                    onValueChange={onChange}
                                                /> */}
                                                <div className='form-group'>
                                                    <label>Date of Birth</label>
                                                    <DatePicker
                                                        selected={value ? new Date(value) : ''}
                                                        placeholderText={'Select Date'}
                                                        onChange={onChange}
                                                        dateFormat="dd MMM yyyy"
                                                        className={`form-control text-input`}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_father_name'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Father Name"
                                                    placeholder="Enter Father Name"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_mother_name'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Mother Name"
                                                    placeholder="Enter Mother Name"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_profession'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Profession"
                                                    placeholder="Enter Profession"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_company_name'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Company Name"
                                                    placeholder="Enter Company Name"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_income'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputRupees
                                                    type="text"
                                                    label="Annual Income"
                                                    placeholder="Enter Income"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                    handleBlur={() => { }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_designation'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    type="text"
                                                    label="Designation"
                                                    placeholder="Enter Designation"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_office_mobile'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <MobileInput
                                                    type="text"
                                                    label="Office Contact No."
                                                    placeholder="Enter Office Contact No."
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e.phone)
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='customer_purpose'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    type="text"
                                                    label="Purchase Purpose"
                                                    placeholder="Enter Purchase Purpose"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value ? Purpose.find(item => item.value === value) : ''}
                                                    options={Purpose}
                                                    onChange={(value) => { onChange(value.value) }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='!mt-4'>
                        <div className='flex items-center !my-3'>
                            <h2 className='text-textblack text-lg font-semibold inter mr-4'>Do you want to have separate buyer details?</h2>
                            <div className='flex'>
                                <div className='mr-4 cursor-pointer'>
                                    <input
                                        type="radio"
                                        id='buyer_yes'
                                        name='buyer'
                                        className='mr-2'
                                        value='yes'
                                        checked={needBuyer}
                                        onChange={() => setNeedBuyer(true)}
                                    />
                                    <label className='cursor-pointer font-semibold' htmlFor="buyer_yes">Yes</label>
                                </div>
                                <div >
                                    <input
                                        type="radio"
                                        id='buyer_no'
                                        name='buyer'
                                        value='no'
                                        className='mr-2'
                                        checked={!needBuyer}
                                        onChange={() => setNeedBuyer(false)}
                                    />
                                    <label className='cursor-pointer font-semibold' htmlFor="buyer_no">No</label>
                                </div>
                            </div>
                        </div>
                        {needBuyer && <>
                            <div
                                className="flex items-center justify-between cursor-pointer"
                                id="headingtwo"
                                data-toggle="collapse"
                                data-target="#collapsetwo"
                                aria-expanded="false"
                                aria-controls="collapsetwo"
                                onClick={() => {
                                    console.log(document.getElementById('buyerName'), ' document.getElementById(')
                                    // document.getElementById('buyerName').focus();
                                    window.setTimeout(function () {
                                        document.getElementById('buyerName').focus();
                                    }, 0);
                                    // toggleSpanClass()
                                }}
                            >
                                <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                    Buyer Details
                                </h5>

                                <span
                                // className={`${isSpanClassAdded ? 'rotate-180' : ''}`}
                                ><DownICon /></span>

                            </div>

                            <div id="collapsetwo" className="collapse " aria-labelledby="headingtwo" data-parent="#accordion">
                                <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4 !mt-4'>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_name'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        label="Name"
                                                        placeholder="Enter Buyer Name"
                                                        type="text"
                                                        name="buyerName"
                                                        id="buyerName"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_email'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        label="Email"
                                                        placeholder="Enter Buyer Email Id"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_mobile'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <MobileInput
                                                        label="Mobile"
                                                        placeholder="Enter Buyer Mobile Number"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={(e) => {
                                                            onChange(e.phone)
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>

                                        <Controller
                                            control={control}
                                            name='buyer_communication_address'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        label='Communication Address'
                                                        placeholder="Enter Communication Address"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <div className='!mb-2 flex items-center justify-between leading-[22px] flex-wrap'>
                                            <span className='text-sm text-black700 inter'>Permanent Address</span>
                                            <div className='flex items-center text-sm text-black700 inter'>
                                                <input type="checkbox"
                                                    id='buyer_address'
                                                    value={sameAsCommunication}
                                                    onChange={() => {
                                                        setSameAsCommunication(!sameAsCommunication)
                                                        if (!sameAsCommunication) {
                                                            setValue('buyer_permanent_address', buyerCommunicationAddress)
                                                        } else {
                                                            setValue('buyer_permanent_address', '')
                                                        }
                                                    }} className='!mr-2 ' />
                                                <label htmlFor='buyer_address' className='!p-1 !border !border-grayLight rounded-md inter m-0'>Same as Communication</label>
                                            </div>
                                        </div>
                                        <div>
                                            <Controller
                                                control={control}
                                                name='buyer_permanent_address'
                                                render={({
                                                    field: { onChange, onBlur, value, ref },
                                                }) => (
                                                    <>
                                                        <InputText
                                                            // label="Permanent Address"
                                                            placeholder="Enter Permanent Address"
                                                            type="text"
                                                            name="name"
                                                            inputclass='m-0 inter'
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_landmark'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputText
                                                        label="Nearby Landmark"
                                                        placeholder="Enter Nearby Landmark"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_dob'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    {/* <DateTimePicker
                                                    label='Date of Birth'
                                                    inputclass='mt-0'
                                                    value={value}
                                                    onValueChange={onChange}
                                                /> */}
                                                    <div className='form-group'>
                                                        <label>Date of Birth</label>
                                                        <DatePicker
                                                            selected={value ? new Date(value) : ''}
                                                            placeholderText={'Select Date'}
                                                            onChange={onChange}
                                                            dateFormat="dd MMM yyyy"
                                                            className={`form-control text-input`}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            maxDate={new Date()}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            name='buyer_relation'
                                            render={({
                                                field: { onChange, onBlur, value, ref },
                                            }) => (
                                                <>
                                                    <InputSelect
                                                        label="Relation"
                                                        placeholder="Select Relation"
                                                        type="text"
                                                        name="name"
                                                        inputclass='m-0 inter'
                                                        value={value}
                                                        options={RelationList}
                                                        onChange={onChange}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                    <div className='!mt-4'>
                        <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                            Customer Document
                        </h5>

                        <div className='grid sm:grid-cols-3 grid-cols-1 gap-x-6	gap-y-4'>
                            <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                                onClick={() => {
                                    document.getElementById(`upload-customer-doc`).click();
                                }}>
                                <input type="file" className='d-none' id={`upload-customer-doc`} accept="application/pdf, image/*" onChange={(e) => handleUploadOtherDocs(e, customerDocuments, setCustomerDocuments)} />
                                <figure className='!table !mx-auto'>
                                    <Uploadicon className='h-[64px] mt-2' />
                                </figure>
                                <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload More Documents </p>
                                <p className='text-center !text-black text-sm font-medium inter'>More Documents </p>
                            </div>
                            {
                                customerDoc.map((doc, index) => (
                                    <React.Fragment key={`${doc.fileName}`}>
                                        <input type="file" className='d-none' accept="application/pdf, image/*" id={`upload-customer-${index}`} onChange={(e) => {
                                            handleCustomerDocumentUpload(e, doc.fileName, index, customerDocuments, setCustomerDocuments)
                                        }} />
                                        {!doc?.url ? <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                                            onClick={() => {
                                                document.getElementById(`upload-customer-${index}`).click();
                                            }}>
                                            <figure className='!table !mx-auto'>
                                                <Uploadicon className='h-[64px] mt-2' />
                                            </figure>
                                            <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload {doc.fileName} </p>
                                            <p className='text-center !text-black text-sm font-medium inter'> {doc.fileName} </p>
                                        </div> :
                                            <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                                <figure className='mb-0 !table !mx-auto relative group'>

                                                    {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                        <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                        : <img src={pdfimg} className='h-[100px] w-[175px]' />}
                                                    <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                        <button
                                                            type='button'
                                                            className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                            onClick={() => {
                                                                document.getElementById(`upload-customer-${index}`).click();
                                                            }}
                                                        ><PluswhiteIcon />Replace</button>

                                                        <button
                                                            type='button'
                                                            className={`${style.delbtn} inter border-0 bg-white`}
                                                            onClick={() => {
                                                                let newFiles = [...customerDocuments];
                                                                newFiles.splice(index, 1);
                                                                setCustomerDocuments(newFiles);
                                                                if (customerDoc[index]?.docType === 'other') {
                                                                    customerDoc.splice(index, 1);
                                                                } else {
                                                                    customerDoc[index].url = ''
                                                                    customerDoc[index].fileName = ''
                                                                    customerDoc[index].type = ''
                                                                    customerDoc[index].size = ''
                                                                    customerDoc[index].extension = ''
                                                                    customerDoc[index].file_type = ''
                                                                }
                                                            }}
                                                        ><TrashIcon /></button>
                                                    </div>
                                                </figure>
                                                <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'>
                                                    {/* <FilePdf />  */}
                                                    {doc.category}
                                                </p>
                                                {doc.showCategory && <InputSelect
                                                    // label='Source'
                                                    placeholder='Select Category'
                                                    inputclass='m-0'
                                                    styles={customStyles}
                                                    value={doc.category ? CustomerDocumentList.find(item => item.value === doc.category) : ''}
                                                    options={CustomerDocumentList}
                                                    onChange={(value) => {
                                                        let customers = [...customerDocuments]
                                                        let fileIndex = customers.findIndex(item => item.fileName === doc.fileName);
                                                        customers[fileIndex].category = value.value
                                                        setCustomerDocuments(customers)
                                                        customerDoc[index].category = value.value
                                                    }}
                                                />}
                                                {doc.fileName === 'Aadhar Front' && <div>
                                                    <Controller
                                                        control={control}
                                                        name='customer_aadhaar_no'
                                                        render={({
                                                            field: { onChange, onBlur, value, ref },
                                                        }) => (
                                                            <>
                                                                <InputText
                                                                    type="text"
                                                                    placeholder="Enter Aadhaar Number"
                                                                    inputclass='m-0 inter'
                                                                    onChange={(e) => {
                                                                        onChange(e.target.value
                                                                            .replace(/^0+/g, '')
                                                                            .replace(/[^0-9]/g, ''))
                                                                    }}
                                                                    value={value}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>}
                                                {doc.fileName === 'PAN' && <div>
                                                    <Controller
                                                        control={control}
                                                        name='customer_pan_no'
                                                        render={({
                                                            field: { onChange, onBlur, value, ref },
                                                        }) => (
                                                            <>
                                                                <InputText
                                                                    type="text"
                                                                    placeholder="Enter PAN Number"
                                                                    inputclass='m-0 inter'
                                                                    onChange={onChange}
                                                                    value={value}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>}
                                                {doc.fileName === 'Booking Form' && <div>
                                                    <Controller
                                                        control={control}
                                                        name='customer_booking_form_no'
                                                        render={({
                                                            field: { onChange, onBlur, value, ref },
                                                        }) => (
                                                            <>
                                                                <InputText
                                                                    type="text"
                                                                    placeholder="Enter Booking Number"
                                                                    inputclass='m-0 inter'
                                                                    onChange={onChange}
                                                                    value={value}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>}
                                                {doc.fileName === 'Passport' && <div>
                                                    <Controller
                                                        control={control}
                                                        name='customer_passport_no'
                                                        render={({
                                                            field: { onChange, onBlur, value, ref },
                                                        }) => (
                                                            <>
                                                                <InputText
                                                                    type="text"
                                                                    placeholder="Enter Passport Number"
                                                                    inputclass='m-0 inter'
                                                                    onChange={onChange}
                                                                    value={value}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>}

                                            </div>}
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                    {/* Co applicant  */}
                    <div className='flex items-center !my-3'>
                        <h2 className='text-textblack text-lg font-semibold inter mr-4'>Do you want to add co-applicants?</h2>
                        <div className='flex'>
                            <div className='mr-4 cursor-pointer'>
                                <input
                                    type="radio"
                                    id='applicant_yes'
                                    name='coapplicant'
                                    className='mr-2'
                                    value='yes'
                                    checked={needCoapplicant}
                                    onChange={() => setNeedCoapplicant(true)}
                                />
                                <label className='cursor-pointer font-semibold' htmlFor="applicant_yes">Yes</label>
                            </div>
                            <div >
                                <input
                                    type="radio"
                                    id='applicant_no'
                                    name='coapplicant'
                                    value='no'
                                    className='mr-2'
                                    checked={!needCoapplicant}
                                    onChange={() => setNeedCoapplicant(false)}
                                />
                                <label className='cursor-pointer font-semibold' htmlFor="applicant_no">No</label>
                            </div>
                        </div>
                    </div>

                    {needCoapplicant && <>
                        {
                            coapplicant.map((applicant, index) => (
                                <React.Fragment key={String(index)}>
                                    <div className='!mt-4' >
                                        <div className='!mb-3 flex justify-between'>
                                            <h5 className=" text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                                Co-applicant {index + 1}
                                            </h5>
                                            {coapplicant.length > 1 &&
                                                <button className='border-0 bg-tr ' onClick={() => {
                                                    const applicants = [...coapplicant];
                                                    applicants.splice(index, 1);
                                                    const applicantDocs = [...coApplicantUploadedDocs]
                                                    applicantDocs.slice(index, 1);
                                                    setCoapplicantDocs(applicantDocs);
                                                    setCoapplicant(applicants);
                                                }}
                                                >
                                                    <CloseIcon />
                                                </button>}
                                        </div>

                                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                            <div>
                                                <label className='text-sm text-black700 !mb-2'>Name<sup>*</sup></label>
                                                <div className='d-flex gap-2'>
                                                    <div>
                                                        <InputSelect
                                                            placeholder='MR'
                                                            isMulti={false}
                                                            inputclass='w-[90px] basis-[90px] shrink-0	'
                                                            styles={customStyles}
                                                            options={SalutationList}
                                                            value={applicant.salutation ? SalutationList.find(i => i.value === applicant.salutation) : ''}
                                                            onChange={(value) => {
                                                                let co_applicant = [...coapplicant];
                                                                co_applicant[index]['salutation'] = value.value;
                                                                setCoapplicant(co_applicant)
                                                            }}
                                                        />


                                                    </div>
                                                    <div>

                                                        <InputText
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            name="name"
                                                            value={applicant?.name}
                                                            inputclass='m-0 inter'
                                                            onChange={(e) => {
                                                                let co_applicant = [...coapplicant];
                                                                co_applicant[index][e.target.name] = e.target.value;
                                                                setCoapplicant(co_applicant)
                                                            }}
                                                        />


                                                    </div>
                                                </div>
                                            </div>
                                            {/* <InputText
                                                    label="Name"
                                                    placeholder="Enter Name"
                                                    type="text"
                                                    name='name'
                                                    
                                                /> */}
                                            <InputText
                                                label="Email"
                                                placeholder="Enter Email"
                                                type="text"
                                                name='email'
                                                value={applicant.email}
                                                inputclass='m-0 inter'
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index][e.target.name] = e.target.value;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />
                                            <MobileInput
                                                label="Mobile"
                                                placeholder="Enter Mobile Number"
                                                type="text"
                                                name='mobile'
                                                value={applicant.mobile}
                                                inputclass='m-0 inter'
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index]['mobile'] = e.phone;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />
                                            <MobileInput
                                                label="Alternate Mobile No."
                                                placeholder="Enter Mobile Number No."
                                                type="text"
                                                inputclass='m-0 inter'
                                                name='alternate_mobile'
                                                value={applicant?.alternate_mobile}
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index]['alternate_mobile'] = e.phone;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />

                                            <InputText
                                                label='Communication Address'
                                                placeholder="Enter Communication Address"
                                                type="text"
                                                inputclass='m-0 inter'
                                                name='communication_address'
                                                value={applicant?.communication_address}
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index][e.target.name] = e.target.value;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />
                                            <div>
                                                <div className='!mb-2 flex items-center justify-between leading-[22px] flex-wrap'>
                                                    <span className='text-sm text-black700 inter'>Permanent Address</span>
                                                    <div className='flex items-center text-sm text-black700 inter'>
                                                        <input
                                                            type="checkbox"
                                                            className='!mr-2 '
                                                            id={`applicant_${index}`}
                                                            checked={applicant?.sameAsCommunication}
                                                            onChange={() => {
                                                                let co_applicant = [...coapplicant];
                                                                co_applicant[index]['sameAsCommunication'] = !co_applicant[index]['sameAsCommunication'];
                                                                if (co_applicant[index]['sameAsCommunication']) {
                                                                    co_applicant[index].permanent_address = co_applicant[index].communication_address
                                                                } else {
                                                                    co_applicant[index].permanent_address = ''
                                                                }
                                                                setCoapplicant(co_applicant)
                                                            }}
                                                        />
                                                        <label htmlFor={`application_${index}`} className='!p-1 !border !border-grayLight rounded-md inter m-0'>
                                                            Same as Communication
                                                        </label>
                                                    </div>
                                                </div>
                                                <InputText
                                                    // label="Permanent Address"
                                                    placeholder="Enter Permanent Address"
                                                    type="text"
                                                    name='permanent_address'
                                                    inputclass='m-0 inter'
                                                    value={applicant?.permanent_address}
                                                    onChange={(e) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index][e.target.name] = e.target.value;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                />
                                            </div>
                                            <InputText
                                                label="Nearby Landmark"
                                                placeholder="Enter Nearby Landmark"
                                                type="text"
                                                name='landmark'
                                                inputclass='m-0 inter'
                                                value={applicant?.landmark}
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index][e.target.name] = e.target.value;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />
                                            <InputSelect
                                                label='Nationality'
                                                placeholder='Select Nationality'
                                                inputclass='m-0 inter'
                                                isMulti={false}
                                                styles={customStyles}
                                                options={CountryList}
                                                value={applicant?.nationality ? CountryList.find(item => item.value === applicant.nationality) : {
                                                    label: "Indian",
                                                    value: "indian"
                                                }}
                                                onChange={(value) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index]['nationality'] = value.value || 'indian';
                                                    setCoapplicant(co_applicant)
                                                }}

                                            />
                                            <InputSelect
                                                label='Religion'
                                                placeholder='Select Religion'
                                                inputclass='m-0 inter'
                                                isMulti={false}
                                                styles={customStyles}
                                                options={ReligionList}
                                                value={applicant?.religion ? ReligionList.find(item => item.value === applicant.religion) : ''}
                                                onChange={(value) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index]['religion'] = value.value;
                                                    setCoapplicant(co_applicant)
                                                }}

                                            />
                                            <div className='form-group m-0'>
                                                <label>Date of Birth</label>
                                                <DatePicker
                                                    selected={applicant?.dob ? new Date(applicant?.dob) : ''}
                                                    placeholderText={'Select Date'}
                                                    onChange={(date) => {
                                                        let co_applicant = [...coapplicant];
                                                        co_applicant[index]['dob'] = date;
                                                        setCoapplicant(co_applicant)
                                                    }}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <InputText
                                                label="Father Name"
                                                placeholder="Enter Father Name"
                                                type="text"
                                                name='father_name'
                                                value={applicant?.father_name}
                                                inputclass='m-0 inter'
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index][e.target.name] = e.target.value;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />
                                            <InputText
                                                label="Mother Name"
                                                placeholder="Enter Mother Name"
                                                type="text"
                                                name='mother_name'
                                                value={applicant?.mother_name}
                                                inputclass='m-0 inter'
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index][e.target.name] = e.target.value;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />



                                            {/* <DateTimePicker
                                            label="Date of Birth"
                                            placeholder="Enter Age"
                                            inputclass='m-0 inter'
                                            value={applicant?.dob}
                                            onValueChange={(date) => {
                                                let co_applicant = [...coapplicant];
                                                co_applicant[index]['dob'] = date;
                                                setCoapplicant(co_applicant)
                                            }}

                                        /> */}

                                            <InputText
                                                label="Profession"
                                                placeholder="Enter Profession"
                                                type="text"
                                                inputclass='m-0 inter'
                                                name='profession'
                                                value={applicant?.profession}
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index][e.target.name] = e.target.value;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />
                                            <InputText
                                                label="Company Name"
                                                placeholder="Enter Company Name"
                                                type="text"
                                                inputclass='m-0 inter'
                                                name='company_name'
                                                value={applicant?.company_name}
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index][e.target.name] = e.target.value;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />
                                            <InputRupees
                                                label="Annual Income"
                                                placeholder="Enter Income"
                                                type="text"
                                                inputclass='m-0 inter'
                                                name='income'
                                                value={applicant?.income}
                                                onChange={(value) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index]['income'] = value;

                                                    setCoapplicant(co_applicant)
                                                }}
                                                handleBlur={() => { }}
                                            />
                                            <InputText
                                                label="Designation"
                                                placeholder="Enter Designation"
                                                type="text"
                                                inputclass='m-0 inter'
                                                name='designation'
                                                value={applicant?.designation}
                                                onChange={(e) => {
                                                    let co_applicant = [...coapplicant];
                                                    co_applicant[index][e.target.name] = e.target.value;
                                                    setCoapplicant(co_applicant)
                                                }}
                                            />


                                        </div>
                                    </div>
                                    {/* Co-applicant document */}
                                    <div className='!mt-4'>
                                        <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                            Co-applicant Document
                                        </h5>
                                        <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-6	gap-y-4'>
                                            <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                                                onClick={() => {
                                                    document.getElementById(`upload-copplicant-doc-${index}`).click();
                                                }}>
                                                <input type="file" className='d-none' id={`upload-copplicant-doc-${index}`} accept="application/pdf, image/*" onChange={(e) => handleCoapplicantDocumentUpload(e, '', index, '', coApplicantUploadedDocs, setCoapplicantDocs, 'other')} />
                                                <figure className='!table !mx-auto'>
                                                    <Uploadicon className='h-[64px] mt-2' />
                                                </figure>
                                                <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload More Documents </p>
                                                <p className='text-center !text-black text-sm font-medium inter'>More Documents </p>
                                            </div>
                                            {
                                                coApplicantUploadedDocs[index].map((doc, docIndex) => (
                                                    <>
                                                        <input type="file" className='d-none' id={`upload-coapplicant-${index}-${docIndex}`} accept="application/pdf, image/*" onChange={(e) => handleCoapplicantDocumentUpload(e, doc.fileName, index, docIndex, coApplicantUploadedDocs, setCoapplicantDocs, '')} />
                                                        {!doc?.url ? <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] cursor-pointer'
                                                            onClick={() => {
                                                                document.getElementById(`upload-coapplicant-${index}-${docIndex}`).click();
                                                            }}>
                                                            <figure className='!table !mx-auto'>
                                                                <Uploadicon className='h-[64px] mt-2' />
                                                            </figure>
                                                            <p className='!text-primary !mb-4 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'><Upload />Upload {doc.fileName} </p>
                                                            <p className='text-center !text-black text-sm font-medium inter'> {doc.fileName} </p>
                                                        </div> :
                                                            <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                                                <figure className='mb-0 !table !mx-auto relative group'>
                                                                    {['jpg', 'jpeg', 'png', 'webp'].includes(doc.extension) ?
                                                                        <img src={doc.url} alt="" className='h-[100px] w-[175px]' />
                                                                        : <img src={pdfimg} />}
                                                                    <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                                        <button
                                                                            type='button'
                                                                            className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                                            onClick={() => {
                                                                                document.getElementById(`upload-coapplicant-${index}-${docIndex}`).click();
                                                                            }}
                                                                        ><PluswhiteIcon />Replace</button>

                                                                        <button
                                                                            type='button'
                                                                            className={`${style.delbtn} inter border-0 bg-white`}
                                                                            onClick={() => {
                                                                                let newFiles = [...coApplicantUploadedDocs];

                                                                                if (newFiles[index][docIndex]?.docType === 'other') {
                                                                                    delete newFiles[index][docIndex]

                                                                                } else {
                                                                                    newFiles[index][docIndex].url = ''
                                                                                    newFiles[index][docIndex].type = ''
                                                                                    newFiles[index][docIndex].size = ''
                                                                                    newFiles[index][docIndex].extension = ''
                                                                                    newFiles[index][docIndex].file_type = ''
                                                                                }
                                                                                setCoapplicantDocs(newFiles);
                                                                            }}
                                                                        ><TrashIcon /></button>
                                                                    </div>
                                                                </figure>
                                                                <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'>
                                                                    {/* <FilePdf />  */}
                                                                    {doc.category}</p>
                                                                {!doc.category && <InputSelect
                                                                    // label='Source'
                                                                    placeholder='Select Category'
                                                                    inputclass='m-0'
                                                                    styles={customStyles}
                                                                    value={doc.category ? CustomerDocumentList.find(item => item.value === doc.category) : ''}
                                                                    options={CustomerDocumentList}
                                                                    onChange={(value) => {
                                                                        let newFiles = { ...coApplicantUploadedDocs }
                                                                        newFiles[index][docIndex].category = value.value
                                                                        setCoapplicantDocs(newFiles);
                                                                        customerDoc[index].category = value.value
                                                                    }}
                                                                />}
                                                                {doc.fileName === 'Aadhar Front' && <div>
                                                                    <InputText
                                                                        type="text"
                                                                        placeholder="Enter Aadhaar Number"
                                                                        inputclass='m-0 inter'
                                                                        onChange={(e) => {
                                                                            let co_applicant = [...coapplicant];
                                                                            co_applicant[index]['aadhaar_no'] = e.target.value
                                                                                .replace(/^0+/g, '')
                                                                                .replace(/[^0-9]/g, '');
                                                                            setCoapplicant(co_applicant)
                                                                        }}
                                                                        value={coapplicant[index]['aadhaar_no']}
                                                                    />
                                                                </div>}
                                                                {doc.fileName === 'PAN' && <div>
                                                                    <InputText
                                                                        type="text"
                                                                        placeholder="Enter PAN Number"
                                                                        inputclass='m-0 inter'
                                                                        onChange={(e) => {
                                                                            let co_applicant = [...coapplicant];
                                                                            co_applicant[index]['pan_no'] = e.target.value;
                                                                            setCoapplicant(co_applicant)
                                                                        }}
                                                                        value={coapplicant[index]['pan_no']}
                                                                    />
                                                                </div>}
                                                            </div>}
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))
                        }

                        <div className="!mt-3">
                            <button
                                type='button'
                                className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2 inter'
                                onClick={() => {
                                    setCoapplicantDocs([...coApplicantUploadedDocs, coapplicantDocs])
                                    setCoapplicant([...coapplicant, {}])
                                }}
                            >
                                <PlusPrimary /> Add Co-Applicant
                            </button>
                        </div>
                    </>}
                </div>
            </form>
            <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                <button type='button' className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] w-full h-[44px] text-center' onClick={() => setSteps('EOIPricing')}>Previous</button>
                <button type='submit' form='eoi-unit-additional' className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] w-full h-[44px] text-center'>Next</button>
            </div>
        </>
    )
}

export default AdditionalInfo