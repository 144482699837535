import React, { useEffect, useState } from 'react';
import { Button, Image } from "react-bootstrap";

import closeIcon from "../../../assets/icons/closeIntentImage.svg";
import checkIcon from "../../../assets/icons/checkIcon.svg";
import TooltipText from '../../Tooltip';
import SearchInput from '../../InputGroup/SearchInput';
import { getInitials } from '../../../helpers/helpers';
import InputSelect from '../../InputGroup/InputSelect';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/NavRight.svg';
import { getAllBrokerCompanyUsers } from '../../../services/private/borker.service';

export default function CPUserAccordian({
    value,
    setValue,
    selectionValues,
    accordionName,
    label,
    brokerCompanyList,
    setBrokerCompanyList,
    getCompanyName
}
) {

    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [viewMoreIndex, setViewMoreIndex] = useState([]);
    const [error, setError] = useState(false);

    const getUsers = async (uuid, index) => {
        const res = await getAllBrokerCompanyUsers(uuid);
        if (res.data.status === 200) {
            console.log(res.data.data, 'userss');
            // setUsers(res.data.data);
            const brokerIndex = brokerCompanyList.findIndex(i => i.value === uuid)
            if (brokerIndex > -1) {
                const list = [...brokerCompanyList]
                list[brokerIndex].users = res.data.data;
                setBrokerCompanyList(list);
                const companyList = [...selectedCompany]
                companyList[index].users = res.data.data;
                setSelectedCompany(companyList)
            }
        }
    }

    console.log(selectedCompany, 'selected Compnay')
    return (
        <>
            <div className="filter-card cursor-pointer">
                <div className="filter-header collapsed" data-toggle="collapse" data-target={"#collapse" + accordionName} aria-expanded="false" aria-controls={"collapse" + accordionName} id={"heading" + accordionName}>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <h2 className="fz16 black mb-0 fw-regular">{label} {value?.length > 0 && <span>{value?.length}</span>}</h2>
                        <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                        </svg></div>
                    </div>
                </div>
                <div id={"collapse" + accordionName} className="filter-body collapse" aria-labelledby={"heading" + accordionName} data-parent="#accordion">

                    <div className="px-20">
                        <div className="fix-filter">
                            {/* <ul className="select-filter">
                                {selectedFilters()}
                            </ul> */}
                            {value?.length > 0 ?
                                <button className="border-0 bg-tr clear-btn px-0" onClick={() => setValue([])}>Clear All</button>
                                : ""
                            }
                            <InputSelect
                                index={4}
                                name="user"
                                menuPlacement="bottom"
                                value={''}
                                options={brokerCompanyList}
                                onChange={(value) => {
                                    const index = selectedCompany.findIndex(i => i.value === value.value)
                                    if (index === -1) {
                                        setSelectedCompany([...selectedCompany, value])
                                        let brokerList = [...brokerCompanyList];
                                        let uIndex = brokerList.findIndex(i => i.value === value.value)
                                        brokerList.splice(uIndex, 1);
                                        setBrokerCompanyList(brokerList);
                                        getUsers(value.uuid, index)
                                    }
                                }}
                                placeholder="Select Company(s)"
                                label="Select Company(s)"
                                error={error && !selectedCompany ? 'Please Select Company' : ''}
                            // isMulti={userProfile?.mulit_user_assign && count > 1}
                            />
                            <div className="">
                                {
                                    selectedCompany.length > 0 && selectedCompany.map((item, index) => (
                                        <div className="mt-4 border p-4 rounded-lg" key={`${item.id}_${index}`}>
                                            <div className="flex justify-between items-start mb-3">
                                                <div>
                                                    <p className="m-0 text-base font-semibold">{item.name}</p>
                                                    <p className="m-0 text-xs text-black700 font-medium">Lead: {item.leader_name}</p>
                                                </div>
                                                <button onClick={() => {
                                                    let brokerList = [...brokerCompanyList];
                                                    brokerList.push(item);
                                                    setBrokerCompanyList(brokerList);
                                                    let brokerListCopy = [...selectedCompany];
                                                    brokerListCopy.splice(index, 1);
                                                    let selectedUserCopy = { ...selectedUser };
                                                    delete selectedUserCopy[index]
                                                    setSelectedCompany(brokerListCopy);
                                                    setSelectedUser({ ...selectedUserCopy })
                                                }}>
                                                    <CloseIcon className="h-5 w-5 text-black700" />
                                                </button>
                                            </div>
                                            {viewMoreIndex?.includes(index) && item.users?.length > 0 && item.users.map((user, userIndex) => (
                                                <div className="flex justify-between px-4 border-b pb-2 bg-blue-light rounded-lg">
                                                    <div className="py-2">
                                                        <p className="m-0 text-sm font-semibold">{user.name}</p>
                                                        <p className="m-0 text-xs text-black700 font-medium">{user?.email}</p>
                                                    </div>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedUser && selectedUser[index]?.includes(user.uuid)}
                                                        onChange={() => {

                                                            // Need to change this logic                                                        
                                                            if (Object.keys(selectedUser).length > 0) {
                                                                let userIds = [...selectedUser[index] || []]
                                                                const uIndex = userIds?.indexOf(user.uuid);
                                                                if (uIndex > -1) {
                                                                    let selectedUserCopy = { ...selectedUser };
                                                                    userIds.splice(uIndex, 1);
                                                                    selectedUserCopy[index] = userIds
                                                                    setSelectedUser({ ...selectedUserCopy })
                                                                } else {
                                                                    let selectedUserCopy = { ...selectedUser };
                                                                    if (!selectedUserCopy[index]) {
                                                                        selectedUserCopy[index] = []
                                                                    }
                                                                    selectedUserCopy[index].push(user.uuid)
                                                                    setSelectedUser(selectedUserCopy)
                                                                }
                                                            } else {
                                                                let selectedUserCopy = { ...selectedUser };
                                                                if (!selectedUserCopy[index]) {
                                                                    selectedUserCopy[index] = []
                                                                }
                                                                selectedUserCopy[index].push(user.uuid);
                                                                setSelectedUser(selectedUserCopy)
                                                            }
                                                        }}
                                                        name=""
                                                        id="" />
                                                </div>
                                            ))
                                            }
                                            <div className="flex justify-end mt-2">
                                                <button
                                                    className="flex items-center"
                                                    onClick={() => {
                                                        if (viewMoreIndex?.includes(index)) {
                                                            let indexes = [...viewMoreIndex];
                                                            indexes.splice(index, 1);
                                                            setViewMoreIndex(indexes)
                                                        } else {
                                                            setViewMoreIndex([...viewMoreIndex, index])
                                                        }
                                                    }}>
                                                    {viewMoreIndex?.includes(index) ? 'Hide' : 'View'} Users
                                                    <RightIcon className={`ml-1 h-5 w-5 ${viewMoreIndex?.includes(index) ? '-rotate-90' : 'rotate-90'}`} />
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}