import React, { useContext, useEffect, useState } from 'react'
import BasicModal from '../../../components/Modals/BasicModal'
import InputSelect from '../../../components/InputGroup/InputSelect'
import InputTextarea from '../../../components/InputGroup/InputTextarea'
import StatusDropDown from '../../../components/Custom Table/TableColumns/StatusDropDown'
import { getSelectedValue } from '../../../utils/selectedOptionUtils'
import Consumer from '../../../helpers/context';
import { getDeadReasons } from '../../../services/public/public.service'
import { EOICancelReasonList } from '../../../utils/constants'
import { changeBookingStatus, changeEoiStatus, getBookingDoneDetails, getEOIDetails } from '../../../services/private/inventory.service'
import InputText from '../../../components/InputGroup/InputText'
import DateTimePicker from '../../../components/InputGroup/DateTime'
import DatePicker from 'react-datepicker';
import InputRupees from '../../../components/InputGroup/InputRupees'
import { toast } from 'react-toastify'

const CancelBooking = ({ show, closeModal, data, ModalType, updateData }) => {

    const [reason, setReason] = useState('');
    const [error, setError] = useState(false);
    const [selectedProject, setSelectedProject] = useState('');
    const [refundAmount, setRefundAmount] = useState('');
    const [refundDate, setRefundDate] = useState('');
    const [removeTagging, setRemoveTagging] = useState(false);
    const [reasonList, setReasonList] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const { companyConfig } = useContext(Consumer);

    const getEOILeadDetails = async (uuid) => {
        const res = await getEOIDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedProject(res.data.data?.project)
            }
        }
    }

    const getBookingDetails = async (uuid) => {
        const res = await getBookingDoneDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedProject(res.data.data?.project)
            }
        }
    }

    useEffect(() => {
        if (ModalType === 'Token' && (data.eoi_uuid || data.booking?.uuid)) {
            getEOILeadDetails(data.eoi_uuid || data.booking?.uuid);
        }
        if (ModalType === 'Booking' && (data.booking_uuid || data.booking?.uuid)) {
            getBookingDetails(data.booking_uuid || data.booking?.uuid, 'booking');
        }

    }, [data]);

    // useEffect(() => {
    //     if (Object.keys(companyConfig).length > 0) {
    //         // setIntentStatuses(companyConfig.Intent)
    //         const mappedStatuses = companyConfig.Status.filter(item => (item.label !== 'Booking Completed' && item.label !== 'EOI Completed'))
    //         setStatuses(mappedStatuses)

    //     }
    // }, [companyConfig]);

    // const getDeadReasonsStatus = () => {
    //     getDeadReasons().then(res => {
    //         if (res.status === 200) {
    //             const reasonsData = res.data.data.map((data) => {
    //                 return {
    //                     label: data.reason,
    //                     value: data.reason
    //                 }
    //             })
    //             setReasonList(reasonsData);
    //         }
    //     })
    // }

    // useEffect(() => {
    //     getDeadReasonsStatus()
    // }, []);

    const handleSubmit = async () => {
        if (!refundDate || !refundAmount || !reason) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        const payload = {
            reason: reason,
            projectId: selectedProject?.id,
            tentativeRefundDate: refundDate,
            refundedAmount: refundAmount,
            removeTagging: removeTagging,
            status: 'cancelled'
        }
        let res = {}

        if (ModalType === 'Booking') {
            res = await changeBookingStatus(data.booking_uuid || data.booking?.uuid, payload)
        } else {
            res = await changeEoiStatus(data.eoi_uuid || data.booking?.uuid, payload)
        }

        if (res.data.status === 200) {
            toast.success(res.data.message);
            closeModal();
        }
    }

    return (
        <BasicModal show={show} closeModal={closeModal}>
            <div className='p-3'>
                <p className='m-0 text-xl font-semibold text-textblack inter capitalize'>Cancel {ModalType}?</p>
                {/* <p className='m-0 text-sm text-black700'>
                    Please select the state before canceling the {ModalType} from the below list.
                </p> */}
                <div className='grid grid-cols-1 gap-3 mt-3'>
                    <InputSelect
                        label={'Reason'}
                        placeholder={'Select Reason'}
                        value={reason ? EOICancelReasonList.find(item => item.value === reason) : ''}
                        onChange={(value) => {
                            setReason(value.value);
                        }}
                        options={EOICancelReasonList}
                        required={true}
                        error={error && !reason ? 'Please Select Reason' : ''}
                    />
                    <InputText
                        label={'Project'}
                        value={selectedProject.name}
                        inputclass={'!mt-0'}
                    />
                    <div className={`form-group mt-0 position-relative`}>
                        <DateTimePicker
                            label='Tentative Refund Date'
                            inputclass='mt-0'
                            placeholder='Select Date'
                            value={refundDate}
                            onValueChange={(date) => {
                                setRefundDate(date)
                            }}
                            showTime={false}
                            required={true}
                            error={error && !reason ? 'Please Select Refund Date' : ''}
                        />
                    </div>
                    <InputRupees
                        type="text"
                        label="Refunded Amount"
                        placeholder="Enter Refunded Amount"
                        name="name"
                        inputclass='m-0 inter'
                        value={refundAmount}
                        onChange={(value) => setRefundAmount(value)}
                        handleBlur={() => { }}
                        error={error && !reason ? 'Please Enter Refunded Amount' : ''}
                        required={true}
                    // error={errors?.booking_amount ? errors?.booking_amount.message : ''}
                    />
                    <div className=''>
                        <label htmlFor="">Remove Tagging?</label>
                        <div className='flex items-center gap-6'>
                            <div className='flex items-center gap-2'>
                                <input type="radio" name="tagging" id="yes_tagging" value={removeTagging} checked={removeTagging} onChange={() => setRemoveTagging(true)} />
                                <label htmlFor="yes_tagging" className='mb-0 font-medium'>Yes</label>
                            </div>
                            <div className='flex items-center gap-2'>
                                <input type="radio" name="tagging" id="no_tagging" value={removeTagging} checked={!removeTagging} onChange={() => setRemoveTagging(false)} />
                                <label htmlFor="no_tagging" className='mb-0 font-medium'>No</label>
                            </div>
                        </div>
                    </div>
                    {/* <InputTextarea
                        type="text"
                        label="Comments"
                        placeholder="Enter Comments"
                        name="name"
                        inputclass='m-0 inter'
                        row='2'
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        required={true}
                        error={error && !remarks ? 'Please enter comments' : ''}
                    /> */}
                    {/* <div className=''>
                        <label htmlFor="">Status</label>
                        <StatusDropDown
                            value={getSelectedValue((leadStatus || data.status), statuses)}
                            options={statuses}
                            id={data.id}
                            reloadData={() => { }}
                            updateLead={(value) => {
                                setLeadStatus(value)
                            }}
                            leadName={data.name}
                            intentOptions={intentStatuses}
                            data={data}
                            width={'full'}
                        />
                    </div> */}
                    <div className="grid grid-cols-2 gap-2">
                        <button className="cancel border rounded-lg py-2.5 text-base font-medium " onClick={closeModal}>
                            Cancel
                        </button>
                        <button className='del-btn border py-2.5 rounded-lg text-base font-medium' onClick={() => {
                            // if (!remarks) {
                            //     setError(true)
                            //     return;
                            // } else {
                            //     setError(false);
                            // }
                            // const payload = {
                            //     reason: reason,
                            //     remarks: remarks,
                            //     status: 'cancelled'
                            // }
                            handleSubmit()
                        }}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </BasicModal>
    )
}

export default CancelBooking;