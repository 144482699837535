import React, { useContext, useState } from 'react'
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useEffect } from 'react'
import AnalyticsSkeleton from './analytics-skeleton';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downoadkit.svg';
import { CSVLink } from 'react-csv';
import Consumer from '../../helpers/context';

const SitevisiteTable = ({ projectLeadsWithStatus, loading, companyConfig, fromDate, toDate, selectedDate }) => {
    const [total, setTotal] = useState({
        sv_completed_count: 0,
        sv_count: 0
    })
    const [tempData, setTempData] = useState([]);

    useEffect(() => {
        let totalCount = {
            sv_completed_count: 0,
            sv_count: 0
        };

        if (Array.isArray(projectLeadsWithStatus) && projectLeadsWithStatus.length > 0) {
            projectLeadsWithStatus.forEach(lead => {
                totalCount.sv_completed_count += +(lead.sv_completed_count || 0)
                totalCount.sv_count += +(lead.sv_count || 0)
            })
        }
        setTotal(totalCount);
    }, [projectLeadsWithStatus])

    const exportData = () => {
        let temp = projectLeadsWithStatus.map((lead, index) => {
            return {
                "Projects": lead.project || '',
                "Site Visit Scheduled": lead.sv_count || '',
                "Site Visit Completed": lead.sv_completed_count || '',
            }
        })
        setTempData(temp);

    };

    const handleAddFilter = (lead, status) => {
        let payload = [];
        const proj = companyConfig.Project.find(p => p.label === lead.project);
        const usersSatus = companyConfig.Status.find(s => s.label === status);
        if (selectedDate !== 'allTime') {
            payload.push({
                'Created Date': {
                    From: fromDate,
                    To: toDate
                }
            })
        }
        payload.push({ Project: [proj.value] }, { Status: [usersSatus.value] })
        localStorage.setItem('filter', JSON.stringify(payload));
        const link = document.createElement("a");
        link.href = '/';
        link.target = '_blank'
        link.click();
        // history.push('/');
    }

    return (
        <div className="table-card project-anlatic d-flex flex-fill flex-column">
            <div className="table-header d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                    <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>Sitevisit Analysis</h2>
                    <div className="info-alert">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" >
                                    <div className='info-msg'>
                                        {/* <h4>Total Leads</h4> */}
                                        <p>Project wise breakup of unique site visits scheduled & completed for leads for selected date range.</p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <Button className='bg-tr border-0' bsstyle="default">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
                                    <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                    <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                </svg>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                {/* <button className='flex items-center border rounded-lg px-3 py-2'>
                    <DownloadIcon className='mr-1 h-5 w-5' /> CSV
                </button> */}
                <CSVLink
                    className={` flex items-center !text-black hover:!text-black hover:bg-[#F6F6F6] border rounded px-3 py-1.5 text-sm gap-x-1`}
                    data={tempData}
                    onClick={exportData}
                    filename={'site_visit_analysis.csv'}>
                    CSV <DownloadIcon />
                </CSVLink>
            </div>
            {projectLeadsWithStatus?.length > 0 && <div className="scroll-table">
                <table className="table table-striped table-bg">
                    <thead>
                        <tr>
                            <th style={{ width: '140px' }}>PROJECTS</th>
                            <th style={{ width: '140px' }}>SV Scheduled</th>
                            <th style={{ width: '140px' }}>SV Completed</th>
                        </tr>
                    </thead>
                    {!loading &&
                        <>
                            <tbody>
                                {projectLeadsWithStatus.map((lead, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ width: '140px' }}> {lead.project} </td>
                                            <td style={{ width: '140px' }} onClick={() => handleAddFilter(lead, 'SV Scheduled')} className={`${lead?.sv_count ? 'cursor-pointer' : ''}`}>{lead.sv_count || '-'}</td>
                                            <td style={{ width: '140px' }} onClick={() => handleAddFilter(lead, 'SV Completed')} className={`${lead?.sv_completed_count ? 'cursor-pointer' : ''}`}>{lead.sv_completed_count || '-'}</td>
                                            {/* <td style={{ width: '160px' }}>
                                        {(lead.sv_completed_count || lead.sv_count) ? <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="button-tooltip" >
                                                    <div className='info-msg' style={{ width: '100px' }}>
                                                        <p>LEADS</p>
                                                        <p className="d-flex justify-content-between mt-2" >
                                                            <span>SV Completed</span>
                                                            <span>{lead.sv_completed_count || '-'}</span>
                                                        </p>
                                                        <p className="d-flex justify-content-between mt-2">
                                                            <span>SV Scheduled</span>
                                                            <span>{lead.sv_count || '-'}</span>
                                                        </p>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <span >{`${lead.sv_completed_count || '-'} / ${parseInt(lead.sv_count || 0) + parseInt(lead.sv_completed_count || 0) || '-'}`}</span>
                                        </OverlayTrigger> : '-'}
                                    </td> */}
                                        </tr>)
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{ width: '140px' }}>Total</td>
                                    <td style={{ width: '140px' }}> {total.sv_count}</td>
                                    <td style={{ width: '140px' }}> {total.sv_completed_count}</td>

                                    {/* <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip" >
                                            <div className='info-msg' style={{ width: '100px' }}>
                                                <p>Leads</p>
                                                <p className="d-flex justify-content-between mt-2" >
                                                    <span>SV Completed</span>
                                                    <span>{total.sv_completed_count || '-'}</span>
                                                </p>
                                                <p className="d-flex justify-content-between mt-2">
                                                    <span>SV Scheduled</span>
                                                    <span>{total.sv_count || '-'}</span>
                                                </p>
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <span >{`${total.sv_completed_count} / ${total.sv_count}`}</span>
                                </OverlayTrigger> */}
                                </tr>
                            </tfoot>
                        </>
                    }
                    {loading &&
                        <tbody className="bg-white p-4">
                            {Array(7).fill().map((data, index) => {
                                return (
                                    <tr key={`site_${index}`}>
                                        <td style={{ width: "140px" }}><Skeleton count={1} height="10px" width="100%" /></td>
                                        <td style={{ width: "140px" }}><Skeleton count={1} height="10px" width="100%" /></td>
                                        <td style={{ width: "140px" }}><Skeleton count={1} height="10px" width="100%" /></td>
                                    </tr>
                                )
                            })}
                        </tbody>}
                </table>
            </div>}
            {(!projectLeadsWithStatus || projectLeadsWithStatus?.totalLeads === 0) && <div className='record-notfound text-center'>
                <figure className='d-table mx-auto'>
                    <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                </figure>
                <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
            </div>}
            {/* {loading &&
                <div className="bg-white p-4">
                    {Array(7).fill().map((data, index) => {
                        return (
                            // <AnalyticsSkeleton key={String(index)} />
                            <Row className="mt-3 d-flex align-items-center skelton-area" key={String(index)}>
                                <Col>
                                    <Skeleton count={1} height="10px" width="100%" />
                                </Col>

                                <Col >
                                    <Skeleton count={1} height="10px" width="100%" />
                                </Col>
                            </Row>
                        )
                    })}
                </div>} */}
        </div>
    )
}

export default SitevisiteTable