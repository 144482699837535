import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TableSkeleton from '../Skeleton/TableSkeletion';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downoadkit.svg';
import { CSVLink } from 'react-csv';
import Consumer from '../../helpers/context';

const EmployeePipelineTable = ({
    assignedToLeads,
    loading,
    getAllAssignedToLeads,
    getAllEmployeeEventAnalysis,
    leadButtonActive,
    companyConfig,
    fromDate,
    toDate,
    selectedDate
}) => {
    const { result, masterCount } = assignedToLeads;
    const [tempData, setTempData] = useState([]);

    useEffect(() => {
        if (result?.length > 0) {
            const sortedData = result?.sort((a, b) => parseFloat(b.totalLeads) - parseFloat(a.totalLeads));
        }
    }, [result])

    const exportData = () => {
        let temp = result.map((lead, index) => {
            return {
                "Users": lead.user_name || '',
                "New Leads": lead.newLead || '',
                "Not Connected": lead.notConnected || '',
                "In Progress": lead.inProgress || '',
                "Site Visit Scheduled": lead.svScheduled || '',
                "Site Visit Completed": lead.svCompleted || '',
                "Token": lead.eoiCompleted || '',
                'Bookings': lead.bookingCompleted || '',
                'Dump': lead.deadLead || '',
                'Total Assigned': lead.totalLeads || '',
            }
        })
        setTempData(temp);

    };

    // console.log(companyConfig)
    const handleAddFilter = (status) => {
        let payload = [];
        const usersSatus = companyConfig.Status.find(s => s.label === status);

        if (selectedDate !== 'allTime') {
            payload.push({
                'Created Date': {
                    From: fromDate,
                    To: toDate
                }
            })
        }
        payload.push({
            Status: [usersSatus.value]
        })
        localStorage.setItem('filter', JSON.stringify(payload));
        const link = document.createElement("a");
        link.href = '/';
        link.target = '_blank'
        link.click();
        // history.push('/');
    }

    return (
        <div className="table-card d-flex flex-fill flex-column userpipeline">
            <div className="table-header d-flex align-items-center justify-content-between">
                <div className='d-flex align-items-center'>
                    <h2 className='fz20 black-dark-800 fw-po-medium mb-0'>Users Pipeline Analysis</h2>
                    <div className="info-alert">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" >
                                    <div className='info-msg'>
                                        {/* <h4>Total Leads</h4> */}
                                        <p>User wise breakup of leads either by lead creation or by actual activity for selected date range.</p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <Button className='bg-tr border-0' bsstyle="default">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
                                    <path d="M8.66601 10.6665V7.33317C8.66601 6.96498 8.36754 6.6665 7.99935 6.6665C7.63116 6.6665 7.33268 6.96498 7.33268 7.33317V10.6665C7.33268 11.0347 7.63116 11.3332 7.99935 11.3332C8.36754 11.3332 8.66601 11.0347 8.66601 10.6665Z" fill="#828282" />
                                    <path d="M7.33268 5.33317C7.33268 4.96498 7.63116 4.6665 7.99935 4.6665C8.36754 4.6665 8.66601 4.96498 8.66601 5.33317C8.66601 5.70136 8.36754 5.99984 7.99935 5.99984C7.63116 5.99984 7.33268 5.70136 7.33268 5.33317Z" fill="#828282" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.33268 7.99984C1.33268 4.31794 4.31745 1.33317 7.99935 1.33317C11.6812 1.33317 14.666 4.31794 14.666 7.99984C14.666 11.6817 11.6812 14.6665 7.99935 14.6665C4.31745 14.6665 1.33268 11.6817 1.33268 7.99984ZM13.3327 7.99984C13.3327 5.05432 10.9449 2.6665 7.99935 2.6665C5.05383 2.6665 2.66601 5.05432 2.66601 7.99984C2.66601 10.9454 5.05383 13.3332 7.99935 13.3332C10.9449 13.3332 13.3327 10.9454 13.3327 7.99984Z" fill="#828282" />
                                </svg>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div>
                    <div className="toggle-group info-alert">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" >
                                    <div className='info-msg'>
                                        {/* <h4>Total Leads</h4> */}
                                        <p>User wise breakup of total leads added in platform for selected date range into their current status.</p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <button className={`${!leadButtonActive ? 'active' : ''}`} onClick={() => {
                                getAllAssignedToLeads();
                            }}>By Lead Creation</button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" >
                                    <div className='info-msg'>
                                        {/* <h4>Total Leads</h4> */}
                                        <p>User wise breakup of total status marked on leads for selected date range irrespective of the lead creation date.</p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <button className={`${leadButtonActive ? 'active' : ''}`} onClick={() => {
                                getAllEmployeeEventAnalysis();
                            }}>By Actual Activity</button>
                        </OverlayTrigger>
                        {/* <button className='flex items-center border rounded-lg px-3 py-2'>
                            <DownloadIcon className='mr-1 h-5 w-5' /> CSV
                        </button> */}
                        <CSVLink
                            className={`!ml-3 flex items-center !text-black hover:!text-black hover:bg-[#F6F6F6] border rounded px-3 py-1.5 text-sm gap-x-1 `}
                            data={tempData}
                            onClick={exportData}
                            filename={'user_pipeline_analysis.csv'}>
                            CSV <DownloadIcon />
                        </CSVLink>
                    </div>
                </div>
            </div>
            {result?.length > 0 && <div className="scroll-table">
                <table className="table table-striped table-bg">
                    <thead>
                        <tr>
                            <th style={{ width: '210px' }}>USERS</th>
                            <th style={{ width: '90px ' }}>New</th>
                            <th style={{ width: '163px ' }}>not connected</th>
                            <th style={{ width: '135px ' }}>in progress</th>
                            <th style={{ width: '146px ' }}>SV Scheduled</th>
                            <th style={{ width: '155px ' }}>SV Completed</th>
                            <th style={{ width: '155px ' }}>Final Negotiation</th>
                            <th style={{ width: '90px ' }}>Token</th>
                            <th style={{ width: '124px ' }}>BOOKINGS</th>
                            <th style={{ width: '90px ' }}>Dead</th>
                            <th style={{ width: '170px ' }}>TOTAL Assigned</th>
                        </tr>
                    </thead>
                    {!loading &&
                        <tbody>

                            {result?.map((lead, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{ width: '210px' }}>{lead.user_name || '-'}</td>
                                        <td
                                            style={{ width: '90px ' }}
                                        // className={`${lead?.newLead ? 'cursor-pointer' : ''}`} 
                                        // onClick={() => handleAddFilter('New')} 
                                        >
                                            {lead.newLead || '-'}
                                        </td>
                                        <td style={{ width: '163px' }} className={`${lead?.notConnected ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter('Not Connected')}>{lead.notConnected || '-'}</td>
                                        <td style={{ width: '135px' }} className={`${lead?.inProgress ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter('In Progress')}>{lead.inProgress || '-'}</td>
                                        <td style={{ width: '146px' }} className={`${lead?.svScheduled ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter('SV Scheduled')}> {lead.svScheduled || '-'} </td>
                                        <td style={{ width: '155px' }} className={`${lead?.svCompleted ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter('SV Completed')}>{lead.svCompleted || '-'}</td>
                                        <td style={{ width: '155px' }} className={`${lead?.svCompleted ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter('Final Negotiation')}>{lead.finalNegotiation || '-'}</td>
                                        <td style={{ width: '90px' }} className={`${lead?.eoiCompleted ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter('EOI Completed')}>{lead.eoiCompleted || '-'}</td>
                                        <td style={{ width: '124px' }} className={`${lead?.bookingCompleted ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter('Booking Completed')}>{lead.bookingCompleted || '-'}</td>
                                        <td style={{ width: '90px' }} className={`${lead?.deadLead ? 'cursor-pointer' : ''}`} onClick={() => handleAddFilter('Dead Lead')}>{lead.deadLead || '-'}</td>
                                        <td style={{ width: '170px' }} >{lead.totalLeads}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    }
                    {loading &&
                        <tbody>

                            {Array(8).fill().map((data, index) => {
                                return (
                                    <TableSkeleton key={String(index)} />
                                )
                            })}
                        </tbody>
                    }
                    {/* <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{masterCount?.totalLeads}</td>
                            <td>{masterCount?.newLead}</td>
                            <td>{masterCount?.notConnected}</td>
                            <td>{masterCount?.inProgress}</td>
                            <td>{masterCount?.svScheduled}</td>
                            <td>{masterCount?.svCompleted}</td>
                            <td>{masterCount?.eoiCompleted}</td>
                            <td>{masterCount?.bookingCompleted}</td>
                            <td>{masterCount?.deadLead}</td>
                        </tr>
                    </tfoot> */}
                </table>
            </div>}
            {(!assignedToLeads || assignedToLeads?.totalLeads === 0) && <div className='record-notfound text-center'>
                <figure className='d-table mx-auto'>
                    <img src={`${process.env.PUBLIC_URL}/anl.png`} />
                </figure>
                <h3 className='black fz16 fw-po-medium mb4'>No Data Found</h3>
                {/* <p className='fz14 mb-0 ro black-dark-600'>You have no data regarding the table.</p> */}
            </div>}
            {/* {loading &&
                <div className="bg-white">
                    <div className='scroll-table'>
                        <table className='table'>
                            <tbody>

                                {Array(8).fill().map((data, index) => {
                                    return (
                                        <TableSkeleton key={String(index)} />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>} */}
        </div>
    )
}

export default EmployeePipelineTable